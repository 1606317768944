import oucy from '@/util/oucyUtil.js'
// 分销管理
const distribution = {
	/**
	 * 
获取分销下级列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryChild: (params) => {
        return oucy.postRequest('/client/user/distribution/queryChild', params)
    }
}

export default distribution

