import oucy from '@/util/oucyUtil.js'
// 公告信息
const notice = {
    /**获取公告列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getAllNotice: (params) => {
        return oucy.postRequest('/client/auth/adnotice/getAllNotice', params)
    },
    /**根据公告编码获取公告详细信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getNoticeById: (params) => {
        return oucy.postRequest('/client/auth/adnotice/getNoticeById', params)
    },





    // 进货车报价清单管理
    // 
    // 客户
    /**添加客户信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createContactBuyer: (params) => {
        return oucy.postRequest('/client/product/notice/createContactBuyer', params)
    },
    /**删除客户信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    delContactBuyer: (params) => {
        return oucy.postRequest('/client/product/notice/delContactBuyer', params)
    },
    /**更新客户信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateContactBuyer: (params) => {
        return oucy.postRequest('/client/product/notice/updateContactBuyer', params)
    },
    /**查询客户信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getAllContactBuyer: (params) => {
        return oucy.postRequest('/client/product/notice/getAllContactBuyer', params)
    },


    // 供货方
    /**添加客供货方
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createContactSeller: (params) => {
        return oucy.postRequest('/client/product/notice/createContactSeller', params)
    },

    /**删除供货方
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    delContactSeller: (params) => {
        return oucy.postRequest('/client/product/notice/delContactSeller', params)
    },

    /**修改供货方信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateContactSeller: (params) => {
        return oucy.postRequest('/client/product/notice/updateContactSeller', params)
    },
    /**查询供货方信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getAllContactSeller: (params) => {
        return oucy.postRequest('/client/product/notice/getAllContactSeller', params)
    },

    
    /**报价清单导出Excel
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    exportExcel: (params) => {
        return oucy.postRequest('/client/product/notice/exportExcel', params)
    },


}

export default notice