<!-- 刷新 置顶 -->
<template>
    <div>
        <el-dialog width="520px" :title="type=='top'?'请选择置顶时限':'确认刷新'" :visible.sync="distributionVisible">
            <!-- 置顶 -->
            <template v-if="type=='top'">
                <template v-if="topList && topList.length">
                    <div>
                        <div class="top df-jc-s-b m-b-20">
                            <div>当前拥有积分<span class="integral">{{signvalue}}</span>积分</div>
                            <div class="c-2090 pointer" @click="gogtIntegral">获取积分<i class="el-icon-arrow-right"></i></div>
                        </div>
                        <div class="content">
                            <div v-for="(v,i) of topList" :key="i" class="df-jc-s-b df-ai-c box" :class="[i==select?'select':'',v.integral>signvalue?'disabled':'pointer']" @click="setSelect(v,i)">
                                <div class="p-l-20"><span class="c-2090 m-r-5">{{v.days}}</span>天置顶时间<span class="integral m-l-20">{{v.integral}}</span>积分<span class="" v-if="v.integral>signvalue"><img src="@/assets/icon/warning.png" alt="" class="warning_icon m-l-5">(当前积分不足，不可选择)</span></div>
                                <div><img src="@/assets/icon/askBuyTop.png" alt="" class="askBuyTopImg"></div>
                                <img src="@/assets/icon/corner.png" alt="" class="corner">
                            </div>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="distributionVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submit" :disabled="!select">确 定</el-button>
                    </span>
                </template>
                <div class="notData" v-else>很抱歉，系统暂未开放置顶时间兑换选项</div>
            </template>
            <template v-else>
                <div>
                    <div class="top df-jc-s-b m-b-20">
                        <div>是否确认刷新，刷新仅会更新发布时间为当前时间</div>
                        <div class="c-2090 pointer" @click="gogtIntegral">获取积分<i class="el-icon-arrow-right"></i></div>
                    </div>
                    <div class="df m-t-10">
                        <div class="c-2">当前拥有积分</div>
                        <div class="m-l-15"><span class="integral">{{signvalue}}</span>积分<span class="cf00 f12" v-if="signvalue<refreshSetting.integralPercent">(当前积分不足)</span></div>
                    </div>
                    <div class="df m-t-10">
                        <div class="c-2">刷新所需积分</div>
                        <div class="m-l-15"><span class="integral">{{refreshSetting.integralPercent}}</span>积分/次</div>
                    </div>
                    <div class="df m-t-10">
                        <div class="c-2">今日刷新次数</div>
                        <div class="df m-l-15">
                            <div>总次数<span class="integral">{{refreshSetting.reflashTimeInDay}}</span>次</div>
                            <div class="m-l-40">已刷新<span class="integral">{{countIntegral}}</span>次</div>
                            <div class="m-l-40" v-if="signvalue>=refreshSetting.integralPercent">还能刷<span class="integral">{{refreshSetting.reflashTimeInDay-countIntegral||0}}</span>次</div>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="distributionVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit" :disabled="!signvalue>=refreshSetting.integralPercent || refreshSetting.reflashTimeInDay<countIntegral">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { buyAsk, sign } from "@/service/index"
export default {
    name: "refreshTopAskBuy",
    props: {
        data: {
            type: Object,
            default: function() {
                return {}
            }
        },
        isShowStock: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            distributionVisible: false,
            select: null,
            signvalue: 0,
            countIntegral: 0,
            type: 'refresh', //refresh top
            topList: [],
            refreshSetting: {},
            buyAsk: {},
        }
    },
    mounted() {
    },
    methods: {
        open({v,type}) {
            this.querySignValue()
            this.type = type
            if (this.type == 'refresh') {
                this.queryBuyRefreshSetting()
                this.countIntegralHis()
            } else {
                this.queryBuyTopSetting()
            }
            this.buyAsk = v
            this.distributionVisible = true
        },
        close() {
            this.distributionVisible = false
        },
        gogtIntegral() {
            this.$message('开发中，敬请期待...')
        },
        submit() {
            if (this.type == 'refresh') {
                this.refreshBuyAsk()
            } else {
                this.buyAskTopping()
            }

        },
        setSelect(v, i) {
            this.select = i
        },
        // 查询求购置顶积分配置
        queryBuyTopSetting() {
            buyAsk.queryBuyTopSetting().then(res => {
                this.topList = res || []
            })
        },
        // 查询刷新求购积分配置
        queryBuyRefreshSetting() {
            buyAsk.queryBuyRefreshSetting().then(res => {
                this.refreshSetting = res
            })
        },
        // 查询当前用户剩余积分
        querySignValue() {
            sign.querySignValue({ signTime: new Date().format('yyyy-MM-dd hh:mm:ss') }).then(res => {
                this.signvalue = res
            })
        },
        // 求购置顶 /取消置顶
        buyAskTopping() {
            let days = this.topList[this.select].days
            buyAsk.buyAskTopping({ 'id': this.buyAsk.id, 'days': days }).then(res => {
                this.close()
                this.$message('设置成功')
                this.$emit('submit', { v: this.buyAsk, type: this.type })
            })
        },
        // 刷新求购
        refreshBuyAsk() {
            buyAsk.refreshBuyAsk({ 'id': this.buyAsk.id }).then(res => {
                this.close()
                this.$message('刷新成功')
                this.buyAsk.askUpdatetime = new Date().format('yyyy-MM-dd hh:mm:ss')
                this.$emit('submit', { v: this.buyAsk, type: this.type })
            })
        },
        // 查询用户今日刷新次数
        countIntegralHis() {
            sign.countIntegralHis({}).then(res => {
                this.countIntegral=res
            })
        },

    },
}
</script>
<style scoped lang="less">
.content {
    max-height: 400px;
    overflow: hidden;
    border: 1px solid #fff;
    padding-right: 10px;
    overflow: auto;

    &:hover {}

    .select {
        border: 1px solid #2090ff;
    }
}

.c-2090 {
    color: #2090FF;
}

.integral {
    color: #ea7735;
}

.box {
    background: #fafafa;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;

    // cursor: pointer;
    &.disabled {
        cursor: not-allowed;

        .c-2090,
        .integral {
            color: #606266
        }
    }
}

.corner {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
    width: 28px;
    height: 28px;
    display: none;
}

.select {
    .corner {
        display: inline-block;

    }
}

.warning_icon {
    width: 12px;
}

.askBuyTopImg {
    width: 68px;
}

.notData {
    color: #aaafb7;
    font-size: 14px;
    text-align: center;
    padding: 60px 0;
}
.cf00{
    color: #f00;
}
</style>