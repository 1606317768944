import oucy from "@/util/oucyUtil.js";
// 企业名片分类管理
const cardEnterprise = {
  /**
   * 查询列表
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
  getCardEnterpriseList: (params) => {
    return oucy.postRequest(
      "/client/enterprise/enterpriseinfo/queryEnterpriseCardPage",
      params
    );
  },
  /**
   * 企业详情
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
  getCardEnterpriseDetail: (params) => {
    return oucy.postRequest(
      "/client/card/cardenterprise/getCardEnterpriseDetail",
      params
    );
  },
  /**
   * 发布企业名片
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
  createCardEnterprise: (params) => {
    return oucy.postRequest(
      "/client/card/cardenterprise/createCardEnterprise",
      params
    );
  },
  /**
   * 修改企业名片
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
  updateCardEnterprise: (params) => {
    return oucy.postRequest(
      "/client/card/cardenterprise/updateCardEnterprise",
      params
    );
  },
  /**
   * 增加企业名片转发数
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
  addCardEnterpriseTransmit: (params) => {
    return oucy.postRequest(
      "/client/card/cardenterprise/addCardEnterpriseTransmit",
      params
    );
  },
};

export default cardEnterprise;
