<!-- 评论框 -->
<template>
    <div>
        <div class="commentBox m-t-30" v-for="(v,i) of list" :key="i">
            <div class="left">
                <img :src="$oucy.ossUrl + v.ltUserDetailFull.detailAvatar" class="pic" v-if="v.ltUserDetailFull">
            </div>
            <div class="right">
                <div class="name">{{v.ltUserDetailFull&&v.ltUserDetailFull.detailNickname}}</div>
                <div class="time">{{v.commentCreatetime}}</div>
                <div class="comment">{{v.commentContent}}</div>
                <div v-if="v.ltCardEmployeeCommentAttachs" class="m-t-10">
                    <el-image 
                    v-for="(vv,ii) of v.ltCardEmployeeCommentAttachs"
                    :key="ii"
                      style="width: 100px; height: 100px;margin-right:10px"
                      :src="$oucy.ossUrl+ vv.cardEmployeeCommentAttachUrl"
                      :preview-src-list="getSrcList(v.ltCardEmployeeCommentAttachs)">
                    </el-image>

                </div>
                <div class="btns df  m-t-5" v-if="isShowInput">
                    <div @click="setParentCommentId(v)" class="pointer">
                        <span class="c-2 f14" v-if="parentCommentId==v.id"><i class="el-icon-chat-dot-round m-r-5"></i>取消回复</span>
                        <span class="c-2 f14" v-else><i class="el-icon-chat-dot-round m-r-5"></i>回复</span>
                    </div>
                    <div @click="isShowComment=!isShowComment" class="pointer" v-if="0">
                        <span class="c-2 f14 m-l-25" v-show="isShowComment">收起回复<i class="el-icon-arrow-right m-r-5"></i></span>
                        <span class="c-2 f14 m-l-25" v-show="!isShowComment">2条回复<i class="el-icon-arrow-down m-r-5"></i></span>
                    </div>
                </div>
                <inputBox v-if="parentCommentId==v.id" :placeholder="placeholder" @callback="callback" :hasShowUploadImage="false" class="m-t-10"></inputBox>
                <!-- <comment v-if="v.ltCardPersonReplys" :list="v.ltCardPersonReplys"></comment> -->
                <div class="commentBox commentBox2 m-t-20" v-for="(vv,ii) of v.ltCardEmployeeReplys" :key="ii">
                    <span class="triangle"></span>
                    <div class="left">
                        <img :src="$oucy.ossUrl + detail.employeePic" class="pic">
                    </div>
                    <div class="right">
                        <div class="name cardName">{{detail.employeeName}}</div>
                        <div class="time">{{vv.cardEmployeeReplyCreatetime}}</div>
                        <div class="comment">{{vv.cardEmployeeReplyContent}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "comment2",
    props: {
        list:{
            type:[Array,Object],
            default:function(){
                return []
            },
        },
        btnText: {
            type: String,
            default: '回复',
        },
        placeholder: {
            type: String,
            default: '请输入评论内容',
        },
        rows: {
            type: Number,
            default: 3,
        },
        detail:{
            type:Object,
            default:function(){
                return {}
            }
        },
        isShowInput:{
            type:Boolean,
            default:false
        },
        // 回复的父级Id
        parentCommentId: {
            type: String,
            default: null,
        },

    },
    data() {
        return {
            // isShowInput: true,
            isShowComment: true,
            textarea: '',
            imageList: [],
            previewSrcList: [],
        }
    },
    mounted() {

    },
    methods: {

        setParentCommentId(v) {
            this.$emit('setParentCommentId', v)
            console.log('点了:', v.id)
        },
        uploadImageBack(v) {
            this.imageList.push(v)
            this.previewSrcList.push(this.$oucy.ossUrl + v)
        },
        deletIme(i) {
            this.imageList.splice(i, 1)
            this.previewSrcList.splice(i, 1)
        },
        callback(v) {
            this.$emit('callback', v)
        },
        getSrcList(list){
            let srcList=[]
            for(let v of list){
                srcList.push(this.$oucy.ossUrl + v.cardEmployeeCommentAttachUrl)
            }
            return srcList
        }
    },
}
</script>
<style scoped lang="less">
.commentBox {
    display: flex;
}
.commentBox+.commentBox{
    border-top:1px dashed #dcdfe6;
    padding-top:20px
}

.pic {
    width: 50px;
    height: 50px;
    border-radius: 42px;
}

.right {
    flex: 1;
    padding-left: 10px
}

.name {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.time {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #888888;
    margin-top: 6px;
}

.comment {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555C68;
    margin-top: 6px;
}
.commentBox2{
    background: #F6F7F8;
    width: 100%;
    padding: 20px;
    position:relative;
    box-sizing: border-box;
}
.cardName{
    display: inline-block;
    color: #fff;
    padding:1px 6px;
    background: #F29F1C;
    border-radius: 2px;
}
.triangle{
    position:absolute;
    left: 20px;
    top: -16px;
    border-top:8px transparent solid; 
    border-left:8px transparent solid; 
    border-right:8px transparent solid; 
    border-bottom:8px #F6F7F8 solid; 
}
</style>