// 个人订单管理

const accOMS = [{
        path: '/acc/OMS',
        name: 'accOMS',
        component: () => import("@/views/Account/app/OMS/Index.vue"),
        meta:{title:'订单管理'}

    },
    // 退款
    {
        path: '/acc/OMSRefund',
        name: 'accRefund',
        component: () => import("@/views/Account/app/OMS/refund/refund.vue"),
        meta:{title:'订单管理 退款'}

    },
    // 发货
    {
        path: '/acc/OMSShipments',
        name: 'accShipments',
        component: () => import("@/views/Account/app/OMS/shipments/shipments.vue"),
        meta:{title:'订单管理 发货'}

    },
    // 查看物流
    {
        path: '/acc/OMSLogistics',
        name: 'accLogistics',
        component: () => import("@/views/Account/app/OMS/logistics/logistics.vue"),
        meta:{title:'订单管理 查看物流'}

    },
    // 打印清单
    {
        path: '/acc/OMSPrintList',
        name: 'accPrintList',
        component: () => import("@/views/Account/app/OMS/printList/printList.vue"),
        meta:{title:'订单管理 打印列表'}

    },
    // 详情
    {
        path: '/acc/OMSDetail',
        name: 'accOMSDetail',
        component: () => import("@/views/Account/app/OMS/detail/detail.vue"),
        meta:{title:'订单管理 详单详情'}

    },
     // 评论
    {
        path: '/acc/OMSComment',
        name: 'accOMSComment',
        component: () => import("@/views/Account/app/OMS/comment/comment.vue"),
        meta:{title:'订单管理 评论'}

    },
     // 评论管理
    {
        path: '/acc/OMSCommentManagement',
        name: 'accOMSCommentManagement',
        component: () => import("@/views/Account/app/OMS/commentManagement/commentManagement.vue"),
        meta:{title:'订单管理 评论管理'}

    },


];

export default accOMS