<!-- 输入框合子 带 推送店铺 插入商品-->
<template>
    <div class="inputBox box" :class="{'hasNest':hasNest}">
        <el-input type="textarea" autosize :rows="rows" :placeholder="placeholder" v-model="textarea" :autosize="{ minRows: 2, maxRows: 4}" maxlength="200" show-word-limit></el-input>
        <div class="spuList">
            <div v-for="(item,index) in spuList" class="spuList_item" :key="index">
                <spu :data="item" :isShowStock="false"></spu>
                <el-button type="text" icon="el-icon-delete" circle class="deleteSpu" @click="deleteSpu(item,index)"></el-button>
            </div>
            <div v-for="(item,index) in enterpriseList" class="spuList_item" :key="index">
                <enterprise :data="item"></enterprise>
                <el-button type="text" icon="el-icon-delete" circle class="deleteSpu" @click="deleteEnterprise(item,index)"></el-button>
            </div>
        </div>
        <div class="m-t-20">
            <div class="text-right">
                <el-button size="mini" type="primary" @click="openDialogFormVisible2">推送店铺</el-button>
                <el-button size="mini" type="primary" @click="openDialogFormVisible">插入商品</el-button>
                <el-button size="mini" type="primary" @click="callback">{{btnText}}</el-button>
            </div>
        </div>
        <el-dialog width="800px" title="添加商品" :visible.sync="dialogFormVisible">
            <template>
                <div>
                    <el-input v-model="QueryFurnitureLibrary.spuName" placeholder="请输入关键字搜索" size="mini" class="m-l-10" style="width: 150px;"></el-input>
                    <el-button type="default" size="mini" @click="search">搜索</el-button>
                </div>
                <el-table :data="prlductData.content" style="width: 100%" @selection-change="handleSelectionChange" ref="multipleTable" height="400" @row-click="rowClick">
                    <el-table-column type="" width="55">
                        <div slot-scope="scope">
                            <img src="@/assets/icon/select.png" class="checkbox " v-if="spuListIds.includes(scope.row.id)">
                            <img src="@/assets/icon/un_select.png" class="checkbox " v-else>
                        </div>
                    </el-table-column>
                    <!-- <el-table-column type="index" label="编号"></el-table-column> -->
                    <el-table-column prop="date" label="产品封面" width="100">
                        <template slot-scope="scope">
                            <!-- <video v-if="scope.row.spuVideo" :src="$oucy.ossUrl+scope.row.spuVideo" style="width:40px;height: 40px;background: #f1f1f1;" controls="1"></video> -->
                            <!-- <div v-else> -->
                            <el-avatar shape="square" :src="scope.row.cover" v-if="hasImportImg(scope.row.cover)"></el-avatar>
                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.cover" v-else></el-avatar>
                            <!-- </div> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuName" label="产品名称/所属品牌">
                        <template slot-scope="scope">
                            <div class="pointer line-1" :title="scope.row.spuName">{{scope.row.spuName}}</div>
                            <div class="c-2" v-if="scope.row.brand && scope.row.brand.brandName">{{scope.row.brand.brandName}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuPrise" label="当前价格">
                        <template slot-scope="scope">
                            <span class="spuPrise" v-if="scope.row.spuPrise=='0.00'">面议</span>
                            <span class="spuPrise" v-else>￥{{scope.row.spuPrise}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-pagination v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSpu">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog width="800px" title="添加店铺" :visible.sync="dialogFormVisible2">
            <template>
                <div>
                    <el-input v-model="QueryFurnitureLibrary2.keyWord" placeholder="请输入关键字搜索" size="mini" class="m-l-10" style="width: 150px;"></el-input>
                    <el-button type="default" size="mini" @click="search2">搜索</el-button>
                </div>
                <el-table :data="prlductData2.content" style="width: 100%" @selection-change="handleSelectionChange2" ref="multipleTable2" height="400" @row-click="rowClick2">
                    <el-table-column type="" width="55">
                        <template slot-scope="scope">
                            <img src="@/assets/icon/select.png" class="checkbox " v-if="enterpriseListIds.includes(scope.row.id)">
                            <img src="@/assets/icon/un_select.png" class="checkbox " v-else>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column type="index" label="编号"></el-table-column> -->
                    <el-table-column prop="date" label="" width="100">
                        <template slot-scope="scope">
                            <!-- <video v-if="scope.row.spuVideo" :src="$oucy.ossUrl+scope.row.spuVideo" style="width:40px;height: 40px;background: #f1f1f1;" controls="1"></video> -->
                            <!-- <div v-else> -->
                            <el-avatar shape="square" :src="scope.row.enterpriseAvata" v-if="hasImportImg(scope.row.enterpriseAvata)"></el-avatar>
                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.enterpriseAvata" v-else></el-avatar>
                            <!-- </div> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuName" label="商家信息">
                        <template slot-scope="scope">
                            <div class="pointer line-1" :title="scope.row.enterpriseName">{{scope.row.enterpriseName}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuPrise" label="星级">
                        <template slot-scope="scope">
                            <el-rate v-model="scope.row.enterpriseStar" disabled></el-rate>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-pagination v-if="prlductData2" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData2.size" :total="prlductData2.totalElements" @size-change="sizeChange2" @current-change="currentChange2" @prev-click="currentChange2" @next-click="currentChange2">
            </el-pagination>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="addEnterprise">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { spu, enterpriseinfo } from "@/service"

export default {
    name: "inputBox2",
    props: {
        btnText: {
            type: String,
            default: '回复',
        },
        placeholder: {
            type: String,
            default: '请输入评论内容',
        },
        rows: {
            type: Number,
            default: 3,
        },
        hasNest: {
            type: Boolean,
            default: false,
        }

    },
    data() {
        return {
            textarea: '',
            imageList: [],
            previewSrcList: [],

            // 添加商品
            dialogFormVisible: false,
            prlductData: {},
            QueryFurnitureLibrary: {
                // category: 0,
                start: 0,
                limit: 10,
                spuName: null,
            },
            spuName: null,
            adImgFlag: 0,
            multipleSelection: [],
            spuList: [],
            spuListIds: [],

            // 添加商品
            dialogFormVisible2: false,
            prlductData2: {},
            QueryFurnitureLibrary2: {
                // category: 0,
                start: 0,
                limit: 10,
                keyWord: null,
            },
            keyWord: null,
            checked: true,
            checked2: false,
            multipleSelection2: [],
            enterpriseList: [],
            enterpriseListIds: [],
        }
    },
    mounted() {},
    methods: {
        // 添加商品的开始
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            me.queryFurnitureLibrary();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            me.queryFurnitureLibrary();
        },
        // 查找
        search() {
            // this.limit=10
            this.QueryFurnitureLibrary.start = 0
            this.queryFurnitureLibrary()
        },

        // 请求数据
        queryFurnitureLibrary() {
            spu.queryFurnitureLibrary(this.QueryFurnitureLibrary).then(res => {
                this.prlductData = res
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 打开商品
        openDialogFormVisible() {
            this.queryFurnitureLibrary()
            this.dialogFormVisible = true
            this.multipleSelection=this.deepCopy(this.spuList)
            this.spuListIds=this.getId(this.spuList)

        },
        // 添加加回调
        addSpu(v) {
            this.spuList=this.deepCopy(this.multipleSelection)
            this.dialogFormVisible = !true
        },
        // 删除
        deleteSpu(v, i) {
            this.spuList.splice(i, 1)
        },
        rowClick(row, column, event) {
            let index = this.spuListIds.indexOf(row.id)
            if (index == -1) {
                this.multipleSelection.push(row)
                this.spuListIds.push(row.id)
            } else {
                this.multipleSelection.splice(index, 1)
                this.spuListIds.splice(index, 1)
            }
        },
        // 添加商品的结束

        // 添加店铺的开始
        // 切换页数
        sizeChange2: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary2.limit = pageSize;
            me.queryEnterpriseLibrary();
        },
        // 切换页码
        currentChange2: function(current) {
            const me = this;
            me.QueryFurnitureLibrary2.start = current - 1;
            me.queryEnterpriseLibrary();
        },
        // 查找
        search2() {
            // this.limit=10
            this.QueryFurnitureLibrary2.start = 0
            this.queryEnterpriseLibrary()
        },

        handleSelectionChange2(val) {
            this.multipleSelection2 = val;
        },
        // 打开店铺
        openDialogFormVisible2() {
            this.queryEnterpriseLibrary()
            this.multipleSelection2=this.deepCopy(this.enterpriseList)
            this.dialogFormVisible2 = true
            this.enterpriseListIds=this.getId(this.enterpriseList)
  
        },
        // 添加加回调
        addEnterprise(v) {
            this.enterpriseList=this.deepCopy(this.multipleSelection2)
            this.dialogFormVisible2 = !true
        },
        // 删除
        deleteEnterprise(v, i) {
            this.enterpriseList.splice(i, 1)
        },
        // 请求数据
        queryEnterpriseLibrary() {
            enterpriseinfo.queryEnterpriseLibrary(this.QueryFurnitureLibrary2).then(res => {
                this.prlductData2 = res || {}
            })
        },
        // 添加店铺的结束

        hasImportImg(v) {
            return v && v.startsWith('//')
        },
        rowClick2(row, column, event) {
            let index = this.enterpriseListIds.indexOf(row.id)
            if (index == -1) {
                this.multipleSelection2.push(row)
                this.enterpriseListIds.push(row.id)
            } else {
                this.multipleSelection2.splice(index, 1)
                this.enterpriseListIds.splice(index, 1)
            }
        },
        // 重置
        reset() {
            this.textarea = ''
            this.spuList = []
            this.enterpriseList = []
        },
        callback() {
            if (!this.textarea) {
                this.$alert('请输入内容')
                return
            }
            this.$oucy.keywordFiltr(this.textarea).then(res=>{
                this.$emit('callback', { enterpriseSpus: this.spuList, enterpriseList: this.enterpriseList, text: this.textarea })
            },err=>{})
        },
        deepCopy(list){
            return JSON.parse( JSON.stringify(list))
        },
        getId(list){
            let ids=[]
            for(let v of list){
                ids.push(v.id)
            }
            return ids
        },
    },
}
</script>
<style scoped lang="less">
.box {
    // padding: 20px;
    background: #F6F7F8;
    border-radius: 2px;
}

/deep/ .el-textarea__inner {
    // padding: 5px 0;
    background: #F6F7F8;
    border: none;
}

.hasNest /deep/ .el-textarea__inner {
    // padding: 5px 0;
    background: #fff;
    border: none;
}

.spuList_item {
    position: relative;




    .deleteSpu {
        z-index: 99;
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px;
        cursor: pointer;
        display: none;
    }

    &:hover {
        .deleteSpu {
            display: inline-block;
        }
    }
}

.spuList {
    display: flex;
    flex-wrap: wrap;
}

.spuList_item {
    width: 200px;
    height: 288px;
    margin: 20px;
    position: relative;
}

.enterpriseList {
    display: flex;
}

.enterpriseList_item {}
.checkbox {
    width: 15px;
    height: 15px;
}
</style>