// 求购

const askBuy = [
    {
        path: '/askBuyList',
        name: 'askBuyList',
        component: () => import("@/views/Index/askBuy/list.vue"),
        meta:{title:'求购列表'}
    },
    {
        path: '/askBuyDetail',
        name: 'askBuyDetail',
        component: () => import("@/views/Index/askBuy/detail.vue"),
        meta:{title:'求购详情'}
    },

];

export default askBuy