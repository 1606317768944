<!--  -->
<template>
    <div class="item_box pointer" @click="go(v)" :class="['coupon_'+v.memberCardUserTicketScheme,v.memberCardUserTicketUsed||v.memberCardUserTicketExpired?'coupon_-1':'',v.id==selectId?'select':'666']">
        <div class="item_top">
            <div class="item_top_left"><span class="f12">￥</span>{{v.memberCardUserTicketFee}}</div>
            <div class="item_top_right">满{{v.memberCardUserTicketLimitFee}}元</div>
            <div class="f12">使用</div>
        </div>
        <div class="right">
            <div class="use" v-if="v.memberCardUserTicketUsed===true">{{v.memberCardUserTicketUsed?'已使用':'未使用'}}</div>
            <div class="right_top">
                <span class="type">{{['现金券','购物券','物流券'][v.memberCardUserTicketScheme]}}</span>
                <span class="label">{{v.memberCardUserTicketName}}</span>
                <!-- <span class="label">{{['全店通用','店铺分类','指定商品'][v.ticketScope]}}券</span> -->
            </div>
            <!-- <div class="period">有效期至：{{new Date(v.memberCardUserTicketLimittime).format('yyyy-MM-dd')}}</div> -->
            <div class="period">有效期至：{{ v.memberCardUserTicketLimittime}}</div>
            <div class="f12 c-2 m-t-10">适用商家：{{v.enterpriseShopName?v.enterpriseShopName:"全平台通用"}}</div>
        </div>
        <img src="@/assets/images/stockCar/corner.png" alt="" class="corner">
    </div>
</template>
<script>
export default {
    name: "coupon2",
    props: {
        v: {
            type: Object,
            default: function() {
                return {}
            }
        },
        selectId: {
            type: String,
            default: null // E  A 
        },        
        source: {
            type: String,
            default: 'E' // E  A 
        }

    },
    data() {
        return {
            aliRts: null,
            video: null,
        }
    },
    mounted() {},
    methods: {
        go(v){
            if(v.memberCardUserTicketUsed||v.memberCardUserTicketExpired){

            }else{
                this.$emit('clickCoupon',v)
                // this.$oucy.go('/acc/StockCar')
            }
        }
    },
}
</script>
<style scoped lang="less">
.items {

    display: flex;
}

.item_top {
    text-align: center;
    color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(137deg, #5FB3F7 0%, #2090FF 100%);
}

.items {
    flex-wrap: wrap;
}


.item_box {
    background: #366;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    background-size: 100% 100%;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #fff;
}

.item {

    margin: 0 15px;
    margin-bottom: 50px;
}


.right {
    position: relative;

    .right_top {
        display: flex;
        align-items: center;
    }

    padding: 12px;

    .type {
        font-size: 12px;
        display: inline-block;
        padding: 4px 10px;
        background: #2090FF;
        color: #fff;
        border-radius: 20px;
    }

    .label {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 0px;
        color: #3A4454;
        margin-left: 4px;
        width: 90px;
    }
}

.item_top_left {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*color: #ED6732;*/
    /*border-right: 1px solid #E7ECF1;*/
    width: 90px;
}

.item_top_right {
    padding-top: 5px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    /*font-weight: bold;*/
    /*color: #555C68;*/
}

.period,
.Release {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #AAAAAA;
    margin-top: 10px;
}

.period span,
.Release span {
    color: #555C68;
}

.use {
    position: absolute;
    top: 8px;
    right: -40px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateZ(45deg);
    background: rgba(102, 102, 102, 0.39);
    font-size: 12px;
    color: #fff;
    padding: 5px 40px;
}
//未使用的现金卷
.coupon_0 {
    .item_top {
        // background: linear-gradient(164deg, #9FACFC 0%, #8492E9 100%);
        background: linear-gradient(137deg, #FFC766 0%, #F6A95A 100%);
    }

    .type {
        background: #F7AD5C;

    }
}
//未使用的购物卷
.coupon_1 {
    .item_top {
        // background: linear-gradient(137deg, #5FB3F7 0%, #2090FF 100%);
        background: linear-gradient(137deg, #5FB3F7 0%, #2090FF 100%);
    }

    .type {
        background: #2090FF;

    }
}
//未使用的物流卷
.coupon_2 {
    .item_top {
    //    background: linear-gradient(164deg, #E5C590 0%, #E5C590 100%);
    background: linear-gradient(137deg, #CEB4FF 0%, #B194FF 100%);
    }

    .type {
        background: #E5C590;

    }
}


//已失效的物流卷
.coupon_-1 {
    .item_top {
        background: #DBDBDB;
    }

    .type {
        background: #DBDBDB;

    }
}
.corner{
    width: 30px;
    height: 30px;
    position:absolute;
    right: 0;
    bottom:0;
    display: none;
}
.select{
    border: 1px solid #2090FF;
    position:relative;
    .corner{
        display: inline-block;
    }
}
</style>