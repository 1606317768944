import oucy from '@/util/oucyUtil.js'
// 用户操作行为管理
const userRecordEvent = {
	/**
     * 发送用户操作行为操作记录
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    receiveUserRecordEvent: (params) => {
        return oucy.postRequest_2('/client/elk/userrecordevent/receiveUserRecordEvent ', params)
    },
    	/**
     * 发送前端错误操作记录
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    receiveErrorEvent: (params) => {
        return oucy.postRequest_2('/error/receiveErrorEvent', params)
    }
}

export default userRecordEvent

