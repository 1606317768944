<template>
  <div id="app" :class="queryTheme && 'queryTheme'">
    <router-view />
  </div>
</template>
<script>
  import oucy from "./util/oucyUtil";
  import pkg from "../package.json"
  export default {
    name: "app",
    data() {
      return {
        queryTheme: false,
      };
    },
    mounted() {
      console.log('version', pkg.version)
      oucy.app = this;
      this.$oucy.postRequest("/client/auth/adnotice/queryTheme").then((res) => {
        this.queryTheme = res || false;
      });
    },
  };
</script>
<style lang="less">
  .queryTheme {
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(100%);
  }
  .el-notification__group{
    flex: 1;
  }
</style>
