<template>
    <div class="scrollPage">
        <Observer @callBack="callBack"><slot></slot></Observer>
    </div>
</template>
<script>
export default {
    name: "scrollPage",
    props: {
        hasMore: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        callBack(v){
            if(v>0)this.load()
        },
        load() {
            if (this.hasMore) {
                this.$emit('load')
            }
        }
    },
    beforeDestroy() {}
}
</script>
<style>
</style>