<!-- 绑定手机号 -->
<template>
    <div>
        <div class="login">
            <el-dialog
                    @close="handleClose"
                    :visible.sync="isVisible"
                    width="444px"
                    height="390px">               

                    <div label="绑定手机号" name="bind">
                        <h3>绑定手机号</h3>
                        <el-form :model="phoneloginForm" class="login_box_form" :rules="phonerules" ref="phoneloginForm">
                            <el-form-item prop="phoneNum">
                                <el-input v-model="phoneloginForm.phoneNum" autocomplete="off" placeholder="请输入您的手机号">
                                    <i slot="prefix" class="el-icon-user"></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="phoneCode">
                                <el-input v-model="phoneloginForm.phoneCode" autocomplete="off" placeholder="请输入短信验证码">
                                    <i slot="prefix" class="el-icon-unlock"></i>
                                    <el-button @click="bindPhoneSendCode" :disabled="!showGetCode" type="text"
                                               style="padding-right:10px;color:#0366C3;" slot="suffix">
                                        <span v-if="showGetCode">获取验证码</span>
                                        <span v-else class="count">{{codeTime}} s</span>
                                    </el-button>
                                </el-input>
                            </el-form-item>
                            <div class="login_button">
                                <el-button style="width: 120px;" @click="phInfoSetup" :disabled="!phoneloginForm.agreement" type="primary">
                                    确定绑定
                                </el-button>
                                <el-button style="width: 120px;background: #fff;color: #0366C3;" @click="handleClose" :disabled="!phoneloginForm.agreement" type="">
                                    暂不绑定
                                </el-button>
                            </div>
 
                        </el-form>

                    </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import oucy from "../util/oucyUtil";
    import {userAuth} from"@/service"
    import {localSet, localGet, localDel} from "@/store/store"

    export default {
        name: 'bindPhone',
        props: {
            isVisible: Boolean,
            default: false
        },
        data() {
            const validatePhone = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入手机号码'))
                } else if (!oucy.isValidPhone(value)) {
                    callback(new Error('请输入正确的11位手机号码'))
                } else {
                    callback()
                }
            };
            return {
                qrCode:null,
                codeTime: 0,
                codeTimer: null,
                showGetCode: true,
                activeName: 'first',//bind  first
                idloginForm: {
                    username: '',
                    password: '',
                    agreement: true
                },
                phoneloginForm: {
                    phoneCode: '',
                    phoneNum: '',
                    agreement: true
                },
                showSuccess: false,
                showDiaBox: true,
                idrules: {
                    username: [
                        {required: true, message: '请输入用户名', trigger: 'blur'},
                        {min: 2, max: 25, message: '长度在 2 到 25 个字符', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 6, max: 25, message: '长度在 6 到 25 个字符', trigger: 'blur'}
                    ]
                },
                phonerules: {
                    phoneNum: [
                        {validator: validatePhone, trigger: 'blur'}
                    ],
                    phoneCode: [
                        {required: true, message: '请输入验证码', trigger: 'blur'},
                    ]
                },
                scanUserEntity:null,
                scanUserConfirm:false,
                timer:null,
            }
        },
        watch:{},
        mounted() {},
        methods: {
            // 验证码倒计时
            getCodeCount() {
                if (!this.timer) {
                    this.showGetCode = false;
                    this.timer = setInterval(() => {
                        if (this.codeTime > 0 ) {
                            --this.codeTime;
                        } else {
                            this.showGetCode = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000);
                }
            },
            handleClose: function () {
                this.$emit('closeBindPhone');
            },
            goRegister: function () {
                this.$emit('goRegister');
            },


            // 发送绑定手机验证码
            bindPhoneSendCode: function () {
                const me = this;
                me.$refs['phoneloginForm'].validateField('phoneNum', (message) => {
                    if (message == null || message.length === 0) {
                        userAuth.bindPhoneSendCode({
                            phoneNumber: me.phoneloginForm.phoneNum,
                            uuid: oucy.getUUID()
                        }).then(res => {
                            if(res){
                                me.codeTime = 60;
                                me.getCodeCount()
                                // me.codeTime = Number(res);
                                me.$notify({
                                    title: '短信发送成功',
                                    message: '验证码已发送，请留意短信通知',
                                    type: 'success'
                                });
                            }
                        });
                    }
                });
            },

            // 设置用户账号的手机号码
            phInfoSetup: function () {
                const me = this;
                me.$refs["phoneloginForm"].validate((valid) => {
                    if (valid) {
                        userAuth.phInfoSetup({phoneNumber:me.phoneloginForm.phoneNum,phoneCode:me.phoneloginForm.phoneCode}).then(res => {
                            // if(!res.isAccComplete){
                            //     oucy.replace('/fullaccount');
                            // }
                            this.handleClose()
                            clearInterval(this.timer);
                            this.timer = null;
                        })
                    }
                });
            },

           
        },
        beforeDestroy() {
            
        }
    }
</script>

<style scoped>
    .login .el-dialog {
        border-radius: 8px;
    }

    /deep/ .el-tabs__item {
        width: 134px !important;
        text-align: center;
    }

    .login_box_form {
        width: 100%;
        padding: 0 60px;
        box-sizing: border-box;
        margin-top: 30px;
    }

    .login_bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 28px;
    }

    .login_bottom {
        font-size: 14px;
        color: #AAAAAA;
    }

    .login_button .el-button {
        text-align: center;
        color: #FFFFFF;
        font-size: 16px;
        width: 285px;
        height: 42px;
        background: #0366C3;
        border-radius: 8px;
    }
    .tabs{
        display: flex;
        justify-content: space-around;
        padding: 0 50px;
    }
    .tab{}
</style>
