import oucy from '@/util/oucyUtil.js'
// 阿里云第三方管理
const aliyun = {
	/**
     * 生成阿里云签名
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    aliyunSignature: (params) => {
        return oucy.postRequest('/client/thirdparty/aliyun/aliyunSignature', params,false)
    },
}

export default aliyun
