<!--  -->
<template>
    <div style="position: absolute;top:-1000px">
      <video id="video" controls width="300"  style=""></video>
      <div id="output"></div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import QRCode from 'qrcodejs2'
import { liveroomp, liveroom } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store";

export default {
    name: "First",
    components: {
    
    },
    props: {


    },
    data() {
        return {

            video: '',
            canvas: '',
            src: '',
            videoObject: {},
            ccallBack: null,
        }
     
    },
    mounted() {
        this.video = document.getElementById("video");
        this.canvas = document.createElement("canvas"); // 创建一个画布
        /*
         * onseeked
         * 寻址操作完成
         * 用于向指定元素添加事件句柄。
         */
        this.video.addEventListener('seeked', this.captureImage); // 
    },
    methods: {
        gitFileFirstImg(files,ccallBack) {
          let video = files[0]
          this.videoObject.name = video.name // 文件名
          this.videoObject.size = video.size // 大小
          this.videoObject.path = window.URL.createObjectURL(video) // URL

          this.ccallBack = ccallBack
          this.video.src = this.videoObject.path
          this.video.currentTime = 0.0001
          this.video.load()
        },
          gitUrlFirstImg(url,ccallBack) {


          this.ccallBack = ccallBack
          this.video.src = url
          // this.video.setAttribute("crossOrigin",'Anonymous')
          this.video.currentTime = 0.0001
          this.video.load()
        },
        captureImage() {

          this.videoObject.duration = this.video.duration
          this.videoObject.width = this.video.videoWidth
          this.videoObject.height = this.video.videoHeight
          this.canvas.width = this.video.videoWidth
          this.canvas.height = this.video.videoHeight
          this.canvas.getContext('2d').drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
            let tempSrc = this.canvas.toDataURL("image/png");
            this.filePath(tempSrc)
          
        },


        filePath(tempSrc) {
          function dataURLtoFile(dataurl, filename) {
            let arr = dataurl.split(','),
              mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]),
              n = bstr.length,
              u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
          }

          let file = dataURLtoFile(tempSrc, `${this.videoObject.name.split('.')[0]}`)
          this.ccallBack({
            file: file,
            tempSrc: tempSrc,
            video: this.videoObject,
          })

        },
        
    },
}
</script>
<style scoped lang="less"></style>