// 个人直播

const accLive = [{
        path: '/acc/liveList',
        name: 'accLiveList',
        component: () => import("@/views/Account/app/live/list.vue"),
        meta: { title: '直播 - 乐搜家 家居供应链服务平台' },
    },
    // 保存
    {
        path: '/acc/liveSave',
        name: 'AccLiveSave',
        component: () => import("@/views/Account/app/live/save"),
        meta: { title: '直播 - 乐搜家 家居供应链服务平台' },

    },

];

export default accLive