<!-- 商家 -->
<template>
        <div class="item" @click="$oucy.openNewPage('/ShopVip?enterpriseId='+data.id)">
            <img :src="$oucy.ossUrl+data.enterpriseAvata" v-if="data.enterpriseAvata" class="spuImgs">
            <div class="spuImgs" v-else></div>
            <div class="p-l-10 p-t-10 p-r-10">
                <div class="spuName line-2 c-3 f16">
                    {{data.enterpriseName}}
                </div>
                <div class="c-m f16 bold p-t-10 p-b-10">
                    <el-rate v-model="data.enterpriseStar" disabled></el-rate>
                </div>
            </div>
        </div>
</template>
<script>
// import { message, schemeOrder } from "@/service/index"
export default {
    name: "enterprise",
    props: {
        data: {
            type: Object,
            default: function() {
                return {}
            }
        },
    },
    mounted() {},
    methods: {},
}
</script>
<style scoped lang="less">
.spuImgs {
    width: 200px;
    height: 200px;
}

.spuName {
    height: 45px;
}

.item {
    background: #fff;
    text-align: center;
}
</style>