<!-- 订单信息内容框 -->
<template>
	<div>
		<div class="setlet hoverDn" v-for="(v,i) of orderData.orderSchemes" :key="i">
			<div class="df-ai-c df-jc-s-b f12 topTitle c-1" style="background-color: #f9fdff">
				<div class="df-ai-c">
					<div class="m-r-30 df-ai-c" v-if="isBuyer">
						<img class="head m-r-15" :src="$oucy.ossUrl + v.enterpriseAvata" />
						{{ v.enterpriseName }}
					</div>
					<div class="m-r-30">
						<span class="bold m-r-15">订单编号</span>
						{{ v.id }}
					</div>
					<div class="m-r-30">
						<span class="bold m-r-15">下单时间</span>
						{{ v.orderCreatetime }}
					</div>
					<div class="m-r-30 df-ai-c" v-if="!isBuyer">
						<span class="bold m-r-15">下单用户</span>
						<img class="head m-r-15" :src="$oucy.ossUrl + v.orderUserauthAvata" />
						{{ v.orderUserauthNickname }} {{ v.orderUserPhone }}
					</div>
				</div>
				<div class="dn">
					<!--    <el-button type="text" icon="el-icon-error" circle></el-button>
             <el-button type="text" icon="el-icon-delete-solid" circle></el-button> -->
					<i class="el-icon-error pointer f16" @click="open(v)" v-if="!isBuyer" title="强制取消"></i>
					<i class="m-l-20 pointer el-icon-delete-solid f16" title="移入回收站" @click="recycleOrder(v)" v-if="!v.recycle"></i>
				</div>
			</div>
			<el-table
				align="center"
				:data="v.orderSchemeSpuDtos"
				:span-method="objectSpanMethod"
				@selection-change="handleSelectionChange"
				ref="multipleTable"
				border
				style="width: 100%;"
			>
				<el-table-column type="selection" align="center" width="55" v-if="isShowSelection">
					<!--                     <template slot-scope="scope" v-if="1">
                        <el-checkbox v-model="checked" disabled></el-checkbox>
                    </template> -->
				</el-table-column>
				<el-table-column align="center" prop="name" width="410" label="商品信息">
					<template slot-scope="scope">
						<div class="df pointer" @click="$oucy.go('/product?spuId=' + scope.row.spuId)">
							<div class=""><img class="banner" :src="$oucy.ossUrl + scope.row.productAvata" /></div>
							<div class="text-left p-l-10">
								<div class="title f14 c-1 line-2">{{ scope.row.spuName }}</div>
								<div class="c-2 f12 m-t-10">规格：{{ scope.row.skuName }}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="productPrise" label="商品单价"></el-table-column>
				<el-table-column align="center" prop="pruductCount" label="商品数量"></el-table-column>
				<el-table-column align="center" prop="" label="订单状态">
					<!-- 订单状态1 待确认/待支付（订单创建完毕）105 已支付 2 备货中 101 部分发货 3 待收货 4 已取消 5 已完成（已收货） -->
					<div class="status F1" v-if="v.orderStatus === 1">未付款</div>
					<div class="status F0" v-else-if="v.orderStatus === 105">待发货</div>
					<div class="status F1" v-else-if="v.orderStatus === 3">待收货</div>
					<div class="status F1" v-else-if="v.orderStatus === 5">已确认收货</div>
					<div class="status EF" v-else-if="v.orderStatus === 200">已完成</div>
					<!-- <div v-else-if="v.orderStatus==1">待处理</div> -->
					<div class="status F2" v-else-if="v.orderStatus === 4">已取消</div>
					<!-- <div v-else-if="v.orderStatus==1">回收站</div> -->
					<!-- <div v-else-if="v.orderStatus==1">已关闭</div> -->
				</el-table-column>
				<el-table-column align="center" prop="amount3" label="订单金额">
					<!-- <template slot-scope="scope"> -->
					<div class="c-m f14" slot-scope="scope">￥{{ v.orderAmount }}</div>
					<!-- </template> -->
				</el-table-column>
				<el-table-column align="center" prop="amount3" label="实付金额">
					<!-- <template slot-scope="scope"> -->
					<div class="c-m">￥{{ v.orderPayfee }}</div>
					<!-- 付款方式 0微信支付 1支付宝 2货到付款 4余额支付 5余额微信组合支付 6余额支付宝组合支付 -->
					<div class="f12 df-ai-c df-jc-c text-center" >
						<img src="@/assets/images/stockCar/WeChat.png" alt="" v-if="v.orderPayChannel === 0"/>
						<img src="@/assets/images/stockCar/Alipay.png" alt="" v-if="v.orderPayChannel === 1"/>
						{{orderPayChannels[v.orderPayChannel]}}						
					</div>

					<div class="c-2 f12 text-center">{{ v.orderPaytime }}</div>
					<!-- </template> -->
				</el-table-column>
				<el-table-column align="center" prop="orderSellerWords" label="订单备注">
					<!-- <div>用户下单备注</div> -->
					<!-- <template slot-scope="scope"> -->

					<div class="line-2" :title="v.orderBuyWords">{{ v.orderBuyWords }}</div>
					<!-- </template> -->
				</el-table-column>
				<el-table-column align="center" width="150" prop="amount3" label="订单操作" v-if="isShowSet">
					<el-button
						@click="$oucy.go('/printOrder?orderId=' + v.id + '&splitOrder=' + orderData.splitOrder)"
						size="mini"
						class="common_margin_20"
						v-if="v.buttonController.order_can_print"
					>
						打印清单
					</el-button>
					<el-button @click="gotoDetail(v)" size="mini" class="common_margin_20">订单详情</el-button>
					<el-button
						@click="$oucy.go('/enter/OMSShipments?orderId=' + v.id + '&splitOrder=' + orderData.splitOrder)"
						size="mini"
						class="common_margin_20"
						v-if="v.buttonController.order_can_post"
						type="primary"
					>
						立即发货
					</el-button>
					<el-button @click="confirmReceipt(v)" size="mini" class="common_margin_20" v-if="v.buttonController.order_can_mark_take">确认收货</el-button>
					<el-button @click="continuePay(v)" size="mini" class="common_margin_20" v-if="v.buttonController.order_can_repay" type="success">继续支付</el-button>
					<el-button disabled size="mini" class="common_margin_20" v-if="v.buttonController.order_can_apply_after_sale">申请售后</el-button>
					<el-button disabled size="mini" class="common_margin_20" v-if="v.buttonController.order_can_audit_after_sale">审核售后</el-button>
					<el-button @click="cancelOrder(v)" size="mini" class="common_margin_20" v-if="v.buttonController.order_can_cancel">取消订单</el-button>
					<el-button disabled size="mini" class="common_margin_20" v-if="v.buttonController.order_can_refund">立刻退款</el-button>
					<el-button disabled size="mini" class="common_margin_20" v-if="v.buttonController.order_can_apply_customer_service">客服介入</el-button>
					<el-button disabled size="mini" class="common_margin_20" v-if="v.buttonController.order_can_cancel_customer_service">取消介入</el-button>
					<el-button @click="goto(v)" size="mini" class="common_margin_20" v-if="v.buttonController.order_can_delete">删除订单</el-button>
					<el-button @click="goto(v)" size="mini" class="common_margin_20" v-if="v.buttonController.order_can_recycle">恢复订单</el-button>
					<el-button @click="goto(v)" size="mini" class="common_margin_20" v-if="v.buttonController.order_can_deep_delete">彻底删除</el-button>
					<el-button
						@click="$oucy.go('/acc/OMSComment?orderId=' + v.id + '&splitOrder=' + orderData.splitOrder)"
						size="mini"
						class="common_margin_20"
						v-if="v.buttonController.order_can_comment"
					>
						商品评论
					</el-button>					
					<el-button size="mini" class="common_margin_20" v-if="v.buttonController.order_can_modify_addr"  @click="openAddress(v)" >修改地址</el-button>
					<el-button disabled size="mini" class="common_margin_20" v-if="v.buttonController.order_can_repay">回复评论</el-button>
					<el-button disabled size="mini" class="common_margin_20" v-if="v.buttonController.order_can_sales_return">订单退货</el-button>
				</el-table-column>
			</el-table>
			<div class="df-ai-c f12 topTitle c-1">
				<div class="m-r-30">
					<span class="bold m-r-10">收货地址</span>
					{{ v.orderPostAddress }} {{ v.orderPostAddressUsername }} {{ v.orderPostAddressPhone }}
				</div>
			</div>
			<div class="df c-1 f12 p-t-10 p-b-10 p-l-30" v-if="orderData.orderBill">
				<div class="m-r-30">
					<span class="bold m-r-10">是否开票</span>
					是
				</div>
				<div class="m-r-30">
					<span class="bold m-r-10">发票类型</span>
					{{ orderData.orderBill.billType === 0 ? '普票' : '专票' }}
				</div>
				<div class="m-r-30">
					<span class="bold m-r-10">发票抬头</span>
					{{ orderData.orderBill.billHeadtype === 0 ? '个人' : '企业' }}
				</div>
				<div class="m-r-30">
					<span class="bold m-r-10">{{ orderData.orderBill.billDetail }}</span>
				</div>
			</div>
			<div class="df c-1 f12 p-t-10 p-b-10 p-l-30" v-else>
				<div class="m-r-30">
					<span class="bold m-r-10">是否开票</span>
					否
				</div>
			</div>
			<div class="f12 c-1 topTitle df-ai-c">
				<span class="bold m-r-10" style="">商家备注</span>
				<span class="m-r-10">{{ v.orderSellerWords }}</span>
				<!-- <el-input v-model="input" size="mini" placeholder="请输入内容" style="flex:1;" class="m-r-10"></el-input> -->
				<el-button type="text" icon="el-icon-edit" size="mini" @click="openMessageDialogVisible(v)" v-if="!isBuyer"></el-button>
			</div>
		</div>
		<el-dialog title="商家备注" :visible.sync="messageDialogVisible" width="30%" :append-to-body="true">
			<div>
				<el-input v-model="message" type="textarea" autosize size="mini" placeholder="请输入备注内容" class="m-r-10" maxlength="45" show-word-limit></el-input>
				<!-- <div class="m-t-30"></div> -->
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="messageDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="leaveMessage()">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 修改地址 -->
		<el-dialog title="修改地址" width="1000px" :visible.sync="addressDialogVisible" :append-to-body="true">
			<div>
				<div class="contentTop">
				    <div class="contentTopLeft">
				        <div class="contentTopLeftBts">
				            <div>选择收货地址</div>
				        </div>
				    </div>
				    <div class="contentTopRight">
				        <el-button type="primary" plain @click="openDialogVisible">新增地址</el-button>
				        <!-- <el-button type="primary" plain @click="$oucy.go('/Address')">管理地址</el-button> -->
				    </div>
				</div>
				<div class="contentCenter">
					<div v-if="1">
						<div class="table site">
							<div class="tableHeader m-t-25">
								<div class="cell-4">收货人</div>
								<div class="cell-3">联系电话</div>
								<div class="cell-2">详细收货地址</div>
								<div class="cell-4">操作</div>
							</div>
							<div class="tableBody">
								<div class="items">
									<div
										class="item p-t-10 p-b-10 pointer"
										v-for="(v, i) of list"
										:key="i"
										:class="{ select: addressObj.addrId == v.id }"
										@click="addressObj.addrId = v.id"
									>
										<div class="cell-4">	
											{{ v.addressUsername }}
										</div>
										<div class="cell-3">{{ v.addressPhone }}</div>
										<div class="cell-2">{{ v.chinaDto && v.chinaDto.namePath }}/{{ v.addressDetail }}</div>
										<div class="cell-4">
											<el-button size="mini" class="common_margin_20" @click="editAddress(v)" >修改收货地址</el-button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>				
			</div>
			<div slot="footer" class="dialog-footer">
			    <el-button @click="addressDialogVisible = false">取 消</el-button>
			    <el-button type="primary" @click="submitForm">确 定</el-button>
			</div>
		</el-dialog>
		<add-add-dialog ref="addAddDialog" :ruleForm="ruleForm" @callback="addAddDialogCallback"></add-add-dialog>
	</div>
</template>
<script>	
import { message, schemeOrder,ltDeliveryAddress } from '@/service/index';
export default {
	name: 'order',
	props: {
		orderData: {
			type: Object,
			default: {
				orderSchemes: []
			}
		},
		isShowSelection: {
			type: Boolean,
			default: false
		},
		isShowSet: {
			type: Boolean,
			default: false
		},
		// 身份类型：true 买家 false 卖家
		isBuyer: {
			type: Boolean,
			default: true, //true 买家 false 卖家
			required: false
		}
	},
	data() {
		return {
			tableData: [],
			multipleSelection: [],
			messageObj: {},
			message: '',
			messageDialogVisible: false,
			// 修改地址
			list:[],
			addressObj:{
				addrId:'',
				orderId:'',
				splitOrder:'',
			},
			addressDialogVisible:false,
			ruleForm: {
			    addressUsername: '',
			    addressPhone: '',
			    cityCode: '',
			    addressDetail: '',
			    addressDefault: false,
			},
			// 付款方式 0微信支付 1支付宝 2货到付款 4余额支付 5余额微信组合支付 6余额支付宝组合支付
			orderPayChannels:['微信支付','支付宝','货到付款','','余额支付','余额微信组合支付','余额支付宝组合支付'],
		};
	},
	mounted() {
		if (this.isShowSelection) {
			setTimeout(() => {
				for (let v of this.$refs.multipleTable) {
					v.toggleAllSelection();
				}
			}, 300);
		}
		this.getAllLtDeliveryAddress();
	},
	methods: {
		// arraySpanMethod({ row, column, rowIndex, columnIndex }) {
		//     if (rowIndex % 2 === 0) {
		//         if (columnIndex === 0) {
		//             return [1, 2];
		//         } else if (columnIndex === 1) {
		//             return [0, 0];
		//         }
		//     }
		// },

		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			// console.log(row)
			if (columnIndex > 2) {
				if (rowIndex == 0) {
					return {
						rowspan: this.getLength(row) * 1,
						// rowspan: this.tableData.length * 1,
						colspan: 1
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0
					};
				}
			}
		},
		getLength(row) {
			for (let v of this.orderData.orderSchemes) {
				for (let vv of v.orderSchemeSpuDtos) {
					if (row.id == vv.id) {
						row.len = v.orderSchemeSpuDtos.length;
						return v.orderSchemeSpuDtos.length;
					}
				}
			}
			return 0;
		},
		deliverOrder() {},
		callBack(path) {
			this.$emit('callBack', { type: 'go', path: path });
			// this.$oucy.go(path)
		},
		open(v) {
			this.$confirm('确认强制取消？, 强制取消将取消订单并退款给用户！', '强制取消', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.cancelOrder(v);
					this.resetData();
				})
				.catch(() => {});
		},
		// 取消订单
		cancelOrder(v) {
			schemeOrder.cancelOrder({ orderId: v.id, splitOrder: this.orderData.splitOrder }).then(res => {
				this.$alert('取消成功', '提示', {
					confirmButtonText: '确定',
					callback: action => {}
				});
				this.resetData();
			});
		},
		// 订单移入/移出回收站
		recycleOrder(v) {
			this.$confirm('确认删除订单，删除后订单将移入回收站！', '删除订单', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					schemeOrder.recycleOrder({ enterpriseOrderId: v.id, isBuyer: this.isBuyer, isOut: false }).then(res => {
						this.$alert('操作成功', '提示', {
							confirmButtonText: '确定',
							callback: action => {}
						});
						this.resetData();
					});
				})
				.catch(() => {});
		},
		gotoDetail(v) {
			if (this.isBuyer) {
				this.$oucy.go(`/acc/OMSDetail?orderId=${v.id}&splitOrder=${this.orderData.splitOrder}`);
			} else {
				this.$oucy.go(`/enter/OMSDetail?orderId=${v.id}&splitOrder=${this.orderData.splitOrder}`);
			}
		},
		goto(v) {
			this.$alert('开发中...', '提示', {
				confirmButtonText: '确定',
				callback: action => {}
			});
			return;
			if (this.isBuyer) {
				this.$oucy.go(`/acc/OMSDetail?orderId=${v.id}&splitOrder=${this.orderData.splitOrder}`);
			} else {
				this.$oucy.go(`/enter/OMSDetail?orderId=${v.id}&splitOrder=${this.orderData.splitOrder}`);
			}
		},
		confirmReceipt(v) {
			schemeOrder.confirmReceipt({ enterpriseOrderId: v.id }).then(res => {
				v.order_can_mark_take = false;
				this.$alert('收货成功', '提示', {
					confirmButtonText: '确定',
					callback: action => {}
				});
				this.resetData();
			});
		},
		continuePay(v) {
			this.$emit('continuePay', v);
		},
		// 重置数据 杀心
		resetData() {
			this.$emit('resetData');
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 留言
		leaveMessage() {
			let data = {
				orderId: this.messageObj.id,
				splitOrder: this.orderData.splitOrder,
				message: this.message,
				role: 1
			};
			schemeOrder.leaveMessage(data).then(res => {
				// this.$('操作成功')
				this.$message('操作成功');
				this.messageObj.orderSellerWords = this.message;
				this.messageDialogVisible = false;
			});
		},
		openMessageDialogVisible(v) {
			this.messageObj = v;
			this.message = v.orderSellerWords;
			this.messageDialogVisible = true;
		},
		
		// 地址
		// 获取地址
		getAllLtDeliveryAddress() {
		    ltDeliveryAddress.getAllLtDeliveryAddress({}).then(res => {
		        this.list = res || []
		        if(this.list.length){
		            this.addressObj.addrId=this.list[0].id
		            for (let v of this.list){
		                if(v.addressDefault){
		                    this.addressObj.addrId=v.id
		                }
		            }
		        }
		    })
		},
		// 修改地址弹框回调
		addAddDialogCallback() {
			this.$refs.addAddDialog.resetFields()
			this.$refs.addAddDialog.closeDialogVisible()
		    this.getAllLtDeliveryAddress()
		},
		// 打开地址弹框
		openAddress(v) {
			this.addressObj.addrId = v.orderPostAddressId
			this.addressObj.orderId = v.id
			this.addressObj.splitOrder = this.orderData.splitOrder;			
			this.addressDialogVisible = true;
		},
		// 打开修改地址弹框
		editAddress(v){
			this.ruleForm = Object.assign({}, v);
			this.ruleForm.cityCode = v.chinaDto && v.chinaDto.idPath ? v.chinaDto.idPath : [];
			this.openDialogVisible();			
		},
		submitForm(){
			const data = {				
				...this.addressObj
			}
			if(!data.addrId){
				return this.$message.error('请选择收货地址');
			}
			this.$oucy.postRequest('/client/financial/schemeorder/modifyOrderAddress', data).then((res)=>{
				this.$emit('resetData');
				this.addressDialogVisible = false;
			})
		},
		openDialogVisible(){		    
		    this.$refs.addAddDialog.openDialogVisible()
		}
	}
};
</script>
<style scoped lang="less">
.topTitle {
	background: #f6f7f8;
	padding: 10px 30px;

	&:hover .dn {
		display: block;
	}
}

.hoverDn {
	&:hover .dn {
		display: block;
	}
}

.dn {
	display: none;
}

.banner {
	width: 100px;
	height: 100px;
	border: 1px solid #eaeaea;
}

.head {
	width: 24px;
	height: 24px;
	border-radius: 24px;
}

.setlet {
	border: 1px solid #f1f1f1;
	border-radius: 2px;
}

.setlet:hover {
	border: 1px solid #409eff;
	border-radius: 2px;
}

/*状态*/
.status {
	border-radius: 2px;
	font-weight: bold;
	font-size: 14px;
}

.E0 {
	/*红*/
	background: #fff2f2;
	color: #e0122a;
}

.F2 {
	/*灰*/
	background: #f2f2f2;
	color: #aaaaaa;
}

.F0 {
	/*黄*/
	background: #fff8f1;
	color: #f07b26;
}

.EF {
	/*绿*/
	background: #effcea;
	color: #70b651;
}

.F1 {
	/*蓝*/
	background: #f1f8ff;
	color: #2090ff;
}

.common_margin_20 {
	margin: 5px 0;
}



// 地址选择
.contentTop {
    display: flex;
    justify-content: space-between;
}


.tableHeader {
    width: 100%;
    display: flex;
    background: #FAFAFA;
    border-radius: 2px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: #555C68;
}

.cell-1 {
    flex: 1;
}

.cell-2 {
    flex: 6;
}

.cell-3,
.cell-4,
.cell-5,
.cell-6 {
    flex: 3;
	text-align: center;
}

.itemsHeader {
    text-align: left;
    display: flex;
    align-items: center;

    span {
        display: inline-block;
        background: #E3EFFF;
        padding: 2px 8px;
    }
}

.item {
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border-radius: 2px;

    &.select {
        border: 1px solid #2090FF;
        background: #fff;
        position: relative;

        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0px;
            height: 0px;
            border: 10px solid #2090FF;
            border-top-color: transparent;
            border-right-color: transparent;
        }
    }

}

.item+.item {
    margin-top: 2px;
}

</style>
