import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import oucy from "./util/oucyUtil";
import eventBus from "./store/bus";
// import store from './store'
import "./assets/style/global.css";
import "./assets/style/common.less";
import "./assets/style/iconfont/iconfont.css";
import ListenerPageVisibility from "./util/ListenerPageVisibility.js";

Vue.use(ElementUI);
Vue.config.productionTip = false;

oucy.initAxios();
oucy.initJAVASCRIPT();
Vue.prototype.$oucy = oucy;
Vue.prototype.eventBus = eventBus;

import VueQuillEditor from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

import Vuex from "vuex";
Vue.use(Vuex);

// 点赞特效
import vueClapButton from "vue-clap-button";
Vue.use(vueClapButton);

import VueCropper from "vue-cropper";
Vue.use(VueCropper);
import "./components/index.js";
import VueDND from "awe-dnd";
Vue.use(VueDND);

import VueSuperSlide from "vue-superslide";
Vue.use(VueSuperSlide);

const store = new Vuex.Store({
  state: {
    statu: 0, //入驻状态(企业)
    status: 0, //认证状态(企业)
  },
  mutations: {
    setStatu(state, statu) {
      state.statu = statu;
    },
    setStatus(state, status) {
      state.status = status;
    },
  },
});

// 前
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
  oucy.path=to.path
});
// 后
router.afterEach((to, from, next) => {
    oucy.recordPageAction({ record: from.meta && from.meta.title ,path:from.path});
  // 用户操作记录
  // oucy.receiveUserRecordEvent({ record: to.meta && to.meta.title });
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// 错误处理
// window.onerror = handleOnError
// window.addEventListener('error', handleListenerError, true);

function handleOnError(
  errorMessage,
  scriptURI,
  lineNumber,
  columnNumber,
  errorObj
) {
  // console.error(errorMessage)
  // some code
  // 　　return true // 阻止浏览器console输出
}

function handleListenerError(eventErr) {
  // console.log(eventErr)
  // some code
  // 　　eventErr.preventDefault() // 阻止浏览器console输出
}

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err);
  console.log(err.toString());
  console.log(err.stack);
  // oucy.receiveErrorEvent({ stack: err.stack, record: err.toString() })

  // console.log(vm)
  // console.log(info)
  // 错误上报到收集报错的平台
  // captureError(err);
};
Vue.config.performance = true;

// 调用实例
let navChange = new ListenerPageVisibility();
navChange.linsternVisibility(
  // 页面是隐藏状态执行方法
  () => {
    oucy.isHidden = true;
  },
  // 页面是可见状态执行方法
  () => {
    oucy.isHidden = false;
  }
);

import { sessionSet, sessionGet, sessionDel } from "@/store/store";
window.onunload = function () {
  // dosomethings
  let count = sessionGet("count");
  if (count) {
    sessionSet("count", --count);
  }
};
let count = sessionGet("count");
sessionSet("count", count ? ++count : 1);

// import SharedWorker from './util/worker.js'

// 这段代码是必须的，打开页面后注册SharedWorker，显示指定worker.port.start()方法建立与worker间的连接
// if (typeof Worker === "undefined") {
//     alert('当前浏览器不支持webworker')
// } else {
//     let worker = new SharedWorker('main',(res)=>{
//         console.log('注册',res)
//     })
//     worker.port.addEventListener('message', (e) => {
//         console.log('来自worker的数据：', e.data)
//     }, false)
//     worker.port.start()
//     window.worker = worker

//     window.worker.port.postMessage(count)
// }

