import oucy from '@/util/oucyUtil.js'
// 企业相册管理
const enterprisePhotoAlbum = {
	/**
     * 新增企业相册[两端通用]
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    addEnterprisePhotoAlbum: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisephotoalbum/addEnterprisePhotoAlbum', params)
    },
    /**
     * 查询企业的相册(客户端使用)
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllEnterprisePhotoAlbumsClient: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisephotoalbum/getAllEnterprisePhotoAlbumsClient', params)
    },
    /**
     * 删除企业相册(客户端使用)
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    delEnterprisePhotoAlbum: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisephotoalbum/delEnterprisePhotoAlbum', params)
    },
    /**
     * 删除企业相册(客户端使用)
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    updateEnterprisePhotoAlbum: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisephotoalbum/updateEnterprisePhotoAlbum', params)
    },

}

export default enterprisePhotoAlbum
