// 工具类
import CryptoUtil from 'crypto-js'
console.log('crypto-js', CryptoUtil)
//约定密钥(与后端密钥保持一致)
let key = CryptoUtil.enc.Utf8.parse("fc6cb28893f45be0"); // 密钥16位长度字符   内容可自定义
let IV = CryptoUtil.enc.Utf8.parse("fc6cb28893f45be0"); //  密钥偏移量    16位长度字符



/**
 * AES对称加密 （CBC模式，需要偏移量）
 * @param {Object} params 明文参数
 */
export function encrypt(params) {
    //明文参数
    let str = CryptoUtil.enc.Utf8.parse(params);
    //加密
    let encryptedData = CryptoUtil.AES.encrypt(str, key, {
        iv: IV,
        mode: CryptoUtil.mode.CBC, //AES加密模式
        padding: CryptoUtil.pad.Pkcs7 //填充方式
    });
    console.log("加密前：" + params); //加密前：QWEASDZXC
    console.log("加密后：" + encryptedData); //加密后：vlgQpBdfVvMplxwOTjxSyQ==
    return CryptoUtil.enc.Base64.stringify(encryptedData.ciphertext); //返回base64格式密文
}

/**
 * AES对称解密
 * @param {Object} params 加密参数
 */
export function decrypt(params) {
    //base64格式密文转换
    let base64 = CryptoUtil.enc.Base64.parse(params);
    let str = CryptoUtil.enc.Base64.stringify(base64);
    //解密
    let decryptedData = CryptoUtil.AES.decrypt(str, key, {
        iv: IV,
        mode: CryptoUtil.mode.CBC, //AES加密模式
        padding: CryptoUtil.pad.Pkcs7 //填充方式
    });
    console.log("解密前：" + params); //解密前：vlgQpBdfVvMplxwOTjxSyQ==
    console.log("解密后：" + CryptoUtil.enc.Utf8.stringify(decryptedData).toString()); //解密后：QWEASDZXC
    return CryptoUtil.enc.Utf8.stringify(decryptedData).toString(); //返回明文
}