import oucy from '@/util/oucyUtil.js'
// 全局分类管理
const globalClassify = {
	/**
     * 查询全局分类树
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllGlobalClassify: (params) => {
        return oucy.postRequest('/client/product/globalclassify/getAllGlobalClassify', params)
    },

}

export default globalClassify
