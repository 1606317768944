<!-- 添加店铺 -->
<template>
    <div class="text-left">
        <el-dialog width="800px" :title="title" :visible.sync="dialogFormVisible2">
            <template>
                <div class="df-jc-s-b">
                    <el-input v-model="QueryFurnitureLibrary2.keyWord" placeholder="请输入关键字搜索" size="mini" class="m-l-10" ></el-input>
                    <el-button class="m-l-10" type="default" size="mini" @click="search2">搜索</el-button>
                </div>
                <el-table :data="prlductData2.content" style="width: 100%" ref="multipleTable2" height="400" @row-click="rowClick2">
                    <el-table-column type="" width="55">
                        <template slot-scope="scope">
                            <img src="@/assets/icon/select.png" class="checkbox " v-if="enterpriseListIds.includes(scope.row.id)">
                            <img src="@/assets/icon/un_select.png" class="checkbox " v-else>
                        </template>
                    </el-table-column>
                    <el-table-column prop="date" label="" width="100">
                        <template slot-scope="scope">

                            <el-avatar shape="square" :src="scope.row.enterpriseAvata" v-if="hasImportImg(scope.row.enterpriseAvata)"></el-avatar>
                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.enterpriseAvata" v-else></el-avatar>
                            <!-- </div> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuName" label="商家信息">
                        <template slot-scope="scope">
                            <div class="pointer line-1" :title="scope.row.enterpriseName" v-html="scope.row.enterpriseName"></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuPrise" label="星级">
                        <template slot-scope="scope">
                            <el-rate v-model="scope.row.enterpriseStar" disabled></el-rate>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-pagination class="p-t-20" v-if="prlductData2" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData2.size" :total="prlductData2.totalElements" @size-change="sizeChange2" @current-change="currentChange2" @prev-click="currentChange2" @next-click="currentChange2">
            </el-pagination>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="addEnterprise">确 定 <span v-if="multipleSelection2 && multipleSelection2.length">({{multipleSelection2.length}})</span></el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { spu, enterpriseinfo } from "@/service"

export default {
    name: "addEnterpriseDialog",
    props: {
        title: {
            type: String,
            default: '添加店铺',
        },

    },
    data() {
        return {
            // 添加商品
            dialogFormVisible2: false,
            prlductData2: {},
            QueryFurnitureLibrary2: {
                // category: 0,
                start: 0,
                limit: 10,
                keyWord: null,
            },
            keyWord: null,
            checked: true,
            checked2: false,
            multipleSelection2: [],
            enterpriseList: [],
            enterpriseListIds: [],
        }
    },
    mounted() {},
    methods: {

        // 添加店铺的开始
        // 切换页数
        sizeChange2: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary2.limit = pageSize;
            me.queryEnterpriseLibrary();
        },
        // 切换页码
        currentChange2: function(current) {
            const me = this;
            me.QueryFurnitureLibrary2.start = current - 1;
            me.queryEnterpriseLibrary();
        },
        // 查找
        search2() {
            this.QueryFurnitureLibrary2.start = 0
            this.queryEnterpriseLibrary()
        },

        // 打开店铺
        openDialogFormVisible2(enterpriseList=[]) {
            this.queryEnterpriseLibrary()
            this.multipleSelection2=this.deepCopy(enterpriseList)
            this.dialogFormVisible2 = true
            this.enterpriseListIds=this.getId(enterpriseList)  
        },
        // 添加加回调
        addEnterprise(v) {
            // this.enterpriseList=this.deepCopy(this.multipleSelection2)
            this.dialogFormVisible2 = !true
            this.$emit('callBack', this.multipleSelection2)
        },

        // 请求数据
        queryEnterpriseLibrary() {
            enterpriseinfo.queryEnterpriseLibrary(this.QueryFurnitureLibrary2).then(res => {
                this.prlductData2 = res || {}
            })
        },
        // 添加店铺的结束

        hasImportImg(v) {
            return v && v.startsWith('//')
        },
        rowClick2(row, column, event) {
            let index = this.enterpriseListIds.indexOf(row.id)
            if (index == -1) {
                this.multipleSelection2.push(row)
                this.enterpriseListIds.push(row.id)
            } else {
                this.multipleSelection2.splice(index, 1)
                this.enterpriseListIds.splice(index, 1)
            }
        },

        deepCopy(list){
            return JSON.parse( JSON.stringify(list))
        },
        getId(list){
            let ids=[]
            for(let v of list){
                ids.push(v.id)
            }
            return ids
        },
    },
}
</script>
<style scoped lang="less">
.box {
    // padding: 20px;
    background: #F6F7F8;
    border-radius: 2px;
}

/deep/ .el-textarea__inner {
    // padding: 5px 0;
    background: #F6F7F8;
    border: none;
}

.hasNest /deep/ .el-textarea__inner {
    // padding: 5px 0;
    background: #fff;
    border: none;
}

.spuList_item {
    position: relative;




    .deleteSpu {
        z-index: 99;
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px;
        cursor: pointer;
        display: none;
    }

    &:hover {
        .deleteSpu {
            display: inline-block;
        }
    }
}

.spuList {
    display: flex;
    flex-wrap: wrap;
}

.spuList_item {
    width: 200px;
    height: 288px;
    margin: 20px;
    position: relative;
}

.enterpriseList {
    display: flex;
}

.enterpriseList_item {}
.checkbox {
    width: 15px;
    height: 15px;
}
</style>