export function localSet(key, value) {
    if (!key || !value) return
    window.localStorage.setItem(key, JSON.stringify(value))
}

export function localGet(key) {
    let value = window.localStorage.getItem(key)
    if (value && value != "Undefined" && value != "undefined" && value != 'Null' && value != 'null') {
        try {
          return JSON.parse(value)
        } catch (err) {
          console.error(err)
          window.localStorage.removeItem(key)
          return null          
        }
    } else {
        return value
    }
}

export function localDel(key) {
    window.localStorage.removeItem(key)
}

export function locaClear(key) {
    window.localStorage.clear()
}

// 会话
export function sessionSet(key, value) {
    if (!key || !value) return
    window.sessionStorage.setItem(key, JSON.stringify(value))
}

export function sessionGet(key) {
    let value = window.sessionStorage.getItem(key)
    if (value && value != "Undefined" && value != "undefined" && value != 'Null' && value != 'null') {
        try {
          return JSON.parse(value)
        } catch (err) {
          console.error(err)
          window.sessionStorage.removeItem(key)
          return null          
        }
    } else {
        return value
    }
}

export function sessionDel(key) {
    window.sessionStorage.removeItem(key)
}

export function sessionClear(key) {
    window.sessionStorage.clear()
}

// Cookie
export function setCookie(key,value,exdays=365){
    var d = new Date();
    d.setTime(d.getTime()+(exdays*24*60*60*1000));
    var expires = "expires="+d.toGMTString();
    document.cookie = key+"="+value+"; "+expires;
}
export function getCookie(key){
    var name = key + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name)==0) { return c.substring(name.length,c.length); }
    }
    return "";
}