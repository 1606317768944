import oucy from '@/util/oucyUtil.js'
// 个人名片回复管理
const cardPersonReply = {

    /**
     * 个人名片评论回复
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    createCardPersonReply: (params) => {
        return oucy.postRequest('/client/card/cardpersonreply/createCardPersonReply', params)
    },

   
}

export default cardPersonReply
