import oucy from '@/util/oucyUtil.js'
// 个人名片评论管理
const cardPersonComment = {
	/**
     * 发布个人名片评论
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    createCardPersonComment: (params) => {
        return oucy.postRequest('/client/card/cardpersoncomment/createCardPersonComment', params)
    },
   	/**
     * 查询个人名片评论列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getCardPersonCommentList: (params) => {
        return oucy.postRequest('/client/card/cardpersoncomment/getCardPersonCommentList', params)
    },
       	/**
     * 查询个人名片评论列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getMyCardPersonCommentList: (params) => {
        return oucy.postRequest('/client/card/cardpersoncomment/getMyCardPersonCommentList', params)
    },
       	/**
     * 
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getMyCommentList: (params) => {
        return oucy.postRequest('/client/card/cardpersoncomment/getMyCommentList', params)
    },

}

export default cardPersonComment
