import oucy from '@/util/oucyUtil.js'
// 优惠券
const ticket = {
    /**
     * 发布优惠券
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    addCoupons: (params) => {
        return oucy.postRequest('/client/financial/ticket/addCoupons', params)
    },
    /**
     * 查询企业旗下优惠券
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getTicketByEnterprise: (params) => {
        return oucy.postRequest('/client/financial/ticket/getTicketByEnterprise', params)
    },
    /**
     * 删除优惠券
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    deleteTicket: (params) => {
        return oucy.postRequest('/client/financial/ticket/deleteTicket', params)
    },
    /**
     * 发布现有优惠券
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    release: (params) => {
        return oucy.postRequest('/client/financial/ticket/release', params)
    },
    /**
     * 修改优惠券
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateTicket: (params) => {
        return oucy.postRequest('/client/financial/ticket/updateTicket', params)
    },
    /**
     * 商家批量赠送用户优惠券
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    batchGiveTickets: (params) => {
        return oucy.postRequest('/client/financial/ticket/batchGiveTickets', params)
    },
    /**
     * 根据id查询优惠券详细信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getTicketById: (params) => {
        return oucy.postRequest('/client/financial/ticket/getTicketById', params)
    },
    /**
     * 查询用户所有当前有效的优惠券
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getMemberCardUseTicketList: (params) => {
        return oucy.postRequest('/client/financial/membercarduser/getMemberCardUseTicketList', params)
    },
    /**
     * 查询优惠券领取用户列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryTicketGetList: (params) => {
        return oucy.postRequest('/client/financial/ticket/queryTicketGetList', params)
    },
    /**
     * 查询优惠券领取数量
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryTicketGetNum: (params) => {
        return oucy.postRequest('/client/financial/ticket/queryTicketGetNum', params)
    },
    /**
     * 查询用户针对某款商品的优惠券列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getProductTicketForUser: (params) => {
        return oucy.postRequest('/client/financial/ticket/getProductTicketForUser', params)
    },    
    /**
     * 用户领取优惠券
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    takeCoupons: (params) => {
        return oucy.postRequest('/client/financial/ticket/takeCoupons', params)
    },
        /**
     * 查询用户针对某个店铺的优惠券列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getEnterpriseTicketForUser: (params) => {
        return oucy.postRequest('/client/financial/ticket/getEnterpriseTicketForUser', params)
    },        
    /**
     * 查询所有发布的优惠券
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getTicketByEnterpriseForPage: (params) => {
        return oucy.postRequest('/client/financial/ticket/getTicketByEnterpriseForPage', params)
    },
    /**
     * 领券中心
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryAllTicket: (params) => {
        return oucy.postRequest('/client/financial/ticket/queryAllTicket', params)
    },

}

export default ticket