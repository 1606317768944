// 雷达

const radar = [
    // 雷达
    {
        path: '/enter/radar',
        name: 'radar',
        component: () => import("@/views/Enterprise/app/radar/Index.vue"),
        meta: { title: '视频' }
    },    {
        path: '/enter/radarDetail',
        name: 'radarDetail',
        component: () => import("@/views/Enterprise/app/radar/detail.vue"),
        meta: { title: '视频' }
    },



];

export default radar