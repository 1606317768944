<!-- 直播公告 -->
<template>
    <div class="notice" style="background-color: #ffffff">
        <div class="notice_main">
            <div class="notice_left">
                <div class="notice_mid">
                    <div class="notice0_type">
                        <i class="el-icon-message-solid "></i>
                    </div>
                    <el-carousel height="19px" style="width: 100%;" direction="vertical" indicator-position="none" loop>
                        <el-carousel-item v-for="(v,i) of noticelist" :key="i">
                            <div class="df-jc-s-b df-ai-c" style="height:19px;overflow:hidden;" @click="callBack(v)">
                                <div class="notice_text pointer">
                                    <span class="noticeTitle line-1">{{v.message_body}}</span>
                                    <span class="noticeCreatetime">{{v.message_createtime}}</span>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { notice, liveroom } from '@/service/index.js'
export default {
    name: "liveNotice",
    props: {
        noticelist: {
            type: Array,
            default: function() {
                return []            }
        },

    },
    data() {
        return {
            pageWidth: oucy.pageWidth,
        }
    },
    mounted() {
    },
    methods: {
        callBack(v){
           this.$emit('callBack',v)
        }
    },
}
</script>
<style scoped lang="less">
    .notice {
        width: 100%;
        /*height: 19px;*/
        background: #f6f6f6;
        padding: 10px 0px;
        box-sizing: border-box;
    }

    .notice_left {
        width: 100%;
        /*width: 1200px;*/
        height: 19px;
    }

    .notice_main {
        height: 19px;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .notice_mid {
        display: flex;
        align-items: center;
    }

    .notice_text {
        font-size: 14px;
        color: #868C94;
        height: 19px;
        line-height: 1.5;
        // line-height: 19px;
        display: flex;
        justify-content: space-between;
        // width: 635px;
        flex: 1;
        padding-left: 5px;
    }

    .el-icon-message-solid {
        color: #868C94;
    }
    .noticeCreatetime{
        text-align: right;
        width: 350px;
    }
</style>