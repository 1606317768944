import oucy from '@/util/oucyUtil.js'
// 关键字过滤管理
const stopword = {
	/**
     * 查询是否包含关键字
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    assertStopwordsList: (params) => {
        return oucy.postRequest('/client/stopword/stopword/assertStopwordsList', params)
    },


}

export default stopword
