<!--  -->
<template>
    <div class="item_box pointer" @click="takeCoupons()" title="点击领取" :class="['coupon_'+v.ticketPoolBasic.ticketScope,v.ticketPoolBasic.isDead?'coupon_-1':'']">
        <div class="item_top">
            <div class="item_top_left"><span class="f12">￥</span>{{v.ticketPoolBasic.ticketFee}}</div>
            <div class="item_top_right">满{{v.ticketPoolBasic.ticketLimitFee}}元</div>
            <div class="f12">使用</div>
        </div>
        <div class="right">
            <!-- <div class="use" v-if="1 ||v.ticketPoolBasic.memberCardUserTicketUsed===true">{{v.ticketPoolBasic.memberCardUserTicketUsed?'已使用':'未使用'}}</div> -->
            <div class="right_top">
                <span class="type">优惠券</span>
                <!-- <span class="label line-1">{{v.ticketPoolBasic.ticketName}}</span> -->
                
                    <el-tooltip class="item" effect="dark" :content="v.ticketPoolBasic.ticketName" placement="top-start">
                        <span class="label line-1">{{v.ticketPoolBasic.ticketName}}</span>
                    </el-tooltip>
            </div>
            <div class="period">{{new Date(v.ticketPoolBasic.ticketEffectiveTime).format('yyyy-MM-dd')}}---{{new Date(v.ticketPoolBasic.ticketDeadTime).format('yyyy-MM-dd')}}</div>
            <div class="Release df-jc-s-b" v-if="source=='E'">
                <!-- <div>发布数量 {{v.ticketPoolBasic.ticketPubTotal}}</div> -->
                <!-- <div>已领数量 {{v.ticketGetTotal}}</div> -->
            </div>
                <span class="f12 c-2">适用范围：{{['全店通用','指定分类可用','指定商品可用'][v.ticketPoolBasic.ticketScope]}}</span>
            <!-- <div class="f12 c-2">适用商家：{{v.ticketPoolBasic.enterpriseShopName}}</div> -->
        </div>
        <div class="lijiliqi" :class="[v.valid?'valid':'']">
            <span v-if="v.valid">立 即 领 取</span>
            <span v-else>已 领 取</span>
        </div>
    </div>
</template>
<script>
import { ticket } from "@/service"

export default {
    name: "coupon3",
    props: {
        v: {
            type: Object,
            default: function() {
                return {}
            }
        },
        source: {
            type: String,
            default: 'E' // E  A 
        }

    },
    data() {
        return {
            aliRts: null,
            video: null,
        }
    },
    created(){
        console.log('created3')
    },
    mounted() {
        console.log('mounted3')
    },
    methods: {
        takeCoupons() {
            ticket.takeCoupons({ ticketId: this.v.ticketPoolBasic.id }).then(res => {
                this.$message('领取成功')
                this.v.valid = !this.v.valid
            })
        }
    },
}
</script>
<style scoped lang="less">
.items {

    display: flex;
}

.item_top {
    text-align: center;
    color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(137deg, #5FB3F7 0%, #2090FF 100%);
}

.items {
    flex-wrap: wrap;
}


.item_box {
    background: #366;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    background-size: 100% 100%;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
}

.item {

    margin: 0 15px;
    margin-bottom: 50px;
}


.right {
    flex: 1;
    position: relative;
    width: 100%;

    .right_top {
        display: flex;
        align-items: center;
    }

    padding: 12px;

    .type {
        text-align: center;
        width: 42px;
        font-size: 12px;
        display: inline-block;
        padding: 4px 10px;
        background: #2090FF;
        color: #fff;
        border-radius: 20px;
    }

    .label {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #3A4454;
        margin-left: 4px;
        width: 160px;
        margin-bottom: 0;
    }
}

.item_top_left {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*color: #ED6732;*/
    /*border-right: 1px solid #E7ECF1;*/
    width: 90px;
}

.item_top_right {
    padding-top: 5px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    /*font-weight: bold;*/
    /*color: #555C68;*/
}

.period,
.Release {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #AAAAAA;
    margin-top: 10px;
}

.period span,
.Release span {
    color: #555C68;
}

.use {
    position: absolute;
    top: 8px;
    right: -40px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateZ(45deg);
    background: rgba(102, 102, 102, 0.39);
    font-size: 12px;
    color: #fff;
    padding: 5px 40px;
}

.coupon_0 {
    .item_top {

        background: linear-gradient(137deg, #5FB3F7 0%, #2090FF 100%);
    }

    .type {
        background: #2090FF;

    }
}

.coupon_-1 {
    .item_top {
        background: #DBDBDB;
    }

    .type {
        background: #DBDBDB;

    }
}

.lijiliqi {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../assets/images/coupon/right_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    writing-mode: tb-rl;
    padding: 0 20px;
    font-size: 14px;
    color: #fff;
    -webkit-filter: grayscale(100%);

    &.valid {
        -webkit-filter: grayscale(0);

    }
}
</style>