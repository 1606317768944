import oucy from '@/util/oucyUtil.js'
// 企业入驻
const enterpriseinfo = {
	/**
     * 查询企业库
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryEnterpriseLibrary: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/queryEnterpriseLibrary', params,false)
    },

   	/**
     * 获取店铺首页商品集[包含主推商品、新品推荐、热销产品，每种最多10件]
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryEnterpriseHomepageSpu: (params) => {
        return oucy.postRequest('/client/product/spu/queryEnterpriseHomepageSpu', params)
    },
    /**
     * 根据企业编码查询企业信息
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    findEnterpriseByLtUserAuthId: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/findEnterpriseByLtUserAuthId', params)
    },
    /**
     * 获取首页随机30个VIP企业
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getVipEnterprise: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/getVipEnterprise', params)
    },
    /**
     * 查询店铺升级支付订单记录
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryOrderShopLevel: (params) => {
        return oucy.postRequest('/client/financial/enterpriseupgrade/queryOrderShopLevel', params)
    },
    /**
     * 企业拥有者申请退款
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    refundMargin: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/refundMargin', params)
    },
        /**
     * 申请加入企业
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    applyEnterpriseEmployee: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/applyEnterpriseEmployee', params)
    },
    /**
     * 查询店铺认领状态
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryApplyEnterpriseStatus: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/queryApplyEnterpriseStatus', params)
    },
    /**
     * 设置企业员工
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    setEmployee: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/setEmployee', params)
    },
    /**
     * 查询企业员工列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getEmployee: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/getEmployee', params)
    },
    /**
     * 解除企业员工关系
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    removeEmployee: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/removeEmployee', params)
    },
        /**
     * 查询企业员工权限信息[
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryEmployeeMenuAuth: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/queryEmployeeMenuAuth', params)
    },
            /**
     * 设置企业员工菜单权限[
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    setEmployeeMenuAuth: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/setEmployeeMenuAuth', params)
    },
    /**
     * 查询企业客服列表[
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryEnterpriseCustomer: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/queryEnterpriseCustomer', params)
    },
        /**
     * 根据企业编码查询企业收入、订单总数、浏览量
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryEnterpriseByEnterpriseId: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/queryEnterpriseByEnterpriseId', params)
    },

    /**
    * 获取用户所绑定的企业
    * @param  {object} 请求的参数
    * @return {Promise} 返回一个Promise
    */
    queryEnterpriseByUser: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/getEnterpriseByUser', params,false)
    },
}

export default enterpriseinfo
