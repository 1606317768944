<!-- 更多 -->
<template>
    <div class="text-center f12 p-10">
        <div class="c-2" v-show="data.hasMore">没有更多了</div>
        <div class="click" v-show="!data.hasMore" @click="callback">{{hasClick?'正在':'点击'}}<span>加载更多</span></div>
    </div>
</template>
<script>
// import { message, schemeOrder } from "@/service/index"
export default {
    name: "hasMore",
    props: {
        data: {
            type: Object,
            default: function() {
                return {}
            }
        },
    },
    data() {
        return {
            hasClick: false,
        }
    },
    mounted() {},
    methods: {
        callback(v) {
            if (this.hasClick) {
                return
            }
            this.hasClick = true
            setTimeout(() => {
                this.hasClick = !true
            }, 1000)
            this.$emit('callback', this.data)
        },
    },
}
</script>
<style scoped lang="less">
.click {
    color: #2090FF;
    cursor: pointer;
}
</style>