import oucy from '@/util/oucyUtil.js'
// 海报参数管理
const poster = {
    /**
     * 生成店铺海报
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    generateEnterprisePoster: (params) => {
        return oucy.postRequest('/client/auth/poster/generateEnterprisePoster', params)
    },
    /**
     * 生成商品海报
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    generateSpuPoster: (params) => {
        return oucy.postRequest('/client/auth/poster/generateSpuPoster', params)
    },
    /**
     * 生成分销海报
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    generateDistributionPoster: (params) => {
        return oucy.postRequest('/client/auth/poster/generateDistributionPoster', params)
    },
        /**
     * 海报
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createPlayBillForList: (params) => {
        return oucy.postRequest('/client/auth/playbill/createPlayBillForList', params)
    },
    

}

export default poster