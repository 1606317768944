// 优惠券

const coupon = [
    // 发布优惠券
    {
        path: '/enter/CouponCreate',
        name: 'CouponCreate',
        component: () => import("@/views/Enterprise/app/Coupon/Create/Create.vue"),
        meta: { title: '发布优惠券' }
    },
    // 管理优惠券
    {
        path: '/enter/CouponManagement',
        name: 'CouponManagement',
        component: () => import("@/views/Enterprise/app/Coupon/Management/Management.vue"),
        meta: { title: '管理优惠券' }
    }, 
    // 管理优惠券
    {
        path: '/enter/CouponDetail',
        name: 'CouponDetail',
        component: () => import("@/views/Enterprise/app/Coupon/detail/detail.vue"),
        meta: { title: '优惠券详情' }
    },


];

export default coupon