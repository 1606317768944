<!-- 上传视频 -->
<template>
    <div>
        <el-upload  ref="upload" accept="video/mp4" :auto-upload="true"  class="avatar-uploader" action="fakeaction" :show-file-list="false" :http-request="handleAvataImgUpload" :limit="1" :list-type="listType">
            <slot></slot>
        </el-upload>       
    </div>
</template>
<script>
import oss from "@/util/oss.js"
export default {
    name: "uploadVideo",
    props: {
        size:{
            type:String,
            default:'medium',//medium mini  
        },
        listType:{
            type:String,
            default:'',//medium mini  
        },
        baseHost:{
            type:String,
            default:'',//medium mini  
        },
        limit:{
            type:Number,
            default:1
        },
        multiple:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            // 防止重复提交
            loading: false,
            // 上传文件对列
            uploadFileList:[],
            setTimeoutId:null,
        }
    },

    methods: {

        // 上传
        handleAvataImgUpload: function(params) {
            const me = this;
            const file = params.file;
            const isLt1M = file.size / 1024 / 1024/ 1024 < 1
            if (!isLt1M) {
                this.$message.error('上传文件大小不能超过 1GB!')
                return false
            }
            if(file.type != 'video/mp4'){
                this.$alert('不支持的文件类型,请重新选择')
                return
            }
            // 定时器是为了解决 多个上传时弹框重叠
            setTimeout(()=>{
                let Oss=new oss()
                Oss.startUpload(file).then(res=>{
                    console.log(res)
                    me.$emit('uploadVideoBack', res)
                    this.limit==1 && this.$refs.upload.clearFiles()
                }).catch(err=>{
                    console.log(err)
                })
            })
            return
/*            me.$oucy.uploadRequest(file).then(res => {
                console.log(666)
                me.$emit('uploadVideoBack', res)
                this.limit==1 && this.$refs.upload.clearFiles()
            });*/
        },

    }
}
</script>
<style scoped>
.avatar.medium,.avatar-uploader-icon.medium{
    width: 146px;
    height: 146px;
    line-height: 146px;
}
.avatar.mini,.avatar-uploader-icon.mini{
    width: 46px;
    height: 46px;
    line-height: 46px;
}

.avatar-uploader>>>.el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    margin: 0 8px 8px 0;
}

.avatar-uploader>>>.el-upload:hover {
    border-color: #409EFF;
}

.cropper-content .cropper {
    width: auto;
    height: 300px;

}
</style>