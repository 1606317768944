import oucy from '@/util/oucyUtil.js'
// 用户详细信息
const userMaterial = {
	/**
     * 查询用户个人详细详细
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryUserMaterial: (params={}) => {
        return oucy.postRequest('/client/user/userauth/queryUserMaterial', params)
    },
	/**
     * 修改用户个人详细详细
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    updateUserMaterial: (params) => {
        return oucy.postRequest('/client/user/userauth/updateUserMaterial', params)
    },

}

export default userMaterial
