<template>
    <div style="text-align: left;" class="selectUserInput">
        <el-autocomplete popper-class="my-autocomplete" v-model="state" :fetch-suggestions="querySearch" :trigger-on-focus="false" :hide-loading="true" placeholder="请输入用户ID或用户名称" @select="handleSelect" style="width: 100%;">
            <!-- <i class="el-icon-edit el-input__icon" slot="suffix" @click="handleIconClick"> -->
            <!-- </i> -->
            <template slot-scope="{ item }">
                <div class="df-jc-s-b df-ai-c user">
                    <div class="df-ai-c">
                        <div>
                            <el-avatar shape="square" :src="$oucy.ossUrl+item.ltUserDetailFull.detailAvatar" v-if="item.ltUserDetailFull && item.ltUserDetailFull.detailAvatar"></el-avatar>
                            <!-- <img src="@/assets/images/message/system_icon.png" class=""> -->
                        </div>
                        <div class="m-l-10">
                            <div class="name">{{item.ltUserDetailFull && item.ltUserDetailFull.detailWechatNickname}}</div>
                            <span class="addr">{{ item.userPhoneNumber }}</span>
                        </div>
                    </div>
                    <div>
                        <el-button size="mini" type="" @click.stop="callBackcreateSession(item)">聊天</el-button>
                        <el-button size="mini" type="">添加</el-button>
                    </div>
                </div>
            </template>
        </el-autocomplete>

    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { adEnterprise, spu, userAuth } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "selectUserInput",
    data() {
        return {

            addObj: {},
            dialogFormVisible: false,
            limit: 50,
            start: 0,
            prlductData: {},
            KeyName: null,
            adImgFlag: 0,

            restaurants: [],
            state: ''
        }
    },
    props: {
        form: {}
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.adImgFlag = this.$route.query.adImgFlag

    },
    methods: {

        callBackcreateSession(v) {
            console.log(v)
            this.$emit('callBackcreateSession', v)
        },
        callBack(v) {
            console.log(v)
            this.$emit('callBack', v)
        },


        querySearch(queryString, cb) {
            console.log(queryString)
            if(queryString){
                userAuth.getAllUser({
                    search: queryString,
                    limit: this.limit,
                    start: this.start,
                    isShelves: true, //  是否上下架 false 下架 ，true 上架
                    spuAuditStatus: 2, //审核状态 1待审核 2审核通过 3审核驳回
                    enterpriseId: this.enterpriseId
                }).then(res => {
                    console.log(res)
                    cb(res && res.content);
                    // this.prlductData = res
                });
            }else{
                cb([]);
            }
        },

        handleSelect(item) {
            console.log(item);
            this.$emit('callBack', item)
        },
        handleIconClick(ev) {
            console.log(ev);
        }

    }
}
</script>
<style scoped lang="less">
.avatar-uploader-icon {
    border: 1px dashed #c0ccda;
    width: 54px;
    height: 54px;
    line-height: 54px;
}

.avatar.mini {
    width: 54px;
    height: 54px;
}

.info {
    padding-left: 20px;
    line-height: 1.5;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
}

.index {
    background: #F2F4F6;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    padding: 18px;
    margin-bottom: 30px;
}

.spu {
    background: #F6F7F8;
    border-radius: 1px;
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
}

.spu .left {
    display: flex;
    align-items: center;

    span {
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #666666;
        padding-left: 24px;
    }

    img {
        width: 60px;
        height: 60px;
    }
}

.spu .right {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #333333;
}

</style>
<style>
 .el-autocomplete-suggestion li{
    line-height: 1.5;
    padding-top: 6px;
    padding-bottom: 6px;
}
</style>