// 个人直播

const live = [

    {
        path: '/live',
        name: 'live',
        component: () => import("@/views/live/live"),
        meta: { title: '直播' }

    },
    {
        path: '/liveList',
        name: 'liveList',
        component: () => import("@/views/live/list"),
        meta: { title: '直播列表' }

    }, {
        path: '/listSearch',
        name: 'listSearch',
        component: () => import("@/views/live/search"),
        meta: { title: '查找直播' }

    },


];

export default live