import oucy from '@/util/oucyUtil.js'
// 海报管理
const playbill = {
    /**生成二维码-小程序码
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    generateOrCode: (params) => {
        return oucy.postRequest('/client/auth/playbill/generateOrCode', params,false)
    },
    /**
     *  新
     * 生成二维码-小程序码
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    generateQrCode: (params) => {
        return oucy.postRequest('/client/auth/playbill/generateQrCode', params,false)
    },

}

export default playbill