import oucy from '@/util/oucyUtil.js'
// 企业视频
const enterprisevideoalbum = {
	/**
     * 查询企业视频分类
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllEnterpriseVideoAlbumsClient: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisevideoalbum/getAllEnterpriseVideoAlbumsClient', params)
    },  /**
     * 查询企业视频
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryAllVideoItemForPage: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisevideoalbumitem/queryAllVideoItemForPage', params)
    },
      /**
     * 添加视频分类
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    addEnterpriseVideoAlbum: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisevideoalbum/addEnterpriseVideoAlbum', params)
    },
          /**
     * 编辑视频分类
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateEnterpriseVideoAlbum: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisevideoalbum/updateEnterpriseVideoAlbum', params)
    },
      /**
     * 删除视频分类
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    delEnterpriseVideoAlbum: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisevideoalbum/delEnterpriseVideoAlbum', params)
    },
    /**
     * 新增企业视频
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    addEnterpriseVideoAlbumItem: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisevideoalbumitem/addEnterpriseVideoAlbumItem', params)
    },
    

}

export default enterprisevideoalbum
