<!-- 搜索框 上面tab版 -->
<template>
    <div class="input-container search">
        <div class="btns c-1">
            <div class="btn" :class="{'select':category==0}" @click="setCategory(0)">搜家具</div>
            <div class="btn" :class="{'select':category==1}" @click="setCategory(1)">搜材料</div>
            <div class="btn" :class="{'select':category==2}" @click="setCategory(2)">搜企业</div>
            <div class="btn" :class="{'select':category==3}" @click="setCategory(3)">搜名片</div>
            <!-- <div class="btn" :class="{'select':category==4}" @click="setCategory(4)">搜物流</div> -->
            <div class="btn" :class="{'select':category==5}" @click="setCategory(5)">搜求购</div>
        </div>
        <el-autocomplete v-model="keyword" :fetch-suggestions="querySearchAsync" :placeholder="category<2 && placeholderHints?placeholderHints:placeholder" @select="handleSelect" @change="keywordFiltr('clickSearch')" style="width: 750px;" >
<!--             <el-select v-model="category" slot="prepend" style="width: 100px;" >
                <el-option label="搜家具" :value="0"></el-option>
                <el-option label="搜材料" :value="1"></el-option>
                <el-option label="搜企业" :value="2"></el-option>
                <el-option label="搜名片" :value="3"></el-option>
                <el-option label="搜物流" :value="5"></el-option>
            </el-select> -->
            <el-button @click="keywordFiltr('clickSearch')" class="rightBtn" type="primary" slot="append">搜索</el-button>
            <el-button @click="go('/ReleaseCenterAskBuySave')" type="success" style="background-color: #ee7b27;border-color: #ee7b27;width: 120px;margin-left: 28px;border-radius: 6px;" slot="append">发布求购信息</el-button>
        </el-autocomplete>
        <div class="df hots" style="min-width: 900px;"  v-if="searchHots.length && category<2">
            <div class="hot pointer" v-for="(v,i) of searchHots" v-if="i<10" :key="i" @click="clickHot(v)">{{v.hot}}</div>
        </div>

    </div>
</template>
<script>
import { spu } from "@/service"
export default {
    name: "searchProduct",
    props: {
        
    },
    data() {
        return {
            // 关键字
            keyword:null,
            raw_query:null,
            old_keyword:null,
            // 分类
            category:0,
            placeholder:'请输入家具名称（例如：红木家具）',
            placeholderList:['请输入家具名称（例如：红木家具）','输入材料名称（如：岩板）','输入企业名称搜索（例如：问一得三）','输入公司名称或个人姓名搜索名片（例如：问一得三）','暂不可用','输入搜索关键词：（如轻奢 或 办公）'],

            searchClassify: "furniture",
            restaurants: [],
            searchHints: [], //底纹列表
            searchHots: [],//热搜列表
            placeholderHints:null,
            placeholderIndex:null,
            placeholderID:null,
            timeoutId:null,
        }
    },
    mounted() {
        if(this.$route.query.keyword){
            this.keyword=this.$route.query.keyword
        }
        this.searchHot()
 /*       this.placeholderIndex=0
        this.setPlaceholder()*/
    },
    methods: {
        setPlaceholder(){
            // console.log('setPlaceholder')
            if(this.searchHints.length>1){
               this.placeholderHints=this.searchHints[this.placeholderIndex].hint
               this.placeholderIndex = this.placeholderIndex==(this.searchHints.length-1)?0:this.placeholderIndex+1
               this.placeholderID = setTimeout(()=>{
                    clearTimeout(this.placeholderID)
                    this.setPlaceholder()
                },5000)
            }

        },
        clickHot(v){
            this.keyword=v.hot
            this.raw_query=v.hot
            this.clickSearch()
        },
        searchHot(){
            spu.searchHot({}).then(res=>{
                if(res){
                    if(res.searchHints){
                        this.searchHints=res.searchHints
                        this.placeholderIndex=0
                        this.setPlaceholder()
                        // this.placeholderHints=this.searchHints[this.placeholderIndex]
                    }
                    if(res.searchHots){
                        this.searchHots=res.searchHots
                    }
                }
            })
        },
        setCategory(v){
            this.category=v
            this.placeholder=this.placeholderList[v]
            if(this.category<2){
                this.placeholderHints= this.placeholder
                this.setPlaceholder()
            }else{
                this.placeholderID && clearTimeout(this.placeholderID)
            }
            
            
        },
        keywordFiltr(fn){
            if(!this.keyword && this.category<2 && this.placeholderHints){
               this.keyword= this.placeholderHints
            }
            this.$oucy.keywordFiltr(this.keyword).then(res=>{
               this[fn]() 
            },err=>{})
        },
        // 点搜索
        clickSearch: function() {
            // if(this.keyword!=null && this.keyword===this.old_keyword){
            //     console.log('已查询出当前关键字结果')
            //     return
            // }
            const me = this;
            console.log(this.searchClassify)
            if(this.category=='0'){
                if(!this.keyword){
                    this.$message.error('请输入要搜索的内容');
                    return
                }
                me.$router.replace('/SearchFurniture?keyword='+this.keyword+'&raw_query='+this.raw_query);
            }else
            if(this.category=='1'){
                if(!this.keyword){
                    this.$message.error('请输入要搜索的内容');
                    return
                }
                me.$router.replace('/SearchMaterial?keyword='+this.keyword+'&raw_query='+this.raw_query);
            }else
            if(this.category=='2'){
                me.$router.replace('/searchenterprise?keyword='+this.keyword+'&raw_query='+this.raw_query);
            }else
            if(this.category=='3'){
                me.$router.replace('/SearchCard?keyword='+this.keyword+'&raw_query='+this.raw_query);
            }else
            if(this.category=='5'){
                me.$router.replace('/SearchAskBuy?keyword='+this.keyword+'&raw_query='+this.raw_query);
            }
            this.old_keyword=this.keyword
        },
          go: function (path) {
            this.$router.push(path)
          },
        // 关键字触发
        querySearchAsync(queryString, cb) {
           this.timeoutId && clearInterval(this.timeoutId)
            if(this.category>1){
                cb([]);
                return
            }
            if(!this.keyword ||!queryString){
                cb([]);
                return
            }
            this.timeoutId = setTimeout(() => {
                spu.searchProductSuggest({category:this.category,keyword:queryString}).then(res => {
                    this.raw_query=queryString
                    var restaurants = []
                    if(res&&res.suggestions){
                        for(let v of res.suggestions){
                         restaurants.push({value:v})   
                        }
                    }
                    cb(restaurants);
                })
            }, 1000);
        },
        // 点选提示触发
        handleSelect(item) {
            console.log(item);
            this.keyword=item.value

            this.clickSearch()
        },
    },
    beforeDestroy() {
        this.placeholderID && clearTimeout(this.placeholderID)
    }
}
</script>
<style scoped lang="less">
    .search{
        display: inline-block;
        width: 700px;
        position: relative;
    }
    .btns{
        text-align: center;
        display: flex;
        /*justify-content: center;*/
        position: relative;
        /*left: -100px;*/
    }
    .btn{
        border-radius: 4px 4px 0px 0px;
        padding:5px 10px;
        margin: 0 15px;
        cursor: pointer;
    }
    .btn.select{
        background: #0366C3;
        color: #fff;

    }
  .search /deep/ .el-input-group__append button.el-button, .el-input-group__append div.el-select .el-input__inner, .el-input-group__append div.el-select:hover .el-input__inner, .el-input-group__prepend button.el-button, .el-input-group__prepend div.el-select .el-input__inner, .el-input-group__prepend div.el-select:hover .el-input__inner {
        background: #0366C3;
        color: #fff;
        border-radius: 0 6px 6px 0;
        border:1px solid #0366C3;
        width: 100px;
    }
   .search /deep/ .el-input__inner{
    border-color: #0366C3;
    border:2px solid #0366C3;
    background: transparent;
    }
    .hots{
        position:absolute;
    }
    .hot{
        font-size: 14px;
        font-weight: 400;
        color: #9BA3AB;
        margin: 10px;
        &:hover{
            color: #0563BC;
        }
    }
    /deep/ .el-input-group__append, /deep/ .el-input-group__prepend{
        background-color:rgba(0, 0, 0, 0);
        border:none;
    }
</style>