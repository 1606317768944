import oucy from '@/util/oucyUtil.js'
// 省市县三级联动
const china = {
	/**
     * 查询客户端用户的所有企业
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    findChinaTree: (params) => {
        return oucy.postRequest('/client/auth/china/findChinaTree', params)
    },

}

export default china
