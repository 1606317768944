<!-- 媒体框 支持图片 视频混排 -->
<template>
    <div class="boxs">
        <span v-for="(url,index) of previewSrcList" class="box" :key="index">
            <video class="video" :src="url" controls="1" v-if="getType(url)=='video'" :style="Mystyle"></video>
            <el-image :style="Mystyle" :src="url" :preview-src-list="imageList" v-else></el-image>
        </span>
    </div>
</template>
<script>
export default {
    name: "MyMediaBig",
    props: {
        srcList: {
            type: Array,
            default: [], //'video/mp4', //'image':''
        },
        Mystyle: {
            type: Object,
            default: function() {
                return {
                    'border-radius': '8px',
                    height: '169px',
                    margin:'10px',
                    'max-width':'90%',
                    'width':'auto'
                }
            }
        },

    },
    data() {
        return {
            imageList: [],
        }
    },
    computed: {
        previewSrcList: function() {
            let list = [],
                imageList = []
            for (let v of this.srcList) {
                let url = null
                if (v.startsWith('http')) {
                    url = v
                } else {
                    url = this.$oucy.ossUrl + v
                }
                list.push(url)
                if (this.getType(url) == 'image') {
                    imageList.push(url)
                }
            }
            this.imageList = imageList
            return list
        }
    },
    methods: {
        getType(url) {
            return url && url.endsWith('mp4') ? 'video' : 'image'
        },
        clickDel(v, i) {
            this.$emit('clickDel', { v: v, i: i })
        }

    }
}
</script>
<style scoped lang="less">
.boxs {

    display: inline-block;
    font-size: 0;

    span {
        font-size: 0;
        display: inline-block;
    }

    .box {
        position: relative;

        &:hover {
            .delete {
                display: inline-block;
            }
        }
    }

    .delete {
        position: absolute;
        padding: 10px;
        z-index: 999;
        right: 10px;
        top: 0px;
        display: none;

        // box-shadow: 1px 1px 3px #fff;
        &:hover {
            color: #f00;
        }
    }

    .video {
        border: 1px solid #f1f1f1;
    }
}
/deep/ .el-image__error,/deep/  .el-image__inner,/deep/  .el-image__placeholder{
    width: auto;
}
</style>