<!-- 签到 -->
<template>
  <div v-if="showSignIn">
    <div class="badge">
      <el-popover placement="left" width="600" trigger="click">
        <div>
          <div class="df-jc-s-b">
            <div class="df-ai-c m-l-20">
              <div class="m-r-30">
                我的积分
                <span class="c-5693ff">{{ signvalue }}</span>
              </div>
              <div class="m-r-30">
                连续签到
                <span class="c-5693ff">{{ signContinuous }}</span>
                天
              </div>
              <div class="m-r-30">
                <span>{{ getM() }}</span>
                月已签到
                <span class="c-5693ff">{{ signCount }}</span>
                天
              </div>
              <div class="m-r-30">
                <span>{{ getM() }}</span>
                月漏签
                <span class="c-5693ff">{{ noSignCount }}</span>
                天
              </div>
            </div>
            <div class="m-r-20">
              <el-button type="primary" size="mini" v-if="hasSign">
                已签到
              </el-button>
              <el-button type="primary" size="mini" v-else @click="doSign">
                签到
              </el-button>
            </div>
          </div>
          <el-calendar v-model="value" @pick="change">
            <template slot="dateCell" slot-scope="{ date, data }">
              <!-- <div>{{date}}</div> -->
              <div :class="data.isSelected ? 'is-selected' : ''">
                <div>
                  {{ data.day.split("-").slice(2).join("") }}
                  <span class="c-5693ff f20">
                    {{ signList.includes(data.day) ? "✔" : "" }}
                  </span>
                </div>
                <div>{{ getcalendar(data.day) }}</div>
              </div>
            </template>
          </el-calendar>
        </div>
        <el-button class="mybtn" slot="reference" @click="open">
          <img src="@/assets/icon/signIn.png" alt="" class="img" />
          <div class="f12">签到</div>
        </el-button>
      </el-popover>
    </div>
    <div class="signInBoxs" v-if="hasShowSignInBoxs">
      <div class="signInBox" v-if="!hasSign">
        <div class="signInBoxClose">
          <i class="el-icon-close" @click="close"></i>
        </div>
        <div class="signInBoxImgTitle">您今天还没有签到哦</div>
        <div class="signInBoxImgTitle2">签到可会获得奖励积分，兑换神秘礼包</div>
        <img
          class="signInBoxImg"
          src="@/assets/images/signin/not_signin.png"
          alt=""
        />
        <div class="signInBoxBtn" @click="doSign">立即签到</div>
      </div>
      <div v-else class="signinSucceedBoxs">
        <div class="signInBoxClose">
          <i class="el-icon-close" @click="close"></i>
        </div>
        <img
          class="signinSucceedImg"
          src="@/assets/images/signin/signin.png"
          alt=""
        />
        <div class="signinSucceedBox">
          <div class="signinSucceedTitle">签到成功</div>
          <div class="signinSucceedTitle2">
            已连续签到{{ signContinuous || 1 }}天
          </div>
          <div class="signinSucceedTitle3">+{{ addSign }}</div>
          <div class="signInBoxBtn" @click="close">知道了</div>
          <!-- <div class="signInBoxBtn" @click="alert('开发中敬请期待...')">赚取更多积分</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { sign } from "@/service/index";
  import { localSet, localGet, localDel } from "@/store/store";
  import calendar from "./calendar.js";
  // console.log(emoji)
  export default {
    name: "signIn",
    props: {},
    data() {
      return {
        noSignCount: null,
        signContinuous: null,
        signCount: null,
        signList: [],
        date: new Date(), //当前
        value: new Date(), //日历
        signTime: new Date(), // 提交后台
        showSignIn: false,
        isShow: false,
        hasSign: false,
        signvalue: 0,
        hasShowSignInBoxs: false,
        addSign: 0,
      };
    },
    mounted() {
      //监听登录事件
      this.eventBus /*.$off(this.$oucy.msgKeys.LOGIN_SUCCESS)*/
        .$on(this.$oucy.msgKeys.LOGIN_SUCCESS, (res) => {
          console.log("登录成功");
          this.initMessage();
        });
      //监听退出登录事件
      this.eventBus /*.$off(this.$oucy.msgKeys.LOGOUT_SUCCESS)*/
        .$on(this.$oucy.msgKeys.LOGOUT_SUCCESS, (res) => {
          console.log("退出登录");
          this.showSignIn = false;
        });
      if (this.$oucy.getToken()) {
        this.initMessage();
      }
    },
    watch: {
      value: {
        handler(val, oldVal) {
          let val_ymd = val.format("yyyy-MM-dd");
          let signTime_ymd = this.signTime.format("yyyy-MM-dd");
          if (
            val_ymd.split("-").slice(0, 2).join("") !=
            signTime_ymd.split("-").slice(0, 2).join("")
          ) {
            this.signTime = val;
            this.getSignInfo();
          }
        },
      },

      $route: {
        handler(val, oldVal) {
          if (val.path == "/" && this.hasSign == false) {
            this.getSignByDate();
          }
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      close() {
        this.hasShowSignInBoxs = false;
      },
      initMessage() {
        // this.showSignIn = true
        this.showSignIn = false;
        this.getSignByDate();
      },
      doSign() {
        sign.doSign({}).then(
          (res) => {
            this.addSign = res;
            this.hasSign = true;
            this.getSignByDate();
            this.querySignValue();
            this.getSignInfo();
          },
          (err) => {
            this.$message(err.errMessage);
            this.close();
            console.log(err);
          }
        );
      },
      open() {
        this.getSignInfo();
        this.getSignByDate();
        this.querySignValue();
      },
      getM() {
        let val_ymd = this.date.format("yyyy-MM");
        let signTime_ymd = this.signTime.format("yyyy-MM");
        if (
          val_ymd.split("-").slice(0, 2).join("") ==
          signTime_ymd.split("-").slice(0, 2).join("")
        ) {
          return "本";
        } else {
          return signTime_ymd.split("-").slice(1).join("");
        }
      },
      getcalendar(date) {
        // console.log(date)
        let ydm = date.split("-");
        let d = calendar.solar2lunar(ydm[0], ydm[1], ydm[2]) || {};
        return d.IDayCn;
      },
      getSignByDate() {
        sign
          .getSignByDate({ signTime: new Date().format("yyyy-MM-dd hh:mm:ss") })
          .then((res) => {
            this.hasSign = res;
            if (
              window.location.pathname == "/" &&
              this.hasShowSignInBoxs == false &&
              this.hasSign == false
            ) {
              // this.hasShowSignInBoxs=true
              this.hasShowSignInBoxs = false;
            }
          });
      },
      querySignValue() {
        sign
          .querySignValue({
            signTime: new Date().format("yyyy-MM-dd hh:mm:ss"),
          })
          .then((res) => {
            this.signvalue = res;
          });
      },
      setShow() {},
      // 获取用户指定日期所在月份的签到情况
      getSignInfo() {
        /**
            "noSignCount": 0, 未签到天数
            "signContinuous": 0, 连续签到天数
            "signCount": 0,已签到天数
            "signList": [
                {
                    "dataStr": "",
                    "isSign": true
                }
            ]             
             */
        sign
          .getSignInfo({
            signTime: this.signTime.format("yyyy-MM-dd hh:mm:ss"),
          })
          .then((res) => {
            this.noSignCount = res.noSignCount;
            this.signContinuous = res.signContinuous;
            this.signCount = res.signCount;
            let signList = [];
            for (let v of res.signList || []) {
              if (v.isSign) signList.push(v.dataStr);
            }
            this.signList = signList;
          });
      },
      change(e) {
        console.log(e);
      },
      alert() {
        this.$alert("开发中敬请期待...");
      },
    },
    beforeDestroy() {},
  };
</script>
<style scoped lang="less">
  .badge {
    // position: fixed;
    position: relative;
    z-index: 9999;
    // right: 42px;
    // bottom: 110px;
    cursor: pointer;
    line-height: 1;
  }

  .c-5693ff {
    color: #5693ff;
  }

  /deep/ .el-calendar-table .el-calendar-day {
    height: 58px;
  }

  .mybtn {
    /*background: transparent;*/
    border: none;
    background: rgba(255, 255, 255, 0.7);
    // padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.07);
    width: 60px;
    height: 60px;
    .img {
      width: 28px;
    }
  }

  /deep/.mybtn.el-button {
    padding: 0;
  }

  .signInBoxs {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }

  .signInBox {
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 20px 40px;
    position: relative;
  }

  .signInBoxClose {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    padding: 10px;
  }

  .signInBoxImgTitle {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #333333;
  }

  .signInBoxImgTitle2 {
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    color: #666666;
    margin-top: 13px;
  }

  .signInBoxImg {
    width: 157px;
    height: auto;
    margin-top: 20px;
  }

  .signInBoxBtn {
    background: linear-gradient(180deg, #ffd600, #ff9e5e);
    border-radius: 21px;
    box-shadow: 0px 2px 8px 0px rgba(249, 165, 2, 0.47),
      0px 1px 10px 0px #ffbc46 inset;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    padding: 10px 0;
    margin-top: 38px;
    cursor: pointer;
    width: 185px;
    margin-left: auto;
    margin-right: auto;
  }
  .signinSucceedBox .signInBoxBtn {
    margin-top: 15px;
  }

  .signinSucceedBoxs {
    position: relative;
  }

  .signinSucceedBox {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    width: 288px;
    text-align: center;
  }

  .signinSucceedImg {
    width: 420px;
  }

  .signinSucceedTitle {
    font-size: 20px;
    font-weight: 700;
    color: #ffec98;
    text-shadow: 0px 2px 1px 0px #207bd5;
  }

  .signinSucceedTitle2 {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 10px;
  }

  .signinSucceedTitle3 {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 10px;
  }
  .signinSucceedBoxs .signInBoxClose {
    font-size: 18px;
    color: #fff;
    right: 70px;
    top: 90px;
  }
</style>
