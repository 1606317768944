<!-- 这个一行4个商品的的列表 -->
<template>
    <div>
        <div class="items">
            <div v-for="(v,i) in data" class="item" :key="v.id">
                <spu :data="v" :isShowStock="isShowStock"></spu>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "spu4List",
    props: {
        data: {
            type: Array,
            default: {},
        },
        isShowStock: {
            type: Boolean,
            default: true
        }

    },

    mounted() {},
    methods: {},
}
</script>
<style scoped lang="less">
.items {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap;
}

.item {
    flex-wrap: wrap;
    width: 305px;
    margin: 10px;
    background: #fff;
}
</style>