<!-- 全局进货车 还没有用到 -->
<template>
    <div class="text-left">
        <div @click="drawer = true" class="top_right_button">
            进
            货
            车
        </div>
        <el-drawer title="我是标题" size="400" :visible.sync="drawer" :with-header="false">
            <div class="df-jc-s-b box">
                <div class="left">
                    <div class="left-top df-jc-s-b">
                        <el-button type="text" icon="el-icon-circle-plus-outline">新建方案</el-button>
                        <el-button type="text" icon="el-icon-sort">价格排序</el-button>
                    </div>
                    <div class="left-lists">
                        <div class="left-list" :class="{select:i==1}" v-for="(v,i) of allScheme" :key="i">
                            <div class="df-jc-s-b">
                                <div class="name c-3 f14"> {{v.schemeName}}</div>
                                <el-button type="text" icon="el-icon-edit-outline" class="p-t-5 p-b-5"></el-button>
                            </div>
                            <div class="c-2 f14">x3</div>
                            <div class="price f16">￥{{v.schemePrise||v.schemeAdjustPrise}}</div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="right_top df-jc-s-b df-ai-c">
                        <div class="f18 bold c-3">欧简沙发</div>
                        <el-checkbox v-model="checked">全选</el-checkbox>
                    </div>
                    <div class="right_lists">
                        <div v-for="(v,i) of list" :key="i">
                            <div v-for="(vv,ii) of v.stockItems" :key="ii" class="right_list">
                                <el-checkbox v-model="checked" class="right_list_left"></el-checkbox>
                                <div class="img right_list_center"></div>
                                <div class="right_list_right p-l-10">
                                    <div class="line-2 f14 c-3">{{vv.itemName}}</div>
                                    <div class="df-jc-s-b">
                                        <div class="c-2 f12">#c0001双人位置 米白色</div>
                                        <el-button type="text" class="p-t-5" size="mini">删除</el-button>
                                    </div>
                                    <div class="df-jc-s-b">
                                        <div class="price f16">￥{{vv.itemPrise}}</div>
                                        <el-input-number v-model="num" @change="handleChange" :min="1" :max="9999" label="描述文字" size="mini"></el-input-number>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right_bottom df-jc-s-b df-ai-c">
                        <div class="f16">方案总价 <span class="price f18">￥4654654</span></div>
                        <el-button type="primary" class="">去结算</el-button>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { stock } from "@/service/index"
export default {
    name: "stock",
    props: {
        size: {
            type: String,
            default: 'medium', //medium mini  
        },

    },
    data() {
        return {
            drawer: false,
            checked: false,
            num: 0,
            schemeId: null,
            allScheme: [],
            list: [],
            obj: {},
        }
    },
    mounted() {
        this.getAllScheme()
    },
    methods: {
        handleChange() {},
        // 查询
        getAllScheme() {
            stock.getAllScheme({}).then(res => {
                this.allScheme = res || []
                if (this.allScheme[0]) {
                    this.schemeId = this.allScheme[0].schemeId
                    this.getBySchemeId()
                }
            })
        },
        // 根据scheme编码查询进货车方案详细信息
        getBySchemeId() {
            stock.getBySchemeId({ schemeId: this.schemeId }).then(res => {
                for (let v of res.stockEnterprises) {
                    for (let vv of v.stockItems) {
                        vv.select = false
                    }
                }
                this.obj = res
                this.list = res.stockEnterprises
            })
        },
    }
}
</script>
<style scoped lang="less">
.top_right_button {
    position: fixed;
    right: 0;
    top: 0;
    padding: 10px 5px;
    width: 20px;
    color: #fff;
    background: #2090FF;
    border-radius: 4px 0px 0px 4px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
}

.box {
    height: 100vh;
}

.img {
    width: 80px;
    height: 80px;
    background: #f1f1f1;
}

.left,
.right {
    max-height: 100%;
    position: relative;
    // overflow: auto;
}

.price {
    color: #ED6732
}

.left-top {
    padding: 5px 10px;
}

.right_top {
    padding: 10px;
}

.left {
    background: #F4F4F4;
    display: flex;
    flex-direction: column;
}

.left-lists {
    width: 220px;
    flex: 1;
    overflow: auto;

}

.left-list {
    padding: 10px 20px;
    border-top: 1px #fff solid;

    &.select {
        background: #fff;
    }
}

.right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #fafafa;
}

.right_top {
    height: 50px;
    width: 100%;
    box-sizing: border-box;
}

.right_lists {
    overflow: auto;
    background: #fff;
}

.right_list {
    display: flex;
    align-items: center;
    padding: 10px;

    // border-top:1px #f1f1f1 solid;
    .right_list_left {
        width: 20px;
    }

    .right_list_center {
        width: 80px;
    }

    .right_list_right {
        flex: 1;
    }
}

.right_bottom {
    height: 68px;
    width: 100%;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    border-top: solid #f1f1f1 1px;

}
</style>