import oucy from '@/util/oucyUtil.js'
// 商品发布相关
const spu = {
    /**
     * 发布或者修改商品SPU(两端通用)
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createSpu: (params) => {
        return oucy.postRequest('/client/product/spu/createSpu', params)
    },
    /**
     * 单独修改产品列表字段[两端通用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateSpu: (params) => {
        return oucy.postRequest('/client/product/spu/updateSpu', params)
    },
    /**
     * 上架
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    putAway: (params) => {
        return oucy.postRequest('/client/product/spu/putAway', params)
    },
    /**
     * 下架
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    soldOut: (params) => {
        return oucy.postRequest('/client/product/spu/soldOut', params)
    },
    /**
     * 删除
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    deleteSpu: (params) => {
        return oucy.postRequest('/client/product/spu/deleteSpu', params)
    },
    /**
     * 查询家具库/材料库
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryFurnitureLibrary: (params) => {
        return oucy.postRequest('/client/product/spu/queryFurnitureLibrary', params)
    },
    /**
     * 根据SPU编码获取spu商品详情
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    querySpu: (params) => {
        return oucy.postRequest('/client/product/spu/querySpu', params)
    },

    /**
     * 搜索提示
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    searchProductSuggest: (params) => {
        return oucy.postRequest('/client/product/spu/searchProductSuggest', params,false)
    },
    /**
     * 商品列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    findAllFurnitureSpu: (params) => {
        return oucy.postRequest('/client/product/spu/findAllFurnitureSpu', params)
    },
    /**
     * 搜索商品
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    search: (params) => {
        return oucy.postRequest('/client/product/spu/search', params,false)
    },
    /**
     * 查询回收站产品列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getAllRecycleSpu: (params) => {
        return oucy.postRequest('/client/product/spu/getAllRecycleSpu', params)
    },
    /**
     * 单删除
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    deleteFurnitureSpuById: (params) => {
        return oucy.postRequest('/client/product/spu/deleteFurnitureSpuById', params)
    },
    /**
     * 彻底删除
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    deepDeleteFurnitureSpuById: (params) => {
        return oucy.postRequest('/client/product/spu/deepDeleteFurnitureSpuById', params)
    },
    /**
     * 恢复
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    recoverDeleteFurnitureSpuById: (params) => {
        return oucy.postRequest('/client/product/spu/recoverDeleteFurnitureSpuById', params)
    },
    /**
     * 随机查出20条产品数据
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getSpu: (params) => {
        return oucy.postRequest('/client/product/spu/getSpu', params)
    },
    /**
     * 查询企业店铺下的所有产品
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    innerSearchSpu: (params) => {
        return oucy.postRequest('/client/product/spu/innerSearchSpu', params)
    },
    /**
     * 查询企业店铺下销量前5的产品
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getSpuRankingWeight: (params) => {
        return oucy.postRequest('/client/product/spu/getSpuRankingWeight', params)
    },
    /**
     * 热搜和底纹
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    searchHot: (params) => {
        return oucy.postRequest('/client/product/spu/searchHot', params)
    },

    /**
     * 以图搜图
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    searchImage: (params) => {
        return oucy.postRequest('/client/product/spu/searchImage', params)
    },
}

export default spu