import oucy from '@/util/oucyUtil.js'
// 直播管理
const liveroomp = {
    /**
     * 获取直播间邀请榜
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getLiveRoomShareInvitationList: (params) => {
        return oucy.postRequest('/client/im/liveroomp/getLiveRoomShareInvitationList', params,false)
    },
    /**
     * 是否关注直播间
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    isFocusHostLiveRoom: (params) => {
        return oucy.postRequest('/client/im/liveroomp/isFocusHostLiveRoom', params,false)
    },
    /**
     * 直播间增加关注人数
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    saveLiveRoomAttention: (params) => {
        return oucy.postRequest('/client/im/liveroomp/saveLiveRoomAttention', params,false)
    },

    /**
     * 直播间点赞
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    saveLiveRoomLike: (params) => {
        return oucy.postRequest('/client/im/liveroomp/saveLiveRoomLike', params,false)
    },
    
    /**
     * 直播间增加点击次数 通过推送点击进来的
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    saveLiveRoomClick: (params) => {
        return oucy.postRequest('/client/im/liveroomp/saveLiveRoomClick', params,false)
    },

    /**
     * 直播间增加观看人数
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    saveLiveRoomWatch: (params) => {
        return oucy.postRequest('/client/im/liveroomp/saveLiveRoomWatch', params,false)
    },
        /**
     * 是否点赞直播间
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    isLikeLiveRoom: (params) => {
        return oucy.postRequest('/client/im/liveroomp/isLikeLiveRoom', params,false)
    },
        /**
     * 关注主播
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    saveUserAttention: (params) => {
        return oucy.postRequest('/client/im/liveroomp/saveUserAttention', params,false)
    },
    /**
     * 是否关注主播
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    isFocusHostUser: (params) => {
        return oucy.postRequest('/client/im/liveroomp/isFocusHostUser', params,false)
    },
    /**
     * 直播间增加分享次数
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    saveLiveRoomShareLine: (params) => {
        return oucy.postRequest('/client/im/liveroomp/saveLiveRoomShareLine', params,false)
    },

    // 直播轮播图管理
    // 
    // 
        /**
     * 查询所有的直播轮播图
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryAllLiveRoomBy: (params) => {
        return oucy.postRequest('/client/im/liveroomby/queryAllLiveRoomBy', params,false)
    },
}

export default liveroomp