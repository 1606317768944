<!-- 页面底部 -->
<template>
    <div class="navbottom">
        <div class="foot_top">
            <div class="foot_top_main">
            <!-- <div class="foot_top_main" :style="{width: this.$oucy.pageWidth+'px'}"> -->
                <div class="ftm_list">
                    <div class="ftm_list_title">
                        <h3 class="pointer" @click="$oucy.go('/About')">关于我们</h3>
                        <div class="ftm_jx"></div>
                    </div>
                    <div class="foot_list">
                            <!-- <p><a href="https://www.lesoujia.com/AfficheDetail?type=1&id=4b744b39a9d64c6ba76e66ff050bd66d" target="_blank">企业简介</a></p> -->
                            <!-- <p><a href="https://www.lesoujia.com/AfficheDetail?type=1&id=4b744b39a9d64c6ba76e66ff050bd66d" target="_blank">团队成员</a></p> -->
                            <p><router-link to="AfficheDetail">企业简介</router-link></p>
                            <p><router-link to="AfficheDetail">团队成员</router-link></p>
                            <p><a href="https://www.lesoujia.com/AfficheDetail?id=3a7b1b07076d47b6a83b5187fae8406e&type=1" target="_blank">隐私政策</a></p>
                    </div>
                </div>
                <div class="ftm_list">
                    <div class="ftm_list_title">
                        <h3>友情链接</h3>
                        <div class="ftm_jx" style="display: flex"></div>
                    </div>
                    <div class="foot_list">
<!--                            <p><a href="https://www.lecong1688.com" target="_blank">合作企业</a></p>-->
<!--                            <p><a href="https://www.i56.cc" target="_blank">i56爱物流</a></p>-->
                      <p><a href="https://www.lecong1688.com" target="_blank">乐从家具网</a></p>
                      <p><a href="https://www.lesoujia.com/AfficheDetail?id=a94330d553f14812903bed5f80334037&type=1" target="_blank">乐搜家平台规则</a></p>
                    </div>
                </div>
                <div class="ftm_list">
                    <div class="ftm_list_title">
                        <h3>客服中心</h3>
                        <div class="ftm_jx"></div>
                    </div>
                    <div class="foot_list">
                            <p><a href="tel:400-168-2356">业务咨询：400-168-2356</a></p>
                            <p><a href="tel:13380504699">合作加盟：13380504699</a></p>
                            <p><a href="tel:400-168-2356">售后服务：400-168-2356</a></p>
                    </div>
                </div>
                <div class="ftm_list">
                    <div class="ftm_list_title">
                        <h3>投诉建议</h3>
                        <div class="ftm_jx"></div>
                    </div>
                    <div class="foot_list">
                            <p><a href="https://www.lesoujia.com/AfficheDetail?type=1Ï&id=d7293b5badf04bb1a689478817c8a75e" target="_blank">在线投诉</a></p>
                            <p><a href="https://www.lesoujia.com/AfficheDetail?type=1&id=d7293b5badf04bb1a689478817c8a75e" target="_blank">意见反馈</a></p>
                            <p><a href="">400-168-2356</a></p>
                    </div>
                </div>
                <div class="ftm_list" style="display: flex;margin-top:30px">
                    <div class="ewm1">
                        <img src="../assets/QRCode/subscription_code.png">
                        <span class="ewm_text">公众号</span>
                    </div>
                    <div class="ewm2">
                        <img src="../assets/QRCode/applets_code.png">
                        <span class="ewm_text">小程序</span>
                    </div>
                </div>
            </div>
        </div>
      <div class="footer">
        <div style="text-align: center">
          <span>
            乐搜家 © 2021-2023
          ICP备案号：<a href="https://beian.miit.gov.cn/" target="_blank"> 粤ICP备17157497号 </a>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060402002291" target="_blank"> <img style="height:15px;width:15px;" src="@/assets/icon/gaba.png">粤公网安备44060402002291号</a> <br>《中华人民共和国增值电信业务经营许可证》编号：<a href="https://aliyunoss.lesoujia.com/web_image/lesoujia-dx.png" target="_blank" style="color:#FFF;">粤B2-20210912</a>
        </span>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "navbottom"
    }
</script>

<style scoped>
    .navbottom{text-align: left}
    .foot_top{width: 100%;height: 340px;background: #F3F3F3;}
    .foot_bottom{width: 100%;height: 54px;background: #6e7077;}
    .foot_top_main{margin: 0 auto;display: flex;justify-content: space-between;padding-top: 80px;width: 1200px;}
    .ftm_list_title h3{font-weight: 400;font-size: 24px;color: #111111;}
    .ftm_list_title img{width: auto;height: 20px;margin-left: 15px;}
    .foot_list{margin-top: 30px;}
    .foot_list p{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-top: 15px;
    }
    .foot_list a{
        text-decoration:none;
        color: #6e7077;
    }

    .ewm1{width: 100px;height:100px;text-align: center;background-color: #FFFFFF}
    .ewm2{width: 100px;height:100px;margin-left: 20px;text-align: center;background-color: #FFFFFF}
    .ewm1 img{width: 100%;height: 100%}
    .ewm2 img{width: 100%;height: 100%}
    .ewm_text{font-size: 14px;color: #111111;line-height: 34px;}
    .ftm_jx{width: 24px;height: 3px;background: #7B91A8;}




    .footer{
      width:100%;
      height: 80px;
      background: #2a2c33;
    }
    .footer a{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFEFE;
      line-height: 32px;
      text-align: center;
      text-decoration:none;
    }
    .footer span{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFEFE;
      line-height: 32px;
      text-align: center;
    }
</style>
