<!-- 分享海报 -->
<template>
    <div class="image__preview" v-if="showPoster" @click="showPoster=false">
        <div>
            <el-image style="height: 80vh;width: auto;" :src="$oucy.ossUrl + playbills[current]">
            </el-image>
            <div class="text-center" v-if="playbills && playbills.length > 1">
                <el-button-group>
                    <el-button :type="current==i?'primary':''" @click.stop="current=i" v-for="(v, i) of playbills" :key="i" size="mini">风格 {{i + 1}}</el-button>
                </el-button-group>
            </div>
        </div>
    </div>
</template>
<script>
import { poster } from '@/service'
import { localSet, localGet, localDel } from "@/store/store";
export default {
    name: "sharePoster",
    props: {},
    data() {
        return {
            showPoster: false,
            playbills: [],
            posterUrl: '',
            current: 0,
            params: null,
            redirectUrls: ['/pages/index/index', 'pages/index/index', "module/Enterprise/cardManagement/personalDetail", "module/furniture/Card/entDetail", "module/Enterprise/cardManagement/entStaffDetail", "module/Live/LiveCheck/LiveCheck", "module/Enterprise/seekBuy/seekBuyDetail", "module/furniture/productDetail/productDetail", "module/Video/content/video/customeVideo", "module/Login/CheckStore"]
        };
    },
    mounted() {

    },
    methods: {
        close() {
            this.showPoster = false;
        },
        show(params, ccallBack) {
            if (
                params &&
                this.params &&
                this.playbills &&
                this.playbills.length &&
                params.playbillCategory == this.params.playbillCategory &&
                params.redirectUrl == this.params.redirectUrl &&
                params.objectId == this.params.objectId
            ) {
                this.showPoster = true;
                return;
            }
            this.$message('生成海报中');
            // params.objectId  海报对象编码
            // params.playbillCategory  海报类型 分销1 、  名片： 个人2、名片  ：企业3 、 名片：员工4、直播间5、求购6、商品7 、视频8、 企业9
            // params.redirectUrl 海报二维码跳转地址
            const defaultUrl = '/pages/index/index';
            let loginUser = localGet(this.$oucy.userInfoKey) || {}
            let parentId = loginUser.id || '0'
            let objectId = params.objectId || params.id
            const data = {
                objectId: objectId,
                playbillCategory: params.playbillCategory,
                redirectUrl: this.redirectUrls[params.playbillCategory] || defaultUrl,
                redirectUrlScene: JSON.stringify({ ...params.redirectUrlScene, "parentId": parentId })
            };
            poster.createPlayBillForList(data).then(res => {
                if (res) {
                    this.showPoster = true;
                    this.playbills = res;
                    this.params = params;
                    this.$message('请点击右键保存');
                    ccallBack && ccallBack(res)
                } else {
                    this.$message({
                        title: '暂无海报',
                        icon: 'none',
                        mask: true
                    });
                }
            });
        },
    },
};
</script>
<style scoped lang="less">
.image__preview {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
}
</style>