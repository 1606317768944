import oucy from '@/util/oucyUtil.js'
// 佣金管理
const financial = {
    /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getCommissionList: (params) => {
        return oucy.postRequest('/client/financial/commission/getCommissionList', params)
    },
    /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getMemberCardTypeList: (params) => {
        return oucy.postRequest('/client/financial/membercardtype/getMemberCardTypeList', params)
    },
    /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getMemberCardUseList: (params) => {
        return oucy.postRequest('/client/financial/membercarduser/getMemberCardUseList', params)
    },
    /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryCardLevelTicket: (params) => {
        return oucy.postRequest('/client/financial/membercardlevelticket/queryCardLevelTicket', params)
    },
    /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryActivity4TigerVipSubsidy: (params) => {
        return oucy.postRequest('/client/financial/activity/queryActivity4TigerVipSubsidy', params)
    },
    /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryOrderMemberCards: (params) => {
        return oucy.postRequest('/client/financial/ordermembercard/queryOrderMemberCards', params)
    },
    /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createMemberCardOrder: (params) => {
        return oucy.postRequest('/client/financial/ordermembercard/createMemberCardOrder', params)
    },
        /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    enterpriseUpgrade: (params) => {
        return oucy.postRequest('/client/financial/enterpriseupgrade/enterpriseUpgrade', params)
    },
        /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryEffectTicket: (params) => {
        return oucy.postRequest('/client/financial/enterpriseupgrade/queryEffectTicket', params)
    },
        /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryUserBalance: (params) => {
        return oucy.postRequest('/client/financial/balance/queryUserBalance', params)
    },
        /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    ordermembercardQueryEffectTicket: (params) => {
        return oucy.postRequest('/client/financial/ordermembercard/queryEffectTicket', params)
    },
     /**
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    accSetupSendCode: (params) => {
        return oucy.postRequest('/client/financial/balance/accSetupSendCode', params)
    },
     /**
      * 标记会员卡取消
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    markOrderCancel: (params) => {
        return oucy.postRequest('/client/financial/ordermembercard/markOrderCancel', params)
    },/**
      * 标记店铺升级订单取消
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise 
     */
    markPayCancel: (params) => {
        return oucy.postRequest('/client/financial/enterpriseupgrade/markPayCancel', params)
    },
}

export default financial