<!-- 插入名片-->
<template>
    <div class="text-left">
        <el-dialog width="800px" :title="title" :visible.sync="dialogFormVisible">
            <template>
                <div class="df-jc-s-b">
                    <!-- <el-input v-model="QueryFurnitureLibrary.cardName" placeholder="请输入关键字搜索" size="mini" class="m-l-10" style=""></el-input> -->
                    <el-input placeholder="请输入内容" v-model="QueryFurnitureLibrary.cardName" class="input-with-select">
                      <el-select v-model="type" slot="prepend" @change="change" placeholder="请输入关键字搜索">
                        <el-option label="个人" :value="9"></el-option>
                        <el-option label="企业" :value="8"></el-option>
                      </el-select>
                      <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
                    </el-input>
                    <el-button class="m-l-10" type="primary" size="mini" @click="search">搜索</el-button>
                </div>
                <el-table :data="prlductData.content" style="width: 100%" height="400" @row-click="rowClick">
                    <el-table-column type="" width="55">
                        <div slot-scope="scope">
                            <img src="@/assets/icon/select.png" class="checkbox " v-if="spuListIds.includes(scope.row.id)">
                            <img src="@/assets/icon/un_select.png" class="checkbox " v-else>
                        </div>
                    </el-table-column>
                    <el-table-column prop="date" label="封面" width="100">
                        <template slot-scope="scope">       
                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.enterpriseAvata" v-if="type==8"></el-avatar>
                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.cardPic" v-if="type==9"></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuName" label="企业信息" v-if="type==8">
                        <template slot-scope="scope">
                            <div class="pointer line-1" :title="scope.row.spuEnterpriseName" v-html="scope.row.spuEnterpriseName"></div>
                            <div class="c-2 line-1" :title="scope.row.enterpriseSignature" v-if="scope.row.enterpriseSignature">{{scope.row.enterpriseSignature}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuName" label="个人信息" v-if="type==9">
                        <template slot-scope="scope">
                            <div class="pointer line-1" :title="scope.row.cardName">{{scope.row.cardName}}</div>
                            <div class="c-2 line-1" :title="scope.row.cardSelf" v-if="scope.row.cardSelf">{{scope.row.cardSelf}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuPrise" label="点赞次数">
                        <template slot-scope="scope">
                            <span class="spuPrise">{{scope.row.cardLike}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-pagination class="p-t-20" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSpu">确 定<span v-if="spuListIds && spuListIds.length">({{spuListIds.length}})</span></el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import { spu, enterpriseinfo ,cardEnterprise,cardPerson} from "@/service"

export default {
    name: "addCardDialog",
    props: {
        title:{
            type:String,
            default:'选择名片'
        }
    },
    data() {
        return {
            type:8,
            textarea: '',
            imageList: [],
            previewSrcList: [],

            // 添加商品
            dialogFormVisible: false,
            prlductData: {},
            QueryFurnitureLibrary: {
                // category: 0,
                start: 0,
                limit: 10,
                cardStatus: 1,
                cardName: null,
                enterpriseName: null,
                cardRegion: null,
            },
            spuName: null,
            adImgFlag: 0,
            multipleSelection: [],
            spuList: [],
            spuListIds: [],
        }
    },
    mounted() {},
    methods: {
        // 添加商品的开始
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            me.queryFurnitureLibrary();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            me.queryFurnitureLibrary();
        },
        // 查找
        search() {
            this.QueryFurnitureLibrary.start = 0
            this.queryFurnitureLibrary()
        },
        change(){
            this.prlductData={}
            this.QueryFurnitureLibrary.start = 0
           this.queryFurnitureLibrary() 
        },
        // 请求数据
        queryFurnitureLibrary() {
            this.QueryFurnitureLibrary.enterpriseName=this.QueryFurnitureLibrary.cardName
            if (this.type == 9) {
                cardPerson.getCardPersonList(this.QueryFurnitureLibrary).then(res => {
                    this.prlductData = res || {}
                })
            } else {
                cardEnterprise.getCardEnterpriseList(this.QueryFurnitureLibrary).then(res => {
                    this.prlductData = res || {}
                })
            }

/*            spu.queryFurnitureLibrary(this.QueryFurnitureLibrary).then(res => {
                this.prlductData = res
            });*/
        },

        // 打开商品
        openDialogFormVisible(list=[]) {
            this.queryFurnitureLibrary()
            this.dialogFormVisible = true
            this.multipleSelection=list //this.deepCopy(this.spuList)
            this.spuListIds=this.getId(list)

        },
        // 添加加回调
        addSpu() {
            // this.spuList=this.deepCopy(this.multipleSelection)
            this.dialogFormVisible = !true
            this.$emit('callBack',{type:this.type,list:this.multipleSelection})
        },
        // 删除
        deleteSpu(v, i) {
            this.spuList.splice(i, 1)
        },
        rowClick(row, column, event) {
            let index = this.spuListIds.indexOf(row.id)
            if (index == -1) {
                this.multipleSelection.push(row)
                this.spuListIds.push(row.id)
            } else {
                this.multipleSelection.splice(index, 1)
                this.spuListIds.splice(index, 1)
            }
        },
        // 添加商品的结束

        hasImportImg(v) {
            return v && v.startsWith('//')
        },

        deepCopy(list){
            return JSON.parse( JSON.stringify(list))
        },
        getId(list){
            let ids=[]
            for(let v of list){
                ids.push(v.id)
            }
            return ids
        },
    },
}
</script>
<style scoped lang="less">
.box {
    // padding: 20px;
    background: #F6F7F8;
    border-radius: 2px;
}

/deep/ .el-input-group__prepend {
    width: 50px;
}

/deep/ .el-textarea__inner {
    // padding: 5px 0;
    background: #fff;
    border: none;
}

.spuList_item {
    position: relative;




    .deleteSpu {
        z-index: 99;
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px;
        cursor: pointer;
        display: none;
    }

    &:hover {
        .deleteSpu {
            display: inline-block;
        }
    }
}

.spuList {
    display: flex;
    flex-wrap: wrap;
}

.spuList_item {
    width: 200px;
    height: 288px;
    margin: 20px;
    position: relative;
}

.enterpriseList {
    display: flex;
}

.enterpriseList_item {}
.checkbox {
    width: 15px;
    height: 15px;
}
</style>