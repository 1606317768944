import oucy from '@/util/oucyUtil.js'
// 用户操作记录控制器
const elk = {
	/**
     * 记录用户页面访问记录
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    recordPageAction: (params) => {
        return oucy.postRequest('/client/elk/useroperation/recordPageAction', params)
    },
    	/**
     * 雷达
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryRadar: (params) => {
        return oucy.postRequest('/client/elk/useroperation/queryRadar', params)
    },    	/**
     * 雷达
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryThread: (params) => {
        return oucy.postRequest('/client/elk/useroperation/queryThread', params)
    },

}

export default elk
