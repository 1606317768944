import oucy from '@/util/oucyUtil.js'
// 查询数量统一控制器
const dataNum = {
	/**
     * 查询用户进货车默认方案商品数量
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryStockNum: (params) => {
        return oucy.postRequest('/client/product/datanum/queryStockNum', params)
    },


}

export default dataNum
