// 分销

const distribution = [{
        path: '/acc/distribution/brokerage',
        name: 'accDistributionBrokerage',
        component: () => import("@/views/Account/app/distribution/brokerage"),
        meta: { title: '佣金明细' }
    },
    {
        path: '/acc/distribution/integral',
        name: 'accDistributionIntegral',
        component: () => import("@/views/Account/app/distribution/integral"),
        meta: { title: '积分明细' }
    },
    {
        path: '/acc/distribution/distribution',
        name: 'accDistributionDistribution',
        component: () => import("@/views/Account/app/distribution/distribution"),
        meta: { title: '分销管理' }
    },
     {
        path: '/acc/distribution/team',
        name: 'accDistributionKTeam',
        component: () => import("@/views/Account/app/distribution/team"),
        meta: { title: '我的团队' }
    },
     {
        path: '/acc/distribution/GotoBalance',
        name: 'accDistributionKGotoBalance',
        component: () => import("@/views/Account/app/distribution/GotoBalance"),
        meta: { title: '转到余额' }
    },
     {
        path: '/acc/distribution/finish',
        name: 'accDistributionKFinish',
        component: () => import("@/views/Account/app/distribution/finish"),
        meta: { title: '佣金转到余额结果' }
    },
];

export default distribution