// 钱包

const wallet = [{
        path: '/acc/wallet',
        name: 'wallet',
        component: () => import("@/views/Account/app/wallet/wallet"),
        meta: { title: '我的钱包' }
    },
    {
        path: '/acc/withdrawal',
        name: 'withdrawal',
        component: () => import("@/views/Account/app/wallet/withdrawal"),
        meta: { title: '余额提现' }
    },
    {
        path: '/acc/topUp',
        name: 'topUp',
        component: () => import("@/views/Account/app/wallet/topUp"),
        meta: { title: '余额充值' }
    },
    {
        path: '/acc/billDetail',
        name: 'billDetail',
        component: () => import("@/views/Account/app/wallet/billDetail"),
        meta: { title: '账单明细' }
    },
    {
        path: '/acc/balancePaySuccess',
        name: 'balancePaySuccess',
        component: () => import("@/views/Account/app/wallet/balancePaySuccess"),
        meta: { title: '账单明细' }
    }

];

export default wallet