<!-- 输入框合子 带上传图片-->
<template>
    <div class="inputBox box">
        <el-input type="textarea" autosize :rows="rows" :placeholder="placeholder" v-model="textarea" :autosize="{ minRows: 3, maxRows: 4}" maxlength="200" show-word-limit>
        </el-input>
        <div class="imageList">
            <span class="imgBox" v-for="(url,index) of previewSrcList" :key="index">
                <el-image class="img" :src="url" :preview-src-list="previewSrcList">
                </el-image>
                <i class="el-icon-delete delete" @click="deletIme(index)"></i>
            </span>
        </div>
        <div class="df-jc-s-b m-t-20">
            <div>
                <uploadImage @uploadImageBack="uploadImageBack" :multiple="true" v-if="hasShowUploadImage">
                    <i class="f26 el-icon-picture"></i>
                </uploadImage>
            </div>
            <div>
                <el-button size="mini" type="primary" @click="callback">{{btnText}}</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "inputBox",
    props: {
        btnText: {
            type: String,
            default: '回复',
        },
        placeholder: {
            type: String,
            default: '请输入评论内容',
        },
        hasShowUploadImage:{
            type:Boolean,
            default:true,
        },
        rows: {
            type: Number,
            default: 3,
        }

    },
    data() {
        return {
            textarea: '',
            imageList: [],
            previewSrcList: [],
        }
    },
    mounted() {},
    methods: {
        uploadImageBack(v) {
            this.imageList.push(v)
            this.previewSrcList.push(this.$oucy.ossUrl + v)
        },
        deletIme(i){
            this.imageList.splice(i,1)
            this.previewSrcList.splice(i,1)
        },
        // 重置
        reset(){
            this.textarea= ''
            this.imageList= []
            this.previewSrcList= []
        },
        callback(){
            if(!this.textarea){
                this.$alert('请输入内容')
                return
            }
            this.$oucy.keywordFiltr(this.textarea).then(res=>{
                this.$emit('callback',{imageList:this.imageList,text:this.textarea})
            },err=>{})
        },
    },
}
</script>
<style scoped lang="less">
.box {
    padding: 20px;
    background: #F6F7F8;
    border-radius: 2px;
}

/deep/ .el-textarea__inner {
    padding: 5px 0;
    background: #F6F7F8;
    border: none;
}

.imgBox {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    .img {
        width: 100%;
        height: 100%;
    }

    .delete {
        z-index: 99;
        position: absolute;
        right: 0;
        top: 0;
        padding:5px;
        cursor: pointer;
        display: none;
    }
    &:hover{
        .delete{
            display: inline-block;
        }
    }
}
</style>