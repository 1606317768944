<!-- 名片 卡片 -->
<template>
  <div v-if="hasEnterprise">
    <div
      class="item df"
      @click="$oucy.openNewPage('/enterpriseDetail?id=' + data.id)"
    >
      <img
        :src="$oucy.ossUrl + data.enterpriseAvata"
        v-if="data.enterpriseAvata"
        class="spuImgs"
      />
      <div class="spuImgs" v-else></div>
      <div class="spuName">
        <div class="line-1 c-3 f14">
          {{ data.enterpriseName }}
        </div>
        <div class="line-1 c-2 f12">点赞 {{ data.cardLike }}</div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="item df" @click="$oucy.go('/personageDetail?id=' + data.id)">
      <img
        :src="$oucy.ossUrl + data.cardPic"
        v-if="data.cardPic"
        class="spuImgs"
      />
      <div class="spuImgs" v-else></div>
      <div class="spuName">
        <div class="line-1 c-3 f14">
          {{ data.cardName }}
        </div>
        <div class="line-1 c-2 f12">点赞 {{ data.cardLike }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "card",
    props: {
      data: {
        type: Object,
        default: function () {
          return {};
        },
      },
      hasEnterprise: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {},
    methods: {},
  };
</script>
<style scoped lang="less">
  .spuImgs {
    width: 88px;
    height: 88px;
  }

  .spuName {
    width: 160px;
    // height: 45px;
    margin-left: 10px;
  }

  .item {
    background: #fff;
    text-align: left;
    cursor: pointer;
    padding: 20px;
  }
</style>
