<!-- 左边一条 | 线 加文字的title -->
<template>
    <div class="f16 c-1 bold df-ai-c" :class="className"><span class="line"></span>{{text}}</div>
</template>
<script>
export default {
    name: "lineTitle",
    props: {
        text: {
            type: String,
            default: '标题',
        },
        className:{
            type:String,
            default: '',
        }

    },
    data() {
        return {}
    },
    mounted() {},
    methods: {},
}
</script>
<style scoped lang="less">
    .line {
        width: 6px;
        height: 16px;
        background: #2090FF;
        border-radius: 1px;
        display: inline-block;
        margin-right: 4px;
    }
</style>