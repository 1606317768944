<!-- 店铺认领 -->
<template>
    <div v-if="!enterprise.id && loginUser.id">
        <button class="btn" @click="open">认领店铺</button>
        <el-dialog title="认领店铺" :visible.sync="dialogVisible" width="550">
            <div v-if="status==null" class="box">
                <el-form :model="ApplyEnterpriseEmployee" :rules="rules" ref="ApplyEnterpriseEmployee" label-width="100px" class="demo-ApplyEnterpriseEmployee" style="width: 100%;">
                    <el-form-item label="认领人姓名" prop="applyPeople">
                        <el-input v-model="ApplyEnterpriseEmployee.applyPeople"></el-input>
                    </el-form-item>
                    <el-form-item label="认领人电话" prop="applyPhone">
                        <el-input v-model="ApplyEnterpriseEmployee.applyPhone"></el-input>
                    </el-form-item>
                    <el-form-item label="认领说明" prop="applyState">
                        <el-input type="textarea" v-model="ApplyEnterpriseEmployee.applyState" maxlength="120" :autosize="{ minRows: 3, maxRows: 5}" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="dialogVisible=false">取消</el-button>
                        <el-button type="primary" @click="keywordFiltr('submitForm')">申请认领</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="text-center box" v-else-if="status==0">
                <div>
                    <div><img src="@/assets/images/sand.png"></div>
                    <div class="title m-t-25">申请提交成功</div>
                    <div class="text m-t-15">您的认领申请已提交，客服正在快速审核中！</div>
                </div>
            </div>
            <div class="text-center box" v-else-if="status==1">
                <div>
                    <!-- <div><img src="@/assets/images/sand.png"></div> -->
                    <div class="title m-t-25">申请已通过</div>
                    <div class="text m-t-15">您的认领申请已通过</div>
                    <el-button class="m-t-25" size="mini" @click="$oucy.go('/acc/enterprise')">前往企业管理中心</el-button>
                </div>
            </div>
            <div class="text-center box" v-else-if="status==2">
                <div>
                    <!-- <div><img src="@/assets/images/sand.png"></div> -->
                    <div class="title m-t-25">申请已驳回</div>
                    <div class="text m-t-15">您的认领申请已驳回，如有疑问可拨打我司客服热线！</div>
                    <el-button class="m-t-25" size="mini" @click="status=null">重新申请</el-button>
                </div>
            </div>
            <div class="text-center box" v-else-if="status==3">
                <div>
                    <div><img src="@/assets/images/sand.png"></div>
                    <div class="title m-t-25">无法申请</div>
                    <div class="text m-t-15">您不能提交认领申请，如有疑问可拨打我司客服热线！</div>
                    <!-- <el-button @click="status=null">重新申请</el-button> -->
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { enterpriseinfo } from "@/service/index"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "enterpriseClaim",
    props: {
        enterpriseId: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            // 审核状态 0待审核 1审核通过 2审核驳回 3黑名单 null就是未申请过
            status: null,
            enterprise: {},
            loginUser: {},
            dialogVisible: false,
            ApplyEnterpriseEmployee: {
                enterpriseId: '',
                applyPeople: '',
                applyPhone: '',
                applyState: '',
            },
            rules: {

                applyPeople: [
                    { required: true, message: '认领人姓名不能为空', trigger: 'change' }
                ],
                applyPhone: [
                    { required: true, message: '认领人电话不能为空', trigger: 'change' }
                ],
                applyState: [
                    { required: true, message: '认领人说明不能为空', trigger: 'change' }
                ],

            }
        }
    },
    mounted() {
        this.enterprise = localGet('enterprise') || {}
        this.loginUser = localGet('loginUser') || {}
        // if(this.loginUser.id&&!this.enterprise.id){
            // this.queryApplyEnterpriseStatus()
        // }
    },
    methods: {
        keywordFiltr(fn){
            this.$oucy.keywordFiltr(this.ApplyEnterpriseEmployee).then(res=>{
               this[fn]() 
            },err=>{})
        },
        open(){
            this.dialogVisible=true
            this.queryApplyEnterpriseStatus()
        },
        queryApplyEnterpriseStatus(){
            enterpriseinfo.queryApplyEnterpriseStatus({enterpriseId:this.enterpriseId}).then(res=>{
                this.status=res
            })
        },
        submitForm() {
            this.$refs['ApplyEnterpriseEmployee'].validate((valid) => {
                if (valid) {
                    this.applyEnterpriseEmployee()
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm() {
            this.$refs['ApplyEnterpriseEmployee'].resetFields();
        },
        applyEnterpriseEmployee() {
            this.ApplyEnterpriseEmployee.enterpriseId = this.enterpriseId
            enterpriseinfo.applyEnterpriseEmployee(this.ApplyEnterpriseEmployee).then(res => {
            // this.$alert('您的认领申请已提交，客服正在快速审核中！', '申请提交成功')
                this.status = 1
                // this.dialogVisible = false
            })
        },
    },
}
</script>
<style scoped lang="less">
.btn {
    width: 78px;
    height: 24px;
    background: #FA612C;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
}

.box {
    height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2090FF;
}

.text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #A1A6AE;
}
</style>