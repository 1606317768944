<!-- 登录弹框 -->
<template>
  <div>
    <div class="login">
      <el-dialog
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        @close="handleClose"
        :visible.sync="isVisible"
        width="444px"
        height="390px"
      >
        <!-- <el-tabs v-model="activeName" @tab-click="tabClickHandle">
                    121
                </el-tabs> -->
        <div label="微信扫码登录" name="first" v-show="activeName == 'first'">
          <h3>微信扫码登录</h3>
          <div
            v-show="scanUserEntity == null"
            style="text-align: center; padding: 25px"
          >
            <div
              ref="myLoginQrCodeUrl"
              style="margin: 0 auto; width: 146px; height: 146px"
            ></div>
            <div style="padding: 15px">请使用微信扫描二维码进行登录</div>
          </div>
          <div
            v-if="scanUserEntity != null"
            style="text-align: center; padding: 25px"
          >
            <img
              :src="
                this.$oucy.ossUrl + scanUserEntity.ltUserDetailFull.detailAvatar
              "
              style="
                margin: 0 auto;
                width: 146px;
                height: 146px;
                border-radius: 3px;
              "
            />
            <div style="color: #1aad19">
              ({{ scanUserEntity.ltUserDetailFull.detailNickname }})
            </div>
            <div v-if="scanUserConfirm" style="color: #cccccc">
              <i
                class="el-icon-success"
                style="color: #1aad19; font-size: 20px"
              ></i>
              操作成功！ 正在登录...
            </div>
            <div v-else style="color: #cccccc">
              扫码成功，请手机按提示进行操作！或者
              <el-button @click="reScanHandle" type="text">重新扫码</el-button>
              。
            </div>
          </div>
          <div class="tabs">
            <div class="tab pointer" @click="activeName = 'second'">
              <svg class="login_icon" aria-hidden="true">
                <use xlink:href="#custom-icon-zhanghaoguanli"></use>
              </svg>
              <br />
              账号登录
            </div>
            <div class="tab pointer" @click="activeName = 'third'">
              <svg class="login_icon" aria-hidden="true">
                <use xlink:href="#custom-icon-duanxin"></use>
              </svg>
              <br />
              短信登录
            </div>
          </div>
        </div>
        <div label="账号密码登录" name="second" v-show="activeName == 'second'">
          <h3>账号密码登录</h3>
          <el-form
            :model="idloginForm"
            class="login_box_form"
            :rules="idrules"
            ref="idloginForm"
          >
            <el-form-item prop="username">
              <el-input
                v-model="idloginForm.username"
                autocomplete="off"
                placeholder="手机号/用户名"
              >
                <i slot="prefix" class="el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="idloginForm.password"
                autocomplete="off"
                placeholder="请输入您的密码"
                show-password
              >
                <i slot="prefix" class="el-icon-unlock"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="agreement">
              <el-checkbox v-model="idloginForm.agreement">
                同意
                <el-link
                  :underline="false"
                  style="color: #0366c3"
                  href="https://www.lesoujia.com/AfficheDetail?id=1b2b95f597244339813895e2e9bee547&type=1"
                  type="primary"
                >
                  《乐搜家用户注册协议》
                </el-link>
                及
                <el-link
                  :underline="false"
                  style="color: #0366c3"
                  href="https://www.lesoujia.com/AfficheDetail?id=3a7b1b07076d47b6a83b5187fae8406e&type=1"
                  type="primary"
                >
                  《隐私协议》
                </el-link>
              </el-checkbox>
            </el-form-item>
            <div class="login_button">
              <el-button
                @click="submitLoginUP"
                :disabled="!idloginForm.agreement"
                type="primary"
              >
                登录
              </el-button>
            </div>
            <!--             <div class="login_bottom">
                <div>
                    没有账号？
                    <el-link @click="goRegister" type="primary" :underline="false">立即注册</el-link>
                </div>
                <div>
                    <el-link type="primary" :underline="false">忘记密码</el-link>
                </div>
            </div> -->
          </el-form>
          <div class="tabs m-t-30">
            <div class="tab pointer" @click="activeName = 'first'">
              <svg class="login_icon" aria-hidden="true">
                <use xlink:href="#custom-icon-weixindenglu"></use>
              </svg>
              <br />
              微信登录
            </div>
            <div class="tab pointer" @click="activeName = 'third'">
              <svg class="login_icon" aria-hidden="true">
                <use xlink:href="#custom-icon-duanxin"></use>
              </svg>
              <br />
              短信登录
            </div>
          </div>
        </div>
        <div label="手机验证码登录" name="third" v-show="activeName == 'third'">
          <h3>手机验证码登录</h3>
          <el-form
            :model="phoneloginForm"
            class="login_box_form"
            :rules="phonerules"
            ref="phoneloginForm"
          >
            <el-form-item prop="phoneNum">
              <el-input
                v-model="phoneloginForm.phoneNum"
                autocomplete="off"
                placeholder="请输入您的手机号"
              >
                <i slot="prefix" class="el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="phoneCode">
              <el-input
                v-model="phoneloginForm.phoneCode"
                autocomplete="off"
                placeholder="请输入短信验证码"
              >
                <i slot="prefix" class="el-icon-unlock"></i>
                <el-button
                  @click="getLoginPhoneCode"
                  :disabled="!showGetCode"
                  type="text"
                  style="padding-right: 10px; color: #0366c3"
                  slot="suffix"
                >
                  <span v-if="showGetCode">获取验证码</span>
                  <span v-else class="count">{{ codeTime }} s</span>
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item prop="agreement">
              <el-checkbox v-model="idloginForm.agreement">
                同意
                <el-link
                  :underline="false"
                  style="color: #0366c3"
                  href="https://www.lesoujia.com/AfficheDetail?id=1b2b95f597244339813895e2e9bee547&type=1"
                  type="primary"
                >
                  《乐搜家用户注册协议》
                </el-link>
                及
                <el-link
                  :underline="false"
                  style="color: #0366c3"
                  href="https://www.lesoujia.com/AfficheDetail?id=3a7b1b07076d47b6a83b5187fae8406e&type=1"
                  type="primary"
                >
                  《隐私协议》
                </el-link>
              </el-checkbox>
            </el-form-item>
            <div class="login_button">
              <el-button
                @click="submitLoginPhone"
                :disabled="!phoneloginForm.agreement"
                type="primary"
              >
                登录
              </el-button>
            </div>
          </el-form>
          <div class="tabs m-t-30">
            <div class="tab pointer" @click="activeName = 'first'">
              <svg class="login_icon" aria-hidden="true">
                <use xlink:href="#custom-icon-weixindenglu"></use>
              </svg>
              <br />
              微信登录
            </div>
            <div class="tab pointer" @click="activeName = 'second'">
              <svg class="login_icon" aria-hidden="true">
                <use xlink:href="#custom-icon-zhanghaoguanli"></use>
              </svg>
              <br />
              账号登录
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import oucy from "../util/oucyUtil";
  import QRCode from "qrcodejs2";
  import { localSet, localGet, localDel, sessionSet } from "@/store/store";
  import { userAuth, userMaterial } from "@/service/index.js";

  export default {
    name: "login",
    props: {
      isVisible: Boolean,
      default: false,
    },
    data() {
      const validatePhone = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入手机号码"));
        } else if (!oucy.isValidPhone(value)) {
          callback(new Error("请输入正确的11位手机号码"));
        } else {
          callback();
        }
      };
      return {
        qrCode: null,
        codeTime: 0,
        codeTimer: null,
        showGetCode: true,
        activeName: "first",
        idloginForm: {
          username: "",
          password: "",
          agreement: true,
        },
        phoneloginForm: {
          phoneCode: "",
          phoneNum: "",
          agreement: true,
        },
        showSuccess: false,
        showDiaBox: true,
        idrules: {
          username: [
            { required: true, message: "请输入用户名", trigger: "blur" },
            {
              min: 2,
              max: 25,
              message: "长度在 2 到 25 个字符",
              trigger: "blur",
            },
          ],
          password: [
            { required: true, message: "请输入密码", trigger: "blur" },
            {
              min: 6,
              max: 25,
              message: "长度在 6 到 25 个字符",
              trigger: "blur",
            },
          ],
        },
        phonerules: {
          phoneNum: [{ validator: validatePhone, trigger: "blur" }],
          phoneCode: [
            { required: true, message: "请输入验证码", trigger: "blur" },
          ],
        },
        scanUserEntity: null,
        scanUserConfirm: false,
        qrCode: null,
      };
    },
    watch: {
      isVisible(n, o) {
        const me = this;
        if (n) {
          me.reScanHandle();
          setTimeout(() => {
            me.creatQrCode();
          }, 500);
        }
      },
    },
    mounted() {
      const me = this;
      oucy.getCodeTime().then((res) => {
        me.codeTime = Number(res);
        me.codeTimer = setInterval(() => {
          if (me.codeTime > 0) {
            me.showGetCode = false;
            me.codeTime--;
          } else {
            me.showGetCode = true;
          }
        }, 1000);
      });
      me.eventBus
        .$off(me.$oucy.msgKeys.WECHAT_PRE_LOGIN)
        .$on(me.$oucy.msgKeys.WECHAT_PRE_LOGIN, (res) => {
          console.log("扫码事件：");
          console.log(res);
          me.scanUserEntity = res;
          me.scanUserConfirm = false;
        })
        .$off(me.$oucy.msgKeys.WECHAT_CONFIRM_LOGIN)
        .$on(me.$oucy.msgKeys.WECHAT_CONFIRM_LOGIN, (res) => {
          console.log("确定事件：");
          console.log(res);
          me.scanUserEntity = res;
          me.scanUserConfirm = true;
          oucy
            .loginWechat(
              me.scanUserEntity.userWechatUnicode,
              me.scanUserEntity.userWechatLogpass
            )
            .then((res) => {
              if (!res.isAccComplete) {
                me.$emit("openBindPhone");
                // oucy.replace('/fullaccount');
              }
              return Promise.resolve(res);
            })
            .then((res) => {
              me.$emit("loginSuccess");
              me.$oucy.app.eventBus.$emit(oucy.msgKeys.LOGIN_SUCCESS, null);
              me.$notify({
                title: "登录成功",
                message: "你好，欢迎来到乐搜家",
                type: "success",
              });
              oucy.receiveUserRecordEvent({ record: "登录" });
              // 查询客户端用户的所有企业
              me.getEnterpriseByUser();
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    methods: {
      // 验证码倒计时
      getCodeCount() {
        if (!this.timer) {
          this.count = 60;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      },
      handleClose: function () {
        this.$emit("close");
      },
      goRegister: function () {
        this.$emit("goRegister");
      },
      submitLoginUP: function () {
        const me = this;
        me.$refs["idloginForm"].validate((valid) => {
          if (valid) {
            let _res = {};
            oucy
              .loginUp(me.idloginForm.username, me.idloginForm.password)
              .then((res) => {
                // if (oucy.thisUUID != res.mqttClientId) {
                // oucy.thisUUID = res.mqttClientId
                // localSet('UUID', res.mqttClientId)
                if (oucy.mqtt && typeof oucy.mqtt.disconnect == "function") {
                  console.log("login oucy.mqtt.disconnect");
                  console.log(oucy.mqtt);
                  // oucy.mqtt.disconnect()
                }

                console.log("重新更新 MQTT");
                oucy.resetMQTT(res.mqttData);
                // }
                _res = res;
                return userMaterial.queryUserMaterial();
              })
              .then((res) => {
                this.$emit("loginSuccess");
                this.$oucy.app.eventBus.$emit(oucy.msgKeys.LOGIN_SUCCESS, null);

                let name = "";
                if (res.userDcodeUsername) {
                  name = res.userDcodeUsername;
                } else if (
                  res.ltUserDetailFull &&
                  res.ltUserDetailFull.detailNickname
                ) {
                  name = res.ltUserDetailFull.detailNickname;
                } else {
                  name = res.userPhoneNumber;
                }
                me.$notify({
                  title: "登录成功",
                  message: "你好，" + name + "，欢迎来到乐搜家",
                  type: "success",
                });
                oucy.receiveUserRecordEvent({ record: "登录" });
                // 查询客户端用户的所有企业
                me.getEnterpriseByUser();
                if (!_res.isAccComplete) {
                  this.$emit("openBindPhone");
                }
              });
          }
        });
      },
      getLoginPhoneCode: function () {
        const me = this;
        me.$refs["phoneloginForm"].validateField("phoneNum", (message) => {
          if (message == null || message.length === 0) {
            oucy
              .postRequest("/client/user/userauth/loginSendCode", {
                phoneNumber: me.phoneloginForm.phoneNum,
                uuid: oucy.getUUID(),
              })
              .then((res) => {
                me.codeTime = Number(res);
                me.$notify({
                  title: "短信发送成功",
                  message: "验证码已发送，请留意短信通知",
                  type: "success",
                });
              });
          }
        });
      },
      submitLoginPhone: function () {
        const me = this;
        me.$refs["phoneloginForm"].validate((valid) => {
          if (valid) {
            oucy
              .loginPhone(
                me.phoneloginForm.phoneNum,
                me.phoneloginForm.phoneCode
              )
              .then((res) => {
                if (!res.isAccComplete) {
                  oucy.replace("/fullaccount");
                }
              })
              .then((res) => {
                this.$emit("loginSuccess");
                this.$oucy.app.eventBus.$emit(oucy.msgKeys.LOGIN_SUCCESS, null);
                me.$notify({
                  title: "登录成功",
                  message: "你好，欢迎来到乐搜家!",
                  type: "success",
                });
                oucy.receiveUserRecordEvent({ record: "登录" });
                // 查询客户端用户的所有企业
                me.getEnterpriseByUser();
              });
          }
        });
      },
      creatQrCode() {
        this.queryUserLoginScanAddress();
        return;
        const me = this;
        if (me.qrCode == null) {
          me.qrCode = new QRCode(this.$refs.myLoginQrCodeUrl, {
            text:
              me.$oucy.baseHost +
              "/routerView/wechartLogin/" +
              me.$oucy.scanKeyOfPreLogin +
              "?p=" +
              me.$oucy.getUUID() +
              "&operationOriginPlatform=0&operationUv=" +
              me.$oucy.getOperationUv(),
            width: 146,
            height: 146,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });

          console.log("creatQrCode");
        }
      },
      tabClickHandle: function (tab) {
        const me = this;
        if (tab.index === "0") {
          me.creatQrCode();
        }
      },
      /**
       * 重新扫码
       */
      reScanHandle: function () {
        const me = this;
        me.scanUserEntity = null;
        me.scanUserConfirm = false;
        this.queryUserLoginScanAddress();
      },
      // 查询客户端用户的所有企业 缓存黑认企业
      getEnterpriseByUser() {
        userAuth.getEnterpriseByUser({}).then((res) => {
          if (res && res.length) {
            let enterprise = res[0];
            for (let v of res) {
              if (v.isdefault) {
                enterprise = v;
              }
            }
            localSet("enterprise", enterprise);
          }
        });
      },
      queryUserLoginScanAddress() {
        userAuth
          .queryUserLoginScanAddress({
            p: this.$oucy.getUUID(),
            operationOriginPlatform: 0,
            operationUv: this.$oucy.getOperationUv(),
          })
          .then((res) => {
            if (!this.qrCode) {
              this.qrCode = new QRCode(this.$refs.myLoginQrCodeUrl, {
                // text: text,
                // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
                width: 146,
                height: 146,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
            }
            this.qrCode && this.qrCode.clear();
            this.qrCode.makeCode(res);
          });
      },
    },
    beforeDestroy() {
      const me = this;
      me.eventBus.$off(me.$oucy.msgKeys.WECHAT_PRE_LOGIN);
      me.eventBus.$off(me.$oucy.msgKeys.WECHAT_CONFIRM_LOGIN);
    },
  };
</script>
<style scoped>
  .login .el-dialog {
    border-radius: 8px;
  }

  /deep/ .el-tabs__item {
    width: 134px !important;
    text-align: center;
  }

  .login_box_form {
    width: 100%;
    padding: 0 60px;
    box-sizing: border-box;
    margin-top: 30px;
  }

  .login_bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
  }

  .login_bottom {
    font-size: 14px;
    color: #aaaaaa;
  }

  .login_button .el-button {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    width: 285px;
    height: 42px;
    background: #0366c3;
    border-radius: 8px;
  }

  .tabs {
    display: flex;
    justify-content: space-around;
    padding: 0 50px;
  }

  .tab {
  }
  .login_icon {
    width: 2.5em;
    height: 2.5em;
    vertical-align: 0.1em;
    fill: currentColor;
    overflow: hidden;
  }
</style>
