<!-- 商品信息 -->
<template>
    <div>
        <div class="item" @click="$oucy.go('/product?spuId='+data.id)">
            <img :src="$oucy.ossUrl+data.spuImgs[0].imgUrl" v-if="data.spuImgs && data.spuImgs[0] && data.spuImgs[0].imgUrl" class="spuImgs">
            <div class="spuImgs" v-else></div>
            <div class="p-l-10 p-t-10 p-r-10 text-left">
                <div class="spuName line-2 c-3 f16">
                    {{data.spuName}}
                </div>
                <div class="c-m f16 bold p-t-10 p-b-10">
                    <span class="price" v-if="data.spuMinPrise*1!=0">
<!--                      联系工厂获取价格-->
                      ￥{{data.spuMinPrise}}
                    </span>
                    <span class="price" v-else>面议</span>
                </div>
                <VIPPrice :data="data"></VIPPrice>
            </div>
        </div>
    </div>
</template>
<script>
// import { message, schemeOrder } from "@/service/index"
export default {
    name: "spu2",
    props: {
        data: {
            type: Object,
            default:{},
        },

    },

    mounted() {},
    methods: {},
}
</script>
<style scoped lang="less">
.spuImgs {
    width: 200px;
    height: 200px;
}
.spuName{
height: 42px;
}
.item {
    cursor: pointer;
background: #fff;
}

</style>