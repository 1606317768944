import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// 进货车相关
import stockCar from './modules/stockCar'
// 优惠券
import coupon from './modules/coupon'
// 店铺管理
import shop from './modules/shop'
// 发布中心
import ReleaseCenter from './modules/ReleaseCenter'
// 求购
import askBuy from './modules/askBuy'
// 企业订单管理
import enterOMS from './modules/enterOMS'
// 个人订单管理
import accOMS from './modules/accOMS'
// 钱包
import wallet from './modules/wallet'
// 名片
import card from './modules/card'
// 企业名片
import enterCard from './modules/enterCard'
// 个人名片
import accCard from './modules/accCard'
// 企业员工
import enterStaff from './modules/enterStaff'
// 个人直播
import accLive from './modules/accLive'
// 直播
import live from './modules/live'

// 分销
import distribution from './modules/distribution'
import video from './modules/video'
import radar from './modules/radar'

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [

    {
        path: '/',
        component: Home,
        children: [{
            path: '/fullaccount',
            name: 'FullAccount',
            component: () => import("../views/Index/AccComplete"),
        }, {
            path: '/',
            name: 'Index',
            component: () => import("../views/Index/Index"),
            children: [
                {
                    path: '/',
                    name: 'HomeIndex',
                    component: () => import("../views/Index/Index/IndexNew"),
                    meta: { title: '乐搜家 家居供应链服务平台' },
                },
                {
                    path: '/IndexNew',
                    name: 'HomeIndex',
                    component: () => import("../views/Index/Index/IndexNew"),
                    meta: { title: '乐搜家 家居供应链服务平台' },
                },
                {
                    path: '/searchcom',
                    name: 'SearchCom',
                    component: () => import("../views/Index/Search/SearchCom"),
                    meta: { title: '乐搜家 家居供应链服务平台' }
                },
                {
                    path: '/searchcard',
                    name: 'SearchCard',
                    component: () => import("../views/Index/Search/SearchCard"),
                    meta: { title: '搜索名片' },
                },
                {
                    path: '/searchmaterial',
                    name: 'SearchMaterial',
                    component: () => import("../views/Index/Search/SearchMaterial"),
                    meta: { title: '搜索材料' },
                },
                {
                    path: '/searchfurniture',
                    name: 'SearchFurniture',
                    component: () => import("../views/Index/Search/SearchFurniture"),
                    meta: { title: '搜索家具' },
                },
                {
                    path: '/searchenterprise',
                    name: 'SearchEnterprise',
                    component: () => import("../views/Index/Search/SearchEnterprise"),
                    meta: { title: '搜索企业' },
                },
                {
                    path: '/searchAskBuy',
                    name: 'SearchAskBuy',
                    component: () => import("../views/Index/Search/SearchAskBuy"),
                    meta: { title: '搜索求购' },
                },
                {
                    path: '/searchImage',
                    name: 'SearchImage',
                    component: () => import("../views/Index/Search/SearchImage"),
                    meta: { title: '以图搜图' },
                },
                {
                    path: '/furniture',
                    name: 'Furniture',
                    component: () => import("../views/Index/Furniture"),
                    meta: { title: '家具库 - 乐搜家 家居供应链服务平台' },
                },
                {
                    path: '/material',
                    name: 'Material',
                    component: () => import("../views/Index/Material"),
                    meta: { title: '材料库 - 乐搜家 家居供应链服务平台' },
                },
                {
                    path: '/company',
                    name: 'Enterprise',
                    component: () => import("../views/Index/Enterprise"),
                    meta: { title: '企业库 - 乐搜家 家居供应链服务平台' },
                },
                // {
                //     path: '/card',
                //     name: 'Card',
                //     component: () => import("../views/Index/Card"),
                // },
                {
                    path: '/AfficheList',
                    name: 'AfficheList',
                    component: () => import("../views/Index/Affiche/list"),
                    meta: { title: '公告列表 - 乐搜家 家居供应链服务平台' },
                },
                {
                    path: '/AfficheDetail',
                    name: 'AfficheDetail',
                    component: () => import("../views/Index/Affiche/detail"),
                    meta: { title: '乐搜家 家居供应链服务平台' },
                },
                {
                    path: '/About',
                    name: 'About',
                    component: () => import("../views/Index/About"),
                    meta: { title: '关于乐搜家' },
                },
                // 求购
                ...askBuy,
                // 名片
                ...card,

            ]
        },
        {
            path: '/clubCard',
            name: 'clubCard',
            component: () => import("../views/Index/clubCard/clubCard"),
            meta: { title: '会员中心' },
        },
        // 直播
        ...live,
        {
            path: '/product',
            name: 'Product',
            component: () => import("../views/Product/product"),
            meta: { title: '商品详情' },
        },
        {
            path: '/Address',
            name: 'Address',
            component: () => import("../views/Address/Index.vue"),
            meta: { title: '地址' },
        },
        ...ReleaseCenter,
        // 企业 普通店铺
        {
            path: '/Shop',
            name: 'Shop',
            component: () => import("../views/Shop/Index.vue"),
            meta: { title: '企业店铺' }
        },
        // 企业VIP店铺
        {
            path: '/ShopVIP',
            name: 'ShopVIP',
            component: () => import("../views/Shop/VIP.vue"),
            meta: { title: '企业店铺' }
        },
        // 企业VIP视频
        {
            path: '/ShopVideo',
            name: 'ShopVideo',
            component: () => import("../views/Shop/video.vue"),
            meta: { title: '企业视频' }
        },
            {
                path: '/spupgrade',
                name: 'SpUpgrade',
                component: () => import("../views/Enterprise/app/upgrade/index"),
                children: [
                    {
                        path: '/',
                        name: 'upgradeSelect',
                        component: () => import("../views/Enterprise/app/upgrade/select"),
                        meta:{title:'店铺升级'}
                    },
                    {
                        path: '/pay',
                        name: 'upgradePay',
                        component: () => import("../views/Enterprise/app/upgrade/pay"),
                        meta:{title:'店铺升级支付'}
                    },
                    {
                        path: '/paySucceed',
                        name: 'PaySucceed',
                        component: () => import("../views/Enterprise/app/upgrade/paySucceed"),
                        meta:{title:'支付成功'}
                    }
                ]
            },
            // 领券中心
            {
                path:'/receiveCoupon',
                name: 'receiveCoupon',
                component: () => import("../views/Index/receiveCoupon/receiveCoupon.vue"),
                meta:{title:'领券中心'}
            },
            // 支付中心 C:\Users\zhangjicnchun\Documents\lsj\pc\src\views\Index\payCenter\pay\pay.vue
            {
                path:'/payCenter/pay',
                name: 'payCenterPay',
                component: () => import("../views/Index/payCenter/pay/pay.vue"),
                meta:{title:'支付中心'}
            },
            {
                path:'/payCenter/finish',
                name: 'payCenterFinish',
                component: () => import("../views/Index/payCenter/finish/finish.vue"),
                meta:{title:'支付中心'}
            },
            {
            path:'/enter',
            name: 'Enter',
            component: () => import("../views/Enterprise/Enter"),
            children: [
                {
                    path: '/enter/',
                    name: 'EnterDefault',
                    component: () => import("../views/Enterprise/app/Index"),
                    meta: { title: '企业管理中心' }
                },
                {
                    path: '/enter/identify',
                    name: 'EnterIdentify',
                    component: () => import("../views/Enterprise/app/Identify"),
                    meta: { title: '企业身份认证' }
                },
                {
                    path: '/enter/product',
                    name: 'Product',
                    component: () => import("../views/Enterprise/app/Product"),
                    meta: { title: '商品管理' }
                },
                // 产品回收站
                {
                    path: '/enter/ProductRecycle',
                    name: 'ProductRecycle',
                    component: () => import("../views/Enterprise/app/ProductRecycle"),
                    meta: { title: '商品回收站' }
                },
                {
                    path: '/enter/publish',
                    name: 'Publish',
                    component: () => import("../views/Enterprise/app/Publish"),
                    meta: { title: '商品发布' },
                    children: [{
                        path: '/enter/publish/good',
                        name: 'Good',
                        component: () => import("../views/Enterprise/app/Publish/Good")
                    }, {
                        path: '/enter/publish/attr',
                        name: 'Attr',
                        component: () => import("../views/Enterprise/app/Publish/Attr")
                    }, {
                        path: '/enter/publish/spec',
                        name: 'Spec',
                        component: () => import("../views/Enterprise/app/Publish/Spec")
                    }]
                },
                {
                    path: '/enter/addbrand',
                    name: 'AddBrand',
                    component: () => import("../views/Enterprise/app/AddBrand"),
                    meta: { title: '添加品牌' },
                },
                // 相册
                {
                    path: '/enter/photo',
                    name: 'Photo',
                    component: () => import("../views/Enterprise/app/Photo/Index.vue"),
                    meta: { title: '相册' }
                },
                // 相册详情
                {
                    path: '/enter/photoDetail',
                    name: 'photoDetail',
                    component: () => import("../views/Enterprise/app/Photo/Detail.vue"),
                    meta: { title: '相册详情' }
                },
                // 新闻
                {
                    path: '/enter/news',
                    name: 'news',
                    component: () => import("../views/Enterprise/app/News/Index.vue"),
                    meta: { title: '新闻' }
                },
                // 新闻回收站
                {
                    path: '/enter/newsRecycle',
                    name: 'newsRecycle',
                    component: () => import("../views/Enterprise/app/News/Recycle.vue"),
                    meta: { title: '新闻回收站' }
                },
                // 发布新闻
                {
                    path: '/enter/newsSave',
                    name: 'newsSave',
                    component: () => import("../views/Enterprise/app/News/Save.vue"),
                    meta: { title: '发布新闻' }
                },
                // 新闻详情
                {
                    path: '/enter/newsDetail',
                    name: 'newsDetail',
                    component: () => import("../views/Enterprise/app/News/Detail.vue"),
                    meta: { title: '发布新闻' }
                },
                // VR管理
                {
                    path: '/enter/VR',
                    name: 'VR',
                    component: () => import("../views/Enterprise/app/VR.vue"),
                    meta: { title: 'VR' }
                },
                // 企业管理
                {
                    path: '/enter/enterpriseapply',
                    name: 'enterpriseapply',
                    component: () => import("../views/Enterprise/app/EnterpriseApply.vue"),
                    meta: { title: '企业管理' }
                },
                // 缴费明细
                {
                    path: '/enter/payment',
                    name: 'Payment',
                    component: () => import("../views/Enterprise/app/payment.vue"),
                    meta: { title: '缴费明细' }
                },

                ...coupon,
                ...shop,
                ...enterOMS,
                ...enterCard,
                ...enterStaff,
                ...video,
                ...radar,

            ]
        },

        {
            path: '/acc',
            name: 'Acc',
            component: () => import("../views/Account/Acc"),
            meta: { title: '个人中心' },
            children: [
                {
                    path: '/acc/',
                    name: 'AccDefault',
                    component: () => import("../views/Account/app/Index"),
                    meta: { title: '个人管理中心' }
                },
                {
                    path: '/acc/index',
                    name: 'AccIndex',
                    component: () => import("../views/Account/app/Index"),
                    meta: { title: '个人管理中心' }
                },
                {
                    path: '/acc/setup',
                    name: 'Setup',
                    component: () => import("../views/Account/app/Setup"),
                    meta: { title: '个人资料设置' },
                },
                {
                    path: '/acc/EditUser',
                    name: 'EditUser',
                    component: () => import("../views/Account/app/editUser"),
                    meta: { title: '资料修改' },
                },
                {
                    path: '/acc/userinfo',
                    name: 'Userinfo',
                    component: () => import("../views/Account/app/Index"),
                    meta: { title: '个人管理中心' }
                },
                {
                    path: '/acc/edituserinfo',
                    name: 'EditUserinfo',
                    component: () => import("../views/Account/app/Index"),
                },
                {
                    path: '/acc/editpassword',
                    name: 'EditPassword',
                    component: () => import("../views/Account/app/EditPassword"),
                    meta: { title: '修改密码' }
                },
                {
                    path: '/acc/findpassword',
                    name: 'FindPassword',
                    component: () => import("../views/Account/app/FindPassword"),
                    meta: { title: '找回密码' }
                },
                {
                    path: '/acc/collection',
                    name: 'Collection',
                    component: () => import("../views/Account/app/Collection"),
                    meta: { title: '我的收藏' },
                },
                {
                    path: '/acc/comment',
                    name: 'Comment',
                    component: () => import("../views/Account/app/comment/comment"),
                    meta: { title: '我的评论' },
                },
                {
                    path: '/acc/realname',
                    name: 'RealName',
                    component: () => import("../views/Account/app/RealnameVertify"),
                    meta: { title: '个人实名认证' }
                },
                {
                    path: '/acc/enterprise',
                    name: 'Enterprise',
                    component: () => import("../views/Account/app/Enterprise"),
                },
                {
                    path: '/acc/enterpriseapply',
                    name: 'EnterpriseApply',
                    component: () => import("../views/Account/app/EnterpriseApply"),
                    meta: { title: '申请企业入驻' }
                },
                {
                    path: '/acc/identity',
                    name: 'IdentityApply',
                    component: () => import("../views/Account/app/Identity"),
                    meta: { title: '买家认证' }
                },
                // 钱包
                ...wallet,
                ...distribution,
                ...accOMS,
                // 进货车相关 stockCar
                ...stockCar,
                ...accCard,
                ...accLive,
                {
                    path: '/askBuyManagement',
                    name: 'askBuyManagement',
                    component: () => import("@/views/Account/app/askBuy/management.vue"),
                    meta: { title: '求购管理' }
                },
                {
                    path: '/acc/MyCoupon',
                    name: 'accMyCoupon',
                    component: () => import("../views/Account/app/coupon/myCoupon.vue"),
                    meta: { title: '我的优惠券' }
                },
                {
                    path: '/acc/MemberCard',
                    name: 'accMmberCard',
                    component: () => import("../views/Account/app/MemberCard/MemberCard.vue"),
                    meta: { title: '我的会员卡' }
                },


            ]
        }]
    }, {
        path: '/scan',
        name: 'Scan',
        component: () => import("../scan/Index"),
    }, {
        path: '/wechaterror',
        name: 'wechaterror',
        component: () => import("../scan/error"),
    },
    // 生成物料清单 结果
    {
        path: '/printResult',
        name: 'PrintResult',
        component: () => import("../views/print/result/result.vue"),
        meta: { title: '生成物料清单' }
    },
    // 打印订单
    {
        path: '/printOrder',
        name: 'PrintOrder',
        component: () => import("../views/print/order/order.vue"),
        meta: { title: '打印订单' }
    },
    // 下载
    {
        path: '/download',
        name: 'download',
        component: () => import("../views/print/download/download.vue"),
        meta: { title: '乐搜家APP下载' }
    },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router
