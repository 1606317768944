<!-- 无限嵌套 侧边栏 -->
<template>
    <div class="text-left myMenu">
        <el-menu :default-active="defaultActive" class="el-menu-vertical-demo p-b-20" @open="handleOpen" @close="handleClose" @select="menuSelect">
            <slot></slot>
            <template v-for="(v,i) of list" v-key="v.id">
                <!-- 有下一层 children -->
                <el-submenu :index="v.id" v-if="v.children">
                    <template slot="title">
                        <img :src="$oucy.ossUrl+v.identityIconPc" v-if="v.identityIconPc" class="identityIconPc">
                        <img :src="$oucy.ossUrl+v.categoryIconPc" v-else-if="v.categoryIconPc" class="identityIconPc">
                        <img :src="$oucy.ossUrl+v.classifyIconPc" v-else-if="v.classifyIconPc" class="identityIconPc">
                        <span :class="v.id==defaultActive?'active':''">{{v.name}}</span>
                    </template>
                    <myMenu :list="v.children" @menuSelect="menuSelect" :default-active="defaultActive"></myMenu>
                </el-submenu>
                <!-- 没有下一层 -->
                <el-menu-item :index="v.id" v-else>
                    <img :src="$oucy.ossUrl+v.identityIconPc" v-if="v.identityIconPc" class="identityIconPc">
                    <img :src="$oucy.ossUrl+v.categoryIconPc" v-else-if="v.categoryIconPc" class="identityIconPc">
                    <img :src="$oucy.ossUrl+v.classifyIconPc" v-else-if="v.classifyIconPc" class="identityIconPc">
                    <span slot="title">{{v.name}}</span>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>
<script>
export default {
    name: "myMenu",
    props: {
        // 菜单列表
        list: {
            type: Array,
            default: [],
        },
        // 选中的菜单
        defaultActive: {
            type: [String,Object,Number],
            default: null,
        },
    },
    data() {
        return {}
    },
    methods: {
        // 打开回调
        handleOpen(v) {
            console.log(v)
            this.$emit('menuSelect', v)
        },
        // 关闭回调
        handleClose() {},
        // 选中回调
        menuSelect(v) {
            this.$emit('menuSelect', v)
        },
    }
}
</script>
<style scoped>
.myMenu {
    /*padding-left: 20px;*/
    box-sizing: border-box;
    overflow: hidden;
}

.identityIconPc {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}
.active{
    color: #409EFF;
}
</style>