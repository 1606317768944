// 名片

const card = [
    // 主页面
    {
        path: '/card',
        name: 'Card',
        component: () => import("@/views/Index/card/Index"),
        meta:{title:'名片库'}
    },
    // 企业名片详情
    {
        path: '/enterpriseDetail',
        name: 'EnterpriseDetail',
        component: () => import("@/views/Index/card/enterpriseDetail"),
        meta:{title:'企业名片详情'}
    },
    // 员工
    {
        path: '/staffDetail',
        name: 'StaffDetail',
        component: () => import("@/views/Index/card/staffDetail"),
        meta:{title:'员工名片详情'}
    },
    // 个人
    {
        path: '/personageDetail',
        name: 'PersonageDetail',
        component: () => import("@/views/Index/card/personageDetail"),
        meta:{title:'个人名片详情'}
    },


];


export default card