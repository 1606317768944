import oucy from '@/util/oucyUtil.js'
// 买家发布求购
const buyAsk = {
    /**
     * 查询所有求购信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getAllBuyAsk: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/getAllBuyAsk', params)
    },
    /**
     * 查询30条最新求购信息[两端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getLatestBuyAsk: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/getLatestBuyAsk', params)
    },
    /**
     * 根据求购编码获取求购信息详细信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getBuyAskById: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/getBuyAskById', params)
    },
    /**
     * 求购评论
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    askComments: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/askComments', params)
    },
    /**
     * 查询求购评论列表第一级
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryBuyAskComment: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/queryBuyAskComment', params)
    },
    /**
     * 搜索所有求购信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    searchAllBuyAsk: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/searchAllBuyAsk', params)
    },
    /**
     * 发布求购[
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    newBuyAsk: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/newBuyAsk', params)
    },
    /**
     * 修改求购[
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateBuyAsk: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/updateBuyAsk', params)
    },
    /**
     * 用户求购
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryUserBuyAsk: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/queryUserBuyAsk', params)
    },
    /**
     * 刷新求购
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    refreshBuyAsk: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/refreshBuyAsk', params)
    },
    /**
     * 求购置顶 
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    buyAskTopping: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/buyAskTopping', params)
    },
        /**
     * 取消置顶
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    buyAskCancel: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/buyAskCancel', params)
    },
    /**
     * 上下架
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    strandUpAndDown: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/strandUpAndDown', params)
    },
    /**
     * 完成/取消求购
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    finishBuyAsk: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/finishBuyAsk', params)
    },
    /**
     * 删除买家发布求购信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    deleteBuyAsk: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/deleteBuyAsk', params)
    },
    /**
     * 点赞求购
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    addAskSizeFavor: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/addAskSizeFavor', params)
    },
    /**
     * 点赞求购
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getMyBuyAskComment: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/getMyBuyAskComment', params)
    },
    /**
     * 点赞求购
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getMyCommentList: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/getMyCommentList', params)
    },
    /**
     * 查询求购置顶积分配置
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryBuyTopSetting: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/queryBuyTopSetting', params)
    },
    /**
     * 查询刷新求购积分配置
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryBuyRefreshSetting: (params = {}) => {
        return oucy.postRequest('/client/buyask/buyask/queryBuyRefreshSetting', params)
    },
}

export default buyAsk