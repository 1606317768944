import oucy from "@/util/oucyUtil.js";
// 企业名片分类管理
const cardCategory = {
  /**
   * 查询名片分类列表
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
  getCardCategoryList: (params) => {
    return oucy.postRequest(
      "/client/card/cardcategory/getCardCategoryList",
      params
    );
  },
  /**
   * 查询名片分类列表
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
  findAllLtIdentity: (params) => {
    return oucy.postRequest(
      "/client/enterprise/ltidentity/findAllLtIdentity",
      params
    );
  },
  /**
   * 查询列表
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
  getCardEnterpriseList: (params) => {
    return oucy.postRequest(
      "/client/enterprise/enterpriseinfo/queryEnterpriseCardPage",
      params
    );
  },
};

export default cardCategory;
