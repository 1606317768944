import oucy from '@/util/oucyUtil.js'
// 消息通知
const message = {
	/**
     * 获取当前用户的所有站内消息
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getMessageContentHisByUser: (params) => {
        return oucy.postRequest('/client/thirdparty/message/getMessageContentHisByUser', params,false)
    },
   	/**
     * 查询平台客服人员列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getImCustomerServiceList: (params) => {
        return oucy.postRequest('/client/im/imCustomerservice/getImCustomerServiceList', params,false)
    },
     	/**
     * 获取用户所有未读消息总数
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllNoReadMessageCount: (params) => {
        return oucy.postRequest('/client/thirdparty/message/getAllNoReadMessageCount', params,false)
    },  
         	/**
     * 获取用户未读系统消息总数
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getNoReadSystemMessageCount: (params) => {
        return oucy.postRequest('/client/thirdparty/message/getNoReadSystemMessageCount', params,false)
    },     
   	/**
     * 标记消息已读
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    markMessageRead: (params) => {
        return oucy.postRequest('/client/thirdparty/message/markMessageRead', params)
    },
   
}

export default message
