<!-- 信息中心弹框 -->
<template>
    <div v-if="showMessage && $oucy.isICPAudit">
        <div class="badge" v-if="!isShow" @click="setShow()">
            <el-badge :value="allMessageCount>0?allMessageCount:null" class="item">
                <img src="@/assets/icon/message.png" class="img">
                <div class="f12">消息</div>
            </el-badge>
        </div>
        <div class="boxs2 " v-if="isShow">
            <div class="box message" v-drag>
                <div class="left">
                    <div class="left_top">
                        <el-badge :value="selectionCount?selectionCount:null" class="item">
                        <div class="pointer" :class="tab==1?'select':''" @click="setTab(1)">会话列表</div>
                        </el-badge>
                        <!-- <el-badge :value="12" class="item"> -->
                        <div class="pointer" :class="tab==0?'select':''" @click="setTab(0)">好友列表</div>
                        <!-- </el-badge> -->
                    </div>
                    <div class="left_list" @mousedown.stop="stop" :class="'left_list_'+tab" ref="left_list">
                        <div v-if="tab==0">
                            <!-- 组 -->
                            <template >

                                <div class="df-jc-s-b p-15 fold pointer" @click="showUser=!showUser">
                                    <div class="f14">平台客服<span class="f12">({{ImCustomerServiceList && ImCustomerServiceList.length||0}})</span></div>
                                    <div class="">
 
                                        <span class="el-icon-arrow-right f12" v-if="!showUser"></span>
                                        <span class="el-icon-arrow-down f12" v-else></span>
                                    </div>
                                </div>
                                <!-- 用户 -->
                                <template v-for="(vv,ii) of ImCustomerServiceList" v-if="ImCustomerServiceList && showUser">
        
                                    <!-- 去会话的 -->
                                    <div class="user" @click="clickSession2(vv,ii)">
                                        <div class="user_head">
                                            <img :src="$oucy.ossUrl + vv.userAuth.detailAvatar">
                                        </div>
                                        <div class="user_right m-l-15">
                                            <div class="df-jc-s-b">
                                                <div class="user_name f14 line-1 text-left">{{vv.customerServiceNickName}}</div>
       
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-for="(v,i) of groupFriendList">
                                <div class="p-15 fold" v-if="updateGroup && updateGroup.groupId==v.groupId">
                                    <el-input v-model="groupName" placeholder="请输入备注名称" @blur="blurUpdateGroup" @keyup.enter.native="blurUpdateGroup" size="mini"></el-input>
                                </div>
                                <div class="df-jc-s-b p-15 fold pointer" v-else @click="v.showUser=!v.showUser">
                                    <div class="f14">{{v.groupName}} <span class="f12">({{v.imFriendGroup_allList && v.imFriendGroup_allList.length||0}})</span></div>
                                    <div class="">
                                        <el-dropdown :hide-on-click="false" @click.stop="stop" v-if="v.groupId">
                                            <span class="el-dropdown-link m-r-10" @click.stop="stop">
                                                <i class="el-icon-setting setting"></i>
                                            </span>
                                            <el-dropdown-menu slot="dropdown" placement="top">
                                                <el-dropdown-item>
                                                    <div type="text" class="user_status f12" @click="setUpdateGroup(v,i)">修改分组</div>
                                                </el-dropdown-item>
                                                <el-dropdown-item>
                                                    <div type="text" class="user_status f12" @click="delGroup(v,i)">删除分组</div>
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                        <span class="el-icon-arrow-right f12" v-if="!v.showUser"></span>
                                        <span class="el-icon-arrow-down f12" v-else></span>
                                    </div>
                                </div>
                                <!-- 用户 -->
                                <template v-for="(vv,ii) of v.imFriendGroup_allList" v-if="v.imFriendGroup_allList && v.showUser">
                                    <!-- 编辑备注的 -->
                                    <div class="user" v-if="TheNote && TheNote.friendId==vv.imFriendBasic_one_ltUserAuthBasic.friendId">
                                        <div class="user_head">
                                            <img :src="$oucy.ossUrl + vv.imFriendBasic_one_ltUserAuthBasic.ltUserAuthBasics.detailAvatar" class="" v-if="vv.imFriendBasic_one_ltUserAuthBasic.ltUserAuthBasics && vv.imFriendBasic_one_ltUserAuthBasic.ltUserAuthBasics.detailAvatar">
                                        </div>
                                        <div class="user_right m-l-15">
                                            <div class="df-jc-s-b">
                                                <el-input v-model="nikeName" placeholder="请输入备注名称" @blur="blurTheNote" @keyup.enter.native="blurTheNote" size="mini"></el-input>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 去会话的 -->
                                    <div class="user" v-else @click="clickSession(vv,ii)">
                                        <div class="user_head">
                                            <img :src="$oucy.ossUrl + vv.imFriendBasic_one_ltUserAuthBasic.ltUserAuthBasics.detailAvatar" class="" v-if="vv.imFriendBasic_one_ltUserAuthBasic.ltUserAuthBasics && vv.imFriendBasic_one_ltUserAuthBasic.ltUserAuthBasics.detailAvatar">
                                        </div>
                                        <div class="user_right m-l-15">
                                            <div class="df-jc-s-b">
                                                <div class="user_name f14 line-1 text-left">{{vv.imFriendBasic_one_ltUserAuthBasic.friendNickName ||vv.imFriendBasic_one_ltUserAuthBasic.ltUserAuthBasics && vv.imFriendBasic_one_ltUserAuthBasic.ltUserAuthBasics.detailNickname}}</div>
                                                <el-dropdown :hide-on-click="false" @click.stop="stop">
                                                    <span class="el-dropdown-link" @click.stop="stop">
                                                        <i class="el-icon-setting setting"></i>
                                                    </span>
                                                    <el-dropdown-menu slot="dropdown" placement="top">
                                                        <el-dropdown-item>
                                                            <el-popover placement="right" width="80" trigger="hover">
                                                                <div v-for="(vvv,iii) of groupFriendList" v-bind:key="vvv.id" class="groupItem" @click="moveGroup(v,vv,vvv,i,ii,iii)" v-if="v.groupId!=vvv.groupId">{{vvv.groupName}}</div>
                                                                <el-button slot="reference" class="reference f12" type="text">移入分组</el-button>
                                                            </el-popover>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item>
                                                            <div type="text" class="user_status f12" @click="openSetTheNote(vv,ii)">修改备注</div>
                                                        </el-dropdown-item>
                                                        <el-dropdown-item>
                                                            <div type="text" class="user_status f12" @click="delFriend(v,vv,i,ii)">删除好友</div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <!-- 新建分组 -->
                            <div class="df-jc-s-b p-15 fold" v-if="hasNewGroup">
                                <div class="f14">
                                    <el-input v-model="groupName" maxlength="10" placeholder="请输入分组名称" @blur="blurNewGroup" @keyup.enter.native="blurNewGroup" @keyup.enter.esc="hasNewGroup=false" size="mini"></el-input>
                                </div>
                                <div class="pointer">
                                    <span class="el-icon-arrow-right f12"></span>
                                    <!-- <span class="el-icon-arrow-down f12" v-else></span> -->
                                </div>
                            </div>
                        </div>
                        <div v-if="tab==1">
                            <!-- 系统通知 -->
                            <div class="user" @click="tabMessage()">
                                <div class="user_head">
                                    <img src="@/assets/images/message/system_icon.png" class="">
                                </div>
                                <div class="user_right m-l-15">
                                    <div class="df-jc-s-b">
                                        <div class="user_name f14 line-1 text-left">系统通知</div>
                                        <span class="red_circle" v-if="newSystemMessageCount>0">{{newSystemMessageCount}}</span>
                                    </div>
                                    <div class="user_info c-2 f12 text-left m-t-10 line-1" v-if="newSystemMessageCount">您有新的系统通知</div>
                                </div>
                            </div>
                            <!-- 好友申请通知 -->
                            <div class="user" @click="tabAddUser()">
                                <div class="user_head">
                                    <img src="@/assets/images/message/newUser.png" class="">
                                </div>
                                <div class="user_right m-l-15">
                                    <div class="df-jc-s-b">
                                        <div class="user_name f14 line-1 text-left">好友申请通知</div>
                                        <!-- <div class="user_status c-2 f12">已忽略</div> -->
                                        <span class="red_circle" v-if="newFriendCOunt>0">{{newFriendCOunt}}</span>
                                    </div>
                                    <div class="user_info c-2 f12 text-left m-t-10 line-1" v-if="newFriendCOunt">您有新的好友申请！</div>
                                </div>
                            </div>
                            <!-- 会话列表 -->
                            <div class="user" v-for="(v,i) of queryAllSessionByUserList" v-bind:key="v.id" @click="setCacheUser(v,i)">
                                <div class="user_head">
                                    <img :src="$oucy.ossUrl + v.sessionUser.detailAvatar" class="" v-if="v.sessionUser && v.sessionUser.detailAvatar">
                                </div>
                                <div class="user_right m-l-15">
                                    <div class="df-jc-s-b df-ai-c" style="height:18px">
                                        <div class="user_name f14 line-1 text-left">{{v.sessionNickName || v.sessionUser && v.sessionUser.detailNickname}}</div>
                                        <span>
                                            <i class="el-icon-remove-outline setting" title="移除会话" @click.stop="hiddenSession(v,i)"></i>
                                            <span class="red_circle" v-if="v.noReadSize>0">{{v.noReadSize}}</span>
                                        </span>
                                    </div>
                                    <div class="user_info c-2 f12 text-left m-t-10 line-1">{{getMessageTypeText(v)}}</div>
                                </div>
                            </div>

                            <!-- 查找用户好友 -->
                            <div>
                            </div>
                        </div>
                    </div>
                    <div v-if="tab==0" class="p-t-10 bg_FA">
                        <el-button size="mini" type="primary" @click="openDialogFormVisible">添加好友</el-button>
                        <el-button size="mini" type="" @click="hasNewGroup=true">添加分组</el-button>
                    </div>
                </div>
                <div class="right">
                    <div class="right_top">
                        <div class="select df-ai-c" @mousedown.stop="stop">
                            <img src="@/assets/images/message/system_icon.png" class="" v-if="rihhtType=='message'">
                            <img src="@/assets/images/message/newUser.png" class="" v-else-if="rihhtType=='newUser'">
                            <img :src="$oucy.ossUrl + rightImg" class="rightImg" v-else-if="rihhtType=='session'">
                            <span class="m-l-10">{{rightTitle}}</span>
                        </div>
                        <div><span class="el-icon-close pointer" title="关闭" @click="isShow=false,getAllNoReadMessageCount()"></span></div>
                    </div>
                    <!-- 空的状态 -->
                    <div class="emptyBox" @mousedown.stop="stop" v-if="!rightTitle">
                        <img src="@/assets/images/message/empty.png" alt="">
                    </div>
                    <div class="right_center" id="msgList" @mousedown.stop="stop" :class="rihhtType">
                        <!-- 新加好友列表 -->
                        <!-- <div class="user" v-for="(v,i) of queryFriendsList" v-if="rihhtType=='newUser'"> -->
                        <div class="user" v-for="(v,i) of list" v-bind:key="v.id" v-if="rihhtType=='newUser'" @click="newUserSession(v)">
                            <div class="user_head">
                                <img :src="$oucy.ossUrl + v.ltUserAuthBasics.detailAvatar" class="" v-if="v.ltUserAuthBasics && v.ltUserAuthBasics.detailAvatar">
                            </div>
                            <div class="user_right m-l-15">
                                <div class="df-jc-s-b">
                                    <div class="user_name f14 line-1 text-left">{{v.ltUserAuthBasics && v.ltUserAuthBasics.detailNickname}}</div>
                                </div>
                                <div class="user_info2 df-jc-s-b c-2 f12 text-left m-t-10 line-1">
                                    <div>申请成为您的好友！</div>
                                    <div v-if="v.friendStatus==0">
                                        <el-button size="mini" type="primary" @click.stop="opencheckFriendVisible(v,i,true)">同意</el-button>
                                        <el-button size="mini" type="" @click.stop="checkFriend(v,i,false)">忽略</el-button>
                                    </div>
                                    <div v-else>
                                        <div class="user_status c-2 f12" v-if="v.friendStatus==1">已同意</div>
                                        <div class="user_status c-2 f12" v-if="v.friendStatus==2">已忽略</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 加载更多的 -->
                        <Observer @callBack="callBackObserver">
                            <div class="text-center">
                                <template v-if="rihhtType && list.length && rihhtType!='newUser'">
                                    <i class="el-icon-loading" v-if="hasMore"></i>
                                    <span v-else class="c-2 f12">没有更多了</span>
                                </template>
                            </div>
                        </Observer>
                        <!-- 系统消息框 -->
                        <div class="right_list" v-for="(v,i) of list" v-bind:key="v.id" v-if="rihhtType=='message'">
                            <div class="right_list_item time" v-if="v.messageCreatetime"><span>{{$oucy.timestampFormat(v.messageCreatetime)}}</span></div>
                            <div class="right_list_item" v-if="!v.hasThis">
                                <div class="user_head">
                                    <img src="@/assets/images/message/system_icon.png" class="">
                                </div>
                                <div class="text"><span class="triangular"></span><span v-html="v.messageTxt"></span> </div>
                            </div>
                            <div class="right_list_item right" v-if="v.hasThis">
                                <div class="text"><span v-html="v.messageTxt"></span><span class="triangular"></span></div>
                                <div class="user_head">
                                    <img src="@/assets/images/message/system_icon.png" class="">
                                </div>
                            </div>
                        </div>
                        <!-- 聊天信息框 -->
                        <!-- <div class="right_list" v-for="(v,i) of sessionList" v-if="rihhtType=='session'"> -->
                        <div class="right_list" v-for="(v,i) of list" v-bind:key="v.id+'_'+i" v-if="rihhtType=='session'">
                            <div class="right_list_item time" v-if="v.message_createtime && $oucy.getShowDate(list,i,'message_createtime')"><span>{{$oucy.timestampFormat(v.message_createtime)}}</span></div>
                            <div class="right_list_item" v-if="!getHasThis(v)">
                                <div class="user_head">
                                    <img :src="$oucy.ossUrl+v.message_from_user.detailAvatar" class="" v-if="v.message_from_user && v.message_from_user.detailAvatar">
                                </div>
                                <div class="text" v-if="v.message_type==0"><span class="triangular"></span><span v-html="v.message_body"></span></div>
                                <div class="text image" v-else-if="v.message_type==1"><span class="triangular"></span>
                                    <el-image class="media" :src="$oucy.ossUrl+v.message_body" :preview-src-list="imageList"></el-image>
                                </div>
                                <div class="text emoji_icon" v-else-if="v.message_type==2"><span class="triangular"></span>
                                    <el-image class="media" :src="$oucy.ossUrl+v.message_body"></el-image>
                                </div>
                                <div class="text video" v-else-if="v.message_type==4"><span class="triangular"></span>
                                    <video class="media" controls="1" :src="$oucy.ossUrl+v.message_body"></video>
                                </div>
                                <!-- 位置 -->
                                <div class="text messageMap" v-else-if="v.message_type==5">
                                    <div style="width: 300px;">
                                        <messageMap :dataStr="v.message_body" :id="'container_'+i"/>
                                        <!-- <enterprise :data="v.ltEnterpriseInfoBasic"></enterprise> -->
                                    </div>
                                    <span class="triangular"></span>
                                </div>
                                <div class="text enterprise" v-else-if="v.message_type==6"><span class="triangular"></span>
                                    <div style="width: 200px;">
                                        <enterprise :data="v.ltEnterpriseInfoBasic"></enterprise>
                                    </div>
                                </div>
                                <div class="text spu" v-else-if="v.message_type==7"><span class="triangular"></span>
                                    <div style="width: 200px;">
                                        <spu :data="v.furnitureSpuFull" />
                                    </div>
                                </div>
                                <!-- 企业名片 -->
                                <div class="text" v-else-if="v.message_type==8"><span class="triangular"></span>
                                    <card :data="v.cardEnterpriseBasic" :hasEnterprise="true"></card>
                                </div>
                                <!-- 个人名片 -->
                                <div class="text" v-else-if="v.message_type==9"><span class="triangular"></span>
                                    <card :data="v.cardPersonBasic"></card>
                                </div>
                            </div>
                            <div class="right_list_item right" v-if="getHasThis(v)">
                                <div class="text" v-if="v.message_type==0"><span v-html="v.message_body"></span><span class="triangular"></span></div>
                                <div class="text image" v-else-if="v.message_type==1">
                                    <el-image class="media" :src="$oucy.ossUrl+v.message_body" :preview-src-list="imageList"></el-image>
                                    <span class="triangular"></span>
                                </div>
                                <div class="text emoji_icon" v-else-if="v.message_type==2">
                                    <el-image class="media" :src="$oucy.ossUrl+v.message_body"></el-image>
                                    <span class="triangular"></span>
                                </div>
                                <div class="text video" v-else-if="v.message_type==4">
                                    <video class="media" controls="1" :src="$oucy.ossUrl+v.message_body"></video>
                                    <span class="triangular"></span>
                                </div>
                                <!-- 位置 -->
                                <div class="text messageMap" v-else-if="v.message_type==5">
                                    <div style="width: 300px;">
                                        <messageMap :dataStr="v.message_body" :id="'container_'+i"/>
                                        <!-- <enterprise :data="v.ltEnterpriseInfoBasic"></enterprise> -->
                                    </div>
                                    <span class="triangular"></span>
                                </div>
                                 <!-- 店铺 -->
                                <div class="text enterprise" v-else-if="v.message_type==6">
                                    <div style="width: 200px;">
                                        <enterprise :data="v.ltEnterpriseInfoBasic"></enterprise>
                                    </div>
                                    <span class="triangular"></span>
                                </div>
                                <!-- 商品 -->
                                <div class="text spu" v-else-if="v.message_type==7">
                                    <div style="width: 200px;">
                                        <spu :data="v.furnitureSpuFull" />
                                    </div>
                                    <span class="triangular"></span>
                                </div>
                                <!-- 企业名片 -->
                                <div class="text card" v-else-if="v.message_type==8">
                                    <card :data="v.cardEnterpriseBasic" :hasEnterprise="true"></card>
                                    <span class="triangular"></span>
                                </div>
                                <!-- 个人名片 -->
                                <div class="text card" v-else-if="v.message_type==9">
                                    <card :data="v.cardPersonBasic"></card>
                                    <span class="triangular"></span>
                                </div>
                                <div class="user_head">
                                    <img :src="$oucy.ossUrl+v.message_from_user.detailAvatar" class="" v-if="v.message_from_user && v.message_from_user.detailAvatar">
                                </div>
                            </div>
                        </div>
                        <Observer @callBack="callBackObserverBottom" id="Observer_1"><div></div></Observer>
                        <div class="newMessage pointer" v-if="newMessageCount>0" @click="scrollTop(true),newMessageCount=0,markSessionRead()"><i class="el-icon-d-arrow-right"></i>{{newMessageCount}} 条新消息<el-divider direction="vertical"></el-divider><i class="el-icon-close" @click.stop="closeNewMessage"></i></div>
                        <!-- 查找员工  -->
                        <div class="p-20" v-if="rihhtType=='AddUser'">
                            <selectUserInput @callBack="callBack" @callBackcreateSession="callBackcreateSession" />
                        </div>
                    </div>
                    <!-- 输入框 -->
                    <div class="right_bottom" @mousedown.stop="stop" v-if="tab==1 && rihhtType=='session'">
                        <div class="inputTopIcons df-ai-c">
                            <el-popover placement="top-start" width="400" trigger="click">
                                <div class="emojis">
                                    <span class="emoji" v-for="v of emoji" @click="setEmoji(v.emoji)" v-bind:key="v.emoji">{{v.emoji}}</span>
                                </div>
                                <el-button type="text" class=" f16" size="mini" title="表情" slot="reference">{{emoji[0].emoji}}</el-button>
                            </el-popover>
                            <el-popover placement="top-start" width="400" trigger="click" v-if="listIcon && listIcon.length" ref="iconPopover">
                                <div class="emojis">
                                    <el-avatar shape="square" class="emoji_icon" size="large" :key="v.id" v-for="v of listIcon" :src="$oucy.ossUrl+v.iconUrl" @click.native="sendIcon(v.iconUrl)"></el-avatar>
                                    <!-- <span class="emoji" v-for="v of listIcon" @click="setEmoji(v.emoji)" v-bind:key="v.id">{{v.emoji}}</span> -->
                                </div>
                                <el-button type="text" class="m-l-10 inputTopIcon"  size="mini" :title="listIcon[0].iconName" icon="el-icon-star-off" slot="reference">
                                    <!-- <el-avatar shape="square" :size="size" :src="$oucy.ossUrl+listIcon[0].iconUrl"></el-avatar> -->
                                </el-button>
                            </el-popover>
                            <upload type='image/jpeg;image/png' @uploadBack="uploadBack">
                                <el-button class="inputTopIcon m-l-10" icon="el-icon-picture-outline" title="图片/视频" type="text"></el-button>
                            </upload>
                            <el-button class="inputTopIcon m-l-10" icon="el-icon-s-shop" title="推送店铺" @click="openAddEnterpriseDialog" type="text"></el-button>
                            <el-button class="inputTopIcon" icon="el-icon-postcard" @click="openAddCardDialog" title="推送名片" type="text"></el-button>
                            <el-button class="inputTopIcon" icon="el-icon-goods" title="推送商品" @click="openAddSpuDialog" type="text"></el-button>
                            <el-button class="inputTopIcon" icon="el-icon-location-outline" title="推送位置" @click="openAddr" type="text"></el-button>
                        </div>
                        <div class=" df-ai-c">
                            <el-input size="mini" v-model.trim="input" id="editor" placeholder="请输入您想咨询的内容" @focus="setSelection" @blur="setSelection" @change="setSelection" @keyup.enter.native="sendText"></el-input>
                            <el-button size="mini" class="m-l-10" type="primary" @click="sendText">发送</el-button>
                        </div>
                        <!-- <el-button @click="scrollTop(false)">scrollTop false</el-button> -->
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="添加好友" :visible.sync="checkFriendVisibl" width="560px" class="text-left">
            <div class="df-jc-s-b df-ai-c">
                <div class="df-jc-s-b df-ai-c">
                    <div><img :src="$oucy.ossUrl + checkFriendOpject.ltUserAuthBasics.detailAvatar" class="" style="width:42px;height:42px;border-radius: 50px;" v-if="checkFriendOpject && checkFriendOpject.ltUserAuthBasics && checkFriendOpject.ltUserAuthBasics.detailAvatar"></div>
                    <div class="m-l-10">
                        <el-input v-model="form.nikeName" placeholder="设置备注" autocomplete="off" size="mini" style="width: 143px;"></el-input>
                    </div>
                </div>
                <div class="df-jc-s-b df-ai-c">
                    <div>
                        <el-select v-model="form.groupId" placeholder="请选择分组" size="mini" style="width: 143px;">
                            <el-option v-for="item in groupFriendList" v-bind:key="item.groupId" :label="item.groupName" :value="item.groupId">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="m-l-10">
                        <el-button type="primary" size="mini" @click="confirm">确 定</el-button>
                    </div>
                </div>
            </div>
            <el-form :model="form" v-if='0'>
                <el-form-item label="备注" label-width="80px">
                    <el-input v-model="form.nikeName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="分组" label-width="80px">
                    <el-select v-model="form.groupId" placeholder="请选择分组" style="width: 100%;">
                        <el-option v-for="item in groupFriendList" v-bind:key="item.groupId" :label="item.groupName" :value="item.groupId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" v-if='0'>
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 添加商品弹框 -->
        <addSpuDialog title="推商品" ref="addSpuDialog" @callBack="callBackAddSpu" />
        <addEnterpriseDialog title="推企业" ref="addEnterpriseDialog" @callBack="callBackAddEnterprise" />
        <addCardDialog title="推名片" ref="addCardDialog" @callBack="callBackaddCard" />
        <addr :mapVisible="addrSelectVisible" @close="addrSelectVisible=false" @choose="addrSelectHandle"/>

        <!-- 新聊天消息语音提醒 -->
        <audio src="https://aliyunoss.lesoujia.com/lsj_mobile/music/newsinfo.mp3" ref="newsinfo"></audio>
        <!-- 新的好友申请语音提醒 -->
        <audio src="https://aliyunoss.lesoujia.com/lsj_mobile/music/NewFriends.wav" ref="NewFriends"></audio>
        <!-- 系统消息提醒 -->
        <audio src="https://aliyunoss.lesoujia.com/lsj_mobile/music/System.mp3" ref="System"></audio>
    </div>
</template>
<script>
    import { message,friend,group,session,icon, enterpriseinfo } from "@/service/index"
import { localSet, localGet, localDel } from "@/store/store";
import emoji from "@/util/emoji.json"
// console.log(emoji)
export default {
    name: "message",
    props: {
/*        isShow: {
            type: Boolean,
            default: !true,   
        },*/

    },
    data() {
        return {
            isShow:false,//显示消息框中
            showMessage:false,// 有登录
            allMessageCount:null,// 所有消息数

            // 会话列表
            list:[],
            tab:1,
            friendStatus:null, // 好友状态 0:待审核 1:审核通过 2审核驳回

            // 右边分页
            start:0,
            limit:10,
            hasMore:true,

            // 右边标题
            rightTitle:null,
            // 右边标题 图片
            rightImg:null,
            // 右边显示类型
            rihhtType:null,// 'message' newUser session

            // 发送信息的输入框
            input:'',
            // 用户列表
            queryFriendsList:[],
 
            // 会话列表
            queryAllSessionByUserList:[],

            // 会话消息列表
            sessionList:[],
            // 缓存用户
            cacheUser:null,
            // 备注
            hasTheNote:null,
            TheNote:null,
            nikeName:null,

            // 分组
            groupList:[],
            groupName:null,
            hasNewGroup:false,

            // 审核好友申请
            checkFriendVisibl:false,
            checkFriendOpject:{},
            form:{
               nikeName:null,
               groupId:null,
            },
            groupFriendList:[],
            updateGroup:{},//更新分组的对象
            groupName:null,//更分组的名称
            loginUser:null,//用户对象
            newMessageCount:0,// 当前聊天新消息数量
            emoji:emoji.data,
            selectionStart:0,
            selectionEnd:0,
            hasSend:false,
            messageTypeEnum:['文本','[图片]','[表情]','[语音]','[视频]','[地图位置]','[企业店铺]','[商品]','[名片]','[名片]'],
            imageList:[],
            queryMessage_setTimeoutID:null,
            listIcon:[],
            newFriendCOunt:0,//好友申请信息
            newSystemMessageCount:0,//好友申请信息
            selectionCount:0,

            addrSelectVisible: false,
            ImCustomerServiceList: [],
            showUser:true,
            currentPage: 0, // 当前页面
            lastPageFlag: false, // 判断最后一页是否需要加载

        }
    },
    mounted() {
        this.loginUser=localGet(this.$oucy.userInfoKey)||{}
        setTimeout(()=>{
            if(this.$oucy.getToken() && this.loginUser.id){
                this.initMessage()
            }
        },2000)
        //监听打开聊天框事件
        this.eventBus.$off(this.$oucy.msgKeys.OPEN_IM_MESSAGE).$on(this.$oucy.msgKeys.OPEN_IM_MESSAGE,(res)=>{
            console.log(res)
            console.log('监听打开聊天框事件');
            
                if(this.showMessage){
                    if(res){
                        this.createSession(res) 
                    }else{
                        this.queryAllSessionByUser()
                    }
                    this.isShow=true
                }else{
                    oucy.app.eventBus.$emit(oucy.msgKeys.LOCAL_LOGIN, null);
                }
            

        });
        //监听登录事件
        this.eventBus/*.$off(this.$oucy.msgKeys.LOGIN_SUCCESS)*/.$on(this.$oucy.msgKeys.LOGIN_SUCCESS,(res)=>{
            console.log('登录成功');
            this.initMessage()
            this.loginUser=localGet(this.$oucy.userInfoKey)
        });
        //监听退出登录事件
        this.eventBus/*.$off(this.$oucy.msgKeys.LOGOUT_SUCCESS)*/.$on(this.$oucy.msgKeys.LOGOUT_SUCCESS,(res)=>{
            console.log('退出登录');
            this.isShow=false
            this.showMessage=false
            this.list=[]
            this.start=0
        });
        // 好友申请信息
        this.eventBus.$off(this.$oucy.msgKeys.IM_FRIEND).$on(this.$oucy.msgKeys.IM_FRIEND,(res)=>{
            console.log('好友申请信息');
            ++this.newFriendCOunt
            this.SetSelectionCount()
            this.setAllMessageCount(res)
        });
        // 收到系统信息
        this.eventBus.$off(this.$oucy.msgKeys.SYSTEM_MESSAGE).$on(this.$oucy.msgKeys.SYSTEM_MESSAGE,(res)=>{
            console.log('好友申请信息');
            ++this.newSystemMessageCount
            this.SetSelectionCount()
            this.setAllMessageCount(res)
        });
        this.eventBus.$off(this.$oucy.msgKeys.IM_MESSAGE).$on(this.$oucy.msgKeys.IM_MESSAGE,(res)=>{
            console.log('收到信息',res);
            this.SetSelectionCount()

           if(this.isShow){
                    // 打开中的
                this.receive(res)
           }else{
                this.$message({
                     showClose: true,
                     message: '收到一条消息',
                     type: 'success'
                });
                if(this.allMessageCount==null){
                    // console.log('设置成1')
                    this.allMessageCount=1
                }else{
                    // console.log('++设置')
                    ++this.allMessageCount
                }
                if(this.$refs.newsinfo && typeof this.$refs.newsinfo.play =='function'){
                    this.$refs.newsinfo.play()
                }
           }
            this.setAllMessageCount(res)
        });

        this.eventBus.$on(this.$oucy.msgKeys.IM_MESSAGE_NO_READ_COUNT,(res)=>{
            this.setAllMessageCount(res)
        });
        this.eventBus.$on(this.$oucy.msgKeys.SYSTEM_ALL_READ_MESSAGE,(res)=>{
            this.setAllMessageCount(res)
        });
        this.eventBus.$on(this.$oucy.msgKeys.CHECKFRIENDSNUM,(res)=>{
            this.setAllMessageCount(res)
        }); 
    },
    created() {
        // var _this = this;	    
        // window.addEventListener('scroll',()=>{//触发事件},true);

        // 首先通过$refs获取dom元素
        //  = this.$refs.left_list;
        // 监听这个dom的scroll事件
        // this.$refs.left_list && this.$refs.left_list.addEventListener('scroll', () => {
        // console.log(this.$refs.left_list.scrollTop)
        // }, false);
    },
    methods: {
        setAllMessageCount(res){
            if(res){
                this.allMessageCount=res.allNoReadCount
            }
        },
        getAllNoReadMessageCount(){
            message.getAllNoReadMessageCount({}).then(res=>{
                this.allMessageCount=res||0
            })
        },
        getNoReadSystemMessageCount(){
            message.getNoReadSystemMessageCount({}).then(res=>{
                this.newSystemMessageCount=res||0
            })
        },
        markMessageRead(){
            message.markMessageRead({}).then(res=>{
                
            })
        },

        
        // 发送文字
        sendText(){
            if(!this.input){
                this.$message('不能发送空内容')
                return
            }
            if(this.hasSend){
                return
            }else{
                this.hasSend=true
                setTimeout(()=>{
                    this.hasSend=false
                },5000)
            }
            this.sendMessage({messageTypeEnum:0,messageContent:this.$oucy.escapeHtml(this.input)},()=>{
               this.input=null 
               this.hasSend=false
            })
        },
        // 发送表情
        sendIcon(url){
            this.sendMessage({messageTypeEnum:2,messageContent:url})
            this.$refs.iconPopover.doClose()
        },
        // 发送图片
        sendImage(url){
            this.sendMessage({messageTypeEnum:1,messageContent:url})
        },
        // 发送视频
        sendVideo(url){
            this.sendMessage({messageTypeEnum:4,messageContent:url})
        },
        // 发送
        sendMessage(SendMessage,callBack){           
            // messageTypeEnum 消息类型 消息类型 文本0,图片1,表情2,语音3,视频4,地图位置5,企业店铺6,商品7
            SendMessage.sessionId=this.cacheUser.id
            SendMessage.fromUserId=this.loginUser.id
                this.$message('发送中...')
            session.sendMessage(SendMessage).then(res=>{
                this.$message('发送成功')
                callBack && callBack()
            })
        },
        // 发送消息是否自己
        sendMessageHasThis(v){
            return this.loginUser.id== v.message_from_user.id
        },
        // 收到
        receive(res){
            if(!res)return
            // 当前聊天的
            let noReadSize=0
            // 发送消息是否自己
            let sendMessageHasThis=this.sendMessageHasThis(res)
            if(this.cacheUser && this.cacheUser.id==res.message_session_id){
                this.list.push({
                    ...res,
                    message_from_user:res.message_from_user
                })
                let ele = document.getElementById("msgList");
                if(ele.scrollHeight> (ele.scrollTop+400+50) && !sendMessageHasThis){
                   this.newMessageCount?++this.newMessageCount:this.newMessageCount=1
                }else{
                    setTimeout(()=>{
                        this.scrollTop(true)
                    })
                    // 单条已读
                    this.sessionMarkMessageReadId(res.id)
                }
                // 如果是图片 插入当前大图列表 
                if(res.message_type==1){
                    this.imageList.push(this.$oucy.ossUrl+res.message_body)
                }
            }else{
              noReadSize=res.session_no_read_count
            }

            for (var i = 0; i < this.queryAllSessionByUserList.length; i++) {
                // 删除旧的会话
                if(this.queryAllSessionByUserList[i].id==res.message_session_id){
                    this.queryAllSessionByUserList.splice(i,1)
                }
            }

            this.queryAllSessionByUserList.unshift({
                ...res,
                id:res.message_session_id,
                noReadSize:noReadSize,
                // 对方名称
                sessionUser:sendMessageHasThis?res.message_from_point:res.message_from_user,
                sessionNickName:!sendMessageHasThis?res.sessionMeNickName:res.sessionNickName,
                sessionLastMsg:res.message_body
            })
            
        },
        SetSelectionCount(v){
            if(this.tab==1){
                this.selectionCount=0
            }else
            if(v==null||v==undefined){
              ++this.selectionCount  
            }else{
                this.selectionCount=v
            }
        },
        // 关闭新消息
        closeNewMessage(){
            this.newMessageCount=0
            this.markSessionRead(this.cacheUser)
        },
        uploadBack(v){
            if(this.$oucy.getType(v)=='video'){
                this.sendVideo(v)
            }else{
                this.sendImage(v)
            }
            console.log(v)
        },
        // 打开添加商品弹框
        openAddSpuDialog(){
            this.$refs.addSpuDialog.openDialogFormVisible()
        },
        // 回调添加商品
        callBackAddSpu(list){
            console.log(list)
            for(let v of list){
                this.sendMessage({messageTypeEnum:7,messageContent:v.id,v:v})
            }
        },

        // 打开添加店铺弹框
        openAddEnterpriseDialog(){
            this.$refs.addEnterpriseDialog.openDialogFormVisible2()
        },
        // 回调添加店铺
        callBackAddEnterprise(list){
            for(let v of list){
                this.sendMessage({messageTypeEnum:6,messageContent:v.id,v:v})
            }
        },
        // 打开添加名片弹框
        openAddCardDialog(){
            this.$refs.addCardDialog.openDialogFormVisible()
        },
        // 回调添加名片
        callBackaddCard({list,type}){
            console.log(list)
            // 0 企业 1 个人
            for(let v of list){
                this.sendMessage({messageTypeEnum:type,messageContent:v.id,v:v})
            }
        },
        openAddr(){
            this.addrSelectVisible = true;
        },
        addrSelectHandle(addr){
            console.log('addr::',addr)
            const me = this;
            this.addrSelectVisible = false;
            // let thisAddr = addr.address+'('+addr.name+')';
            // if(!!me.addrSelectPoint){
                let data=JSON.stringify({
                    positionName:addr.name,
                    address:addr.address,
                    longitude:addr.location.lng,
                    latitude:addr.location.lat,
                })
                this.sendMessage({messageTypeEnum:5,messageContent:data})                
            // }
        },
        // 获取消息类型
        getMessageTypeText(v){
            if(!v)return
            if(v.message_type==0 || v.messageTypeEnum==0){
               return v.sessionLastMsg || v.message_body || v.messageContent
            }else{
               return this.messageTypeEnum[v.message_type||v.messageTypeEnum]  
            }
        },
        // 设置表情
        setEmoji(v){
            let editor = document.querySelector('#editor');
            if(this.input){
                let start =this.input.substring(0,this.selectionEnd)
                let end = this.input.substring(this.selectionEnd,this.input.length)
                // 插入表情
                this.input=start+v+end
                setTimeout(()=>{
                    // 设置焦点
                   editor.setSelectionRange((start+v).length,(start+v).length)
                   editor.focus()
                },0)
            }else{
               this.input=v 
                editor.focus()
            }
        },
        // 记录焦点
        setSelection(){
            let editor = document.querySelector('#editor');
            // 开始位置 结束位置
            // this.selectionStart = editor.selectionStart;
            this.selectionEnd = editor.selectionEnd;
        },
        // 顶部Tab
        setTab(v){
            this.tab=v
            if(v==0){
                this.queryGroupFriends()
            }else{
                this.SetSelectionCount()
                this.queryAllSessionByUser()
            }
            this.TheNote=this.updateGroup=this.rihhtType=this.rightTitle=this.cacheUser=null
        },

        // 切换成系统信息
        tabMessage(){    
            this.hasMore=true        
            this.rightTitle='系统通知'
            this.rightImg='@/assets/images/message/system_icon.png'
            this.rihhtType='message'
            this.start=0
            this.newSystemMessageCount=0
            this.list=[]
            this.getMessageContentHisByUser()
            this.cacheUser=null
        },
        // 切换添加好友
        tabAddUser(){
            this.rightTitle='好友申请通知'
            this.rihhtType='newUser'
            this.rightImg='@/assets/images/message/newUser.png'
            this.start=0
            this.newFriendCOunt=0
            this.queryFriendsList=[]
            this.list=[]
            this.queryCheckFriends()
            this.cacheUser=null
        },
        // 添加好友
        addUser(v){
            this.rihhtType='AddUser'
            this.rightTitle='添加好友'
            this.rightImg=null

        },
        // 切换会话
        setCacheUser(v){
            console.log('2222',v)
            this.getEnterpriseByUser(v);
            if(this.cacheUser && this.cacheUser.id==v.id)return
            this.rihhtType='session'
            this.cacheUser=v
            this.rightImg=v.sessionUser.detailAvatar
            
            this.sessionList=[]
            this.list=[]
            this.start=0
            this.newMessageCount=0
            this.hasMore=false
            this.queryMessage()
            this.markSessionRead(v)
        },
        callBackObserver(v){
            if(v>0){
                this.loadMore()
            }
        },
        callBackObserverBottom(v){
            if(v>0){
                if(this.newMessageCount>0){
                    this.newMessageCount=0
                    this.markSessionRead(this.cacheUser)
                }
            }
            console.log(2,v>0)
        },
        setShow(){
            // this.isfixedScroll = true;
            this.isShow=!this.isShow
            // 每次出现都查最新的
            // this.isShow&&this.getMessageContentHisByUser()

            // 好友分组 用户好友列表
            this.queryAllGroupByUser()

            // 获取用户会话
            this.queryAllSessionByUser();
            this.allMessageCount=null

             //监听滚动条事件
            this.$nextTick(()=>{
                this.$refs.left_list && this.$refs.left_list.addEventListener('scroll', this.handleScroll); 
            });
        },
        loadMore(){
            console.log('loadMore')
            if(!this.hasMore)return
            if(this.rihhtType=='message'){
                this.getMessageContentHisByUser()
            }else
            if(this.rihhtType=='session'){
                this.queryMessage()
            }else
            if(this.rihhtType=='newUser'){
                this.queryCheckFriends()
            }

        },
        stop(){},
        initMessage(){
            this.showMessage=true
            this.queryAllListIcon()            
            this.getImCustomerServiceList()            
            this.getAllNoReadMessageCount()            
            this.getNoReadSystemMessageCount()            
        },
        // 获取系统消息
        getMessageContentHisByUser(){
            if(!this.hasMore)return
            this.hasMore=false
            message.getMessageContentHisByUser({limit: this.limit,
            start:this.start,}).then(res=>{
                if(res && res.content){
                    let ele = document.getElementById("msgList");
                    let height=ele.scrollHeight
                    res.content.reverse()
                    this.list.unshift(...res.content)
                    // 等待页面
                    setTimeout(() => {
                        ele.scrollTop = ele.scrollHeight-height;
                    }, 0);
                    
                    ++this.start
                    this.hasMore=!res.last
                    this.markMessageRead()
                }else{
                    // this.hasMore=false
                }
            })
        },
        // 查询平台客服人员列表
        getImCustomerServiceList(){

            message.getImCustomerServiceList({}).then(res=>{
                if(res){
                   this.ImCustomerServiceList=res
                }else{
                    // this.hasMore=false
                }
            })
        },
        /**
         * 滚动
         * @param  { Boolean } isTop 滚动类型 是否到顶
         */
        scrollTop(isTop=true){
            let ele = document.getElementById("msgList");
            this.animate(ele.scrollTop,isTop?ele.scrollHeight:0,isTop,ele)
        },
        /**
         * 动画
         * @param  { Number } i    开始位置
         * @param  { Number } to   目标位置
         * @param  { Boolean } isTop 滚动类型 是否到顶
         * @param  { objcet } ele  滚动元素
         */
        animate(i,to,isTop,ele){
            if((isTop==true && i< to) ||(isTop==false && i> to)){
                i=isTop?i+50:i-50
                ele.scrollTop = i
                requestAnimationFrame(()=>{
                    this.animate(i,to,isTop,ele)
                });
            }
        },
        //////////////////////////////////////////////////////////////
        // 好友
        //////////////////////////////////////////////////////////////
        // 查询用户的所有好友分组
        queryAllGroupByUser(){
            this.queryGroupFriends()
           group.queryAllGroupByUser({start:0,limit:100}).then(res=>{
            this.groupList=res||[]
           }) 
        },

        // 查询用户的所有好友分组
        queryGroupFriends(){
           friend.queryGroupFriends({start:0,limit:100}).then(res=>{
            if(res){
                for(let v of res){
                    v.showUser=!false
                }
                this.groupFriendList=res||[]
            }
           }) 
        },
 
        // 查询好友关系列表
        queryCheckFriends(){
           friend.queryCheckFriends({start:this.start,limit:10,friendStatus:null}).then(res=>{
            if(res&& res.content){
                 this.list.push(...res.content)
                 ++this.start
                 this.hasMore=!res.last
            }else{
                this.hasMore=false
            }
           }) 
        },   
        // 添加好友
        addFriend(v){
            friend.addFriend({targetUserId:v.id}).then(res=>{
              this.$message('操作成功')
            }) 
        },
        // 打开 审核好友申请
        opencheckFriendVisible(v,i){
            this.type='friend'
            this.form.nikeName=null
            this.form.groupId=null
            this.checkFriendOpject=v
            this.checkFriendVisibl=true
        },
        // 审核好友申请 同意
        confirm(){
            // 审核
            if(this.type=='friend'){                
              friend.checkFriend({
                friendId:this.checkFriendOpject.friendId,
                isPass:true,
                nikeName:this.form.nikeName,
                groupId:this.form.groupId,
                }).then(res=>{
                  this.checkFriendOpject.friendStatus=1
                    this.$message('操作成功')
                    this.checkFriendVisibl=false
              }) 
            }else{
                // 添加
                friend.addFriend({
                    targetUserId:this.checkFriendOpject.id,
                    nikeName:this.form.nikeName,
                    groupId:this.form.groupId,
                }).then(res=>{
                  this.$message('操作成功')
                    this.checkFriendVisibl=false
                }) 
            }
        },
        // 审核好友申请 忽略
        checkFriend(v,i,isPass){
            friend.checkFriend({friendId:v.friendId,isPass:isPass}).then(res=>{
                v.friendStatus=isPass?1:2
                  this.$message('操作成功')
            }) 

        },
        // 删除好友
        delFriend(v,vv,i,ii){
            this.$oucy.confirm('删除好友将会在移出分组','确认删除好友？').then(res=>{
                friend.delFriend({friendId:vv.imFriendBasic_one_ltUserAuthBasic.friendId}).then(res=>{
                  this.groupFriendList[i].imFriendGroup_allList.splice(ii,1)
                  this.$message('操作成功')
                }) 
            },err=>{}) 
        },
        // 设置好友备注
        openSetTheNote(v,i){
            console.log(v)
            this.hasTheNote=null
            this.TheNote=v.imFriendBasic_one_ltUserAuthBasic
            this.nikeName=v.imFriendBasic_one_ltUserAuthBasic.friendNickName
        },
        
        // 提交好友备注
        blurTheNote(){
            this.hasTheNote && clearTimeout(this.hasTheNote)
            this.hasTheNote = setTimeout(()=>{
                this.setTheNote()
            },2)

        },

        // 提交好友备注
        setTheNote(){
            if(this.TheNote==null)return
            
            friend.setTheNote({friendId:this.TheNote.friendId,nikeName:this.nikeName}).then(res=>{
                  this.$message('操作成功')
                  this.TheNote.friendNickName=this.nikeName
                  this.TheNote=null
                  this.nikeName=null
                  this.hasTheNote=null
              // this.queryFriendsList.splice(i,1)
            }) 
        },
        // 获取表情列表
        queryAllListIcon(){
            icon.queryAllListIcon({}).then(res=>{
                if(res){
                    this.listIcon=res
                }
            })
        },
        //////////////////////////////////////////////////////////////////////////////////////
        // 分组
        //////////////////////////////////////////////////////////////////////////////////////

        // 新增分组
        blurNewGroup(){
            if(!this.groupName){
                this.hasNewGroup=false
                return
            }
            group.createGroup({groupName:this.groupName}).then(res=>{
                  this.$message('操作成功')
                  this.hasNewGroup=false
                  this.groupName=null
                  this.queryAllGroupByUser()
            }) 
        },
        // 删除分组
        delGroup(v,i){
            this.$oucy.confirm('删除后组内好友将移至“我的好友”分组','确认删除分组？').then(res=>{
                group.delGroup({groupId:v.groupId}).then(res=>{
                     this.$message('操作成功')
                     this.queryAllGroupByUser()
                })  
            },err=>{})
        },
        setUpdateGroup(v,i){
            this.updateGroup=v
            this.groupName=v.groupName
        },
        // 修改分组
        blurUpdateGroup(){
            if(!this.groupName){
                this.updateGroup={}
                return
            }
           group.updateGroup({groupId:this.updateGroup.groupId,groupName:this.groupName}).then(res=>{
                this.groupName=null
                this.updateGroup={}
                 this.$message('操作成功')
                 this.queryAllGroupByUser()
           })  
        },

        // 移动分组
        moveGroup(v,vv,vvv,i,ii,iii){
            // 目标是我的好友分组为移出
            if(!vvv.groupId){
                this.removeGroup(vv)
            }else{
                this.JoinGroup(vv,vvv)
            }
        },
        // 添加好友进分组
        JoinGroup(vv,vvv){
            group.JoinGroup({groupId:vvv.groupId,friendId:vv.imFriendBasic_one_ltUserAuthBasic.friendId}).then(res=>{
                this.$message('操作成功')
                this.queryAllGroupByUser()                
            })
        },
        // 将好友移出分组
        removeGroup(v,i){
            group.removeGroup({friendGroupId:v.friendGroupId}).then(res=>{
                this.$message('操作成功')
                this.queryAllGroupByUser()                
            })
        },
        // 获取用户会话列表
        queryAllSessionByUser(hasFirst=false){
            const param = {
                start: this.currentPage,
                limit: 10, // 每页显示30条
            }
           session.queryAllSessionByUser({
               ...param
           }).then(res=>{
                if(res){
                    const { content , last} = res;
                    this.currentPage ++ ;
                    this.lastPageFlag = last;
                    this.tab=1
                    console.log('>>>>>>>>');
                    console.log(res);
                    // this.queryAllSessionByUserList=res.content;
                    this.queryAllSessionByUserList= [...this.queryAllSessionByUserList, ...content];
                    if(hasFirst && this.queryAllSessionByUserList.length){
                        this.setCacheUser(this.queryAllSessionByUserList[0])
                    }             
                }
           }) 
        },
        // 点击好友会话
        clickSession(v,i){
            this.createSession({targetUserId:v.imFriendBasic_one_ltUserAuthBasic.ltUserAuthBasics.id})
        },
        // 点击平台客服会话
        clickSession2(v,i){
            this.createSession({targetUserId:v.userAuth.id})
        },
        // 创建会话
        createSession({targetUserId}){
            session.createSession({targetUserId:targetUserId}).then(res=>{
                this.queryAllSessionByUser(true)
            })
        },
        // 隐藏会话
        hiddenSession(v,i){
            this.$oucy.confirm('确认隐藏会话？','提示').then(res=>{
                session.hiddenSession({sessionId:v.id,isHide:true}).then(res=>{
                    if(this.cacheUser && v.id==this.cacheUser.id){
                         this.rihhtType=this.rightTitle=this.cacheUser=null
                    }
                  this.queryAllSessionByUserList.splice(i,1)
                  this.$message('操作成功')
                }) 
            },err=>{})
        },
        // 查询会话列表
        queryMessage(){
            this.queryMessage_setTimeoutID && clearTimeout(this.queryMessage_setTimeoutID)
            this.queryMessage_setTimeoutID= setTimeout(()=>{
                session.queryMessage({sessionId:this.cacheUser.id,start:this.start,limit:this.limit}).then(res=>{
                    if(res && res.content){
                        let ele = document.getElementById("msgList");
                        let height=ele.scrollHeight
                        if(res && res.content){
                            res.content.reverse()
                            // 首条的都显示时间
                            res.content[0].showDate=true
                            this.list.unshift(...res.content)
                        }
                        // 等待页面
                        setTimeout(() => {
                            ele.scrollTop = ele.scrollHeight-height;
                        }, 0);
                        
                        ++this.start
                        this.hasMore=!res.last
                        let imageList=[]
                        for (let v of this.list){
                            if(v.message_type==1){
                                imageList.push(this.$oucy.ossUrl+v.message_body)
                            }
                        }
                        this.imageList=imageList
                    }else{
                        this.hasMore=false
                    }
                })
                this.queryMessage_setTimeoutID=null
            },100)
        },
        // 标记会话已读
        markSessionRead(v=this.cacheUser){
            session.markSessionRead({sessionId:v.id}).then(res=>{
                v.noReadSize=0
            })
        },
        // 标记消息已读
        sessionMarkMessageReadId(id){
            if(!id)return
            session.markMessageRead({jsonArray_messageIds:JSON.stringify([id])}).then(res=>{
                // v.noReadSize=0
            })
        },
        // 打开选择用户
        openDialogFormVisible() {
            this.addUser()
        },
        // 选择用户回调
        callBack(v) {
            console.log(v)
            this.type='add'
            this.form.nikeName=null
            this.form.groupId=null
            this.checkFriendOpject=v
            this.checkFriendVisibl=true
            // this.addFriend(v)
            // this.enterApplyForm.userAuthId = v.id
            // this.selectObj = v
        },
        // 加用户回调聊天
        callBackcreateSession(v){
            this.createSession({targetUserId:v.id})
        },
        // 加用户回调聊天
        newUserSession(v){
            this.createSession({targetUserId:v.ltUserAuthBasics.id})
        },
        // 获取是否自己
        getHasThis(v){
            if(v.message_from_user && this.loginUser && v.message_from_user.id==this.loginUser.id){
                return true
            }else{
                return false
            }
        },

        handleScroll(){
            console.log(1111);
            const domRef = this.$refs.left_list;
            // let scrollTop = this.$refs.left_list ? this.$refs.left_list.scrollTop: '';
            const offsetHeight = domRef && domRef.offsetHeight;
            const scrollTop = domRef &&  domRef.scrollTop;
            const scrollHeight = domRef &&  domRef.scrollHeight;
            if (offsetHeight + scrollTop >= scrollHeight && !this.lastPageFlag ) {
                //触发事件
                this.queryAllSessionByUser();
            }

            // console.log(this.$refs.left_list);
            //let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            console.log('1111',scrollTop)
            
        },

        async getEnterpriseByUser(v){
            this.rightTitle = '';
            const {sessionUser} = v;
            if(!sessionUser.id) {
               return this.$message('id不能为空')
            }
           
            await enterpriseinfo.queryEnterpriseByUser({
                userAuthId: sessionUser.id
            }).then((res) => {
                const  result = res || [];
                console.log(result);
                if(result && result.length > 0) {
                    let len = result.length;
                    for(let i = 0 ;i < len ;i++ ){
                        if(result[i].isdefault) {
                            this.rightTitle= (v.sessionNickName || v.sessionUser.detailNickname)+ '--' + result[i].enterpriseName
                            break
                        }
                    }  
                      
                } else {
                    this.rightTitle= v.sessionNickName || v.sessionUser.detailNickname
                }
                console.log(this.rightTitle);
            })
        }


    },
    beforeDestroy() {
        this.eventBus.$off(this.$oucy.msgKeys.IM_MESSAGE);
        this.eventBus.$off(this.$oucy.msgKeys.LOCAL_LOGIN);
        // this.eventBus.$off(this.$oucy.msgKeys.LOGOUT_SUCCESS);
    },
    //注册局部组件指令
    directives: {
        drag: function(el) {
            let dragBox = el; //获取当前元素
            dragBox.onmousedown = e => {
                //算出鼠标相对元素的位置
                let disX = e.clientX - dragBox.offsetLeft;
                let disY = e.clientY - dragBox.offsetTop;
                document.onmousemove = e => {
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let left = e.clientX - disX;
                    let top = e.clientY - disY;
                    //移动当前元素
                    dragBox.style.left = left + "px";
                    dragBox.style.top = top + "px";
                };
                document.onmouseup = e => {
                      //鼠标弹起来的时候不再移动
                      document.onmousemove = null;
                     //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）  
                    document.onmouseup = null;
                };
            };
        }
    }
}
</script>
<style scoped lang="less">
.boxs {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    position: fixed;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    height: 500px;
    width: 790px;
    display: flex;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.left {
    flex: 1;
    border-right: 1px solid #EAEAEA;
}

.right {
    flex: 2;
}

.left_top {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    // padding: 20px 0;
    line-height: 54px;
    border-bottom: 1px solid #EAEAEA;
    box-sizing: border-box;
    height: 54px;

    .select {
        border-bottom: 2px solid #2090FF;
        color: #2090FF;
    }
}

.left_list {
    overflow: auto;
}

.left_list_0 {
    height: 400px;
}

.left_list_1 {
    height: 440px;
}

.left_top2 {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

.right_top {
    height: 54px;
    line-height: 54px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 14px;
    border-bottom: 1px solid #EAEAEA;
}

.user {
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;

    // border-bottom: 1px solid #f1f1f1;

    &:hover {
        background: #F4F9FF;
    }
}

.user+.user {}

.user_head {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }
}

.user_right {
    flex: 1;
}

.user_status {
    width: 90px;
}

.fold {
    // border-bottom: 1px solid #EAEAEA;
}

.right_list_item {
    display: flex;
    padding: 0 20px;
    margin: 20px 0;

    .text {
        max-width: 308px;
        margin-left: 16px;
        background: #F6F7F8;
        border-radius: 5px;
        padding: 6px 10px;
        position: relative;
        font-size: 12px;
        color: #666;
        line-height: 1.5;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;

        span.triangular {
            position: absolute;
            top: 6px;
            left: -22px;
            display: inline-block;
            width: 0;
            height: 0;
            border-bottom: 10px solid transparent;
            border-left: 14px solid transparent;
            border-right: 14px solid #F6F7F8;
            border-top: 10px solid transparent;
        }

        &.image,
        &.video,
        &.enterprise,
        &.spu,
        &.card {
            padding: 6px;
            // background: #F6F7F8;
            // span.triangular {
            // border-right: 14px solid #F6F7F8;  
            // }
        }

        &.emoji_icon {
            width: 48px;
        }
    }

    &.right {
        justify-content: flex-end;

        .text {
            // text-align: right;
            margin-left: auto;
            margin-right: 16px;

            background: #2090FF;
            color: #fff;
            white-space: pre-wrap;
            word-wrap: break-word;

            span.triangular {
                left: auto;
                right: -22px;
                border-right: 14px solid transparent;
                border-left: 14px solid #2090FF;
            }

            &.messageMap,
            &.image,
            &.video,
            &.enterprise,
            &.spu,
            &.card {
                background: #F6F7F8;
                padding: 6px;

                span.triangular {
                    border-left: 14px solid #F6F7F8;
                }
            }

            &.emoji_icon {
                width: 48px;
                // height: auto;
            }
        }
    }

}

.time {
    font-size: 12px;
    color: #666;
    justify-content: center;

    span {
        display: inline-block;
        border-radius: 9px;
        background: #F6F7F8;
        padding: 2px 11px;

    }
}

.right_center {
    background: ;
    overflow: hidden;
    overflow: auto;
    height: 440px;

    &.session {
        height: 368px;
    }
}

.badge {
    // position: fixed;
    position: relative;
    z-index: 9999;
    // right: 42px;
    // bottom: 38px;
    cursor: pointer;
    background: rgba(255,255,255,0.7);
    // padding: 10px 15px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.07);
    line-height: 1;
    margin: auto;
    &:hover{
        color: #409eff;
    }
    .img{
        width: 28px;
    }
    .item {
        margin-top: 6px;
    }
}

.right_center::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: rgba(0, 0, 0, 0);
    /**/
}

.right_center::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.right_center::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
    border-radius: 3px;
}

.right_center:hover::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.right_center::-webkit-scrollbar-thumb:hover {
    // background: #CECECE;
}

.right_center::-webkit-scrollbar-corner {}


.left_list::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: rgba(0, 0, 0, 0);
    /**/
}

.left_list::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.left_list::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
    border-radius: 3px;
}

.left_list:hover::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.left_list::-webkit-scrollbar-thumb:hover {
    // background: #CECECE;
}

.left_list::-webkit-scrollbar-corner {}

.friendStatus {
    color: #aaa;

    &.select {
        color: #2090FF;
        background: #F6F7F8;
    }
}

.right_bottom {
    box-sizing: border-box;
    padding: 9px 10px;
    background: #FAFAFA;
}

.emptyBox {
    // height:100%;
    padding-top: 150px;
}

.setting {
    color: #ccc;
    display: none;
}

.user:hover .setting,
.fold:hover .setting {
    display: inline-block;
}

.groupItem {
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
}

.groupItem:hover {
    background: #F4F9FF;
    color: #409EFF;
}

.reference {
    color: #333;
}

.reference:hover {
    color: #409EFF;
}

.rightImg {
    width: 32px;
    height: 32px;
    border-radius: 50px;
}

.red_circle {
    display: inline-block;
    padding: 0 6px;
    // width: 14px;
    height: 18px;
    background: #FE3333;
    border-radius: 50px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    // line-height: 21px;
    color: #FFFFFF;
    margin-left: 5px;
}

.user_name {
    max-width: 160px;
}

.bg_FA {
    background: #FAFAFA;
}

.newMessage {
    position: absolute;
    right: 20px;
    bottom: 85px;
    background: #FAFAFA;
    color: #0c0;
    font-size: 12px;
    padding: 6px;
    border-radius: 6px;

    .el-icon-d-arrow-right {
        margin-right: 5px;
        transform: rotate(90deg);
    }
}

.emojis {
    max-height: 150px;
    overflow: auto;

    .emoji {
        display: inline-block;
        cursor: pointer;
        padding: 4px;
        margin: 1px;
        border-radius: 4px;

        &:hover {
            background: #f1f1f1;
        }
    }

    .emoji_icon {
        display: inline-block;
        margin: 5px;
        cursor: pointer;
    }

}

.media {
    max-width: 200px;
    max-height: 200px;
}

.inputTopIcons {
    margin-top: -5px;
    padding-bottom: 5px;
}

.inputTopIcon {
    font-size: 20px;
    padding: 4px;
    color: #3A4454;
}

.user_info {
    max-width: 150px;
}

.user_info.user_info2 {
    max-width: 100%;
}
</style>
<style type="text/css">
.el-popover {
    min-width: 80px;
}

.message .el-badge__content.is-fixed {
    top: 19px;
}
</style>