export default {
    data() {
      return {
        wrapperDom: null,
        // headerDom: null,
        disX: 0,
        disY: 0,
   
        minLeft: 0,
        maxLeft: 0,
   
        minTop: 0,
        maxTop: 0,
   
        prevLeft: 0,
        prevTop: 0,
      };
    },
    methods: {
      initDrag() {
        this.wrapperDom = this.$refs.dragWrapper;
        this.wrapperDom.addEventListener('mousedown', this.onMousedown, false);//点击区域拖拽
      },
      onMousedown(e) {
        this.minLeft = this.wrapperDom.offsetLeft;
        this.minTop = this.wrapperDom.offsetTop;
   
        this.maxLeft =
          window.innerWidth - this.minLeft - this.wrapperDom.offsetWidth;
        this.maxTop =
          window.innerHeight - this.minTop - this.wrapperDom.offsetHeight;
   
        const { left, top } =  window.getComputedStyle(this.wrapperDom, false);
       
        this.prevLeft = parseFloat(left);
        this.prevTop = parseFloat(top);
   
        document.addEventListener('mousemove', this.onMousemove, false);
        document.addEventListener('mouseup', this.onMouseup, false);
        document.body.style.userSelect = 'none'; //消除拖拽中选中文本干扰
      },
      onMousemove(e) {
        let left = e.clientX - this.minLeft - 110;
        let top = e.clientY - this.minTop;
   
        if (-left > this.minLeft) {
          left = -this.minLeft;
        } else if (left > this.maxLeft) {
          left = this.maxLeft;
        }
   
        if (-top > this.minTop) {
          top = -this.minTop;
        } else if (top > this.maxTop) {
          top = this.maxTop;
        }
   
        this.wrapperDom.style.left = this.prevLeft + left + 'px';
        this.wrapperDom.style.top = this.prevTop + top + 'px';

        // console.log('this.wrapperDom.style', this.wrapperDom.style)
      },
      onMouseup() {
        document.removeEventListener('mousemove', this.onMousemove, false);
        document.removeEventListener('mouseup', this.onMouseup, false);
        document.body.style.userSelect = 'auto'; //恢复文本可选中
      },
    },
    mounted() {
      this.initDrag();
    }
  };