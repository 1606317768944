// 发布中心

const ReleaseCenter = [
    {
        path: '/ReleaseCenter',
        name: 'ReleaseCenter',
        component: () => import("@/views/ReleaseCenter/Index/Index.vue"),
        meta:{title:'发布中心'}
    },
    {
        path: '/ReleaseCenterAskBuySave',
        name: 'ReleaseCenterAskBuy',
        component: () => import("@/views/ReleaseCenter/askBuy/save.vue"),
        meta:{title:'发布求购'}
    },


        {
        path: '/ReleaseCenterPay',
        name: 'ReleaseCenterPay',
        component: () => import("@/views/ReleaseCenter/pay/pay.vue"),
        meta:{title:'发布求购'}
    },
     {
        path: '/ReleaseCenterFinish',
        name: 'ReleaseCenterFinish',
        component: () => import("@/views/ReleaseCenter/finish/finish.vue"),
        meta:{title:'发布求购'}
    },
    

];

export default ReleaseCenter