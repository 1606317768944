import oucy from '@/util/oucyUtil.js'
// 用户收藏
const collectuser = {
	/**
     * 查询客户端用户的所有企业
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    saveCollectUser: (params) => {
        return oucy.postRequest('/client/user/collectuser/saveCollectUser', params)
    },
    /**
 * 是否收藏
 * @param  {object} 请求的参数
 * @return {Promise} 返回一个Promise
 */
    isCollect: (params) => {
        return oucy.postRequest('/client/user/collectuser/isCollect', params,false)
    },
    /**
* 收藏类型 1店铺 2商品（不用了）
* @param  {object} 请求的参数
* @return {Promise} 返回一个Promise
*/
    getCollectType: (params) => {
        return oucy.postRequest('/client/user/collectuser/getCollectUserList', params)
    },
    /**
     * 查询个人名片收藏列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getCardPersonCollect: (params) => {
        return oucy.postRequest('/client/card/cardpersoncollect/getCardPersonCollectList', params)
    },
    /**
     * 查询员工名片收藏列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getCardEmployeeCollect: (params) => {
        return oucy.postRequest('/client/card/cardemployeecollect/getCardEmployeeCollectList', params)
    },
    /**
    * 查询企业名片收藏列表
    * @param  {object} 请求的参数
    * @return {Promise} 返回一个Promise
    */
    getCardEnterpriseCollect: (params) => {
        return oucy.postRequest('/client/card/cardenterprise/getCardEnterpriseCollectList', params)
    },

    /**
     * 置顶用户收藏的商品/店铺
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    collectTopping: (params) => {
        return oucy.postRequest('/client/user/collectuser/collectTopping', params)
    },
    /**
    * 置顶个人名片收藏
    * @param  {object} 请求的参数
    * @return {Promise} 返回一个Promise
    */
    collectPersonTopping: (params) => {
        return oucy.postRequest('/client/card/cardpersoncollect/saveCardPersonCollectTopping', params)
    },
    /**
     * 置顶员工名片收藏
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    collectEmployeeTopping: (params) => {
        return oucy.postRequest('/client/card/cardemployeecollect/saveCardEmployeeCollectTopping', params)
    },
    /**
     * 置顶企业名片收藏
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    collectEnterpriseTopping: (params) => {
        return oucy.postRequest('/client/card/cardenterprise/saveCardEnterpriseCollectTopping', params)
    },
    /**
    * 收藏夹删除个人名片
    * @param  {object} 请求的参数
    * @return {Promise} 返回一个Promise
    */
    collectCardPerson: (params) => {
        return oucy.postRequest('/client/card/cardperson/collectCardPerson', params)
    },
    /**
    * 收藏夹删除个人名片(批量取消)
    * @param  {object} 请求的参数
    * @return {Promise} 返回一个Promise
    */
   batchCollectCardPerson: (params) => {
    return oucy.postRequest('/client/card/cardperson/batchCollectCardPerson', params)
},
    
    /**
   * 收藏夹删除员工名片
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
    delCardEmployee: (params) => {
        return oucy.postRequest('/client/card/cardemployee/collectCardEmployee', params)
    },
     /**
   * 收藏夹删除员工名片(批量取消)
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
  batchCollectCardemployee: (params) => {
    return oucy.postRequest('/client/card/cardemployee/batchCollectCardEmployee', params)
},
    /**
  * 收藏夹删除企业名片
  * @param  {object} 请求的参数
  * @return {Promise} 返回一个Promise
  */
    cardEnterpriseCollect: (params) => {
        return oucy.postRequest('/client/card/cardenterprise/cardEnterpriseCollect', params)
    },
    /**
  * 收藏夹删除企业名片(批量取消)
  * @param  {object} 请求的参数
  * @return {Promise} 返回一个Promise
  */
 batchCancelCardEnterpriseCollect: (params) => {
    return oucy.postRequest('/client/card/cardenterprise/batchCancelCardEnterpriseCollect', params)
},
    /**
  * 收藏夹删除用户收藏 1企业 2商品 3企业视频
  * @param  {object} 请求的参数
  * @return {Promise} 返回一个Promise
  */
    delCollectUser: (params) => {
    return oucy.postRequest('/client/user/collectuser/delCollectUser', params)
},
    /**
  * 收藏夹删除用户收藏 1企业 2商品 3企业视频(批量取消)
  * @param  {object} 请求的参数
  * @return {Promise} 返回一个Promise
  */
 batchDelCollectUser: (params) => {
    return oucy.postRequest('/client/user/collectuser/batchDelCollectUser', params)
},
    /**
     * 生成商品海报
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    generateSpuPoster: (params) => {
        return oucy.postRequest('/client/auth/poster/generateSpuPoster', params)
    },
}

export default collectuser
