<!-- 用户注册 弹框-->
<template>
  <div class="register">
    <el-dialog
        :visible.sync="isVisible"
        custom-class="reg_box"
        width="703px"
        title="用户注册"
        @close="handleClose">
      <el-form :model="regiForm" :label-position="labelPosition" status-icon :rules="rules" ref="regiForm" label-width="100px">
        <el-form-item label="账号" label-width="80px" prop="username">
          <el-input v-model="regiForm.username" autocomplete="off" placeholder="请输入您的用户名称"></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="80px" prop="password">
          <el-input v-model="regiForm.password" autocomplete="off" placeholder="请输入您的登录密码"
                    show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" label-width="80px" prop="password_confirm">
          <el-input v-model="regiForm.password_confirm" autocomplete="off" placeholder="请再次输入您的密码"
                    show-password></el-input>
        </el-form-item>
        <el-form-item label="手机号码" label-width="80px" prop="phoneNumber">
          <el-input v-model="regiForm.phoneNumber" autocomplete="off" placeholder="请输入您的手机号码"></el-input>
        </el-form-item>
        <el-form-item label="验证码" label-width="80px" prop="phoneCode">
          <el-input v-model="regiForm.phoneCode" autocomplete="off" placeholder="请输入短信验证码">
            <el-button @click="getRegisterPhoneCode" :disabled="!showGetCode" type="text"
                       style="padding-right:10px;color:#0366C3;" slot="suffix">
              <span v-if="showGetCode">获取验证码</span>
              <span v-else class="count">{{ codeTime }} s</span>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item label-width="100px">
          <el-checkbox v-model="regiForm.agreement">同意
            <el-link :underline="false" style="color:#0366C3;" href="" type="primary">《乐搜家平台服务条款》
            </el-link>
            及
            <el-link :underline="false" style="color:#0366C3;" href="" type="primary">《隐私协议》</el-link>
          </el-checkbox>
        </el-form-item>
          <div class="reg_button">
            <el-button style="width: 100%" :disabled="!regiForm.agreement" @click="submitRegister" type="primary">注册</el-button>
          </div>
      </el-form>
      <div class="login_bottom">
          <span>已有账号？</span>
          <el-link @click="goLogin" type="primary" :underline="false">立即登录</el-link>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import oucy from "../util/oucyUtil"

export default {
  name: 'register',
  props: {
    isVisible: Boolean,
    default: false
  },
  data() {
    const me = this;
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入用户名称'));
      } else if (value.length < 3 || value.length > 15) {
        callback(new Error('长度在 3 到 15 个字符'));
      } else {
        oucy.postRequest('/client/user/userauth/vertifyRegisterUsername', {
          username: value
        }).then(res => {
          callback();
        }).catch(err => {
          callback(new Error('该用户名已被注册'));
        })
      }
    };
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'));
      } else if (value.length < 6 || value.length > 25) {
        callback(new Error('长度在 6 到 25 个字符'));
      } else {
        if (this.regiForm.password_confirm !== '') {
          this.$refs.regiForm.validateField('password_confirm');
        }
        callback();
      }
    };
    const validatePass_confirm = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.regiForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号码'))
      } else if (!oucy.isValidPhone(value)) {
        callback(new Error('请输入正确的11位手机号码'))
      } else {
        callback()
      }
    };
    return {
      codeTime: 0,
      codeTimer: null,
      showGetCode: true,
      visible: false,
      show: 'false',
      count: '',
      labelPosition: 'right',
      regiForm: {
        username: '',
        password: '',
        password_confirm: '',
        phoneNumber: '',
        phoneCode: '',
        agreement: false,
      },
      rules: {
        username: [
          {required: true, validator: validateUsername, trigger: 'blur'},
        ],
        password: [
          {required: true, validator: validatePass, trigger: 'blur'},
        ],
        password_confirm: [
          {required: true, validator: validatePass_confirm, trigger: 'blur'},
        ],
        phoneNumber: [
          {required: true, validator: validatePhone, trigger: 'blur'},
        ],
        phoneCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ]
      }
    }
  },
  mounted() {
    const me = this;
    oucy.getCodeTime().then(res => {
      me.codeTime = Number(res);
      me.codeTimer = setInterval(() => {
        if (me.codeTime > 0) {
          me.showGetCode = false;
          me.codeTime--;
        } else {
          me.showGetCode = true;
        }
      }, 1000);
    });
  },
  methods: {
    getRegisterPhoneCode: function () {
      const me = this;
      me.$refs['regiForm'].validateField('phoneNumber', (message) => {
        if (message == null || message.length === 0) {
          oucy.postRequest('/userAuth/registerSendCode', {
            phoneNumber: me.regiForm.phoneNumber,
            uuid: oucy.getUUID()
          }).then(res => {
            me.codeTime = Number(res);
            me.$notify({
              title: '短信发送成功',
              message: '验证码已发送，请留意短信通知',
              type: 'success'
            });
          });
        }
      });
    },
    submitRegister: function () {
      const me = this;
      me.$refs["regiForm"].validate((valid) => {
        if (valid) {

          oucy.postRequest('/userAuth/registerAuth4up', {
            username: me.regiForm.username,
            password: me.regiForm.password,
            passwordRepeat: me.regiForm.password_confirm,
            phoneCode: me.regiForm.phoneCode,
            phoneNumber: me.regiForm.phoneNumber
          }).then(res => {
            me.handleClose();
            me.$notify({
              title: '账号注册成功',
              message: '账号注册成功，现在你可以使用该账号登录了',
              type: 'success'
            });
          })

        }
      });
    },
    handleClose: function () {
      this.$emit('close');
    },
    goLogin: function () {
      this.$emit('goLogin');
    }
  }
}
</script>

<style scoped>

/deep/ .reg_box {
  border-radius: 8px;
  height:590px;
  margin: 200px auto 0;
  padding: 0 130px;
  text-align: center;
}

/deep/ .el-dialog__title {
  font-size: 28px;
  color: #0366C3;
  line-height: 60px;
  font-weight: bold;
  margin-left:20px;
}

.register {
  text-align: left;
}

.reg_button >>> .el-button {
  text-align: center;
  color: #FFFFFF;
  font-size: 16px;
  width: 324px;
  height: 42px;
  background: #0366C3;
  border-radius: 8px;
}

.login_bottom {
  width: 100%;
  margin-top: 18px;
}

/deep/ .el-form-item label:after {
  content: " ";
}

/deep/ .el-form-item__label {
  text-align-last: justify
}
/* 这里去除必选字段的*,这个符号会造成一定影响 */
/deep/ .el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}
</style>
