import oucy from '@/util/oucyUtil.js'
// 会话管理
const session = {
    /**
     * 创建会话
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createSession: (params) => {
        return oucy.postRequest('/client/im/session/createSession', params,false)
    },
    /**
     * 隐藏会话
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    hiddenSession: (params) => {
        return oucy.postRequest('/client/im/session/hiddenSession', params,false)
    },
    /**
     * 验证用户之前是否存在会话记录
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    isHasSession: (params) => {
        return oucy.postRequest('/client/im/session/isHasSession', params,false)
    },
    /**
     * 标记消息已读
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    markMessageRead: (params) => {
        return oucy.postRequest('/client/im/session/markMessageRead', params,false)
    },
    /**
     * 标记会话全部消息已读
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    markSessionRead: (params) => {
        return oucy.postRequest('/client/im/session/markSessionRead', params,false)
    },
    /**
     * 获取当前用户的会话列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryAllSessionByUser: (params) => {
        return oucy.postRequest('/client/im/session/queryAllSessionByUser', params,false)
    },
    /**
     * 搜索消息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryMessage: (params) => {
        return oucy.postRequest('/client/im/session/queryMessage', params,false)
    },
    /**
     * 发送消息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    sendMessage: (params) => {
        return oucy.postRequest('/client/im/session/sendMessage', params,false)
    }
}

export default session