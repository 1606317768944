import oucy from '@/util/oucyUtil.js'
// 企业广告图管理
const adEnterprise = {
	/**
     * 新建
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    createAdEnterprise: (params) => {
        return oucy.postRequest('/client/auth/adenterprise/createAdEnterprise', params)
    },

   	/**
     * 删除企业广告图
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    delAdEnterprise: (params) => {
        return oucy.postRequest('/client/auth/adenterprise/delAdEnterprise', params)
    },
   	/**
     * 修改企业广告图
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    updateAdEnterprise: (params) => {
        return oucy.postRequest('/client/auth/adenterprise/updateAdEnterprise', params)
    },
   	/**
     * 查询企业广告图
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryAdEnterprises: (params) => {
        return oucy.postRequest('/client/auth/adenterprise/queryAdEnterprises', params)
    },
}

export default adEnterprise
