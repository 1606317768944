import oucy from '@/util/oucyUtil.js'
// 直播间心跳管理
const liveheat = {
    /**
     * 获取直播间邀请榜
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getLiveRoomOnlineUserList: (params) => {
        return oucy.postRequest('/client/im/liveheat/getLiveRoomOnlineUserList', params)
    },
      /**
     * 直播间心跳
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    saveLiveRoomHeat: (params) => {
        return oucy.postRequest('/client/im/liveheat/saveLiveRoomHeat', params,false)
    },
  
}

export default liveheat