import oucy from '@/util/oucyUtil.js'
// 求购类型表单管理
const certificationFile = {

	/**
     * 根据求购类型编码查询求购类型表单
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getBuyAskCategoryFormByCategoryId: (params) => {
        return oucy.postRequest('/client/buyask/buyaskcategoryform/getBuyAskCategoryFormByCategoryId', params)
    },
}

export default certificationFile
