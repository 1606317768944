<!-- 插入商品-->
<template>
    <div class="text-left">
        <el-dialog width="800px" :title="title" :visible.sync="dialogFormVisible">
            <template>
                <div class="df-jc-s-b">
                    <el-input v-model="QueryFurnitureLibrary.spuName" placeholder="请输入关键字搜索" size="mini" class="m-l-10" style=""></el-input>
                    <el-button class="m-l-10" type="primary" size="mini" @click="search">搜索</el-button>
                </div>
                <el-table :data="prlductData.content" style="width: 100%" height="400" @row-click="rowClick">
                    <el-table-column type="" width="55">
                        <div slot-scope="scope">
                            <img src="@/assets/icon/select.png" class="checkbox " v-if="spuListIds.includes(scope.row.id)">
                            <img src="@/assets/icon/un_select.png" class="checkbox " v-else>
                        </div>
                    </el-table-column>
                    <el-table-column prop="date" label="产品封面" width="100">
                        <template slot-scope="scope">       
                            <el-avatar shape="square" :src="scope.row.cover" v-if="hasImportImg(scope.row.cover)"></el-avatar>
                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.cover" v-else></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuName" label="产品名称/所属品牌">
                        <template slot-scope="scope">
                            <div class="pointer line-1" :title="scope.row.spuName">{{scope.row.spuName}}</div>
                            <div class="c-2" v-if="scope.row.brand && scope.row.brand.brandName">{{scope.row.brand.brandName}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuPrise" label="当前价格">
                        <template slot-scope="scope">
                            <span class="spuPrise" v-if="scope.row.spuPrise=='0.00'">面议</span>
                            <span class="spuPrise" v-else>￥{{scope.row.spuPrise}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-pagination class="p-t-20" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSpu">确 定<span v-if="spuListIds && spuListIds.length">({{spuListIds.length}})</span></el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import { spu, enterpriseinfo } from "@/service"

export default {
    name: "addSpuDialog",
    props: {
        title:{
            type:String,
            default:'添加商品'
        }
    },
    data() {
        return {
            textarea: '',
            imageList: [],
            previewSrcList: [],

            // 添加商品
            dialogFormVisible: false,
            prlductData: {},
            QueryFurnitureLibrary: {
                // category: 0,
                start: 0,
                limit: 10,
                spuName: null,
            },
            spuName: null,
            adImgFlag: 0,
            multipleSelection: [],
            spuList: [],
            spuListIds: [],
        }
    },
    mounted() {},
    methods: {
        // 添加商品的开始
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            me.queryFurnitureLibrary();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            me.queryFurnitureLibrary();
        },
        // 查找
        search() {
            this.QueryFurnitureLibrary.start = 0
            this.queryFurnitureLibrary()
        },

        // 请求数据
        queryFurnitureLibrary() {
            spu.queryFurnitureLibrary(this.QueryFurnitureLibrary).then(res => {
                this.prlductData = res
            });
        },

        // 打开商品
        openDialogFormVisible(list=[]) {
            this.queryFurnitureLibrary()
            this.dialogFormVisible = true
            this.multipleSelection=list //this.deepCopy(this.spuList)
            this.spuListIds=this.getId(list)

        },
        // 添加加回调
        addSpu() {
            // this.spuList=this.deepCopy(this.multipleSelection)
            this.dialogFormVisible = !true
            this.$emit('callBack',this.multipleSelection)
        },
        // 删除
        deleteSpu(v, i) {
            this.spuList.splice(i, 1)
        },
        rowClick(row, column, event) {
            let index = this.spuListIds.indexOf(row.id)
            if (index == -1) {
                this.multipleSelection.push(row)
                this.spuListIds.push(row.id)
            } else {
                this.multipleSelection.splice(index, 1)
                this.spuListIds.splice(index, 1)
            }
        },
        // 添加商品的结束

        hasImportImg(v) {
            return v && v.startsWith('//')
        },

        deepCopy(list){
            return JSON.parse( JSON.stringify(list))
        },
        getId(list){
            let ids=[]
            for(let v of list){
                ids.push(v.id)
            }
            return ids
        },
    },
}
</script>
<style scoped lang="less">
.box {
    // padding: 20px;
    background: #F6F7F8;
    border-radius: 2px;
}

/deep/ .el-textarea__inner {
    // padding: 5px 0;
    background: #F6F7F8;
    border: none;
}

/deep/ .el-textarea__inner {
    // padding: 5px 0;
    background: #fff;
    border: none;
}

.spuList_item {
    position: relative;




    .deleteSpu {
        z-index: 99;
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px;
        cursor: pointer;
        display: none;
    }

    &:hover {
        .deleteSpu {
            display: inline-block;
        }
    }
}

.spuList {
    display: flex;
    flex-wrap: wrap;
}

.spuList_item {
    width: 200px;
    height: 288px;
    margin: 20px;
    position: relative;
}

.enterpriseList {
    display: flex;
}

.enterpriseList_item {}
.checkbox {
    width: 15px;
    height: 15px;
}
</style>