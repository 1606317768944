<!-- 添加新地址 | 修改地址 -->
<template>
    <div class="text-left">
        <el-dialog :title="ruleForm.id?'编辑地址':'添加新地址'" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
            <!-- <span>这是一段信息</span> -->
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="收货人" prop="addressUsername">
                    <el-input v-model="ruleForm.addressUsername" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="addressPhone">
                    <el-input v-model="ruleForm.addressPhone" placeholder="13位手机号码，或座机格式 区号之后用'-'隔开"></el-input>
                </el-form-item>
                <el-form-item label="城市" prop="cityCode" v-if="dialogVisible">
                    <el-cascader v-model="ruleForm.cityCode" style="width: 100%" :props="cityProps" :placeholder="ruleForm.cityPlaceholder"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="addressDetail">
                    <el-input type="textarea" v-model="ruleForm.addressDetail" maxlength="64" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-checkbox v-model="ruleForm.addressDefault">默认地址</el-checkbox>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil"

import { localSet, localGet, localDel } from "@/store/store";
import { ltDeliveryAddress } from "@/service/index"

export default {
    name: 'addAddDialog',
    components: {

    },
    props: {
        ruleForm: {
            type: Object,
            default: function() {
                return {
                    addressUsername: '',
                    addressPhone: '',
                    cityCode: '',
                    addressDetail: '',
                    addressDefault: false,
                }
            },
            required: false
        }
    },
    data() {

       var ValidatePhone=(rule ,val ,callback)=> {
            var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/; //手机号码
            var isMob = /^0?1[3|4|5|8][0-9]\d{8}$/; // 座机格式 区号之后用'-'隔开
            if (isMob.test(val) || isPhone.test(val)) {
               callback();
            } else {
                callback(new Error('请输入正确的联系方式!'));
            }
        }
        return {
            rules: {

                addressUsername: [
                    { required: true, message: '请输入收货人', trigger: 'change' }
                ],
                addressPhone: [
                    { validator: ValidatePhone, trigger: 'blur' },
                    { required: true, message: '请输入联系方式', trigger: 'blur' }
                ],
                cityCode: [
                    { required: true, message: '请输入城市', trigger: 'blur' }
                ],
                addressDetail: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }
                ]
            },
            dialogVisible: !true,

            list: [],
            cityProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    oucy.findChina(level === 0 ? null : node.value).then(res => {
                        resolve(Array.from(res).map(item => ({
                            value: item.chinaId,
                            label: item.chinaName,
                            leaf: !item.hasChildren
                        })));
                    });
                }
            },

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

        

        // 打开 弹框
        openDialogVisible() {
            this.dialogVisible = true
            setTimeout(() => {
                this.$refs['ruleForm'].clearValidate()
            })
        },
        // 关闭 弹框
        closeDialogVisible() {
            this.dialogVisible = false
        },
        resetFields() {
            this.dialogVisible = true
            setTimeout(() => {
                this.$refs['ruleForm'].resetFields()
            })
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
        // 点提交
        submitForm() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.$oucy.keywordFiltr(this.ruleForm).then(res => {
                        if (this.ruleForm.id) {
                            this.updateLtDeliveryAddress()
                        } else {
                            this.insertLtDeliveryAddress()
                        }
                    }, err => {})
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        // 提交 新建
        insertLtDeliveryAddress() {
            this.ruleForm.chinaId = this.$oucy.getArrayLast(this.ruleForm.cityCode)
            ltDeliveryAddress.insertLtDeliveryAddress(this.ruleForm).then(res => {
                // this.getAllLtDeliveryAddress()
                this.dialogVisible = !true
                this.callback('insert')
            })
        },
        // 提交 更新
        updateLtDeliveryAddress() {
            this.ruleForm.chinaId = this.$oucy.getArrayLast(this.ruleForm.cityCode)
            ltDeliveryAddress.updateLtDeliveryAddress(this.ruleForm).then(res => {
                // this.getAllLtDeliveryAddress()
                this.dialogVisible = !true
                this.callback('update')
            })
        },
        // 回调
        callback(type) {
            // close update insert
            this.$emit('callback', { type: type })
        },

        alert(text, title) {
            this.$alert(text, title || '输入内容不正确', {
                confirmButtonText: '确定',
            });
        },

    }
}
</script>
<style scoped></style>