// 进货车相关

const stockCar = [
    // 缺省页
    {
        path: '/acc/stockCar',
        name: 'accStockCar',
        component: () => import("@/views/Account/app/StockCar/Index/Index.vue"),
        meta:{title:'进货车'}

    },
    // 确认订单  
    {
        path: '/acc/confirmOrder',
        name: 'accConfirmOrder',
        component: () => import("@/views/Account/app/StockCar/confirmOrder/confirmOrder.vue"),
        meta:{title:'确认订单'}
    },
    // 支付订单
    {
        path: '/acc/payOrder',
        name: 'accPayOrder',
        component: () => import("@/views/Account/app/StockCar/payOrder/payOrder.vue"),
        meta:{title:'支付订单'}
    },
    // 完成订单
    {
        path: '/acc/finishOrder',
        name: 'accFinishOrder',
        component: () => import("@/views/Account/app/StockCar/finishOrder/finishOrder.vue"),
        meta:{title:'完成订单'}
    },
    // 生成物料清单
    {
        path: '/acc/offer',
        name: 'accOffer',
        component: () => import("@/views/Account/app/StockCar/offer/offer.vue"),
        meta:{title:'生成物料清单'}
    },


];


export default stockCar