import oucy from '@/util/oucyUtil.js'
// 买家发布求购信息标签管理
const buyAskCategory = {
	/**
     * 查询所有求购信息
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllBuyAskCategory: (params={}) => {
        return oucy.postRequest('/client/buyask/buyaskcategory/getAllBuyAskCategory', params)
    },
}

export default buyAskCategory
