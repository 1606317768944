import oucy from '@/util/oucyUtil.js'
// 企业菜单管理
const ltEnterpriseMenu = {
	/**
     * 查询企业菜单树
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getLtEnterpriseMenuTree: (params) => {
        return oucy.postRequest('/client/enterprise/ltenterprisemenu/getLtEnterpriseMenuTree', params)
    },

}
export default ltEnterpriseMenu
