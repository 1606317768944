import oucy from '@/util/oucyUtil.js'
// 买家发布求购信息标签管理
const buyAskTag = {
	/**
     * 查询所有买家求购信息标签 
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllBuyAskTag: (params={}) => {
        return oucy.postRequest('/client/buyask/buyAskTag/getAllBuyAskTag', params)
    }
}

export default buyAskTag

