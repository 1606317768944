import oucy from '@/util/oucyUtil.js'
// 用户地址管理
const ltDeliveryAddress = {
    /**
     * 新增用户地址[两端通用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    insertLtDeliveryAddress: (params) => {
        return oucy.postRequest('/client/user/ltdeliveryaddress/insertLtDeliveryAddress', params)
    },
    /**
     * 查询用户地址[两端通用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getAllLtDeliveryAddress: (params) => {
        return oucy.postRequest('/client/user/ltdeliveryaddress/getAllLtDeliveryAddress', params)
    },
    /**
     *修改用户地址[两端通用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateLtDeliveryAddress: (params) => {
        return oucy.postRequest('/client/user/ltdeliveryaddress/updateLtDeliveryAddress', params)
    },
    /**
     *删除用户地址[两端通用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    deleteLtDeliveryAddress: (params) => {
        return oucy.postRequest('/client/user/ltdeliveryaddress/deleteLtDeliveryAddress', params)
    },
}

export default ltDeliveryAddress