import oucy from '@/util/oucyUtil.js'
// 员工名片评论回复管理
const cardEmployeeReply = {
	/**
     * 发布员工名片评论回复
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    createCardPersonReply: (params) => {
        return oucy.postRequest('/client/card/cardemployeereply/createCardPersonReply', params)
    },
    	

   
}

export default cardEmployeeReply
