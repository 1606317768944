<!-- 上传图片 带剪裁图片功能 -->
<template>
    <div>
        <el-upload accept="image/jpeg,image/gif,image/png" :before-upload="handleOnBeforeUploadAndSquare" :auto-upload="false" :on-change='changeUpload' class="avatar-uploader" action="fakeaction" :show-file-list="false" :http-request="handleAvataImgUpload" :multiple="multiple" :limit="20" :list-type="listType">
            <slot></slot>
        </el-upload>

        <!-- vueCropper 剪裁图片实现-->
        <el-dialog title="图片剪裁" :visible.sync="dialogVisible" width="800" append-to-body>
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                    <vueCropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed" :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue" :fixedBox="option.fixedBox"></vueCropper>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import oss from "@/util/oss.js"
export default {
    name: "uploadImage",
    props: {
        size:{
            type:String,
            default:'medium',//medium mini  
        },
        listType:{
            type:String,
            default:'',//medium mini  
        },
        limit:{
            type:Number,
            default:1
        },
        multiple:{
            type:Boolean,
            default:false
        },
        ratio:{
            type:Array,
            default:function(){
                return [1,1]
            }
        },
    },
    data() {
        return {
            // 裁剪弹框
            dialogVisible: false,
            // 裁剪组件的基础配置option
            option: {
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'png', // 裁剪生成图片的格式
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                // autoCropWidth: 300, // 默认生成截图框宽度
                // autoCropHeight: 200, // 默认生成截图框高度
                fixedBox: !true, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [1, 1], // 截图框的宽高比例
                full: !false, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: !true, // 截图框是否被限制在图片里面
                infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            fileinfo: {},
            // 防止重复提交
            loading: false,
            // 上传文件对列
            uploadFileList:[],
            setTimeoutId:null,
        }
    },
    mounted() {
        console.log('uploadimage mounted',this.ratio)

       this.option.fixedNumber=this.ratio
    },
    methods: {
        // 上传前检查
        handleOnBeforeUploadAndSquare: function(file) {
            const me = this;
            return new Promise((resolve, reject) => {
                me.$oucy.checkImgFile(file,10).then(res => {
                    return me.$oucy.checkImgSquare(res, this.option.fixedNumber[0], this.option.fixedNumber[1]);
                }).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            });

        },
        // 上传
        handleAvataImgUpload: function(params) {
            const me = this;
            const file = params.file;
            me.$oucy.checkImgFile(file,10).then(res => {
                // 定时器是为了解决 多个上传时弹框重叠
                // setTimeout(()=>{
                    let Oss=new oss()
                    return Oss.startUpload(file)
                // })
                // return me.$oucy.uploadRequest(file);
            }).then(res => {
                me.$emit('uploadImageBack', res)
                // me.enterApplyForm.avata = res;
            });
        },
        // 选择图片回调  限制图片大小
        changeUpload(file, fileList) {
            const isLt10M = file.size / 1024 / 1024 < 10
            if (!isLt10M) {
                this.$message.error('上传文件大小不能超过 10MB!')
                return false
            }
            const isLt10kb = file.size / 1024 < 10
            if (isLt10kb) {
                this.$message.error('为保证图片清晰上传图片大小不能小于 10KB!')
                return false
            }

            this.$oucy.checkImgSquare2(file.raw, this.option.fixedNumber[0], this.option.fixedNumber[1]).then(res => {
                this.handleAvataImgUpload({ file: res })
            }, err => {
                console.log(file)
                this.uploadFileList.push(file)
               this.setTimeoutId && clearTimeout(this.setTimeoutId)
               this.setTimeoutId = setTimeout(()=>{
                    this.startTailor()
                    this.setTimeoutId=null
                },500)
            })
        },
        startTailor(){
          let file=this.uploadFileList.shift()  
          this.fileinfo = file
          // 上传成功后将图片地址赋值给裁剪框显示图片
          this.$nextTick(() => {
              this.option.img = URL.createObjectURL(file.raw);
              this.dialogVisible = true
          })
        },

        // 点击裁剪，这一步是可以拿到处理后的地址
        finish() {
            this.$refs.cropper.getCropBlob((data) => {
                this.fileinfo.uid
                this.loading = true
                let files = new window.File([data], this.fileinfo.name, { type: 'image/jpeg' })
                //上传
                this.handleAvataImgUpload({ file: files })
                this.loading = false
                this.dialogVisible = false
                // 还有要处理的图片
                if(this.uploadFileList.length){
                    this.startTailor()
                    // console.log('有')
                }else{
                    // 没有要处理的图片
                    this.uploadFileList=[]
                    // console.log('没有')
                }
            })
        },
    }
}
</script>
<style scoped>
.avatar.medium,.avatar-uploader-icon.medium{
    width: 146px;
    height: 146px;
    line-height: 146px;
}
.avatar.mini,.avatar-uploader-icon.mini{
    width: 46px;
    height: 46px;
    line-height: 46px;
}

.avatar-uploader>>>.el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    margin: 0 8px 8px 0;
}

.avatar-uploader>>>.el-upload:hover {
    border-color: #409EFF;
}

.cropper-content .cropper {
    width: auto;
    height: 300px;

}
</style>