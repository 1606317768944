import oucy from '@/util/oucyUtil.js'
// 企业新闻册册册册册册册册册册册册册册册册册册册册册册册册册管理
const enterpriseNewsAlbum = {
	/**
     * 查询企业的新闻册
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllEnterpriseNewsAlbumsClient: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbum/getAllEnterpriseNewsAlbumsClient', params)
    },
    /**
     * 新增企业新闻册
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    addEnterpriseNewsAlbum: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbum/addEnterpriseNewsAlbum', params)
    },
    /**
     * 删除企业新闻册
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    delEnterpriseNewsAlbum: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbum/delEnterpriseNewsAlbum', params)
    },
    /**
     * 修改企业新闻册
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    updateEnterpriseNewsAlbum: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbum/updateEnterpriseNewsAlbum', params)
    },

}

export default enterpriseNewsAlbum
