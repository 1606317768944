<!-- 企业 图片 标签 -->
<template>
    <div class="df">
        <img class="icon3 m-r-10" src="@/assets/images/qiyeku/vip.png" v-if="enterprise.vipLeve==2">
        <img class="icon3 m-r-10" src="@/assets/images/qiyeku/realName.png" v-if="enterprise.enterpriseAuthenticationStatus==2">
        <span class="protectBox m-r-10 icon3 c-m" v-if="enterprise.enterpriseSecurityDepositFee">
            <span class="protectBox_text">￥{{enterprise.enterpriseSecurityDepositFee}}</span>
            <img class="protectBox_img icon3" src="@/assets/images/qiyeku/protect.png">
        </span>
        <img class="icon3" src="@/assets/images/qiyeku/Keep_your_about_icon.png" v-if="0">
    </div>
</template>
<script>
export default {
    name: "enterpriseImgTag",
    props: {
        enterprise: {
            type: Object,
            default: {},
        },
        
    },

    mounted() {},
    methods: {},
}
</script>
<style scoped lang="less">
.icon3 {
    height: 30px;
}


.protectBox {
    position: relative;
}

.protectBox_text {
    position: absolute;
    top: 6px;
    left: 26px;
    width: 60px;
    font-weight: 500;
    /*background: #f00;*/
    font-size: 14px;
    text-align: center;
    color: #fff;
}

</style>