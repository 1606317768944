<!-- 加入进货车 -->
<template>
    <div class="addStockDialog">
        <el-dialog title="加入进货车" :visible.sync="dialogVisible" width="1000px" :before-close="handleClose">
            <div class="m-t-40" style="background-color:#fff">
                <div class="middle">
                    <div class="middle_top">
                        <el-row>
                            <el-col :span="8" class="">
                            <carousel :carousel="carousel" :clickImgUrl="clickImg" @change="clickImg=null"></carousel>
                            </el-col>
                            <el-col :offset="1" :span="15">
                                <div class="goods_conditon text-left">
                                    <span>{{detail.spuName}}</span>
                                </div>
                                <el-row class="m-t-10">
                                    <el-col :span="3" v-if="detail.spuIsNew">
                                        <div class="label1">
                                            <span style="font-size:12px;color:#888888;width: 66px;line-height: 12px;">新品</span>
                                        </div>
                                    </el-col>
                                    <el-col :span="3" v-if="detail.spuIsSpecial">
                                        <div class="label1">
                                            <span style="font-size:12px;color:#888888;width: 66px;line-height: 12px;">特价</span>
                                        </div>
                                    </el-col>
                                    <el-col :span="3" v-if="0">
                                        <div class="label1">
                                            <span style="font-size:12px;color:#888888;width: 66px;line-height: 12px;">库存</span>
                                        </div>
                                    </el-col>
                                </el-row>
                                <div style="text-align: left;">
                                    <div class="price">
                                        <el-row class="df-jc-s-b df-ai-c">
                                            <el-col :span="2">
                                                <p>价格</p>
                                                <!-- <p>领券</p> -->
                                            </el-col>
                                            <el-col :span="11" class="text-left">
                                                <div v-if="select">
                                                    <span style="width: 131px;height: 21px;font-size: 28px;font-family: Microsoft YaHei;font-weight: bold;color: #ED6732;text-align: left;" v-if="select.price=='0.00'">面议</span>
                                                    <span style="width: 131px;height: 21px;font-size: 28px;font-family: Microsoft YaHei;font-weight: bold;color: #ED6732;text-align: left;" v-else>￥{{select.price}}</span>
                                                </div>
                                                <div v-else>
                                                    <span style="width: 131px;height: 21px;font-size: 28px;font-family: Microsoft YaHei;font-weight: bold;color: #ED6732;text-align: left;" v-if="detail.spuPrise=='0.00'">面议</span>
                                                    <span style="width: 131px;height: 21px;font-size: 28px;font-family: Microsoft YaHei;font-weight: bold;color: #ED6732;text-align: left;" v-else>￥{{detail.spuPrise}}</span>
                                                </div>
                                           <!--      <div class="coupon">
                                                    <img src="@/assets/goods/coupon2.png">
                                                    <img src="@/assets/goods/coupon1.png">
                                                </div> -->
                                            </el-col>
                                            <el-col :span="11">
                                                <div class="df-jc-s-b df-ai-c f12">
                                                    <div class="price_right m-r-20">
                                                        <span v-if="select.quantity!=null">库存&nbsp&nbsp{{select.quantity}}</span>
                                                        <span v-else>库存&nbsp&nbsp{{detail.spuRepertory}}</span>
                                                    </div>
                                                    <div class="price_right m-r-20">
                                                        <span>累计销量&nbsp&nbsp{{detail.spuSoldAmount}}</span>
                                                    </div>
                                                    <div class="price_right pointer m-r-20" @click="enlargeCode">
                                                        <span>手机购买&nbsp&nbsp<img src="@/assets/QRCode/qr_code.png" style="vertical-align:middle;"></span>
                                                       <!--  <img src="@/assets/QRCode/purchase_code.png" v-if="isPhone" style="position: absolute;right: 0;top: 60px;"> -->
                                                       <div ref="myLoginQrCodeUrl"  v-show="isPhone" class="payQRCode" style="position: absolute;right: 0;top: 60px;z-index: 999;"></div>
                                                    </div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <VIPPrice :data="detail"></VIPPrice>
                                    </div>
                                    <el-row class="text-left m-t-10">
                                        <el-col :span="8" v-if="0">
                                            <div class="demo-input-suffix ">
                                                <span class="f12 c-2 m-r-5">配送至</span>
                                                <el-select v-model="value" size="mini" placeholder="请选择" style="width: 150px;">
                                                    <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </el-col>
                                        <el-col :span="8" v-if="0">
                                            <div class="demo-input-suffix ">
                                                <span class="f12 c-2 m-r-5">物流</span>
                                                <el-select v-model="value" size="mini" placeholder="请选择" style="width: 150px;">
                                                    <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </el-col>
                                        <el-col :span="8">
                                            <div style="font-family: Microsoft YaHei;font-weight: 400;color: #AAAAAA;" class="f12 m-t-5">运费 <span class="" style="color: #ED6732;">自提</span></div>
                                        </el-col>
                                    </el-row>
                                    <!-- <div class="goods_size text-left" v-if="detail.sku && detail.sku.length>1"> -->
                                    <!-- <div>选择规格</div> -->
                                    <!-- </div> -->
                                    <div class="hr dashed"></div>
                                    <div class="text-left">
                                        <div v-for="(v,i) of detail.props" v-if="v.attrName" :key="i">
                                            <div class="m-t-20 df-ai-c"><span class="hLine"></span>{{v.attrName}}</div>
                                            <div class="z-skus">
                                                <div v-for="(vv,ii) of v.furnitureAttrOption" class="size1 pointer z-sku" :class="vv.select?'select':''" @click="setSelect(v,i,vv,ii)" :title="vv.optionValue">
                                                    <img :src="$oucy.ossUrl+vv.optionImg" v-if="vv.optionImg" @click="clickImg=$oucy.ossUrl+vv.optionImg">
                                                    <div class="p-l-10 optionValue" v-else>{{vv.optionValue}}</div>
                                                    <img src="@/assets/images/stockCar/corner.png" class="corner">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hr dashed"></div>
                                    <!-- <el-divider content-position="left"></el-divider> -->
                                    <div class="text-left">
                                        <span style="font-family: Microsoft YaHei;font-weight: 400;color: #AAAAAA;" class="f12">
                                            数量</span>
                                        <el-input-number v-model="num" :min="1" size="" class="m-l-10" round></el-input-number>
                                        <el-button @click="addStock" size="" type="danger" round class="m-l-10">加入进货车</el-button>
                                        <el-button type="primary" size="" @click="gotoBuy()" round>立即购买</el-button>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <!-- 添加新方案 | 修改方案 -->
            <el-dialog title="添加新方案" :visible.sync="createSchemaDialogVisible" width="500px" :before-close="handleClose" :append-to-body="true">
                <!-- <span>这是一段信息</span> -->
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="方案名称" prop="schemeName">
                        <el-input v-model="ruleForm.schemeName" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="方案内容" prop="schemeInformation">
                        <el-input type="textarea" v-model="ruleForm.schemeInformation" maxlength="120" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="createSchemaDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitForm">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 加入进货车 -->
            <el-dialog title="加入进货车" :visible.sync="AddStockDialogVisible" width="400px" :before-close="handleClose" :append-to-body="true">
                <!-- <span>这是一段信息</span> -->
                <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="选择方案">
                        <el-select v-model="AddStockValue" placeholder="请选择">
                            <el-option v-for="item in allScheme" :key="item.schemeId" :label="item.schemeName" :value="item.schemeId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="schemeName">
                        
                    <el-button @click="AddStockDialogVisible = false,createSchemaDialogVisible=true">新建方案</el-button>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="AddStockDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="appendSku">确 定</el-button>
                </span>
            </el-dialog>
        </el-dialog>
    </div>
</template>
<script>
import { spu, furnitureClassify, stock } from "@/service"
import { localSet, localGet, localDel } from "@/store/store";
import QRCode from 'qrcodejs2'
export default {
    name: "addStockDialog",
    data() {
        return {
            checkAll: '',
            num: 1,
            num2: 1,
            activeName: 'first',
            labelPosition: 'left',
            isChoose: false,
            isPhone: false,
            isActive: '0',
            text: '',
            textarea: '',
            drawer: false,
            tabPosition: 'left',
            list: [1, 2, 3],
            size: '660px',
            goodsList: [],
            srcList: [],
            detailsList: {
                brand: '馨吉祥',
                id: 100014655188,
                weight: '64.0KG',
                place: '四川',
                quality: '棉类',
                clarify: '布艺沙发',
                person: '三人以上',
                area: '中户型(60-90㎡)',
                fillout: '海绵',
                isWash: '可拆洗',
                shape: 'L型',
                isStorage: '可储物',
                color: '多色',
                isGuifei: '带贵妃榻',
                style: '北欧',
                isCustomized: '不可定制',
                framequality: '实木',
                scene: '客厅',
            },
            // 头部
            searchClassifyVal: '',
            searchClassify: '1',
            topoptions: [{
                value: '1',
                label: '乐搜家导航'
            }],
            options2: [{
                value: '1',
                label: '广州市/天河区'
            }],
            options3: [{
                value: '1',
                label: '南康'
            }],
            value: '1',
            levelList: [],
            spuId: null,
            // 详情
            detail: {},
            // 选中列表
            select: {},
            // 店内分类列表
            byLtUserAuthAndClassifyCategoryList: [],
            carousel: [],

            // 进货车
            rules: {
                schemeName: [
                    { required: true, message: '请输入方案名称', trigger: 'change' }
                ],
                schemeInformation: [
                    { required: true, message: '请输入方案内容', trigger: 'blur' }
                ]
            },
            createSchemaDialogVisible: false,
            AddStockDialogVisible: false,
            AddStockValue: null,
            allScheme: [],
            ruleForm: {
                schemeName: '',
                schemeDefault: true,
                schemeInformation: '',
            },
            clickImg: null,
            spuName: null,
            dialogVisible: !true,
        }

    },
    mounted() {
    },
    methods: {
        creatQrCode(text) {
            const me = this;
            if (me.qrCode == null) {
                me.qrCode = new QRCode(this.$refs.myLoginQrCodeUrl, {
                    text: text,
                    // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
                    width: 146,
                    height: 146,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })

                console.log('creatQrCode')
            }
        },
        open(id) {
            this.loginUser = localGet('loginUser')
            if(this.loginUser && this.loginUser.id){
                this.spuId = id
                this.querySpu()
                this.dialogVisible = true
                setTimeout(()=>{

                this.creatQrCode('https://h5.lesoujia.com/#/module/furniture/productDetail/productDetail?id='+this.spuId)
                },2000)
                
            }else{
                this.$oucy.confirm('请先登录').then(res=>{
                    this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.LOCAL_LOGIN, null);
                },err=>{

                })
                
            }
        },
        handleClose(c) {
            c()
        },
        handleCheckAllChange() {},
        lisChange(index) {
            console.log(index)
            this.$refs.remarkCarusel.setActiveItem(index);
            this.isActive = index;
        },
        imgScc: function() {
            this.isChoose = !this.isChoose
        },
        enlargeCode: function() {
            this.isPhone = !this.isPhone
            // alert('dsgsdgdf')
        },
        // 查询商品详情
        querySpu() {
            spu.querySpu({ spuId: this.spuId }).then(res => {
                if (res.props && res.props.length) {
                    for (let v of res.props) {
                        for (var i = 0; i < v.furnitureAttrOption.length; i++) {
                            let vv = v.furnitureAttrOption[i]
                            if (i == 0) {
                                vv.select = true
                            } else {
                                vv.select = false
                            }
                        }

                    }
                    setTimeout(() => {
                        this.getSelect()
                    }, 500)
                } else {
                    if (res.sku && res.sku.length) {
                        this.select = res.sku[0]
                    }
                }
                // this.appendSku(res.sku[0].skuId)                        
                this.detail = res
                let carousel = []
                if (this.detail.spuVideo) {
                    carousel.push({ type: 'video', url: this.detail.spuVideo })
                }
                // 点击查看大图列表
                // let carousel=[]
                let srcList = []
                for (let v of res.spuImgs) {
                    carousel.push({ type: 'image', url: v.imgUrl })
                    srcList.push(this.$oucy.ossUrl + v.imgUrl)
                }
                this.carousel = carousel
                this.srcList = srcList
                // 查询店内分类
                // this.findByLtUserAuthAndClassifyCategory()
                // this.getBreadcrumb()
            })
        },
        // 选择分类
        setSelect(v, i, vv, ii) {
            if (vv.select) return
            for (let vvv of v.furnitureAttrOption) {
                vvv.select = false
            }
            vv.select = true
            this.getSelect()
        },
        getSelect() {
            let attr = []
            for (let v of this.detail.props) {
                for (let vv of v.furnitureAttrOption) {
                    if (vv.select == true) {
                        attr.push({
                            key: v.id,
                            value: vv.id
                        })
                    }
                }
            }
            for (let vvv of this.detail.sku) {
                // 对比
                if (this.diff(attr, vvv)) {
                    this.select = vvv
                    // console.log(vvv)
                }
            }
        },
        /**
         * 查询店内分类
         * @return {[type]} [description]
         */
        findByLtUserAuthAndClassifyCategory() {
            furnitureClassify.findByLtUserAuthAndClassifyCategory({ enterpriseId: this.detail.enterpriseId }).then(res => {
                this.byLtUserAuthAndClassifyCategoryList = res
            })
        },
        diff(ar, vvv) {

            let propPath = vvv.propPath.split(";");
            let attr = []
            for (let vv of propPath) {
                let ar = vv.split(':')
                attr.push({
                    key: ar[0],
                    value: ar[1]
                })
            }

            let len = 0
            for (let obj1 of ar) {
                for (let obj2 of attr) {
                    if (this.$oucy.isObjectValueEqual(obj1, obj2)) {
                        ++len
                    }
                }
            }

            return ar.length == len
        },
        // 进货车相关
        addStock() {
            this.getAllScheme()
        },
        // 添加 方案
        createSchema() {
            stock.createSchema(this.ruleForm).then(res => {
                if (res && res.schemeId) {
                    this.getAllScheme()
                    this.createSchemaDialogVisible = false
                    this.ruleForm= {
                        schemeName: '',
                        schemeDefault: true,
                        schemeInformation: '',
                    }
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        // 查询 方案
        getAllScheme() {
            stock.getAllScheme({}).then(res => {
                this.allScheme = res || []
                if (this.allScheme && this.allScheme.length) {
                    this.AddStockDialogVisible = true
                    this.AddStockValue = this.allScheme[0].schemeId
                } else {
                    this.createSchemaDialogVisible = true
                }
            },err=>{
                if(err.statusCode==401){
                    this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.LOCAL_LOGIN, null);
                }
                console.log(err)
            })
        },
        openDialogVisible(v) {
            this.dialogVisible = true
        },
        closeDialogVisible() {
            this.dialogVisible = false
        },
        submitForm() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.$oucy.keywordFiltr(this.ruleForm.schemeName+','+this.ruleForm.schemeInformation).then(res=>{
                            this.createSchemaDialogVisible=false
                            this.createSchema()
                    },err=>{})
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        appendSku() {
            stock.appendSku({ schemeId: this.AddStockValue, skuId: this.select.skuId }).then(res => {
                this.AddStockDialogVisible = false
                this.createSchemaDialogVisible = false
                this.dialogVisible = false
                this.$message('添加成功')
                this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.UPDATA_STOCK_CAR_NUM, null);
            })
        },
        gotoBuy(){
            if(this.select && this.select.price>0){
                this.dialogVisible = false
                this.$oucy.go('/acc/confirmOrder?skuId='+this.select.skuId+'&skuNum='+this.num)
            }else
            {
                this.$alert('您所选择的商品为"面议"商品，无法生成订单','提示')
            }
        }
    }
}
</script>
<style>
.addStockDialog .middle_top .left .el-carousel__container {
    height: 280px;
}
</style>
<style scoped lang="less">
.middle {
    background-color: #FFFFFF;
}

.middle_top {
    /*width: 76%;*/
    /*float: left;*/
    /*padding: 20px;*/
}

.left.el-carousel {
    width: 280px;
    height: 280px;

    img {
        width: 100%;
        height: 100%;
    }
}

.middle_top ul img {
    width: 64px;
}

.middle_top_left {
    /*margin-top: -400px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 25px;

}

/*设置左边的堆叠顺序，前提是要有定位，例如这里的position：relative*/
.middle_top_left img {
    z-index: 1000;
    position: relative;
}

.middle_top_left ul {
    width: 200px;
    height: 64px;
    display: flex;
    overflow: hidden;
}

.middle_top_left li {
    /*display: inline-block;*/
    /*float: left;*/
    list-style-type: none;
    padding-left: 2px;
}

.middle_top_left li :after {
    border: 3px #2090ff solid;
}

.goods_conditon span {
    font-size: 18px;
    font-weight: bold;
}

.label1 {
    width: 66px;
    height: 24px;
    background: #F8F8F8;
    border-radius: 2px;
}

.price {
    /*width: 510px;*/
    /*height: 105px;*/
    background: #F8F8F8;
    margin-top: 10px;
}

.price p {
    width: 28px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-left: 15px;
}

.coupon {
    padding: 10px;
    float: left;
}

.coupon img {
    margin-right: 10px;
}

.price_right span {
    width: 100px;
    height: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
    /*float: right;*/
    margin-top: 20px;
}

.p2 {
    width: 60px;
    height: 11px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.goods_size span {
    width: 64px;
    height: 16px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-top: 20px;
    float: left;
}

.size1 {
    /*width: 235px;*/
    /*height: 49px;*/
    line-height: 49px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid #EAEAEA;
    margin-top: 10px;
    position: relative;
}

.size1 img {
    float: left;
    width: 48px;
    height: 48px;
}

.size1 div {

    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 50px;
    margin: 0 auto;
}

.size2 img {
    float: left;
    margin-top: -66px;
}

.middle_right {
    /*float: right;*/
    /*padding: 20px;*/
}


.middle_right span {
    color: rgb(136, 136, 136);
}


.buy_button {
    margin-left: -10px;
    margin-top: 15px;
}

.buy_button .el-button {
    width: 150px;
    position: relative;
    z-index: 1000;
}

.middle_bottom_left {
    margin-left: 20px;
    width: 210px;
    float: left;

}

.middle_bottom_right img {
    margin-left: 50px;

}

.com_card {
    background-image: url("../assets/goods/background.png");
    width: 210px;
    height: 150px;
    /*margin-top: 65px;*/
}

.com_card p {
    line-height: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    text-align: center;
    margin: 0 auto;
}

.com_card img {
    padding: 10px;
    margin-top: 10px;
}

.store_kind {
    border-spacing: 0;
    width: 210px;
    /*height: 280px;*/
    margin-top: 20px;
}

.store_kind th,
td {
    height: 40px;
    border: 1px solid #EAEAEA;
    text-align: center;
    line-height: 40px;
}

.store_kind th {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background: #F8F8F8;
    color: #666666;
    text-align: center;
    line-height: 40px;
}

.store_kind .el-button {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    position: relative;
    z-index: 1000;
}

.customer_service {
    border-spacing: 0;
    width: 210px;
    height: 280px;
    margin-top: 20px;
}

.customer_service th {
    font-family: Microsoft YaHei;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    background: #F8F8F8;
    color: #666666;
    border: 1px solid #EAEAEA;
    text-align: center;
    line-height: 40px;
}

.customer_service td {
    height: 60px;
    border: 1px solid #EAEAEA;
    margin-top: 30px;
    text-align: center;
    line-height: 60px;
}

.customer_service img {
    float: left;
}

.customer_service .el-button {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    position: relative;
    z-index: 1000;
}

.sales_rank {
    border-spacing: 0;
    width: 210px;
    margin-top: 20px;
    /*height: 1302px;*/
    background: #FFFFFF;
    border: 1px solid #EFDFCA;
    border-collapse: collapse;
    padding-bottom: 20px;
}

.sales_rank th {
    width: 210px;
    height: 48px;
    background: linear-gradient(0deg, #FFFDF8, #FFF6EA);
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.sales_rank td {
    border: none;
}

.goods_name {
    width: 188px;
    height: 40px;
    background: #FAFAFA;
    margin: 0 auto;
    position: relative;
    top: -8px;
}

.goods_name span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 40px;
}

.goods_name2 {
    /*width: 188px;*/
    /*height: 40px;*/
    background: #FAFAFA;
    /*margin: -5px auto;*/
}

.goods_name2 span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 40px;
}

.top {
    width: 820px;
    height: 32px;
    background: #F8F8F8;
    margin-left: 80px;
    margin-top: 20px;
}

.top p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    line-height: 11px;
    text-align: center;
    float: left;
    margin-left: 22px;
}

.goods_condition {
    width: 650px;
    float: left;
    margin-left: 20px;
}

.goods_condition td {
    width: 140px;
    text-align: left;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    border: none;
}

.goods_comment /deep/ .el-tabs {
    width: 930px;
    margin-left: 20px;
}

.goods_comment /deep/ .el-tabs__item {
    width: 140px;
    height: 39px;
    background: #F3F3F3;
    border: none;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}

.goods_comment /deep/ .el-tabs__item.is-active {
    background: #2090FF;
    color: white;
}

.goods_comment /deep/ .el-tabs__nav-wrap {
    height: 41px;
    background: #2090FF;
}

.goods_comment /deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none;
}

.goods_comment /deep/ .el-tabs__nav-scroll {
    background-color: #F8F8F8;
}

.goods_comment /deep/ .el-form-item__label {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    width: 10px;
}

// /deep/ .el-divider {
//     width: 820px;
//     margin-left: 80px;
// }

.vhtml /deep/ img {
    max-width: 100%;
}

.right_button {
    float: right;
    margin-right: 30px;
}

.right_button .el-button {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}

.right_button .el-button:focus {
    font-weight: bold;
    color: #2894FF !important;
}

.comment {
    float: right;
    width: 820px;
    margin-right: 30px;
}

.comment_1 {
    background: #F8F8F8;
    border-radius: 4px;
}

.comment_2 {
    background: #F8F8F8;
    border-radius: 4px;
    margin-top: 10px;
}

.comment_3 {
    background: #F8F8F8;
    border-radius: 4px;
    margin-top: 10px;
}

.comment_4 {
    background: #F8F8F8;
    border-radius: 4px;
    margin-top: 10px;
}

.comment_right {
    width: 760px;
    height: 112px;
    padding: 10px;
    text-align: left
}

.comment_right_p1 {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}

.comment_right_p2 {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #AAAAAA;
    text-align: left;
    position: relative;
}

.comment_right span {
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #AAAAAA;
    line-height: 52px;
}

.comment_img {
    width: 100%;
    margin-left: -10px;
}

.comment_img img {
    margin-left: 10px;
    float: left;
}

.comment_img img .active {
    border: 4px #2090FF solid;
}

.comment_reply {
    background-image: url("../assets/background/reply_bk.png");
    background-repeat: no-repeat;
    padding: 30px;
    width: 665px;
}

.comment_reply span {
    float: left;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #5B6069;
    line-height: 20px;
    margin-left: -8px;
}

.comment_reply p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 4px;
}

.comment_reply img {
    margin-top: 0px;
    margin-left: 0px;
}

.username {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
}

.footer_footer {
    margin-top: 150px;
}

.footer_footer table {
    margin: 0px auto;
}

.footer_footer table td {
    border: none;
    text-align: left;
}

.footer_footer span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    padding: 10px;
}


.drawer_left_top {
    width: 220px;
    height: 64px;
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart_left {
    width: 220px;
    height: 935px;
    margin-top: -20px;
    background-color: #F4F4F4;
}

.product_name ul li:hover {
    background: #FFFFFF;
}

.product_name ul li {
    width: 180px;
    height: 96px;
    list-style: none;
    cursor: pointer;
    margin-left: -40px;
    padding: 20px 20px;
}

.product_name span {
    line-height: 30px;
}

.product_condition {
    text-align: left;
    margin-top: -15px;
}

.product_condition p {
    width: 207px;
    height: 29px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.product_condition .span1 {
    width: 132px;
    height: 12px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
}

.product_condition .span2 {
    width: 63px;
    height: 12px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ED6732;
}

/*/deep/ .el-input-number__inner {
    width: 20px;
}*/

.fangan_name {
    height: 18px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    float: left;
    padding: 20px;
}

/*头部*/
.topNav {
    background: #fff;
    padding: 10px 0;
}

.df-jc-s-b {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 58px;
    width: 163px;
}

.input-container {
    // width: 500px;
    /*margin: 0 auto;*/
}

.topCenter {
    background: url('../assets/images/product/bG.png');
    background-size: 100%;
}

.topCenterBody {
    height: 216px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: left;

}

.merchantLogo {
    width: 128px;
    height: 128px;
}

.merchantText {
    flex: 1;
}

.topBottom {
    width: 100%;
    background: #fff;
}

.breadcrumbBarBox {
    padding: 3px;

    background: #F5F6F7;
}

.breadcrumbBar,
.breadcrumb-item,
    {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #666666;
}

.z-skus {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.z-sku {
    /*width: 30%;*/
    /*padding-right: 10px;*/
    box-sizing: border-box;
    margin-right: 10px;
}

.z-sku .optionValue {
    padding-right: 10px;
}

.lisColor {
    box-sizing: border-box;
    border: 3px solid #2090FF;
}

.notlisColor {
    box-sizing: border-box;
    border: 3px solid #fff;

}

/*头部*/

.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.product.topBottom .el-menu.el-menu--horizontal {
    border: none;
    font-size: 16px;
    color: #333;
}

.product .el-menu-item,
.product /deep/ .el-submenu__title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

.product .el-menu--horizontal>.el-menu-item {}

.product .el-menu--horizontal>.el-submenu .el-submenu__title {

    /*font-size: 16px;*/
    /*color: #333;*/
}

.size1 img.corner {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
}

.size1.select {
    border-color: #2090FF;
}

.size1.select img.corner {
    display: inline-block;
}

.rightCarouseBox {
    width: 100%;
}

.rightCarouselImg {
    width: 100%;
    /*width: 118px;*/
    /*height: 118px;*/
}

.guessWhatYouLike {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #888888;
    display: inline-block;
    margin-bottom: 10px;
}

.hLine {
    display: inline-block;
    width: 5px;
    height: 14px;
    background: #2090FF;
    border-radius: 1px;
    margin-right: 5px;
}

.hr.dashed {
    margin: 20px 0;
    border-top: 1px dashed #EAEAEA;
}

.clickImgBox {
    position: relative;
}

.clickImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 280px;
    height: 280px;
    z-index: 98;
}
</style>