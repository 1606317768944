// 企业名片管理

const enterCard = [
    // 保存
    {
        path: '/enter/CardSave',
        name: 'EnterCardSave',
        component: () => import("@/views/Enterprise/app/card/save"),
        meta:{title:'企业名片保存'}

    },
    // 详情
    {
        path: '/enter/CardDetail',
        name: 'EnterCardDetail',
        component: () => import("@/views/Enterprise/app/card/detail"),
        meta:{title:'企业名片详情'}
    },
    // 添加员工名片
    {
        path: '/enter/CardStaffSave',
        name: 'EnterCardStaffSave',
        component: () => import("@/views/Enterprise/app/card/staffSave"),
        meta:{title:'企业名片 添加员工'}
    },
    // 员工名片详情
    {
        path: '/enter/CardStaffDetail',
        name: 'EnterCardStaffDetail',
        component: () => import("@/views/Enterprise/app/card/staffDetail"),
        meta:{title:'企业名片 员工详情'}
    },
];

export default enterCard
