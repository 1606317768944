// 企业名片管理

const enterStaff = [

    // 详情
    {
        path: '/enter/StaffDetail',
        name: 'EnterStaffDetail',
        component: () => import("@/views/Enterprise/app/staff/detail"),
        meta:{title:'企业名片详情'}
    },
    // 保存
    {
        path: '/enter/StaffSave',
        name: 'EnterStaffSave',
        component: () => import("@/views/Enterprise/app/staff/save"),
        meta:{title:'企业名片 添加员工'}
    },

];

export default enterStaff
