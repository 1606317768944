<!-- 轮播图 小图切换 -->
<template>
    <div>
        <!-- 上面大图 -->
        <div class="clickImgBox">
            <el-carousel class="" indicator-position="none" height="424px" arrow="never" :autoplay="false" trigger="click" ref="remarkCarusel" @change="lisChange">
                <el-carousel-item v-for="(item, index) in carousel" :key="index" class="bigBox">
                    <img :src="$oucy.ossUrl+item.url" :preview-src-list="srcList" fit="cover" v-if="item.type=='image'" class="img"></img>                    
                    <el-image :src="$oucy.ossUrl+item.url" :preview-src-list="srcList" fit="cover" v-if="item.type=='image'" class="img0"></el-image>
                    <video :src="$oucy.ossUrl+item.url" controls v-if="item.type=='video'"></video>
                </el-carousel-item>
            </el-carousel>
            <img :src="clickImgUrl" alt="" class="clickImg" v-if="clickImgUrl">
        </div>
        
        <!-- 下面小图 -->
        <div class="miniBox m-t-10">

            <!-- 往左 -->
            <div @click="next('left')" class="pointer c-2 f30"><i class="el-icon-arrow-left"></i></div>

            <!-- 小图 -->
            <div class="miniBox_imgVideo">
                <div v-for="(item, index) in carousel" :key="index" @click="lisChange(index)" :class="[isActive == index?'lisColor':'notlisColor' ,'miniBox_imgVideo_'+index]" class="imgVideoBOx pointer" v-if="getShow(item, index)">
                    <img :src="$oucy.ossUrl+item.url" alt="" v-if="item.type=='image'" />
                    <video :src="$oucy.ossUrl+item.url" v-if="item.type=='video'"></video>
                </div>
            </div>

            <!-- 往右 -->
            <div @click="next('right')" class="pointer c-2 f30"><i class="el-icon-arrow-right"></i></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "carousel",
    props: {
        // 图片列表
        carousel: {
            type: Array,
            default: [],
        },
        // 盖在上面的图片
        clickImgUrl: {
            type: String,
            default: null,
        }

    },
    data() {
        return {
            // 当前显示的索引
            isActive: '0',
        }
    },
    computed: {
        // 设置 点击查看大图的列表
        srcList: function() {
            let srcList = []
            for (let v of this.carousel) {
                srcList.push(this.$oucy.ossUrl + v.url)
            }
            return srcList
        },
    },
    mounted() {},
    methods: {
        // 切换
        lisChange(index) {
            this.$refs.remarkCarusel.setActiveItem(index);
            this.isActive = index;
            this.$emit('change', index)
        },
        // 点 下一张
        next(v) {
            if (this.carousel && this.carousel.length == 1) {
                return
            }
            let index
            if (v == 'left') {
                if (this.isActive == 0) {
                    index = this.carousel.length - 1
                } else {
                    index = this.isActive - 1
                }
            } else if (v == 'right') {
                if (this.isActive >= this.carousel.length) {
                    index = 0
                } else {
                    index = this.isActive + 1
                }
            }else{
                console.error('参数错误:',v)
                return
            }
            this.lisChange(index)
            // 如果可以滚动，就滚动到中间
            let element = `.miniBox_imgVideo_${index}`
            let imgBox = document.querySelector(element)
            // console.log(element,imgBox)
            if (imgBox) {
                imgBox.scrollIntoViewIfNeeded()
            }
        },
        getShow(v,i){
            if(i==this.isActive){
                return true
            }
            if(i==this.isActive+1){
                return true
            }
            if(i==this.isActive-1){
                return true
            }
            if(0==this.isActive){
                if(i<3)return true
            }
            if(this.carousel.length-1==this.isActive){
                if(i<this.carousel.length-4)return true
            }
        }
    },
}
</script>
<style scoped lang="less">
.clickImgBox {
    position: relative;
}

.clickImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
}

.bigBox {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #f1f1f1;
    .img {
        max-width: 100%;
        max-height: 100%;
    }
    /deep/ img {
            max-width: 100%;
            max-height: 100%;
        }
    video {
        max-width: 100%;
        max-height: 100%;
        background: #f1f1f1;
    }

}

// 下面的
.miniBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.miniBox_imgVideo {
    display: flex;
    width: 280px;
    overflow: auto;
    justify-content: center;
    font-size: 0;
    align-items: center;
    // flex-wrap:nowrap ;//| wrap | wrap-reverse;
    // white-space:nowrap;
    // flex-wrap:nowrap;
}

// 小图
.imgVideoBOx {
    margin: 0;
    padding: 0;
    background: #f1f1f1;
    // width: 60px;
    // height: 60px;
    font-size: 0;

    img {
        width: 60px;
        height: 60px;
    }

    video {
        width: 60px;
        height: 60px;
        background: #f1f1f1;
    }
}

// 选中
.lisColor {
    box-sizing: border-box;
    border: 3px solid #2090FF;
}

// 没选中
.notlisColor {
    box-sizing: border-box;
    border: 3px solid #fff;

}
.img0{
    position: absolute;
    opacity: 0;
}
</style>