<!-- 上传组件 -->
<template>
    <div style="">
        <el-upload :accept="accept" ref="upload" :auto-upload="true" class="avatar-uploader" action="fakeaction" :show-file-list="false" :http-request="handleAvataImgUpload" :multiple="multiple" :limit="limit" :list-type="listType">
            <slot></slot>
        </el-upload>
    </div>
</template>
<script>
import oss from "@/util/oss.js"
export default {
    name: "upload",
    props: {
        accept: {
            type: String,
            default: 'video/mp4,image/jpeg,image/png,image/gif',
        },
        size: {
            type: String,
            default: 'medium', //medium mini  
        },
        listType: {
            type: String,
            default: '', //medium mini  
        },
        limit: {
            type: Number,
            default: 10
        },
        multiple: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            // 防止重复提交
            loading: false,
            // 上传文件对列
            uploadFileList: [],
            setTimeoutId: null,
        }
    },

    methods: {

        // 上传
        handleAvataImgUpload: function(params) {
            // this.$message('上传中...')
            const me = this;
            const file = params.file;
            const isLt1M = file.size / 1024 / 1024/ 1024 < 1
            if (!isLt1M) {
                this.$message.error('上传文件大小不能超过 1GB!')
                return false
            }
            // 定时器是为了解决 多个上传时弹框重叠
            setTimeout(()=>{
                let Oss=new oss()
                Oss.startUpload(file).then(res=>{
                    console.log(res)
                    me.$emit('uploadBack', res)
                    this.limit==1 && this.refs.upload.clearFiles()
                }).catch(err=>{
                    console.log(err)
                })
            })
            return
/*            me.$oucy.uploadRequest(file).then(res => {
                me.$message('上传成功')
                me.$emit('uploadBack', res)
                this.limit==1 && this.refs.upload.clearFiles()
            });*/
        },

    }
}
</script>
<style scoped>
</style>