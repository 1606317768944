<template>
    <div style="text-align: left;">

        <el-dialog width="800px" title="搜索用户" 
            :close-on-click-modal="false" 
            :visible.sync="dialogFormVisible">
            <template>
                <div class="df-jc-s-b">
                    <el-input v-model="KeyName" placeholder="请输入关键字搜索" size="mini" class="m-l-10" style="flex: 1;"></el-input>
                    <el-button type="default" size="mini" class="m-l-10" @click="search">搜索</el-button>
                </div>
                <el-table :data="prlductData.content" style="width: 100%" height="400px">
                    <!-- <el-table-column type="selection" width="55">                         </el-table-column> -->
                    <!-- <el-table-column type="index" label="编号"></el-table-column> -->
                    <el-table-column prop="date" label="用户信息">
                        <div slot-scope="scope" class="df-ai-c">

                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.ltUserDetailFull.detailAvatar" v-if="scope.row.ltUserDetailFull && scope.row.ltUserDetailFull.detailAvatar"></el-avatar>
                            <span class="m-l-10">
                            {{scope.row.ltUserDetailFull && scope.row.ltUserDetailFull.detailWechatNickname}}
                            </span>
                        </div>
                    </el-table-column>
                    <el-table-column prop="KeyName" label="用户联系方式">
                        <template slot-scope="scope">
                            <div class="c-2">{{scope.row.userPhoneNumber}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="是否实名" width="100">                        
                        <template slot-scope="scope">
                            <div v-if="scope.row.ltRealNameAuthenticationDto && scope.row.ltRealNameAuthenticationDto.authenticationIdCard">是</div>
                            <div v-else>否</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button :type="seteltIds.includes(scope.row.id)?'primary':'default'" size="mini" @click="checkboxSetelt(scope.row)" v-if="type=='checkbox'">
                            <span v-if="seteltIds.includes(scope.row.id)">已选择</span>
                            <span v-else>选择</span>
                        </el-button>
                            <el-button type="default" size="mini" @click="setelt(scope.row)" v-else>选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-pagination v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>

            <span slot="footer" class="dialog-footer" v-if="type=='checkbox'">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="setelt2">确 定 <span v-if="seteltIds && seteltIds.length">({{seteltIds.length}})</span></el-button>
            </span>

        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { adEnterprise, spu,userAuth } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "selectUserDialog",
    data() {
        return {
            addObj: {},
            dialogFormVisible: false,
            limit: 5,
            start: 0,
            prlductData: {},
            KeyName: null,
            adImgFlag: 0,
            seteltlist:[],
            seteltIds:[],
        }
    },
    props: {
        type:{
            type:String,
            default:'radio'//checkbox 
        },
        maxlength:{
            type:Number,
            default:20
        },
        isSelectedClose : {
            type: Boolean,
            default: true,
        }
    },
    mounted() {


    },
    methods: {
        
        
        // 产品列表
        // 
        search() {
            // this.limit=10
            this.start = 0
            if(!this.KeyName){
                this.$message('请输入关键字')
                return
            }
            // if(this.KeyName.length<3){
            //     this.$message('最少输入三个字符')
            //     return
            // }
            this.getAllUser()
        },
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            me.getAllUser();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            me.getAllUser();
        },
        getAllUser() {
            userAuth.getAllUser({
                search: this.KeyName,
                limit: this.limit,
                start: this.start,
                isShelves: true, //  是否上下架 false 下架 ，true 上架
                spuAuditStatus: 2, //审核状态 1待审核 2审核通过 3审核驳回
                enterpriseId: this.enterpriseId
            }).then(res => {
                console.log(res)
                this.prlductData = res
            });
        },

        openDialogFormVisible(list) {
            // this.addObj=v
            this.seteltlist=[]
            this.seteltIds=[] 
            if(list && list.length){
                this.setSelectList(list)
            }else{
               this.seteltlist=[]
               this.seteltIds=[]    
            }
            // this.getAllUser()
            this.dialogFormVisible = true
        },
        setSelectList(list){
            for(let v of list){
             this.seteltlist.push(v)
             this.seteltIds.push(v.id||v.userId)   
            }
        },

        setelt(v) {
            this.dialogFormVisible = !true
            this.callBack(v)
        },

        setelt2(v) {
            // 为ture 才关闭
            if(this.isSelectedClose) {
                this.dialogFormVisible = !true
            }
            this.callBack(this.seteltlist)
        },
        checkboxSetelt(v){
            let index=this.seteltIds.indexOf(v.id)
            if(index>-1){
                this.seteltlist.splice(index,1)
                this.seteltIds.splice(index,1)
            }else{
                if(this.seteltIds.length>=this.maxlength){
                    this.$message('最多只能选'+this.maxlength+'个')
                    return
                }
                this.seteltlist.push(v)
                this.seteltIds.push(v.id)
            }

        },

        closeDialogFormVisible() {
            this.dialogFormVisible = false;
        },


        callBack(v){
            this.$emit('callBack',v)
        },
       

    }
}
</script>
<style scoped lang="less">
.avatar-uploader-icon {
    border: 1px dashed #c0ccda;
    width: 54px;
    height: 54px;
    line-height: 54px;
}

.avatar.mini {
    width: 54px;
    height: 54px;
}

.info {
    padding-left: 20px;
    line-height: 1.5;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
}

.index {
    background: #F2F4F6;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    padding: 18px;
    margin-bottom: 30px;
}

.spu {
    background: #F6F7F8;
    border-radius: 1px;
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
}

.spu .left {
    display: flex;
    align-items: center;

    span {
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #666666;
        padding-left: 24px;
    }

    img {
        width: 60px;
        height: 60px;
    }
}

.spu .right {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #333333;
}
</style>