// 这个一个全局组件的入口

import Vue from 'vue'

// 无限层级分类
import menu from "@/components/myMenu.vue"
Vue.component(menu.name,menu)

// 上传图片
import uploadImage from "@/components/uploadImage.vue"
Vue.component(uploadImage.name,uploadImage)

// 上传视频
import uploadVideo from "@/components/uploadVideo.vue"
Vue.component(uploadVideo.name,uploadVideo)

// 进货车
import stock from "@/components/stock.vue"
Vue.component(stock.name,stock)

// 搜索
import searchProduct from "@/components/searchProduct.vue"
Vue.component(searchProduct.name,searchProduct)

// 搜索
import searchProduct2 from "@/components/searchProduct2.vue"
Vue.component(searchProduct2.name,searchProduct2)
// 搜索
import searchProductNew from "@/components/searchProductNew.vue"
Vue.component(searchProductNew.name,searchProductNew)

// 消息中心
import message from "@/components/message.vue"
Vue.component(message.name,message)

// 订单
import order from "@/components/order.vue"
Vue.component(order.name,order)

// 订单
import lineTitle from "@/components/lineTitle.vue"
Vue.component(lineTitle.name,lineTitle)

// 倒计时
import countDown from "@/components/countDown.vue"
Vue.component(countDown.name,countDown)

// 新建编辑地址
import addAddDialog from "@/components/addAddDialog.vue"
Vue.component(addAddDialog.name,addAddDialog)

// 加入进货车
import addStockDialog from "@/components/addStockDialog.vue"
Vue.component(addStockDialog.name,addStockDialog)

// 输入框
import inputBox from "@/components/inputBox.vue"
Vue.component(inputBox.name,inputBox)
// 输入框
import inputBox2 from "@/components/inputBox2.vue"
Vue.component(inputBox2.name,inputBox2)

// 回复
import comment from "@/components/comment.vue"
Vue.component(comment.name,comment)

// 回复
import comment2 from "@/components/comment2.vue"
Vue.component(comment2.name,comment2)

// 示购回复
import comment3 from "@/components/comment3.vue"
Vue.component(comment3.name,comment3)

// 上传
import upload from "@/components/upload.vue"
Vue.component(upload.name,upload)

// 媒体
import MyMedia from "@/components/MyMedia.vue"
Vue.component(MyMedia.name,MyMedia)

// 商品
import spu from "@/components/spu.vue"
Vue.component(spu.name,spu)
// 商品
import spu2 from "@/components/spu2.vue"
Vue.component(spu2.name,spu2)

// 商家
import enterprise from "@/components/enterprise.vue"
Vue.component(enterprise.name,enterprise)

// 页面上
import pageTop from "@/components/pageTop.vue"
Vue.component(pageTop.name,pageTop)

// 商品4个版
import spu4List from "@/components/spu4List.vue"
Vue.component(spu4List.name,spu4List)

// 商品5个版
import spu5List from "@/components/spu5List.vue"
Vue.component(spu5List.name,spu5List)

// 轮播切换
import carousel from "@/components/carousel.vue"
Vue.component(carousel.name,carousel)

// 企业 图片 标签 
import enterpriseImgTag from "@/components/enterpriseImgTag.vue"
Vue.component(enterpriseImgTag.name,enterpriseImgTag)

// 企业 图片 等级 
import enterpriseGrade from "@/components/enterpriseGrade.vue"
Vue.component(enterpriseGrade.name,enterpriseGrade)

// 选择用户弹框 
import selectUserDialog from "@/components/selectUserDialog.vue"
Vue.component(selectUserDialog.name,selectUserDialog)

// 店铺认领 
import enterpriseClaim from "@/components/enterpriseClaim.vue"
Vue.component(enterpriseClaim.name,enterpriseClaim)

//  选择用户输入框
import selectUserInput from "@/components/selectUserInput.vue"
Vue.component(selectUserInput.name,selectUserInput)

//  观察属性
import Observer from "@/components/Observer.vue"
Vue.component(Observer.name,Observer)

//  添加商品弹框
import addSpuDialog from "@/components/addSpuDialog.vue"
Vue.component(addSpuDialog.name,addSpuDialog)

//  添加店铺弹框
import addEnterpriseDialog from "@/components/addEnterpriseDialog.vue"
Vue.component(addEnterpriseDialog.name,addEnterpriseDialog)

//  添加名片弹框
import addCardDialog from "@/components/addCardDialog.vue"
Vue.component(addCardDialog.name,addCardDialog)

//  名片 卡片
import card from "@/components/card.vue"
Vue.component(card.name,card)

//  位置
import messageMap from "@/components/messageMap.vue"
Vue.component(messageMap.name,messageMap)

//  选择位置
import addr from "@/components/Addr.vue"
Vue.component(addr.name,addr)

//  直播---------------
import live from "@/components/live.vue"
Vue.component(live.name,live)

//  直播公告
import liveNotice from "@/components/liveNotice.vue"
Vue.component(liveNotice.name,liveNotice)

//  直播标题
import liveTitle from "@/components/liveTitle.vue"
Vue.component(liveTitle.name,liveTitle)

//  直播播放
import livePlay from "@/components/livePlay.vue"
Vue.component(livePlay.name,livePlay)
//  直播状态
import liveType from "@/components/liveType.vue"
Vue.component(liveType.name,liveType)
//  查询直播
import searchLive from "@/components/searchLive.vue"
Vue.component(searchLive.name,searchLive)
//  查询直播
import MyMediaBig from "@/components/MyMediaBig.vue"
Vue.component(MyMediaBig.name,MyMediaBig)
//  更多
import hasMore from "@/components/hasMore.vue"
Vue.component(hasMore.name,hasMore)

//  会员价
import VIPPrice from "@/components/VIPPrice.vue"
Vue.component(VIPPrice.name,VIPPrice)

//  优惠券
import coupon from "@/components/coupon.vue"
Vue.component(coupon.name,coupon)
//  优惠券
import coupon2 from "@/components/coupon2.vue"
Vue.component(coupon2.name,coupon2)
//  优惠券
import coupon3 from "@/components/coupon3.vue"
Vue.component(coupon3.name,coupon3)
//  优惠券(未使用/已使用/已失效)
import coupon4 from "@/components/coupon4.vue"
Vue.component(coupon4.name,coupon4)
//  优惠券
import enterpriseCoupon from "@/components/enterpriseCoupon.vue"
Vue.component(enterpriseCoupon.name,enterpriseCoupon)

//  签到
import signIn from "@/components/signIn/signIn.vue"
Vue.component(signIn.name,signIn)

//  
import First from "@/components/First/First.vue"
Vue.component(First.name,First)
//  
import sharePoster from "@/components/sharePoster.vue"
Vue.component(sharePoster.name,sharePoster)

//  
import scrollPage from "@/components/scrollPage.vue"
Vue.component(scrollPage.name,scrollPage)
//  
import refreshTopAskBuy from "@/components/refreshTopAskBuy.vue"
Vue.component(refreshTopAskBuy.name,refreshTopAskBuy)