import oucy from '@/util/oucyUtil.js'
// 自定义表情
const icon = {
	/**
     * 获取自定义表情
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryAllListIcon: (params) => {
        return oucy.postRequest('/client/im/icon/queryAllListIcon', params,false)
    },
   
}

export default icon
