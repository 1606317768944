// 企业订单管理

const enterOMS = [{
        path: '/enter/OMS',
        name: 'enterOMS',
        component: () => import("@/views/Enterprise/app/OMS/Index.vue"),
        meta:{title:'企业订单管理'}

    },
    // 退款
    {
        path: '/enter/OMSRefund',
        name: 'enterRefund',
        component: () => import("@/views/Enterprise/app/OMS/refund/refund.vue"),
        meta:{title:'企业订单管理 退款'}

    },
    // 发货
    {
        path: '/enter/OMSShipments',
        name: 'enterShipments',
        component: () => import("@/views/Enterprise/app/OMS/shipments/shipments.vue"),
        meta:{title:'企业订单管理 发货'}

    },
    // 查看物流
    {
        path: '/enter/OMSLogistics',
        name: 'enterLogistics',
        component: () => import("@/views/Enterprise/app/OMS/logistics/logistics.vue"),
        meta:{title:'企业订单管理 查看物流'}

    },
    // 打印清单
    {
        path: '/enter/OMSPrintList',
        name: 'enterPrintList',
        component: () => import("@/views/Enterprise/app/OMS/printList/printList.vue"),
        meta:{title:'企业订单管理 打印清单'}

    },
    // 详情
    {
        path: '/enter/OMSDetail',
        name: 'enterOMSDetail',
        component: () => import("@/views/Enterprise/app/OMS/detail/detail.vue"),
        meta:{title:'企业订单管理 详情'}

    },
     // 评论
    {
        path: '/enter/OMSComment',
        name: 'enterOMSComment',
        component: () => import("@/views/Enterprise/app/OMS/comment/comment.vue"),
        meta:{title:'企业订单管理 评论'}

    },
     // 评论管理
    {
        path: '/enter/OMSCommentManagement',
        name: 'enterOMSCommentManagement',
        component: () => import("@/views/Enterprise/app/OMS/commentManagement/commentManagement.vue"),
        meta:{title:'企业订单管理 评论管理'}

    },
         // 回复评论
    {
        path: '/enter/OMScommentReply',
        name: 'enterOMScommentReply',
        component: () => import("@/views/Enterprise/app/OMS/commentReply/commentReply.vue"),
        meta:{title:'企业订单管理 回复评论'}

    },


];


export default enterOMS