<template>
    <div class="Observer" :id="id">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "Observer",
    props: {
        id:{
            type:[String,],
            default:'Observer'
        }
    },
    data() {
        return {
            observer: null
        }
    },
    mounted() {
        this.observer = new IntersectionObserver(
            (entries) => {
                // 如果不可见，就返回
                if (entries[0].intersectionRatio <= 0) {
                    // console.log('不可见')
                } else {
                    // console.log('可见')
                };
                this.$emit('callBack', entries[0].intersectionRatio)
            });
        this.observer.observe(
            document.querySelector('#'+this.id)
        );

    },
    methods: {},
    beforeDestroy() {
        this.observer && this.observer.disconnect();
    }
}
</script>
<style>
    .Observer{
        min-height: 1px;
        opacity: 0;
        user-select: none;
    }
</style>