<!--  -->
<template>
    <div>
        live
        <video class="video" id="video" controls="1"></video>
        <el-button @click="isSupport">检测是否可用</el-button>
        <!-- 拉流地址 -->
        <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 1}" placeholder="请输入拉流地址" v-model="pullStreamUrl"></el-input>
        <el-button @click="subscribe">开始拉流</el-button>
        <!-- <input class="form-control mx-sm-3" id="pullurl" type="text" value="artc://pull.rts.grtn.aliyunlive.com/live/t4438917712?auth_key=600001626946515-0-0-2f44d03c7a00ac0f79258e7ce6974c76"/> -->
        <!-- <el-button @click="createStream">获取本地流</el-button> -->
        <br>
   
        推流地址
        <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 1}" placeholder="请输入推流地址" v-model="pushUrl">
        </el-input>
        <el-button @click="createStream">第1 获取本地流</el-button>
        <el-button @click="publish">第2 开始推流</el-button>
        <!-- <input class="form-control mx-sm-3" id="pullurl" type="text" value="artc://pull.rts.grtn.aliyunlive.com/live/t4438917712?auth_key=600001626946515-0-0-2f44d03c7a00ac0f79258e7ce6974c76"/> -->
        <!-- <el-button @click="createStream">获取本地流</el-button> -->
    </div>
</template>
<script>
import {AliRTS,RtsClient,LocalStream,RemoteStream} from "aliyun-rts-sdk"

export default {
    name: "live",
    props: {
        data: {
            type: Object,
            default: function() {
                return {}
            }
        },
        isShowStock: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            aliRts: null,
            video: null,
            pushUrl: null,
            localStream: null,
            pullStreamUrl:'rtmp://live.lesoujia.com/lesoujia-boss/202d4e9d72e646558c867ba0e28f9f50?auth_key=1637921630-0-0-aa9d60e8ae1478827721541f9a1f4e74',
        }
    },
    mounted() {
        console.log('AliRTS',AliRTS)

        this.init()
        // this.subscribe()
    },
    methods: {
        init(){
          this.video = document.querySelector('#video');

          this.aliRts= new AliRTS.createClient();
          /*
           * 在onError中获取到错误码10201时，此时网页的音频是静音的，
           * 需要用户在网页上手动触发事件（必须有用户交互，不能直接通过代码控制）
           * 调用remoteStream.muted = false取消静音
           */
          this.aliRts.on("onError", (err)=> {
            console.log(`errorCode: ${err.errorCode}`);
            console.log(`message: ${err.message}`);
          })

          const PLAY_EVENT = {
            CANPLAY: "canplay",
            WAITING: "waiting",
            PLAYING: "playing"
          }

          this.aliRts.on('onPlayEvent', (play)=>{
            if(play.event === PLAY_EVENT.CANPLAY){
              //拉流可以播放
            }else if(play.event === PLAY_EVENT.WAITING){
              //拉流卡顿等待缓冲中 （仅Chrome）
            }else if(play.event === PLAY_EVENT.PLAYING){
              //拉流卡顿结束恢复播放 （仅Chrome）
            }
          })  
        },
        /**
         * isSupport检测是否可用
         * @param {Object} supportInfo 检测信息
         * @param {Boolean} supportInfo.isReceiveVideo 是否拉视频流
         * @return {Promise}
         */
        isSupport(supportInfo = { isReceiveVideo: true }) {
            this.aliRts.isSupport(supportInfo).then(re => {
                this.$message('可用')
                // 可用
                // this.createStream()
            }).catch(err => {
                this.$message('不可用')
                // 不可用
                console.log(`not support errorCode: ${err.errorCode}`);
                console.log(`not support message: ${err.message}`);
            })
        },

        /**
         * rts开始拉流接口
         * @param {String} pullStreamUrl 拉流地址,在地址后添加@subaudio=no或者@subvideo=no来表示不订阅音频流或视频流
         * @return {Promise}
         */
        subscribe() {
            this.aliRts.subscribe(this.pullStreamUrl).then((remoteStream) => {
                this.$message('拉流成功')
                // mediaElement是媒体标签audio或video
                remoteStream.play(this.video);
            }).catch((err) => {
                this.$message('报错:'+err.errorCode)
                console.log(err)
                // 订阅失败
            })
        },

        /**
         * 停止rts播放接口
         */
        unsubscribe() {
            this.aliRts.unsubscribe();
        },
        /**
         * 获取本地流localStream
         */
        createStream() {


            AliRTS.createStream({
                video: true,
                audio: true,
                screen: !true,
            }).then((localStream) => {
                // 预览推流内容，mediaElement是媒体标签audio或video
                this.localStream=localStream
                localStream.play(this.video);
                this.$message('获取本地流成功')
            }).catch((err) => {
                console.log(err)
                this.$message('创建本地流失败'+err.errorCode)
                // 创建本地流失败
            })
        },

        /**
         * 开始推流
         */
        publish() {

            this.aliRts.publish(this.pushUrl, this.localStream).then(() => {
                // 推流成功
            this.$message('推流成功')
            }).catch((err) => {
                this.$message('推流失败'+err.errorCode)
                // 推流失败
            })
        },
    },
}
</script>
<style scoped lang="less">
</style>