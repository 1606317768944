import oucy from '@/util/oucyUtil.js'
// 企业新闻管理
const enterpriseNewsAlbumItem = {
	/**
     * 查询企业的新闻
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllEnterpriseNewsAlbumItems: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbumitem/getAllEnterpriseNewsAlbumItems', params)
    },
    /**
     * 新增企业新闻
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    addEnterpriseNewsAlbumItem: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbumitem/addEnterpriseNewsAlbumItem', params)
    },
    /**
     * 删除企业新闻
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    delEnterpriseNewsAlbumItem: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbumitem/delEnterpriseNewsAlbumItem', params)
    },
    /**
     * 修改企业新闻
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    updateenterpriseNewsAlbumItem: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbumitem/updateEnterpriseNewsAlbumItem', params)
    },    
    /**
     * 置顶
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    topping: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbumitem/topping', params)
    },
     /**
     * 取消置顶
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    cancelTopping: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbumitem/cancelTopping', params)
    },
     /**
     * 修改排序
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateSort: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbumitem/updateSort', params)
    },
     /**
     * 导入微信文章
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    importFromWechat: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbumitem/importFromWechat', params)
    },
     /**
     * 根据新闻编码查看新闻详情
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getEnterpriseNewsAlbumItems: (params={}) => {
        return oucy.postRequest('/client/enterprise/enterprisenewsalbumitem/getEnterpriseNewsAlbumItems', params)
    },

}

export default enterpriseNewsAlbumItem
