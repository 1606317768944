import oucy from '@/util/oucyUtil.js'
// 身份数据
const ltIdentity = {
	/**
     * 查询第一级的所有身份标识
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    findAllLtIdentity: (params={}) => {
    	// identityIsEnterprise 是否企业身份类型 (true or false)
    	let url = params.identityIsEnterprise?'/client/enterprise/ltidentity/findAllLtIdentity':'/client/user/ltidentity/findAllLtIdentity'
        return oucy.postRequest(url, params)
    },
    	/**
     * 查询第一级的所有身份标识[树形结构]
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    findFirstIdentity: (params={}) => {
    	// identityIsEnterprise 是否企业身份类型 (true or false)
    	let url = params.identityIsEnterprise?'/client/enterprise/ltidentity/findFirstIdentity':'/client/user/ltidentity/findFirstIdentity'
        return oucy.postRequest(url, params)
    },
   	/**
     * 查询客户端用户的所有企业
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    findAll: (params) => {
        return oucy.postRequest('/client/user/ltIdentity/findAll', params)
    },   	
    /**
     * 查询第一级的所有身份标识
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
/*    findFirstIdentity: (params) => {
        return oucy.postRequest('/ltIdentity/findFirstIdentity', params)
    },*/
}

export default ltIdentity
