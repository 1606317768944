<!-- 地图选址 -->
<template>
    <div>
        <el-dialog
                title="地图选址"
                :visible.sync="mapVisible"
                :width="(this.$oucy.pageWidth - 100)+'px'"
                :close-on-click-modal="false"
                :show-close="true"
                :destroy-on-close="false"
                :before-close="handleClose">
            <div style="padding: 0 0 25px 0;">
                <el-autocomplete
                    v-model="state"
                    style="width: 100%"
                    :fetch-suggestions="querySearchAsync"
                    placeholder="请输入内容"
                    @select="handleSearchSelect"
                >
                    <template slot-scope="{ item }">
                        <div class="name" v-if="!!item.location">
                            <span class="search-addr-name">{{ item.name }}</span>
                            <span class="search-addr-address">{{ item.address }}</span>
                            <span class="search-addr-tel">{{ item.tel }}</span>
                        </div>
                    </template>
                    <template slot="prepend">模糊匹配</template>
                    <el-button @click="state=null" v-if="!!state" type="text" style="padding-right:10px;color:#0366C3;" slot="suffix">清空</el-button>
                </el-autocomplete>
            </div>
            <div class="map-container">
                <div class="addrList">
                    <el-collapse v-model="activeAddrNames" accordion v-if="lists && lists.length>0">
                        <template v-for="(item, index) in lists">
                            <el-collapse-item :name="index">
                                <template slot="title">
                                    <el-button @click.stop="doChoose(item)" icon="el-icon-thumb" style="padding: 0 15px 0 0" type="text">选中</el-button>{{index+1}}.{{item.name}}
                                </template>
                                <div>{{item.address}}{{item.tel}}</div>
                            </el-collapse-item>
                        </template>
                    </el-collapse>
                </div>
                <div id="container"></div>
            </div>
            <div class="addr-foot">
                <div>
                    <template v-if="!!finalChooseAddr">
                        <i class="el-icon-s-flag" style="padding: 0 5px 0 0;color: #000;font-size: 20px;"></i>
                        <span class="search-addr-name">{{ finalChooseAddr.name }}</span>
                        <span class="search-addr-address">{{ finalChooseAddr.address }}</span>
                        <span class="search-addr-tel">{{ finalChooseAddr.tel }}</span>
                    </template>
                </div>
                <div>
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary" :disabled="!finalChooseAddr" @click="handleFinalChoose">确 定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Addr",
        props: {
            mapVisible: Boolean,
            default: false
        },
        data(){
            return{
                mapCenter: [113.098697,22.986871],
                lists: [],
                activeAddrNames:null,
                state:null,
                finalChooseAddr:null,
                map:null
            }
        },
        mounted(){
            if (navigator.geolocation) {
                    console.log('3')
                navigator.geolocation.getCurrentPosition((position)=> {
                        var lat = position.coords.latitude;
                        var lon = position.coords.longitude;
                        this.mapCenter= [lat,lon]
                    console.log('1',position.coords)
                    },error=>{
                        this.mapCenter= [113.098697,22.986871]
                        switch (error.code) {
                            case 1:
                                console.log("位置服务被拒绝。");
                                break;
                            case 2:
                                console.log("暂时获取不到位置信息。");
                                break;
                            case 3:
                                console.log("获取位置信息超时。");
                                break;
                            default:
                                console.log("未知错误。");
                                break;
                        }
                    })
                    console.log('1')
                }else{
                    console.log('0')
                }
        },
        watch:{
            mapVisible(n,o){
                const me = this;
                if(n){
                    me.activeAddrNames = null;
                    me.state = null;
                    me.finalChooseAddr = null;
                    setTimeout(me.adMap, 500);
                }
            }
        },
        methods:{
            adMap: function () {
                const me = this;

                if(!!me.map){
                    me.map.setCenter(me.mapCenter);
                } else {
                    me.map = new AMap.Map('container',{
                        zoom: 14,//缩放级别
                        center: me.mapCenter,//设置地图中心点
                        resizeEnable: true
                    });
                }


                //获取初始中心点并赋值
                //此方法是获取当前地图的中心点
                //let currentCenter = me.map.getCenter();
                //将获取到的中心点的纬度经度赋值给data的center
                //me.mapCenter = [currentCenter.lng,currentCenter.lat];

                //根据地图中心点查附近地点，此方法在下方
                me.centerSearch();

                //监听地图移动事件，并在移动结束后获取地图中心点并更新地点列表
                /*let moveendFun = (e) => {
                    // 获取地图中心点
                    currentCenter = map.getCenter();
                    me.mapCenter = [currentCenter.lng,currentCenter.lat];
                    //根据地图中心点查附近地点
                    me.centerSearch();
                };*/

                // 绑定事件移动地图事件
                me.map.on('moveend', me.moveendFun);

            },
            moveendFun: function(){
                const me = this;
                let currentCenter = me.map.getCenter();
                me.mapCenter = [currentCenter.lng,currentCenter.lat];
                me.centerSearch();
            },
            centerSearch: function () {
                const me = this;
                AMap.service(["AMap.PlaceSearch"], () => {
                    //构造地点查询类
                    let placeSearch = new AMap.PlaceSearch({
                        type: '汽车服务|餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务|公司企业|地名地址信息', // 兴趣点类别
                        pageSize: 50, // 单页显示结果条数
                        pageIndex: 1, // 页码
                        city: "全国", // 兴趣点城市
                        autoFitView: false // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
                    });
                    //根据地图中心点查附近地点
                    placeSearch.searchNearBy('', [me.mapCenter[0],me.mapCenter[1]], 200, (status, result) => {
                        if(status === 'complete'){
                            me.lists = result.poiList.pois;
                            me.activeAddrNames = null;
                        }
                    });
                });
            },
            keySearch(search_key){
                return new Promise((resolve, reject) => {
                    AMap.plugin('AMap.Autocomplete', function(){
                        // 实例化Autocomplete
                        var autoOptions = {
                            //city 限定城市，默认全国
                            city: '全国'
                        }
                        var autoComplete= new AMap.Autocomplete(autoOptions);
                        autoComplete.search(search_key, function(status, result) {
                            // 搜索成功时，result即是对应的匹配数据
                            if(status === 'complete'){
                                if(result.count === 0){
                                    resolve([]);
                                }else{
                                    resolve(result.tips);
                                }
                            }else{
                                resolve([]);
                            }
                        })
                    })
                });
            },
            querySearchAsync(queryString, cb) {
                const me = this;
                if(!!queryString){
                    me.keySearch(queryString).then(res=>{
                        cb(res);
                    });
                } else {
                    cb([]);
                }
            },
            doChoose: function (addr) {
                this.finalChooseAddr = addr;
            },
            handleSearchSelect: function (item) {
                this.mapCenter = [item.location.lng,item.location.lat];
                this.adMap();
            },
            handleClose: function (done) {
                this.$emit('close');
                return done && done(false);
            },
            handleFinalChoose: function () {
                console.log(this.finalChooseAddr)
                this.$emit('choose', this.finalChooseAddr);
            }
        }
    }
</script>

<style scoped>
    .map-container{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .addrList{
        width: 400px;
        height: 400px;
        border-radius: 4px;
        margin: 0 20px 0 0;
        overflow: auto;
    }
    #container{
        height: 400px;
        flex: 1;
    }
    .search-addr-name{
        color: #000000;
    }
    .search-addr-address{
        color: #5c5b75;
        font-size: 12px;
        padding: 0 10px;
    }
    .search-addr-tel{
        font-style: italic;
    }
    .addr-foot{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0 0 0;
    }
</style>
