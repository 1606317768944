import oucy from "@/util/oucyUtil.js";
// 家具分类
const furnitureClassify = {
  /**
   * 根据用户和分类归类查询(客户端使用)
   * @param  {object} 请求的参数
   * @return {Promise} 返回一个Promise
   */
  findByLtUserAuthAndClassifyCategory: (params = {}) => {
    return oucy.postRequest(
      "/client/product/furnitureclassify/getAllFurnitureClassifys",
      params
    );
  },
};

export default furnitureClassify;
