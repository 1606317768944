<!-- 直播播放 -->
<template>
    <div class="livePlay">
        <img src="@/assets/images/live/bg.png" alt="" class="bg">
        <div class="videoBox text-center">
            <!-- 结束 有回放 -->
            <template v-if="liveRoom.roomStatus==4 &&liveRoom.roomVideoEnable">
                <video ref="video" v-if="replyUrlList && replyUrlList.length" :src="replyUrlList[playIndex].liveRecordUrl" controls controlsList="nodownload" object-fit='contain' autoplay></video>
                <img v-else src="@/assets/images/live/stopPlay.png" alt="" class="pauseImg">
            </template>
            <video style="max-width: 100%;max-height: 100%;height: 100%;" v-else-if="LiveStream" id="roomVideo" class="video-js vjs-default-skin vjs-big-play-centered" x-webkit-airplay="allow" :poster="$oucy.ossUrl+liveRoom.roomIcon" webkit-playsinline playsinline x5-video-player-type="h5" x5-video-player-fullscreen="true" autoplay preload="auto" controls>
                <source :src="LiveStream" type="application/x-mpegURL">
            </video>
            <div class="imgBox" v-else>
                <img v-if="liveRoom.roomIcon" :src="$oucy.ossUrl + liveRoom.roomIcon" alt="" class="roomIcon">
                <!-- roomStatus  直播状态 0预告 1暂停 2禁止开播 3开播中 4已结束 -->
                <el-button type="primary" class="btn" @click="settingLiveAction" v-if="hasManage && liveRoom.roomStatus==0">开始直播</el-button>
            </div>
            <!-- 倒计时 -->
            <div class="timeBOxs" v-if="!hasManage && liveRoom.roomStatus==0 && hasShowUCountDown>0">
                <div class="timeBOx">
                    <span class="m-r-20">距离开播</span>
                    <uCountDown @end="hasShowUCountDown=false" :timestamp="time" separator="false" separatorColor="#fff" color="#fff" bgColor=""></uCountDown>
                </div>
            </div>
            <!-- 未开始 -->
            <div class="pause" v-if="!hasManage && liveRoom.roomStatus==0 && hasShowUCountDown<0"><img src="@/assets/images/live/notStart.png" alt="" class="pauseImg" v-if="liveRoom.pauseImg"></div>
            <!-- 0预告 1暂停 2禁止 3 开播中 4已结束 -->
            <!-- 暂停 -->
            <div class="pause" v-if="liveRoom.roomStatus==1"><img :src="$oucy.ossUrl + liveRoom.pauseImg" alt="" class="pauseImg" v-if="liveRoom.pauseImg"></div>
            <!-- 违规 -->
            <div class="pause" v-if="liveRoom.roomStatus==2 && hasManage"><img src="@/assets/images/live/illegal.png" alt="" class="pauseImg"></div>
            <div class="pause" v-if="liveRoom.roomStatus==2 && !hasManage"><img :src="$oucy.ossUrl + liveRoom.pauseImg" alt="" class="pauseImg" v-if="liveRoom.pauseImg"></div>
            <!-- 结束 无回放 -->
            <div class="pause" v-if="liveRoom.roomStatus==4 &&!liveRoom.roomVideoEnable"><img src="@/assets/images/live/stop.png" alt="" class="pauseImg"></div>
            <!-- 推送记录 -->
            <el-popover placement="left" title="推送记录" width="400" trigger="hover">
                <div class="messageBox">
                    <livePushList :hasManage="hasManage" :messageObj="messageObj" @callback="callback" v-if="messageObj"></livePushList>
                </div>
                <el-button slot="reference" icon="el-icon-present" type="text" v-if="!hasManage && messageObj.content && messageObj.content.length" class="present"></el-button>
            </el-popover>
            <div v-if="liveRoom.roomStatus==4 &&liveRoom.roomVideoEnable && replyUrlList && replyUrlList.length>1">
                <el-button type="text" icon="el-icon-d-arrow-left" @click="left" :disabled="!(playIndex > 0)">上一节</el-button>
                <!-- <el-button type="text" icon="el-icon-video-pause" @click="pause">暂停</el-button> -->
                <el-button type="text" @click="playIndex=i,load()" :disabled="playIndex==i" v-for="(v,i) of replyUrlList" :key="i">第{{i+1}}节</el-button>
                <!-- <el-button type="text" icon="el-icon-video-play" @click="play">播放</el-button> -->
                <el-button type="text" icon="el-icon-d-arrow-right" @click="right" :disabled="!(playIndex < replyUrlList.length-1)">下一节</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import livePushList from "@/components/livePushList.vue"
import uCountDown from "@/components/u-count-down.vue"
import { liveroom } from '@/service/index.js'
export default {
    components: {
        livePushList,
        uCountDown,
    },
    name: "livePlay",
    props: {
        liveRoom: {
            type: Object,
            default: function() {
                return {}
            }
        },
        messageObj: {
            type: Object,
            default: function() {
                return {}
            }
        },
        hasManage: {
            type: Boolean,
            default: false
        },
        replyUrlList: {
            type: [Object, Array],
            default: function() {
                return []
            }
        },
        roomPassword: {
            type: String,
            default: null
        },

    },
    data() {
        return {
            hasPlay: false,
            myPlayer: null,
            LiveStream: null,
            id: null,
            hasShowUCountDown: !false,
            url: null,
            playIndex: 0,
        }
    },
    computed: {
        time: function() {
            let time = 0
            if (this.liveRoom && this.liveRoom.roomTimeBeginStart) {
                time = ~~(new Date(this.liveRoom.roomTimeBeginStart).getTime() - new Date().getTime()) / 1000
            }
            this.hasShowUCountDown = time > 0
            return time
        }
    },
    mounted() {
        this.id = this.$route.query.id
        /*        setTimeout(()=>{
                    
                this.getLiveStream()
                },5000)*/

    },
    methods: {
        settingLiveAction() {
            // 直播操作 0开播 1结束 2暂停 3暂停恢复 4禁止直播 5禁止直播恢复
            this.$oucy.confirm('开始直播前请确认已经在推流中', '提示').then(res => {
                this.$emit('settingLiveAction', 0)
            }, err => {})
        },
        initVideoJs() {
            let THIS = this
            this.myPlayer = videojs('roomVideo', {
                bigPlayButton: true,
                textTrackDisplay: true,
                posterImage: true,
                errorDisplay: !true,
                controlBar: true
            }, () => {
                this.myPlayer.on('loadedmetadata', () => {
                    console.log('loadedmetadata');
                    //加载到元数据后开始播放视频
                })

                this.myPlayer.on('ended', function() {
                    console.log('ended')
                })
                this.myPlayer.on('firstplay', function() {
                    console.log('firstplay')
                })
                this.myPlayer.on('loadstart', function() {
                    //开始加载
                    console.log('loadstart')
                })
                this.myPlayer.on('loadeddata', function() {
                    console.log('loadeddata')
                })
                this.myPlayer.on('seeking', function() {
                    //正在去拿视频流的路上
                    console.log('seeking')
                })
                this.myPlayer.on('seeked', function() {
                    //已经拿到视频流,可以播放
                    console.log('seeked')
                    // THIS.startVideo();
                })
                this.myPlayer.on('waiting', function() {
                    console.log('waiting')
                })
                this.myPlayer.on('pause', function() {
                    console.log('pause')
                })
                this.myPlayer.on('play', function() {
                    console.log('play')
                })

            });

        },
        startVideo() {
            console.log('startVideo')
            this.myPlayer.play();

            //微信内全屏支持
            // document.getElementById('roomVideo').style.width = window.screen.width + "px";
            // document.getElementById('roomVideo').style.height = window.screen.height + "px";


            //判断开始播放视频，移除高斯模糊等待层


            //判断视频是否卡住，卡主3s重新load视频
            var lastTime = -1,
                tryTimes = 0,
                currentTime;
            /*
                        clearInterval(this.isVideoBreak);
                        this.isVideoBreak = setInterval(() => {
                            currentTime = this.myPlayer.currentTime();
                            console.log('currentTime' + currentTime + 'lastTime' + lastTime);

                            if (currentTime == lastTime) {
                                //此时视频已卡主3s
                                //设置当前播放时间为超时时间，此时videojs会在play()后把currentTime设置为0
                                this.myPlayer.currentTime(currentTime + 10000);
                                this.myPlayer.play();

                                //尝试5次播放后，如仍未播放成功提示刷新
                                if (++tryTimes > 5) {
                                    this.$message('您的网速有点慢，刷新下试试');
                                    tryTimes = 0;
                                }
                            } else {
                                lastTime = currentTime;
                                tryTimes = 0;
                            }
                        }, 3000)*/

        },
        pause() {
            console.log('pause')
            if (this.myPlayer && typeof this.myPlayer.pause == 'function') {
                this.myPlayer.pause();                
            }
        },
        stop() {
            console.log('stop')
            if (this.myPlayer && typeof this.myPlayer.stop == 'function') {
                this.myPlayer.stop();
            }
        },
        // 获取拉流地址
        getLiveStream(id) {
            this.myPlayer = null
            console.log('getLiveStream')
            liveroom.getLiveStream({ id: id || this.liveRoom.id ,roomPassword:this.roomPassword}).then(res => {
                // 有数据
                if (res) {
                    this.LiveStream = res.m3u8
                    // this.LiveStream=res.rtmp
                    setTimeout(() => {
                        this.initVideoJs()
                        this.startVideo()
                    }, 100)
                }

            })
        },
        callback() {
            console.log('callback')
            this.$emit('callBackHasMore', { type: "hasMore", data: this.messageObj })
        },
        left() {
            console.log('left')
            if (this.playIndex > 0) {
                --this.playIndex
            } else {
                this.playIndex = 0
            }
            this.load()
        },
        // pause() {
        //     console.log('pause')
        //     this.$refs.video.pause()
        // },
        play() {
            console.log('play')
            this.$refs.video.play()
        },
        right() {
            console.log('right')
            if (this.replyUrlList.length - 1 > this.playIndex) {
                ++this.playIndex
            } else {
                this.playIndex = this.replyUrlList.length - 1
            }
            this.load()
        },
        load() {
            console.log('load')
            this.$refs.video.load()
            setTimeout(() => {

                this.play()
            }, 1000)
        },

    },
    beforeDestroy() {
        this.myPlayer && this.myPlayer.dispose();
    }
}
</script>
<style scoped lang="less">
.bg {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #1A202A;
}

.videoBox {
    justify-content: center;
    align-items: center;
    position: relative;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

video {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
}

.roomIcon {
    width: 100%;
    border-radius: 8px;
}

.imgBox {
    position: relative;
    font-size: 0;
    img{
        filter: brightness(30%);
    }

}

.btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow:0 0 20px #fff;
}

.video-js {
    width: 100%;

    .vjs-tech {

        position: relative;
    }
}

.livePlay {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    font-size: 0;
}

.pause {
    position: absolute;
    /*background: #f00;*/
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // height: auto;
    overflow: hidden;

}

.pauseImg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    width: 100%;

}

.present {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    box-shadow: 0 0 20px #fff;
    position: absolute;
    right: 20px;
    bottom: 90px;
    background: linear-gradient(135deg, #FF6767 0%, #FF1D1D 100%);
    color: #Fff;
}

.messageBox {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    // margin-left: -15px;
    // margin-right: -15px;
}

.timeBOxs {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 23px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    background: rgba(30, 30, 32, .8);
    width: 100%;
    height: 100%;
    // opacity: 0.81;
    display: flex;
    justify-content: center;
    align-items: center;

    .timeBOx {
        padding: 10px 20px;
        display: flex;
        background: rgba(0, 0, 0, .2);
        // opacity: 0.2;
        border-radius: 33px;
    }
}
</style>