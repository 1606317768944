import oucy from '@/util/oucyUtil.js'
// 
const sign = {
    /** 积分记录
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getAllUserSignInfo: (params) => {
        return oucy.postRequest('/client/user/sign/queryUserIntegralHisPage', params,false)
    },
    /** 获取用户指定日期所在月份的签到情况
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getSignInfo: (params) => {
        return oucy.postRequest('/client/user/sign/getSignInfo', params,false)
    },
    /** 获取用户指定日期的签到情况
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getSignByDate: (params) => {
        return oucy.postRequest('/client/user/sign/getSignByDate', params,false)
    },
    /** 查询当前用户剩余积分
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    querySignValue: (params) => {
        return oucy.postRequest('/client/user/sign/querySignValue', params,false)
    },
        /** 签到
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    doSign: (params) => {
        return oucy.postRequest('/client/user/sign/doSign', params,false)
    },
    /** 查询用户今日刷新次数
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    countIntegralHis: (params) => {
        return oucy.postRequest('/client/user/sign/countIntegralHis', params,false)
    },

}

export default sign