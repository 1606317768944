import oucy from '@/util/oucyUtil.js'
// 员工名片评论管理
const cardEmployeeComment = {
	/**
     * 发布员工评论
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    createCardCategory: (params) => {
        return oucy.postRequest('/client/card/cardemployeecomment/createCardCategory', params)
    },
   	/**
     * 查询个人名片评论列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getCardEmployeeCommentList: (params) => {
        return oucy.postRequest('/client/card/cardemployeecomment/getCardEmployeeCommentList', params)
    },
       	/**
     * 
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getMyCardEmployeeCommentList: (params) => {
        return oucy.postRequest('/client/card/cardemployeecomment/getMyCardEmployeeCommentList', params)
    },
           	/**
     * 
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getMyCommentList: (params) => {
        return oucy.postRequest('/client/card/cardemployeecomment/getMyCommentList', params)
    },

}

export default cardEmployeeComment
