// 个人名片管理

const accCard = [

    // 保存
    {
        path: '/acc/cardSave',
        name: 'AccCardSave',
        component: () => import("@/views/Account/app/card/save"),
        meta:{title:'保存个人名片'}

    },
    // 详情
    {
        path: '/acc/cardDetail',
        name: 'AccStaffDetail',
        component: () => import("@/views/Account/app/card/detail"),
        meta:{title:'个人名片详情'}
    },

];

export default accCard
