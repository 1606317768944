import oucy from '@/util/oucyUtil.js'
// 商品评论
const spuComment = {
	/**
     * 商品评论
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    comment: (params) => {
        return oucy.postRequest('/spuComment/comment', params)
    },
	/**
     * 商品批量评论
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    batchComment: (params) => {
        return oucy.postRequest('/client/financial/spucomment/batchComment', params)
    },

    /**
     * 查询商品评价列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getCommentSpuList: (params) => {
        return oucy.postRequest('/client/financial/spucomment/getCommentSpuList', params)
    },

    /**
     * 回复评论
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    commentReply: (params) => {
        return oucy.postRequest('/client/financial/spucomment/commentReply', params)
    },

    /**
     * 查询商品评价详细数据
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getOrderSpuComment: (params) => {
        return oucy.postRequest('/client/financial/spucomment/getOrderSpuComment', params)
    },


}

export default spuComment
