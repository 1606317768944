<!--  -->
<template>
    <div style="background-color:#fff">
        <div class="df-jc-s-b df-ai-c searchLive newBodyWidth">
            <div class="df-ai-c df-jc-s-b" style="width:100%">
                <div class="df-ai-c left">
                    <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" class="m-l-15" src="../assets/logo.png">
                    <el-select v-model="roomCategory" size="mini" placeholder="请选择" class="m-l-25">
                        <el-option v-for="item in liveTypes" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <el-input v-model="roomTitle" size="mini" placeholder="请输入直播相关内容搜索" class="m-l-35" style="width: 420px;">
                    <el-button slot="append" icon="el-icon-search" @click="goto"></el-button>
                </el-input>
                <div class="right"></div>
            </div>
            <div class="df" v-if="0">
                <div class="btn">家具库</div>
                <div class="btn">求购库</div>
                <div class="btn">材料库</div>
                <div class="btn">求购库</div>
                <div class="btn">企业库</div>
                <div class="btn">名片库</div>
            </div>
        </div>
    </div>
</template>
<script>
import { AliRTS, RtsClient, LocalStream, RemoteStream } from "aliyun-rts-sdk"

export default {
    name: "searchLive",
    props: {
        data: {
            type: Object,
            default: function() {
                return {}
            }
        },
        isShowStock: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            value: '',
            roomTitle: '',
            // 直播分类：0展会直播 1行业直播 2工厂直播 3个人直播
            liveTypes: [{
                id: null,
                name: '所有直播'
            }, {
                id: 0,
                name: '展会直播'
            }, {
                id: 1,
                name: '行业直播'
            }, {
                id: 2,
                name: '工厂直播'
            }, {
                id: 3,
                name: '个人直播'
            }],
            roomCategory: null
        }
    },
    mounted() {

        this.roomTitle = this.$route.query.roomTitle

        // this.isSupport()
    },
    methods: {
        goto() {
            if (!this.roomTitle) {
                this.$message('请输入要查找的内容')
                return
            }
            try {
                this.$router.replace(`/listSearch?roomTitle=${this.roomTitle}&roomCategory=${this.roomCategory}`);
            } catch (err) {
                console.error(err)
                // this.$message('请输入要查找的内容')

            }
        }
    },
}
</script>
<style scoped lang="less">
.searchLive {
    background: #fff;
    padding: 10px 0;
}
.left,.right{
    width: 260px;
}
.btn {
    cursor: pointer;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #707070;
    margin: 0 15px;
}
</style>