<!-- 企业 等级 -->
<template>
    <div class="df box">
        <img class="image m-r-10" src="@/assets/images/qiyeku/vip.png" v-if="enterprise.vipLeve==2">
        <img class="image m-r-10" src="@/assets/images/qiyeku/realName.png" v-if="enterprise.enterpriseAuthenticationStatus==2">
        <span class="protectBox m-r-10 image c-m" v-if="enterprise.enterpriseSecurityDepositFee">
            <span class="protectBox_text">￥{{enterprise.enterpriseSecurityDepositFee}}</span>
            <img class="protectBox_img image" src="@/assets/images/qiyeku/protect.png">
        </span>
        <img class="image" src="@/assets/images/qiyeku/Keep_your_about_icon.png" v-if="0">
        <img class="image m-r-10" :src="$oucy.ossUrl + enterprise.ltIdentity.ltIdentityParentIcon" v-if="enterprise.ltIdentity && enterprise.ltIdentity.ltIdentityParentIcon">
    </div>
</template>
<script>
export default {
    name: "enterpriseGrade",
    props: {
        enterprise: {
            type: Object,
            default: {},
        },
        
    },

    mounted() {},
    methods: {},
}
</script>
<style scoped lang="less">
    .box{
        margin-left: 5px;
        display: flex;
        align-items:center;
    }
.image {
    height: 25px;
}


.protectBox {
    position: relative;
}

.protectBox_text {
    position: absolute;
    top: 4px;
    left: 16px;
    width: 60px;
    font-weight: 500;
    /*background: #f00;*/
    font-size: 13px;
    text-align: center;
    color: #fff;
}

</style>