<!-- 直播标题 -->
<template>
    <div class="liveTitle">
        <div class="left df-ai-c">
            <img v-if="liveRoom.ltUserAuthBasic && liveRoom.ltUserAuthBasic.detailAvatar" :src="$oucy.ossUrl +liveRoom.ltUserAuthBasic.detailAvatar" alt="" class="detailAvatar">
            <div class="right">
                <div class="df-jc-s-b df-ai-c">
                    <div class="roomTitle df-ai-c">{{liveRoom.roomTitle}}<liveType :data="liveRoom" class="liveType m-l-15"></liveType>
                    </div>
                    <div class="info df-ai-c">
                        <el-popover placement="top" width="400" trigger="hover" @show="click" v-model="showPopover">
                            <div class="df-jc-s-b df-ai-c">
                                <div>
                                    <div ref="myLoginQrCodeUrl"></div>
                                    <div class="m-t-10">
                                        微信扫一扫 轻松看直播
                                    </div>
                                </div>
                                <div class="m-l-10 text-center">
                                    <el-input style="flex: 1;" id="sherUrl" v-model="url" size="mini" placeholder="请输入内容"></el-input>
                                    <el-button class="m-t-10" type="primary" size="mini" @click="Mycopy('sherUrl')">复制链接分享</el-button>
                                    <el-button class="m-t-10" type="primary" size="mini" @click="sharePoster">生成海报</el-button>
                                </div>
                            </div>
                            <!-- <el-button slot="reference" type="text" size="mini" plain round @click="click"></el-button> -->
                            <span slot="reference" class="m-l-20 pointer df-ai-c" @mouseenter="showPopover=true,saveLiveRoomShareLine()" @mouseleave="showPopover=false"><img src="@/assets/images/live/share.png"><span class="m-r-5 m-l-5">分享</span>{{liveRoom.liveRoomPBasic.pshare||0}}</span>
                        </el-popover>
                        <span class="m-l-20 pointer df-ai-c" ><vue-clap-button @click.native.stop="saveLiveRoomLike(false)" colorNormal="#F56C6C" colorActive="#F56C6C" ref="vueClapButton" :maxClick="10000" :size="25"></vue-clap-button><span @click="saveLiveRoomLike(true)" class="m-r-5 m-l-5">点赞</span><span @click="saveLiveRoomLike(true)">{{liveRoom.liveRoomPBasic.plike||0}}</span></span>
                        
                        <span class="m-l-20 pointer df-ai-c"><img src="@/assets/images/live/fire.png"><span class="m-r-5 m-l-5">人气</span>{{liveRoom.liveRoomPBasic.pwatch||0}}</span>
                        <span class="m-l-20 pointer focusHost  df-ai-c" :class="isFocusHost?'isFocusHost':''" @click="saveLiveRoomAttention"><img src="@/assets/images/live/heart_.png" v-if="isFocusHost"><img src="@/assets/images/live/heart.png" v-else><span class="m-r-5 m-l-5">关注房间</span>{{liveRoom.liveRoomPBasic.pattention||0}}</span>
                    </div>
                </div>
                <div class="df-jc-s-b df-ai-c m-t-5">
                    <div v-if="liveRoom.ltUserAuthBasic" class="detailNickname df-ai-c">{{liveRoom.ltUserAuthBasic.detailNickname}}
                        <span class="df-ai-c c-2 m-l-5 pointer" @click="saveUserAttention"><img src="@/assets/images/live/person_.png" v-if="isFocusHostUser_"><img src="@/assets/images/live/person.png" v-else><span class="m-r-5 m-l-5">关注主播</span>{{focusHostUserNum}}</span>
                    </div>
                    <div class="info df-ai-c" v-if="liveRoom.liveRoomPBasic">
                        <span class="m-l-20 pointer df-ai-c"><img src="@/assets/images/live/home.png"><span class="m-r-5 m-l-5">房间号</span>{{liveRoom.roomNumber}}</span>
                        <span class="m-l-20 pointer df-ai-c"><img src="@/assets/images/live/eye.png"><span class="m-r-5 m-l-5">正在观看</span>{{liveRoom.liveRoomPBasic.ponline||0}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 分享海报 -->
        <sharePoster ref="sharePoster"></sharePoster>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import QRCode from 'qrcodejs2'
import { liveroomp, liveroom } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store";
//
export default {
    name: "liveTitle",
    components: {
    
    },
    props: {
        liveRoom: {
            type: Object,
            default: function() {
                return {}
            }
        },

    },
    data() {
        return {
            // pageWidth: oucy.pageWidth,
            qrCode: null,
            url: null,
            isFocusHost: false,
            isLikeLive: false,
            isFocusHostUser_: false,
            showPopover: false,
            focusHostUserNum: 0,

        }
     
    },
    mounted() {
        this.qrCode = new QRCode(this.$refs.myLoginQrCodeUrl, {
            // text: text,
            // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
            width: 146,
            height: 146,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
        this.isFocusHostLiveRoom()
        this.isLikeLiveRoom()
        this.isFocusHostUser()

    },
    methods: {
        Mycopy(id) {
            let dom = document.getElementById(id)
            dom.select();
            document.execCommand("Copy");
            this.$message({
                type: 'success',
                message: '复制成功!'
            });
        },
        click() {
            if (!this.url) {

                this.url = `${window.location.origin}${window.location.pathname}?id=${this.$route.query.id}`
                let QrCodeurl = `https://h5.lesoujia.com/#/module/Live/BroadcastRoom/BroadcastRoom?LiveId=${this.$route.query.id}`
                this.loginUser = localGet(this.$oucy.userInfoKey)
                if (this.loginUser && this.loginUser.id) {
                    this.url += `&fromUserId=${this.loginUser.id}`
                    this.url += `&parentId=${this.loginUser.id}`
                    QrCodeurl += `&fromUserId=${this.loginUser.id}`
                }
                if(this.liveRoom.roomPwd){
                    this.url += `&Pwd=true`
                    QrCodeurl += `&Pwd=true`

                }
                // this.creatQrCode('https://haibao.lesoujia.com')
                this.creatQrCode(QrCodeurl)
            }
        },
        creatQrCode(text = 12132) {
            console.log(this.qrCode)
            this.hasQrcodeShow = true
            this.qrCode && this.qrCode.clear();
            this.qrCode.makeCode(text)
        },
        // 是否关注直播间
        isFocusHostLiveRoom() {
            liveroomp.isFocusHostLiveRoom({ liveRoomId: this.liveRoom.id }).then(res => {
                this.isFocusHost = res
            })
        },
        // 是否点赞直播间
        isLikeLiveRoom() {
            liveroomp.isLikeLiveRoom({ liveRoomId: this.liveRoom.id }).then(res => {
                this.isLikeLive = res.isLike
            })
        },
        // 是否关注主播
        isFocusHostUser() {
            liveroomp.isFocusHostUser({ hostId: this.liveRoom.ltUserAuthBasic.id }).then(res => {
                this.focusHostUserNum = res.focusHostUserNum
                this.isFocusHostUser_ = res.focusHostUser
            })
        },
        // 关注主播
        saveUserAttention() {
            liveroomp.saveUserAttention({ hostId: this.liveRoom.ltUserAuthBasic.id }).then(res => {
                this.isFocusHostUser_ = !this.isFocusHostUser_
                this.isFocusHostUser_ ?++this.focusHostUserNum :--this.focusHostUserNum 
            })
        },

        // 直播间增加关注人数
        saveLiveRoomAttention() {
            liveroomp.saveLiveRoomAttention({ liveRoomId: this.liveRoom.id }).then(res => {
                this.isFocusHost = !this.isFocusHost
                this.isFocusHost?++this.liveRoom.liveRoomPBasic.pattention:--this.liveRoom.liveRoomPBasic.pattention
            })
        },
        // 直播间点赞
        saveLiveRoomLike(e) {
            if(e){
                this.$refs.vueClapButton.click()
            }
            liveroomp.saveLiveRoomLike({ liveRoomId: this.liveRoom.id }).then(res => {
                this.isLikeLive = true
                this.isLikeLive?++this.liveRoom.liveRoomPBasic.plike:--this.liveRoom.liveRoomPBasic.plike
            })
        },
                // 直播间增加分享次数
        saveLiveRoomShareLine() {
            return
            liveroomp.saveLiveRoomShareLine({ liveRoomId: this.liveRoom.id ,liveRoomShareEnum:0}).then(res => {
               
            })
        },
        // 生成海报
        sharePoster() {
          let obj = {
              objectId: this.liveRoom.id,
              playbillCategory: 5,
              redirectUrlScene:{LiveId:this.liveRoom.id}
          };
          this.$refs.sharePoster.show(obj)

        },

    },
}
</script>
<style scoped lang="less">
.right {
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
}

.detailAvatar {
    width: 49px;
    height: 49px;
    border-radius: 50%;
}

.roomTitle {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 16px;
    color: #555C68;
}

.detailNickname {
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 16px;
    color: #555C68;
}

.info {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
    color: #AAAFB7;
}

.focusHost {
    padding: 2px 10px;
    border-radius: 20px;
    background: #FAFAFA;

    &.isFocusHost {
        background: #E96666;
        color: #fff;

    }
}
</style>