<!--  -->
<template>
    <div class="item_box pointer" @click="$oucy.go('/enter/CouponDetail?id='+v.id)" title="点击查看详情" :class="['coupon_'+v.ticketScope,v.isDead?'coupon_-1':'']">
        <div class="item_top">
            <div class="item_top_left"><span class="f12">￥</span>{{v.ticketFee}}</div>
            <div class="item_top_right">满{{v.ticketLimitFee}}元</div>
            <div class="f12">使用</div>
        </div>
        <div class="right">
            <!-- <div class="use" v-if="1 ||v.memberCardUserTicketUsed===true">{{v.memberCardUserTicketUsed?'已使用':'未使用'}}</div> -->
            <div class="right_top">
                <span class="type">优惠券</span>
                
                <el-tooltip class="item" effect="dark" :content="v.ticketName" placement="top-start">
                    <span class="label">{{v.ticketName}}</span>
                </el-tooltip>
                <!-- <span class="label">{{['全店通用','店铺分类','指定商品'][v.ticketScope]}}券</span> -->
            </div>
            <div class="period">{{new Date(v.ticketEffectiveTime).format('yyyy-MM-dd')}}---{{new Date(v.ticketDeadTime).format('yyyy-MM-dd')}}</div>
            <div class="Release df-jc-s-b" v-if="source=='E'">
                <div>发布数量 {{v.ticketPubTotal}}</div>
                <!-- <div>已领数量 {{v.ticketGetTotal}}</div> -->
            </div>
            <!-- <div class="f12 c-2">适用商家：{{v.enterpriseShopName||'全平台通用'}}</div> -->
        </div>
    </div>
</template>
<script>

export default {
    name: "coupon",
    props: {
        v: {
            type: Object,
            default: function() {
                return {}
            }
        },
        source:{
            type:String,
            default:'E'// E  A 
        }
       
    },
    data() {
        return {
            aliRts: null,
            video: null,
        }
    },
    mounted() {},
    methods: {
        click(v){
            this.$emit('clickCoupon',v)
        },
    },
}
</script>
<style scoped lang="less">

    .items {

        display: flex;
    }
    .item_top {
        text-align: center;
        color: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(137deg, #5FB3F7 0%, #2090FF 100%);
    }

    .items {
        flex-wrap: wrap;
    }


    .item_box {
        background: #366;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        background-size: 100% 100%;
        display: flex;
        border-radius: 10px;
        overflow: hidden;
    }

    .item {

        margin: 0 15px;
        margin-bottom: 50px;
    }


    .right {
        position: relative;
        padding: 12px;
        width: 100%;
        .right_top {
            display: flex;
            align-items: center;
        }

        .type {
            font-size: 12px;
            display: inline-block;
            padding: 4px 10px;
            background: #2090FF;
            color: #fff;
            border-radius: 20px;
            height: 16px;
            width: 40px;
        }

        .label {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #3A4454;
            margin-left: 4px;
            width: 160px;
            margin-bottom: 0;
            display: inline-block;
            overflow: hidden;//溢出隐藏
            white-space: nowrap;//禁止换行
            text-overflow: ellipsis;//...

        }
    }

    .item_top_left {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        /*color: #ED6732;*/
        /*border-right: 1px solid #E7ECF1;*/
        width: 90px;
    }

    .item_top_right {
        padding-top: 5px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        /*font-weight: bold;*/
        /*color: #555C68;*/
    }

    .period,
    .Release {
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #AAAAAA;
        margin-top: 10px;
    }

    .period span,
    .Release span {
        color: #555C68;
    }
    .use {
        position: absolute;
        top: 8px;
        right: -40px;
        /*text-align: center;*/
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotateZ(45deg);
        background: rgba(102, 102, 102, 0.39);
        font-size: 12px;
        color: #fff;
        padding: 5px 40px;
    }
    .coupon_0 {
        .item_top {

            background: linear-gradient(137deg, #5FB3F7 0%, #2090FF 100%);
        }

        .type {
            background: #2090FF;

        }
    }

    .coupon_-1 {
        .item_top {
            background: #DBDBDB;
        }

        .type {
            background: #DBDBDB;

        }
    }

</style>