// 店铺管理

const shop = [
    {
        path: '/enter/shop',
        name: 'shop',
        component: () => import("@/views/Enterprise/app/Shop/Index.vue"),
        meta:{title:'店铺广告管理'}

    },
    {
        path: '/enter/shopSave',
        name: 'shopSave',
        component: () => import("@/views/Enterprise/app/Shop/Save.vue"),
        meta:{title:'保存店铺广告管理'}

    },

];

export default shop