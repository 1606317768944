import oucy from '@/util/oucyUtil.js'
// 商品订单相关
const schemeOrder = {
	/**
     * 生成订单
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryTmpOrderScheme: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/queryTmpOrderScheme', params)
    },
   	/**
     * 查询订单详情
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryOrderDetail: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/queryOrderDetail', params)
    },
     /**
     * 确认支付
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    confirmOrderScheme: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/confirmOrderScheme', params)
    },
      /**
     * 查询企业销售订单[客户端使用][分页]
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryOrderForEnterprise: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/queryOrderForEnterprise', params)
    },      
    /**
     * 商家发货
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    deliverOrder: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/deliverOrder', params)
    },
    /**
     * 查询订单详情
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryOrderDetail: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/queryOrderDetail', params)
    },
    /**
     * 取消订单
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    cancelOrder: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/cancelOrder', params)
    },
    /**
     * 订单移入/移出回收站
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    recycleOrder: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/recycleOrder', params)
    },
        /**
     * 查询B端用户进货单
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryOrderForUser: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/queryOrderForUser', params)
    },
          /**
     * 用户确认收货
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    confirmReceipt: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/confirmReceipt', params)
    },
    /**
     * 继续发起支付
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    reConfirmOrderScheme: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/reConfirmOrderScheme', params)
    },
    /**
     * 继续发起支付
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryTmpPromptlyOrderScheme: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/queryTmpPromptlyOrderScheme', params)
    },
     /**
     * 留言
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    leaveMessage: (params) => {
        return oucy.postRequest('/client/financial/schemeorder/leaveMessage', params)
    },
   
}

export default schemeOrder
