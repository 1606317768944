// 视频

const video = [
    // 发布视频
    {
        path: '/enter/video',
        name: 'video',
        component: () => import("@/views/Enterprise/app/video/Index.vue"),
        meta: { title: '视频' }
    },
    // // 管理视频
    {
        path: '/enter/videoManagement',
        name: 'videoManagement',
        component: () => import("@/views/Enterprise/app/video/Management.vue"),
        meta: { title: '管理视频' }
    }, 
    // // 管理视频
    {
        path: '/enter/videoCreate',
        name: 'videoCreate',
        component: () => import("@/views/Enterprise/app/video/Create.vue"),
        meta: { title: '视频详情' }
    },


];

export default video