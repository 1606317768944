<template>
    <div class="home">
        <!--公共顶部-->
        <navtop v-if="showNavtop"/>
        <!--路由-->
        <router-view/>
        <navbottom v-if="showButton"/>
        
        <div class="drag" ref="dragWrapper" >
            <signIn class="sign-box" ></signIn>
            <message class="message-box"></message>  
        </div>
       
    </div>

    </div>
</template>

<script>
    import navtop from "../components/Navtop"
    import navbottom from "../components/Navbottom"
    import oucy from "../util/oucyUtil";
     import drug from '../mixin/index';
    export default {
        name: 'Home',
        components: {
            navtop,
            navbottom
        },
        mixins:[drug],
        data() {
            return {
                newTopUrl:['/','/askBuyList','/furniture',''],
                notShowTopNavUrl:['/acc','/enter'],
                filterButtonUrl: ['StockCar','confirmOrder','payOrder','finishOrder','offer'],
                pageWidth: oucy.pageWidth,
                showButton: true,
                showNavtop: true,
            }
        },
        watch:{
            $route:{
                handler(val, oldVal) {
                    for(let i=0;i<this.filterButtonUrl.length;i++){
                      if(val.path.indexOf(this.filterButtonUrl[i])!==-1){
                        this.showButton = false;
                        break;
                      }else{
                        this.showButton = true;
                      }
                    }
                    if(val && val.matched && val.matched.length>1 && val.matched[1].name=='Index'){
                        this.showNavtop=false
                    }else{
                        this.showNavtop=true
                    }
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
            const me = this;
            for(let i=0;i<this.filterButtonUrl.length;i++){
              if(window.location.href.indexOf(this.filterButtonUrl[i])!==-1){
                this.showButton = false;
                break;
              }else{
                this.showButton = true;
              }
            }
            oucy.initMQTT();

           
        },
     
    }
</script>
<style>
    .home {
        text-align: center;
    }
    .pageCenter{
        margin: 0 auto;
    }
    .max1600_min1200{
        width: 100%;
        max-width: 1630px;
        min-width: 1200px;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
    }
    .bg_f{
        background-color: #fff;
    }
    .bg_fa{
         background: #fafafa;;
    }
    .drag{
        position: fixed;
        z-index: 999;
        bottom: 110px;
        width: 100px;
        height: 150px;
        right: 20px;
    }

    .drag .sign-box{
        margin-bottom: 20px; 
    }

    
</style>
