<!-- 内容顶部 还没有用到 -->
<template>
    <div style="background-color: #ffffff;">
        <div class="pageCenter titleBar" :style="{width: pageWidth+'px'}">
            <div class="titleBar_left">
                <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="@/assets/logo.png">
                <div class="titleBar_left_txt">
                    {{tetle}}
                </div>
            </div>
            <div class="titleBar_right" @click="$oucy.replace('/')">
                返回首页
            </div>
        </div>
    </div>
</template>
<script>
    import oucy from "@/util/oucyUtil";
export default {
    name: "pageTop",
    props: {
        tetle: {
            type: String,
            default: '回复',
        },
        

    },
    data() {
        return {
            pageWidth: oucy.pageWidth,
        }
    },
    mounted() {},
    methods: {},
}
</script>
<style scoped lang="less">
    .pageCenter{
        padding-left:30px;
        padding-right:30px;
    }
    .titleBar {
        height: 100px;
        /*background-color: #0366C3;*/
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .titleBar_left {
        /*background-color: #42b983;*/
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .titleBar_left_txt {
        /*background-color: #409EFF;*/
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 50px;
        font-size: 25px;
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px #eeeeee solid;
        color: gray;
    }

    .titleBar_right {
        font-size: 25px;
        color: gray;
    }

    .titleBar_right:hover {
        cursor: pointer;
        color: #000000;
    }

    .breadcrumbBar {
        padding: 10px 0;
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .breadcrumbBar.enterHeadWidth{
        padding:0 60px;
    }
    .el-menu-vertical-demo {
        text-align: left;
        min-height: 700px;
        border-radius: 4px;
    }
    .el-menu-item,.el-submenu__title{
        font-size: 16px;
    }
    .el-menu-item span{
        font-size: 16px;
    }
</style>