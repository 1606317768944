import oucy from '@/util/oucyUtil.js'
// 好友管理
const friend = {
    /**
     * 添加好友
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    addFriend: (params) => {
        return oucy.postRequest('/client/im/friend/addFriend', params,false)
    },
    /**
     * 审核好友申请
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    checkFriend: (params) => {
        return oucy.postRequest('/client/im/friend/checkFriend', params,false)
    },
    /**
     * 删除好友
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    delFriend: (params) => {
        return oucy.postRequest('/client/im/friend/delFriend', params,false)
    },
    /**
     * 获取用户好友列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryFriends: (params) => {
        return oucy.postRequest('/client/im/friend/queryFriends', params,false)
    },
        /**
     * 查询好友关系列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryCheckFriends: (params) => {
        return oucy.postRequest('/client/im/friend/queryCheckFriends', params,false)
    },
    /**
     * 设置好友备注
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    setTheNote: (params) => {
        return oucy.postRequest('/client/im/friend/setTheNote', params,false)
    },
    /**
     * 获取用户好友列表[1、包含分组信息；2、只查询审核通过的好友]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryGroupFriends: (params) => {
        return oucy.postRequest('/client/im/friend/queryGroupFriends', params,false)
    },


}

export default friend
