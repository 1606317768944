import oucy from '@/util/oucyUtil.js'
// 品牌分类
const furnitureGlobalBrand = {
	/**
     * 获取当前客户端用户的所有品牌信息(客户端使用)
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getUserBrands: (params={}) => {
        return oucy.postRequest('/client/product/furnitureglobalbrand/getUserBrands', params)
    },
   	/**
     * 随机返回50条品牌信息
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getBrand: (params={}) => {
        return oucy.postRequest('/client/product/furnitureglobalbrand/getBrand', params)
    },
       	/**
     * 查询企业名下的品牌列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getUserBrands: (params={}) => {
        return oucy.postRequest('/client/product/furnitureglobalbrand/getUserBrands', params)
    },
}

export default furnitureGlobalBrand
