import oucy from '@/util/oucyUtil.js'
// 全局属性
const furnitureGlobalAttr = {
	/**
     * 查询所有的属性不分页[不分页](客户端使用)
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllFurnitureGlobalAttrs: (params) => {
        return oucy.postRequest('/client/product/furnitureglobalattr/getAllFurnitureGlobalAttrs', params,false)
    },

}

export default furnitureGlobalAttr
