import oucy from '@/util/oucyUtil.js'
// 会员卡用户
const membercarduser = {
	/**
     * 查询用户的优惠券数量
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    queryUserUserTicketNum: (params) => {
        return oucy.postRequest('/client/financial/membercarduser/queryUserUserTicketNum', params)
    },
   
}

export default membercarduser
