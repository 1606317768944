<!-- 商品块 加入进货车 可选显示 -->
<template>
    <div>
        <div class="item" @click="$oucy.openNewPage('/product?spuId='+data.id)">
            <img :src="$oucy.ossUrl+data.cover" v-if="data.cover" class="spuImgs">
            <div class="spuImgs" v-else></div>
            <div class="p-l-10 p-t-10 p-r-10 text-left">
                <div class="line-2 c-3 f16 text-left spuName">
                    {{data.spuName}}
                </div>
                <div class="df-jc-s-b df-ai-c m-t-10">
                    <div class="c-m f16 bold ">
                        <span class="price" v-if="data.spuMinPrise*1!=0">
                            ￥{{data.spuMinPrise}}
                        </span>
                        <span class="price" v-else>面议</span>
                    </div>
                    <div class="f14 c-2" v-if="isShowStock">
                        <span v-if="data.brand">{{data.brand.brandName}}</span>
                    </div>
                    <div class="f14 c-2" v-else>
                        <span>关注 ({{data.spuCollect||0}})</span>
                    </div>
                </div>
                    <VIPPrice :data="data"></VIPPrice>
                <div class="m-t-10" v-if="isShowStock">
                    <el-button type="primary" class="" size="mini" plain @click.stop="addStock(data)">加入进货车</el-button>
                </div>
            </div>
        </div>
        <add-stock-dialog ref="addStockDialog"></add-stock-dialog>
    </div>
</template>
<script>
// import { message, schemeOrder } from "@/service/index"
export default {
    name: "spu",
    props: {
        data: {
            type: Object,
            default: function() {
                return {}
            }
        },
        isShowStock: {
            type: Boolean,
            default: true
        }
    },

    mounted() {},
    methods: {
        addStock(v) {
            // console.log(v)
            this.$refs.addStockDialog.open(v.id)
        },
    },
}
</script>
<style scoped lang="less">
.spuImgs {
    width: 100%;
}

.item {
    cursor: pointer;
    background: #fff;
    position: relative;
    padding-bottom: 10px;
}

.spuName {
    height: 42px;
    line-height: 1.4;
}
</style>