<!-- 地图 -->
<template>
    <div @click="open" class="pointer">
        <div class="title line-1 c-1 f14 bold">{{data.positionName}}</div>
        <div class="info c-2 f12 line-1">{{data.address}}</div>
        <div :id="id" class="m-t-5 container pointer"></div>
    </div>
</template>
<script>
export default {
    name: "messageMap",
    props: {
        dataStr: {
            type: String,
            default: '添加店铺',
        },
        id:{
            type:[String,Number]
        }
    },
    data() {
        return {
            mapCenter: [106.532357, 29.57212],
            lists: [],
            activeAddrNames: null,
            state: null,
            finalChooseAddr: null,
            map: null,
            mapVisible: false
        }
    },
    watch: {
        mapVisible(n, o) {
            const me = this;
            if (n) {
                me.activeAddrNames = null;
                me.state = null;
                me.finalChooseAddr = null;
                setTimeout(me.adMap, 500);
            }
        }
    },
    computed:{
        data:function(){
            if(typeof this.dataStr =='string'){
                return JSON.parse(this.dataStr)
            }else{
                return this.dataStr||{}
            }
        }
    },
    mounted() {
        this.adMap()
    },
    methods: {
        adMap: function() {
            const me = this;

/*            if (!!me.map) {
                me.map.setCenter(me.mapCenter);
            } else {*/
                me.map = new AMap.Map(this.id, {
                    zoom: 14, //缩放级别
                    center: [this.data.longitude,this.data.latitude],//me.mapCenter, //设置地图中心点
                    resizeEnable: true,
                    zoomToAccuracy:true,
                    dragEnable:false,
                    zoomEnable:false,
                    doubleClickZoom:false,
                });
            // }
            // 实例化点标记
            this.addMarker()


            //获取初始中心点并赋值
            //此方法是获取当前地图的中心点
            //let currentCenter = me.map.getCenter();
            //将获取到的中心点的纬度经度赋值给data的center
            //me.mapCenter = [currentCenter.lng,currentCenter.lat];

            //根据地图中心点查附近地点，此方法在下方
            // me.centerSearch();
            //监听地图移动事件，并在移动结束后获取地图中心点并更新地点列表
            /*let moveendFun = (e) => {
                // 获取地图中心点
                currentCenter = map.getCenter();
                me.mapCenter = [currentCenter.lng,currentCenter.lat];
                //根据地图中心点查附近地点
                me.centerSearch();
            };*/
            // me.map.InfoWindow({isCustom:true,autoMove:true})
            // 绑定事件移动地图事件
            // me.map.on('moveend', me.moveendFun);

        },
        // 实例化点标记
        addMarker() {
           let marker = new AMap.Marker({
                icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
                position: [this.data.longitude,this.data.latitude],
                offset: new AMap.Pixel(-13, -30)
            });
            marker.setMap(this.map);
        },
        moveendFun: function() {
            const me = this;
            let currentCenter = me.map.getCenter();
            me.mapCenter = [currentCenter.lng, currentCenter.lat];
            me.centerSearch();
        },
        centerSearch: function() {
            const me = this;
            AMap.service(["AMap.PlaceSearch"], () => {
                //构造地点查询类
                let placeSearch = new AMap.PlaceSearch({
                    type: '汽车服务|餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务|公司企业|地名地址信息', // 兴趣点类别
                    pageSize: 50, // 单页显示结果条数
                    pageIndex: 1, // 页码
                    city: "全国", // 兴趣点城市
                    autoFitView: false // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
                });
                //根据地图中心点查附近地点
                placeSearch.searchNearBy('', [me.mapCenter[0], me.mapCenter[1]], 200, (status, result) => {
                    if (status === 'complete') {
                        me.lists = result.poiList.pois;
                        me.activeAddrNames = null;
                    }
                });
            });
        },
        keySearch(search_key) {
            return new Promise((resolve, reject) => {
                AMap.plugin('AMap.Autocomplete', function() {
                    // 实例化Autocomplete
                    var autoOptions = {
                        //city 限定城市，默认全国
                        city: '全国'
                    }
                    var autoComplete = new AMap.Autocomplete(autoOptions);
                    autoComplete.search(search_key, function(status, result) {
                        // 搜索成功时，result即是对应的匹配数据
                        if (status === 'complete') {
                            if (result.count === 0) {
                                resolve([]);
                            } else {
                                resolve(result.tips);
                            }
                        } else {
                            resolve([]);
                        }
                    })
                })
            });
        },
        querySearchAsync(queryString, cb) {
            const me = this;
            if (!!queryString) {
                me.keySearch(queryString).then(res => {
                    cb(res);
                });
            } else {
                cb([]);
            }
        },
        doChoose: function(addr) {
            this.finalChooseAddr = addr;
        },
        handleSearchSelect: function(item) {
            this.mapCenter = [item.location.lng, item.location.lat];
            this.adMap();
        },
        handleClose: function(done) {
            this.$emit('close');
            return done(false);
        },
        handleFinalChoose: function() {
            this.$emit('choose', this.finalChooseAddr);
        },
        open(){

            let url =`https://map.qq.com/?type=marker&isopeninfowin=1&markertype=1&pointx=${this.data.longitude}&pointy=${this.data.latitude}&name=${this.data.positionName}&addr=${this.data.address}&ref=WeChat`
            // let url =`https://www.amap.com/search?query=${this.data.positionName}&city=441200&geoobj=${this.data.longitude}&_src=around&zoom=10.81`
            window.open(encodeURI(url),'_blank')

        },

    },
    beforeDestroy() {
        this.map.destroy && this.map.destroy()
    }
}
</script>
<style scoped>
.map-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.addrList {
    width: 400px;
    height: 400px;
    border-radius: 4px;
    margin: 0 20px 0 0;
    overflow: auto;
}

.container {
    height: 140px;
    flex: 1;
}

.search-addr-name {
    color: #000000;
}

.search-addr-address {
    color: #5c5b75;
    font-size: 12px;
    padding: 0 10px;
}

.search-addr-tel {
    font-style: italic;
}

.addr-foot {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 0 0;
}
</style>