import oucy from '@/util/oucyUtil.js'
// 分组管理
const group = {
    /**
     * 添加好友进分组
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    JoinGroup: (params) => {
        return oucy.postRequest('/client/im/group/JoinGroup', params,false)
    },
    /**
     * 将好友移出分组
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    removeGroup: (params) => {
        return oucy.postRequest('/client/im/group/removeGroup', params,false)
    },
    /**
     * 创建好友分组
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createGroup: (params) => {
        return oucy.postRequest('/client/im/group/createGroup', params,false)
    },
    /**
     * 删除好友分组
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    delGroup: (params) => {
        return oucy.postRequest('/client/im/group/delGroup', params,false)
    },
    /**
     * 查询用户的所有好友分组
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryAllGroupByUser: (params) => {
        return oucy.postRequest('/client/im/group/queryAllGroupByUser', params,false)
    },
        /**
     * 修改好友分组
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateGroup: (params) => {
        return oucy.postRequest('/client/im/group/updateGroup', params,false)
    },


}

export default group