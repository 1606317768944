<!-- 页面顶部 -->
<template>
    <div>
        <!--顶部工具栏-->
        <div style="background-color: #f5f5f5;border-bottom: 1px #eeeeee solid;">
            <div class="navi-menu-container" :class="[statu==4&&hasEnter?'enterHeadWidth':'pageWidth']">
              <div class="navi-menu-container-left">
                <div @click="$oucy.go('/', null)" class="top-nav-title" style="display: flex;align-items: center;margin-right: 15px;cursor: pointer;">
                  <svg class="qyicon" aria-hidden="true" style="color:#0487ff;margin-right: 3px">
                    <use xlink:href="#icon-nav-report-overview"></use>
                  </svg> 乐搜家首页</div>
                <div class="df">
                  <div class="m-r-20 webid"><span class="m-r-10 top-nav-title"><i class="el-icon-location-information"></i>当前站点:</span>
                    <el-select v-model="siteId" placeholder="请选择" style="width: 70px;" size="mini" @change="changeSite">
                      <el-option label="全部" value="null"></el-option>
                      <el-option
                          v-for="item in sites"
                          :key="item.id"
                          :label="item.siteName"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="top-nav-hello" v-if="isLogin">
                    您好，
                    <template v-if="userInfoEntity && userInfoEntity.detailAvatar">
                      <img :src="this.$oucy.ossUrl+userInfoEntity.detailAvatar" class="detailAvatar">
                    </template>
                    <span :title="$oucy.getUserName(userInfoEntity).name">{{$oucy.getUserName(userInfoEntity).nameSubstr}}</span>
                      <!-- width="400" -->
                    <el-popover
                      placement="bottom"
                      v-model="hasShowCode"
                      @show="showCode"
                      trigger="hover">
                      <!-- trigger="click"> -->
                      <div class="df text-center  downloadCodes" v-if="hdQrCode||appletCode">
                        <div class=" downloadCode" v-if="hdQrCode"><img :src="$oucy.ossUrl+hdQrCode" alt="" class="downloadCode_code"><div class="f12 downloadCodeInof">H5二维码</div><el-button @click="download(hdQrCode,'H5二维码')" type="text" size="mini">下载</el-button></div>
                        <div class=" downloadCode" v-if="appletCode"><img :src="$oucy.ossUrl+appletCode" alt="" class="downloadCode_code"><div class="f12 downloadCodeInof">小程序</div><el-button @click="download(appletCode,'小程序')" type="text" size="mini">下载</el-button></div>
                      </div>
                      <div v-else class="downloadCodeInof text-center f12">加载中...</div>
                      <el-button type="text" class="downloadCodesReference" slot="reference"><img src="@/assets/icon/code_icon.png" alt="" class="code_icon"></el-button>
                    </el-popover>

                    ，欢迎来到乐搜家！
                  </div>
                  <div @click="showLoginDialog" class="top-nav-hello" v-else>您好，欢迎来到乐搜家，请登录!</div>
                </div>
              </div>

              <!--左右分割-->

              <div>
                <div v-if="isLogin">
                 <span class="el-dropdown-link" @click="go('/clubCard')">
                      <svg class="icon" aria-hidden="true">
                          <use xlink:href="#custom-icon-1_tequan-23"></use>
                      </svg> 会员中心
                  </span>
                 <span class="el-dropdown-link" @click="go('/liveList')">
                      <svg class="icon" aria-hidden="true">
                          <use xlink:href="#custom-icon-zhibojian"></use>
                      </svg> 直播间
                  </span>
                  <span class="el-dropdown-link" @click="go('/acc/StockCar')">
                      <svg class="icon" aria-hidden="true">
                          <use xlink:href="#custom-icon-mianxinggouwuche"></use>
                      </svg> 进货车({{stockCarNum}})
                  </span>
                  <span>
                      <el-dropdown size="mini">
                          <span class="el-dropdown-link" @click="go('/ReleaseCenter')">
                             <svg class="fbicon" aria-hidden="true">
                          <use xlink:href="#icon-1_qiandan-21"></use>
                           </svg> 发布中心<i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item><div @click="go('/ReleaseCenterAskBuySave')">发布求购</div></el-dropdown-item>

                          </el-dropdown-menu>
                      </el-dropdown>
                  </span>
                  <span>
                      <el-dropdown size="mini">
                          <span class="el-dropdown-link" @click="go('/acc/userinfo')">
                            <svg class="qyicon" aria-hidden="true">
                          <use xlink:href="#icon-a-userpermissions-fill"></use>
                           </svg>
                            用户中心
                            <i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item><div @click="go('/acc/OMS')">订单管理</div></el-dropdown-item>
                              <el-dropdown-item><div @click="go('/Address')">收货地址</div></el-dropdown-item>
<!--                              <el-dropdown-item disabled>预约管理</el-dropdown-item>-->
                              <!-- <el-dropdown-item disabled>我的收藏</el-dropdown-item> -->
                              <el-dropdown-item ><div @click="go('/acc/collection')">我的收藏</div></el-dropdown-item>
                              <el-dropdown-item @click.native="logout">退出账号</el-dropdown-item>
                          </el-dropdown-menu>
                      </el-dropdown>
                  </span>
                  <span  v-if="+userInfoEntity.userIdentity === 3">
                    <el-dropdown size="mini">
                      <span class="el-dropdown-link" @click="go('/acc/enterprise')">
                        <svg class="qyicon" aria-hidden="true">
                          <use xlink:href="#icon-qi"></use>
                        </svg>
                        企业管理
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item><div @click="go('/acc/enterprise')">企业入驻</div></el-dropdown-item>
                        <el-dropdown-item><div @click="go('/acc/enterprise')">企业管理</div></el-dropdown-item>
                        <el-dropdown-item><div @click="go('/enter/product')">产品管理</div></el-dropdown-item>
                        <el-dropdown-item><div @click="go('/enter/OMS')">订单管理</div></el-dropdown-item>
<!--                        <el-dropdown-item disabled>预约管理</el-dropdown-item>-->
                      </el-dropdown-menu>
                    </el-dropdown>
                  </span>
                  <span >
                    <el-dropdown size="mini">
                      <span class="el-dropdown-link">
                        <svg class="qyicon" aria-hidden="true">
                          <use xlink:href="#icon-nav-collapsed"></use>
                        </svg>
                        快速导航<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item><div @click="go('/liveList')"><svg class="icon" aria-hidden="true">
                          <use xlink:href="#custom-icon-zhibojian"></use>
                      </svg>直播间</div></el-dropdown-item>
                        <el-dropdown-item><div @click="go('/download')"><svg class="icon" aria-hidden="true" style="color: #0366C3">
                          <use xlink:href="#custom-icon-caiyouduo_shouye-appxiazai"></use>
                      </svg>APP下载</div></el-dropdown-item>
                        <!--                        <el-dropdown-item disabled>签到中心</el-dropdown-item>
                                               <el-dropdown-item disabled>厂家活动</el-dropdown-item>
                                               <el-dropdown-item disabled>在线展会</el-dropdown-item>
                                               <el-dropdown-item disabled>地图搜店</el-dropdown-item>
                                               <el-dropdown-item disabled>短视频</el-dropdown-item>
                                               <el-dropdown-item disabled>优惠券</el-dropdown-item> -->
                      </el-dropdown-menu>
                    </el-dropdown>
                  </span>

                </div>
                <div class="top-nav-entrance" v-else>
                  <span>
                    <el-button type="text" @click="showLoginDialog">注册或登录</el-button>
<!--                    <el-button type="text" @click="go('/ReleaseCenterSave')">发布求购信息</el-button>-->
                  </span>
                  <span >
                    <el-dropdown size="mini">
                      <span class="el-dropdown-link">
                        <svg class="qyicon" aria-hidden="true">
                          <use xlink:href="#icon-nav-collapsed"></use>
                        </svg>
                        快速导航<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item><div @click="go('/liveList')">直播间</div></el-dropdown-item>
                        <el-dropdown-item><div @click="go('/download')">APP下载</div></el-dropdown-item>
                        <!--                        <el-dropdown-item disabled>签到中心</el-dropdown-item>
                                               <el-dropdown-item disabled>厂家活动</el-dropdown-item>
                                               <el-dropdown-item disabled>在线展会</el-dropdown-item>
                                               <el-dropdown-item disabled>地图搜店</el-dropdown-item>
                                               <el-dropdown-item disabled>短视频</el-dropdown-item>
                                               <el-dropdown-item disabled>优惠券</el-dropdown-item> -->
                      </el-dropdown-menu>
                    </el-dropdown>
                  </span>
                </div>
              </div>
            </div>
        </div>

        <register :isVisible="dialog_regiform_visible" @close="handleRegisterClose" @goLogin="handleGoLogin"/>
        <login :isVisible="dialog_loginform_visible" @loginSuccess="handleLoginSuccess" @openBindPhone="openBindPhone" @goRegister="handleGoRegister"
               @close="handleLoginClose"/>
        <bind-phone :isVisible="isShowBindPhone" @closeBindPhone="closeBindPhone"></bind-phone>

    </div>
</template>

<script>
    import oucy from "../util/oucyUtil"
    import register from './Register.vue'
    import login from './Login.vue'
    import bindPhone from './bindPhone.vue'
    import {localSet, localGet, localDel} from "../store/store";
    import {logicSite,dataNum,userMaterial,playbill } from "@/service"
    import {downloadfile} from '@/util/downloadfile'
   
    export default {
        name: 'navtop',
        components: {
            register,
            bindPhone,
            login
        },
        data() {
            return {
                isLogin: false,
                userInfoEntity: null,
                dialog_regiform_visible: false,
                dialog_loginform_visible: false,
                pageWidth: oucy.pageWidth,
                siteId:'全部',
                isShowBindPhone:false,
                sites:[],
                stockCarNum:null,
                appletCode:null,
                hdQrCode:null,
                hasShowCode:false,
            }
        },
        computed:{
            statu(){
                return this.$store.state.statu
            },
            hasEnter(){
                return (this.$route.path.includes('enter') || this.$route.path.includes('spupgrade'))&&!this.$route.path.includes('ReleaseCenter')
            }
        },
        mounted() {
            this.getAllLogicSite()
            const me = this;
            //初始化登录数据
            this.initLoginStatus();
            //监听登录事件
            me.eventBus.$off(me.$oucy.msgKeys.UPDATA_STOCK_CAR_NUM).$on(me.$oucy.msgKeys.UPDATA_STOCK_CAR_NUM,(res)=>{
                console.log('更新进货车数量');
                 me.queryStockNum()
            });
            //监听登录事件
            me.eventBus.$off(me.$oucy.msgKeys.LOCAL_LOGIN).$on(me.$oucy.msgKeys.LOCAL_LOGIN,(res)=>{
                console.log('登录事件：');
                me.isLogin=false
                me.showLoginDialog();
            });
            //监听更新登录用户数据事件
            me.eventBus.$off(me.$oucy.msgKeys.UPDATE_LOGIN_USER_INFO).$on(me.$oucy.msgKeys.UPDATE_LOGIN_USER_INFO,(res)=>{
                console.log('更新登录用户数据事件：');
                me.initLoginStatus();
                me.queryStockNum()
            });
            
        },
        methods: {
            showRegiDialog() {
                this.dialog_regiform_visible = true;
            },
            handleRegisterClose: function () {
                this.dialog_regiform_visible = false;
            },
            showLoginDialog() {
                this.dialog_loginform_visible = true;
            },
            handleLoginClose: function () {
                this.dialog_loginform_visible = false;
            },
            handleGoRegister: function () {
                this.handleLoginClose();
                this.showRegiDialog();
            },
            handleGoLogin:function(){
                this.handleRegisterClose();
                this.showLoginDialog();
            },
            handleLoginSuccess: function () {
                this.handleLoginClose();
                this.initLoginStatus();
            },
            logout: function () {
                const me = this;
                this.$confirm('即将退出登录, 是否继续?', '提示', {
                  confirmButtonText: '继续退出',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  oucy.logout();
                  me.initLoginStatus();
                  oucy.app.$router.replace("/").then(res=>{
                    location.reload();
                  });
                  localDel('enterprise')
                  this.$message({
                    type: 'success',
                    message: '退出成功!'
                  });
                  oucy.receiveUserRecordEvent({record:'退出登录'})
                  oucy.app.eventBus.$emit(oucy.msgKeys.LOGOUT_SUCCESS, null);
                }).catch(() => {});

            },
            initLoginStatus: function () {
                const me = this;
                let userInfoEntity = oucy.getLocalUserEntity();
                if (oucy.getToken() && userInfoEntity != null) {
                    userMaterial.queryUserMaterial().then(res=>{
                      if(res && res.id){
                        me.isLogin = true;
                        me.userInfoEntity = res;
                        me.queryStockNum()
/*                        if(oucy.thisUUID!=res.id){
                           oucy.thisUUID=res.id
                           oucy.initMQTT();
                           console.log('重新更新 MQTT')
                        }*/
                      }else{
                        oucy.removeToken()   
                        me.isLogin = false;
                        me.userInfoEntity = null;                     
                        localDel(this.$oucy.userInfoKey);
                        
                      }
                    })
                } else {
                    localDel(this.$oucy.userInfoKey);
                    me.isLogin = false;
                    me.userInfoEntity = null;
                }
            },
            go: function (path) {
                this.$router.push(path)
            },
            getAllLogicSite(){
                logicSite.getAllLogicSite({siteLatitude:null,siteLongtitude:null,a:123}).then(res=>{
                    this.sites=res||[]
                    let siteId = localGet('siteId')
                    if(siteId){
                        this.siteId=siteId
                    }
                    // this.sites.unshift({siteName:'全部',id:null})
                })
            },
            changeSite(){
              if(this.siteId){
                  localSet('siteId', this.siteId)
              }else{
                  this.siteId=null
                  localDel('siteId')
              }
                let path=this.$route.path
                oucy.receiveUserRecordEvent({record:'切换站点'+this.siteId})
                if(path=='/furniture'||path=='/material'||path=='/enterprise'||path=='/company'){
                    this.$confirm('你已切换站点, 是否重新加载数据?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                     location.reload();
                    }).catch(() => {
 
                    });
                }
            },
            openBindPhone(){
               this.isShowBindPhone=true 
            },
            closeBindPhone(){
               this.isShowBindPhone=false 
            },
            queryStockNum(){
              dataNum.queryStockNum({}).then(res=>{
                this.stockCarNum=res||0
              })
            },
            showCode(){
              if(!this.appletCode || !this.hdQrCode){
                this.generateQrCode()
              }
            },
            generateQrCode(){
              console.log('generateQrCode')
              console.log('generateQrCode')
              console.log(this.userInfoEntity)
              console.log('generateQrCode')
              console.log('generateQrCode')
              // appletCode  小程序二维码页面跳转地址    false 
              // hdQrCode  普通二维码地址   false 
              // scene 小程序二维码参数字符串

              let userInfo = this.userInfoEntity||{}
              if(!userInfo.id) return
              let path= 'pages/index/index'
              playbill.generateQrCode({
                appletCode:path,
                hdQrCode:`https://h5.lesoujia.com/#/${path}?parentId=${userInfo.id}`,
                scene:JSON.stringify({ parentId: userInfo.id }),
              }).then(res=>{
                if (res) {
                  this.hasShowCode=true
                  this.appletCode = res.appletCode         
                  this.hdQrCode =  res.hdQrCode         
                }
              })
            },
            download(url,name){   
        
               let a = document.createElement("a");
               a.download = `${this.$oucy.getUserName(this.userInfoEntity).name}_个人高清_${name}`;
               a.setAttribute('href',this.$oucy.ossUrl+url)
               a.setAttribute('target','_blank')
               a.click(); 
            },
            RequestImg(imgurl){
              let me=this
              let request = new XMLHttpRequest()
              request.responseType = 'blob'
              // 传入文件链接
              // request.open('GET', this.tokenData.serverUrl + item.path)
              request.open('GET', imgurl)
              request.onload = function () {
                let url = window.URL.createObjectURL(this.response)
                let a = document.createElement('a')
                document.body.appendChild(a)
                a.href = url
                a.download =  `${me.$oucy.getUserName(me.userInfoEntity).name}_个人高清_${name}`;
                a.click()
              }
              request.send()
            },
            downloadIamge(imgsrc, name) {
                //下载图片地址和图片名
                var image = new Image();
                // 解决跨域 Canvas 污染问题
                image.setAttribute('crossOrigin', 'anonymous');
                image.onload = function () {
                    var canvas = document.createElement('canvas');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    var context = canvas.getContext('2d');
                    context.drawImage(image, 0, 0, image.width, image.height);
                    var _dataURL = canvas.toDataURL('image/png'); //得到图片的base64编码数据

                    var blob_ = dataURLtoBlob(_dataURL); // 用到Blob是因为图片文件过大时，在一部风浏览器上会下载失败，而Blob就不会

                    var url = {
                        name: name || "图片.png", // 图片名称不需要加.png后缀名
                        src: blob_
                    };

                    if (window.navigator.msSaveOrOpenBlob) {   // if browser is IE
                        navigator.msSaveBlob(url.src, url.name);//filename文件名包括扩展名，下载路径为浏览器默认路径
                    } else {
                        var link = document.createElement("a");
                        link.setAttribute("href", window.URL.createObjectURL(url.src));
                        link.setAttribute("download", url.name + '.png');
                        document.body.appendChild(link);
                        link.click();
                    }
                };
                image.src = imgsrc;

                function dataURLtoBlob(dataurl) {
                    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    return new Blob([u8arr], {type: mime});
                }
            }


        }
    }
    
</script>
<style scoped>
.top-nav-title{
  font-size: 12px;
  color: #999;
}
.top-nav-hello {
  text-align: left;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #999;
}

.top-nav-hello .detailAvatar{
  width:20px;
  height:20px;
  border-radius: 4px;
  margin: 0 5px 0 0;
}

.top-nav-hello a{
  text-decoration: none;
  color:#999;
}
.top-nav-entrance {
  text-align: right;
}

.top-nav-entrance span {
  padding: 0 3px;
  /*color: green;*/
  font-size: 12px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #999;
  font-size: 12px;
  padding: 0 6px;

}

.el-icon-arrow-down {
  font-size: 12px;
}

.navi-menu-container {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 1200px;
}

.navi-menu-container-left{
  display: flex;
}
.downloadCodes{

}
.downloadCode{
  margin:10px;
}
.downloadCode +.downloadCode{
  margin-left: 10px;
}
.downloadCodeInof{
  color: #555C68;
  margin-top: 10px;
}
.downloadCode_code{
  width: 120px;
  height: 120px;
  border: solid #f9f9f9 1px;
}
.code_icon{
  width: 20px;
  height: 20px;
}
.downloadCodesReference{
  vertical-align: middle;
  margin-left: 10px;
}
</style>
<style>
.top-nav-hello {
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.top-nav-hello img{
  width:30px;
  height:30px;
  border-radius: 4px;
  margin: 0 5px 0 0;
}

.top-nav-hello a{
  text-decoration: none;
  color:#2090FF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.navi-menu-container {
  line-height: 50px;
  margin: 0 auto;
}

.navi-menu {
  padding: 0;
  background-color: #ffffff;
  margin-top: 50px;
}

.navi-menu-ele {
  padding: 10px;
  font-size: 18px;
  border: 1px #ffffff solid;
  border-bottom: 2px #ffffff solid;
  font-family: "Microsoft YaHei";
}

.navi-menu-ele:hover {
  cursor: pointer;
  color: #0366c3;
  /*border-bottom: 3px #0366c3 solid;*/
  background-color: #0366c3;
  color: #fff;
}

.el-button--text{
  color:black;
}
.webid .el-input__inner{
  border: 0px solid #2a2c33;
  border-color: #f5f5f5;
}
.icon{
  width: 22px;
  height: 22px;
  vertical-align: -0.3em;
  fill: currentColor;
  overflow: hidden;
}
.qyicon{
  width: 14px;
  height: 14px;
  vertical-align: -0.25em;
  fill: currentColor;
  overflow: hidden;
}
.fbicon{
  width: 22px;
  height: 22px;
  vertical-align: -0.5em;
  fill: currentColor;
  overflow: hidden;
}

</style>
