<!-- 搜索框 下拉版 -->

<template>
    <div class="search">
        <el-autocomplete v-model="keyword" :fetch-suggestions="querySearchAsync" @change="keywordFiltr('ourShop')" :placeholder="placeholder" @select="handleSelect" style="width: 400px;">
            <el-select v-model="category" slot="prepend" style="width: 100px;">
                <el-option label="搜家具" :value="0"></el-option>
                <el-option label="搜材料" :value="1"></el-option>
                <el-option label="搜企业" :value="2"></el-option>
                <el-option label="搜名片" :value="3"></el-option>
                <!-- <el-option label="搜物流" :value="4"></el-option> -->
                <el-option label="搜求购" :value="5"></el-option>
            </el-select>
        </el-autocomplete>
        <el-button @click="keywordFiltr('ourShop')" class="rightBtn m-l-10" type="primary">搜本店</el-button>
        <el-button @click="keywordFiltr('clickSearch')" class="rightBtn" type="primary">搜全站</el-button>
    </div>
</template>
<script>
import { spu } from "@/service"
export default {
    name: "searchProduct2",
    props: {

    },
    data() {
        return {
            // 关键字
            keyword: null,
            timeoutId: null,
            raw_query:null,
            old_keyword: null,
            // 分类
            category: 0,
            placeholder: '请输入家具名称（例如：红木家具）',
            placeholderList: ['请输入家具名称（例如：红木家具）', '输入材料名称（如：岩板）', '输入企业名称搜索（例如：问一得三）', '输入公司名称或个人姓名搜索名片（例如：问一得三）', '暂不可用' ,'输入搜索关键词：（如轻奢 或 办公）'],

            searchClassify: "furniture",
            restaurants: [],
        }
    },
    mounted() {
        if(this.$route.query.keyword){
            this.keyword=this.$route.query.keyword
        }
    },
    methods: {
        setCategory(v) {
            this.category = v
            this.placeholder = this.placeholderList[v]

        },
        // 点搜索
        keywordFiltr(fn){
            this.$oucy.keywordFiltr(this.keyword).then(res=>{
               this[fn]() 
            },err=>{})
        },
        clickSearch: function() {

            // if(this.keyword!=null && this.keyword===this.old_keyword){
            //     console.log('已查询出当前关键字结果')
            //     return
            // }
            const me = this;
            console.log(this.searchClassify)
            if(this.category=='0'){
                if(!this.keyword){
                    this.$message.error('请输入要搜索的内容');
                    return
                }
                me.$router.replace('/SearchFurniture?keyword='+this.keyword+'&raw_query='+this.raw_query);
            }else
            if(this.category=='1'){
                if(!this.keyword){
                    this.$message.error('请输入要搜索的内容');
                    return
                }
                me.$router.replace('/SearchMaterial?keyword='+this.keyword+'&raw_query='+this.raw_query);
            }else
            if(this.category=='2'){
                me.$router.replace('/searchenterprise?keyword='+this.keyword+'&raw_query='+this.raw_query);
            }else
            if(this.category=='3'){
                me.$router.replace('/SearchCard?keyword='+this.keyword+'&raw_query='+this.raw_query);
            }else
            if(this.category=='5'){
                me.$router.replace('/SearchAskBuy?keyword='+this.keyword+'&raw_query='+this.raw_query);
            }
            this.old_keyword=this.keyword
            
        },
        // 搜本店
        ourShop(){
            this.$emit('ourShop',{keyword:this.keyword})
                
        },
        // 关键字触发
        querySearchAsync(queryString, cb) {
           this.timeoutId&& clearInterval(this.timeoutId)
            if(this.category>1){
                cb([]);
                return
            }
            if(!this.keyword ||!queryString){
                cb([]);
                return
            }
            this.timeoutId = setTimeout(() => {
                spu.searchProductSuggest({category:this.category,keyword:queryString}).then(res => {
                    this.raw_query=queryString
                    var restaurants = []
                    if(res&&res.suggestions){
                        for(let v of res.suggestions){
                         restaurants.push({value:v})   
                        }
                    }
                    cb(restaurants);
                })
            }, 1000);
        },
        // 点选提示触发
        handleSelect(item) {
            console.log(item);
            this.keyword = item.value
            this.clickSearch()
        },
    }
}
</script>
<style >
    
/*.
    width: 700px;
}*/
</style>
<style scoped>
.search {
    position: relative;
}
.btns {
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.btn {
    border-radius: 4px 4px 0px 0px;
    padding: 5px 10px;
    margin: 0 15px;
    cursor: pointer;
}

.btn.select {
    background: #0366C3;
    color: #fff;

}

.search /deep/ .el-input-group__append button.el-button,
.el-input-group__append div.el-select .el-input__inner,
.el-input-group__append div.el-select:hover .el-input__inner,
.el-input-group__prepend button.el-button,
.el-input-group__prepend div.el-select .el-input__inner,
.el-input-group__prepend div.el-select:hover .el-input__inner {
    background: #0366C3;
    color: #fff;
    /*border-radius: 0 6px 6px 0;*/
    /*border:1px solid #0366C3;*/
}

.search /deep/ .el-input__inner {
    /*border-color: #0366C3;*/
    /*border:2px solid #0366C3;*/
    /*background: transparent;*/
}
</style>