import oucy from '@/util/oucyUtil.js'
// 用户鉴权
const userAuth = {
	/**
     * 查询客户端用户的所有企业
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getEnterpriseByUser: (params) => {
        return oucy.postRequest('/client/enterprise/enterpriseinfo/getEnterpriseByUser', params)
    },
   	/**
     * 判断用户是否有进行企业入驻或者买家身份认证[客户端使用]
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    isEnterpriseOrBuyAuth: (params) => {
        return oucy.postRequest('/client/user/userauth/isEnterpriseOrBuyAuth', params)
    },
   	/**
     * 发送绑定手机验证码
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    bindPhoneSendCode: (params) => {
        return oucy.postRequest('/client/user/userauth/bindPhoneSendCode', params)
    },
   	/**
     * 设置用户账号的手机号码
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    phInfoSetup: (params) => {
        return oucy.postRequest('/client/user/userauth/phInfoSetup', params)
    },
   	/**
     * 设置客户端用户的默认企业
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    setUserDefaultEnterprise: (params) => {

        return oucy.postRequest('/client/enterprise/enterpriseinfo/setUserDefaultEnterprise', params)
        // return oucy.postRequest('/client/user/userauth/setUserDefaultEnterprise', params)
    },
   	/**
     * 查询所有的用户列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllUser: (params) => {
        return oucy.postRequest('/client/user/userauth/getAllUser', params)
    },
    /**
     * 获取微信扫码授权[信息绑定场景]二维码地址
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryUserBindScanAddress: (params) => {
        return oucy.postRequest('/client/user/userauth/queryUserBindScanAddress', params)
    },
    /**
     * 获取微信扫码授权[后台登录场景]二维码地址
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryUserLoginScanAddress: (params) => {
        return oucy.postRequest('/client/user/userauth/queryUserLoginScanAddress', params)
    },
    /**
     * 获取微信扫码授权[实名登录场景]二维码地址
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryUserRealAvataScanAddress: (params) => {
        return oucy.postRequest('/client/user/userauth/queryUserRealAvataScanAddress', params)
    },

    /**
     * 查询用户是否有聊天、拨号的权限 返回true - false
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryUserBuyAskActionIsAuth: (params) => {
        return oucy.postRequest('/client/buyask/buyask/queryUserBuyAskActionAuth', params)
    }
}

export default userAuth
