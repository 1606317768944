import oucy from '@/util/oucyUtil.js'
// 招标信息
const bidding = {
	/**获取所有招标信息列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
     getAllTender: (params) => {
        return oucy.postRequest('/client/auth/adtender/getAllTender', params)
    },
	/**根据招标编码获取招标详细信息
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
     getTenderById: (params) => {
        return oucy.postRequest('/client/auth/adtender/getTenderById', params)
    },
}

export default bidding