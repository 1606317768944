<!-- 直播状态 -->
<template>
    <div class="liveType">
        <!-- 0未开播 1暂停 2禁止开播 3开播中 4已结束 5预告中 -->
        <el-button  @click="goto" type="primary" icon="el-icon-s-data" size="mini" v-if="data.roomStatus==0">预告中</el-button>
        <el-button  @click="goto" type="primary" icon="el-icon-s-data" size="mini" v-if="data.roomStatus==1">暂停中</el-button>
        <el-button  @click="goto" type="primary" icon="el-icon-s-data" size="mini" v-if="data.roomStatus==3">直播中</el-button>
        <!-- <el-button type="danger" icon="el-icon-time" size="mini" v-if="data.roomStatus==5">预告中</el-button> -->
        <el-button type="info" icon="el-icon-time" size="mini" v-if="!data.roomVideoEnable && data.roomStatus==4">已结束</el-button>
        <el-button  @click="goto" type="warning" icon="el-icon-refresh-left" size="mini" v-if="data.roomVideoEnable&& data.roomStatus==4">去回看</el-button>
    </div>
</template>
<script>
export default {
    name: "liveType",
    props: {
        data: {
            type: Object,
            default: function() {
                return {}
            }
        },

    },
    data() {
        return {
            status:1
        }
    },
    mounted() {},
    methods: {
        goto(){
            console.log(this.$route)
            if(this.$route.path=='/live'){
                return
            }
            if(this.data.roomAuthStatus==1){
                this.$oucy.go('/live?id='+this.data.id)
            }else{
                this.$alert('直播还没通过审核，请耐心等待')
            }
          // this.$oucy.go('/live?id='+this.data.id)  
        },
    },
}
</script>
<style scoped lang="less">
</style>