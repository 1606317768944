import oucy from '@/util/oucyUtil.js'
// 个人名片
const cardPerson = {

    /**
     * 查询个人名片列表
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getCardPersonList: (params) => {
        return oucy.postRequest('/client/card/cardperson/getCardPersonList', params)
    },
    /**
     * 获取个人名片的详细信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getCardPersonDetail: (params) => {
        return oucy.postRequest('/client/card/cardperson/getCardPersonDetail', params)
    },
    /**
     * 发布个人名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createCardPerson: (params) => {
        return oucy.postRequest('/client/card/cardperson/createCardPerson', params)
    },
    /**
     * 修改自己个人名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateCardPerson: (params) => {
        return oucy.postRequest('/client/card/cardperson/updateCardPerson', params)
    },
        /**
     * 获取本本本本本本本本本本本本本本本本本本本本本本本本本本本本本人名片的详细信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getMyCardPersonDetail: (params) => {
        return oucy.postRequest('/client/card/cardperson/getMyCardPersonDetail', params)
    },
            /**
     * 点赞个人名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    savelikeAddTime: (params) => {
        return oucy.postRequest('/client/card/cardperson/savelikeAddTime', params)
    },            /**
     * 收藏个人名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    collectCardPerson: (params) => {
        return oucy.postRequest('/client/card/cardperson/collectCardPerson', params)
    },
    /* 增加个人名片转发
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    saveTransmitAddTime: (params) => {
        return oucy.postRequest('/client/card/cardperson/saveTransmitAddTime', params)
    },
   
}

export default cardPerson
