import oucy from '@/util/oucyUtil.js'
// 直播管理
const liveroom = {
    /**
     * 查询用户的直播列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryUserLiveRoom: (params) => {
        return oucy.postRequest('/client/im/liveroom/queryUserLiveRoom', params,false)
    },
    /**
     * 查询所有的直播列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryAllLiveRoom: (params) => {
        return oucy.postRequest('/client/im/liveroom/queryAllLiveRoom', params,false)
    },
    /**
     * 创建直播间
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createLiveRoom: (params) => {
        return oucy.postRequest('/client/im/liveroom/createLiveRoom', params,false)
    },
    /**
     * 根据直播编码查询详细信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getLiveRoom: (params) => {
        return oucy.postRequest('/client/im/liveroom/getLiveRoom', params,false)
    },
    /**
     * 查询我的直播详细信息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getMyLiveRoom: (params) => {
        return oucy.postRequest('/client/im/liveroom/getMyLiveRoom', params,false)
    },
    /**
     * 修改直播间
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    modifyLiveRoom: (params) => {
        return oucy.postRequest('/client/im/liveroom/modifyLiveRoom', params,false)
    },
    /**
     * 删除直播间
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    delLiveRoom: (params) => {
        return oucy.postRequest('/client/im/liveroom/delLiveRoom', params,false)
    },
    /**
     * 获取推流地址
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getPushStream: (params) => {
        return oucy.postRequest('/client/im/liveroom/getPushStream', params,false)
    },
    /**
     * 获取拉流地址
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getLiveStream: (params) => {
        return oucy.postRequest('/client/im/liveroom/getLiveStream', params,false)
    },
    /**
     * 发送直播消息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    sendLiveRoomMessage: (params) => {
        return oucy.postRequest('/client/im/liveroom/sendLiveRoomMessage', params, false)
    },
    /**
     * 搜索直播间消息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryLiveRoomMessage: (params) => {
        return oucy.postRequest('/client/im/liveroom/queryLiveRoomMessage', params,false)
    },
    /**
     * 直播间禁言用户
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    bannedLiveMessage: (params) => {
        return oucy.postRequest('/client/im/liveroom/bannedLiveMessage', params,false)
    },
    /**
     * 直播间踢人
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    kickingLiveUser: (params) => {
        return oucy.postRequest('/client/im/liveroom/kickingLiveUser', params,false)
    },
    /**
     * 删除直播聊天室消息
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    delLiveMessage: (params) => {
        return oucy.postRequest('/client/im/liveroom/delLiveMessage', params,false)
    },
    /**
     * 直播间修改助理
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateRoomAssistant: (params) => {
        return oucy.postRequest('/client/im/liveroom/updateRoomAssistant', params,false)
    },
    /**
     * 直播操作 直播操作 0暂停 1禁止 2开播 3结束 4恢复
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    settingLiveAction: (params) => {
        return oucy.postRequest('/client/im/liveroom/settingLiveAction', params,false)
    },
    /**
     * 获取直播间正在直播列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getLiveOnlineList: (params) => {
        return oucy.postRequest('/client/im/liveroom/getLiveOnlineList', params,false)
    },
    /**
     * 验证直播间的密码
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getVerifyLiveRoomPassword: (params) => {
        return oucy.postRequest('/client/im/liveroom/getVerifyLiveRoomPassword', params,false)
    },
    /**
     * 获取直播间黑名单
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getLiveRoomBlackList: (params) => {
        return oucy.postRequest('/client/im/liveroom/getLiveRoomBlackList', params,false)
    },
        /**
     * 删除黑名单
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    delLiveRoomBlack: (params) => {
        return oucy.postRequest('/client/im/liveroom/delLiveRoomBlack', params,false)
    },
        /**
     * 禁言列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryBanUserList: (params) => {
        return oucy.postRequest('/client/im/liveroom/queryBanUserList', params,false)
    },
            /**
     * 直播间回放
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    liveRoomRecord: (params) => {
        return oucy.postRequest('/client/im/liveroom/liveRoomRecord', params,false)
    },
            /**
     * 热播推荐
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    queryHotLiveRoomList: (params) => {
        return oucy.postRequest('/client/im/liveroom/queryHotLiveRoomList', params,false)
    },


}

export default liveroom