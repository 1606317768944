import oucy from '@/util/oucyUtil.js'
// 员工名片管理
const cardEmployee = {


    /**
     * 查询员工名片列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getCardEmployeeList: (params) => {
        return oucy.postRequest('/client/card/cardemployee/getCardEmployeeList', params)
    },
    /**
     * 查询员工名片详细
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getEmployeeDetail: (params) => {
        return oucy.postRequest('/client/card/cardemployee/getEmployeeDetail', params)
    },
    /**
     * 删除员工名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    delCardEmployee: (params) => {
        return oucy.postRequest('/client/card/cardemployee/delCardEmployee', params)
    },
    /**
     * 创建员工名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createCardEmployee: (params) => {
        return oucy.postRequest('/client/card/cardemployee/createCardEmployee', params)
    },
    /**
     * 编辑员工名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateCardEmployee: (params) => {
        return oucy.postRequest('/client/card/cardemployee/updateCardEmployee ', params)
    },
    /**
     * 查询回收站员工名片列表
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getRecycleCardEmployeeList: (params) => {
        return oucy.postRequest('/client/card/cardemployee/getRecycleCardEmployeeList ', params)
    },
    /**
     * 彻底删除员工名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    deepCardEmployee: (params) => {
        return oucy.postRequest('/client/card/cardemployee/deepCardEmployee ', params)
    },
    /**
     * 恢复员工名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    recoverCardEmployee: (params) => {
        return oucy.postRequest('/client/card/cardemployee/recoverCardEmployee ', params)
    },
        /**
     * 增加员工名片分享数
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    addCardEmployeeTransmit: (params) => {
        return oucy.postRequest('/client/card/cardemployee/addCardEmployeeTransmit', params)
    },
            /**
     * 点赞员工名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    savelikeAddTime: (params) => {
        return oucy.postRequest('/client/card/cardemployee/savelikeAddTime', params)
    },
            /**
     * 收藏员工名片
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    collectCardEmployee: (params) => {
        return oucy.postRequest('/client/card/cardemployee/collectCardEmployee', params)
    },

}

export default cardEmployee