import oucy from '@/util/oucyUtil.js'
// 进货车控制器
const stock = {
    /**
     * 创建方案[客户端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createSchema: (params) => {
        return oucy.postRequest('/client/product/stock/createSchema', params)
    },
    /**
     * 修改方案基本信息[客户端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    modifySchemaBasicInfo: (params) => {
        return oucy.postRequest('/client/product/stock/modifySchemaBasicInfo', params)
    },
    /**
     * 获取当前用户名下的所有进货车方案[客户端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getAllScheme: (params) => {
        return oucy.postRequest('/client/product/stock/getAllScheme', params)
    },
    /**
     * 删除方案[客户端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    delSchema: (params) => {
        return oucy.postRequest('/client/product/stock/delSchema', params)
    },
    /**
     * 添加商品[客户端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    appendSku: (params) => {
        return oucy.postRequest('/client/product/stock/appendSku', params)
    },
    /**
     * 根据scheme编码查询进货车方案详细信息[客户端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getBySchemeId: (params) => {
        return oucy.postRequest('/client/product/stock/getBySchemeId', params)
    },
    /**
     * 移除商品[客户端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    removeItem: (params) => {
        return oucy.postRequest('/client/product/stock/removeItem', params)
    },
    /**
     * 清除失效商品[客户端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    clearInvalidItems: (params) => {
        return oucy.postRequest('/client/product/stock/clearInvalidItems', params)
    },
    /**
     * 修改商品数量[客户端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    updateItemCount: (params) => {
        return oucy.postRequest('/client/product/stock/updateItemCount', params)
    },
     /**
     * 
     * 调整方案总金额[客户端使用]
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    adjustPriseWithDiscount: (params) => {
        // 折扣额度
        return oucy.postRequest('/client/product/stock/adjustPriseWithDiscount', params)
    },
    adjustPriseWithReduce: (params) => {
        // 减去金额
        return oucy.postRequest('/client/product/stock/adjustPriseWithReduce', params)
    },
    adjustPriseWithTotalPrise: (params) => {
        // 调整后总金额
        return oucy.postRequest('/client/product/stock/adjustPriseWithTotalPrise', params)
    },

    // 设置方案物料单基本信息
    setupSchemaMaterial: (params) => {
        return oucy.postRequest('/client/product/stock/setupSchemaMaterial', params)
    },
    
    // 报价清单导出Excel
    exportExcel: (params) => {
        return oucy.postRequest('/client/product/stock/exportExcel', params)
    },
    
}

export default stock