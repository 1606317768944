<!-- 搜索框 上面tab版 -->
<template>
    <div class="input-container search">
        <div class="btns c-1">
            <div class="btn" :class="{'select':category==0}" @click="setCategory(0)">搜家具</div>
            <div class="btn" :class="{'select':category==1}" @click="setCategory(1)">搜材料</div>
            <div class="btn" :class="{'select':category==2}" @click="setCategory(2)">搜企业</div>
            <div class="btn" :class="{'select':category==3}" @click="setCategory(3)">搜名片</div>
            <div class="btn" :class="{'select':category==5}" @click="setCategory(5)">搜求购</div>
        </div>
        <el-autocomplete v-model="keyword" :fetch-suggestions="querySearchAsync" :placeholder="category<2 && placeholderHints?placeholderHints:placeholder" @select="handleSelect" @change="keywordFiltr('clickSearch')" style="width: 885px;padding-bottom: 10px;">
            <template v-if="category<2">
                <img class="el-icon-camera-solid pointer f26 camera" slot="suffix" @click="clickCamera" :src="$oucy.ossUrl +searchImageUrl" alt="" v-if="searchImageUrl">
                <i class="el-icon-camera-solid pointer f26 camera" slot="suffix" @click="clickCamera" v-else></i>
            </template>
            <el-button @click="keywordFiltr('clickSearch')" class="rightBtn" type="primary" slot="append">搜索</el-button>
            <!-- <el-button @click="go('/ReleaseCenterAskBuySave')" type="success" style="background-color: #ee7b27;border-color: #ee7b27;width: 120px;margin-left: 28px;border-radius: 6px;" slot="append">发布求购信息</el-button> -->
        </el-autocomplete>
        <div class="df-ai-c hots" style="min-width: 900px;" v-if="searchHots.length && category<2">
            <div class="blod"><span class="m-l-15 m-r-5">热搜</span>
                <el-divider direction="vertical"></el-divider>
            </div>
            <div class="hot pointer" v-for="(v,i) of searchHots" v-if="i<10" :key="i" @click="clickHot(v)">{{v.hot}}</div>
        </div>
        <input ref="uploader" type="file" accept="image/jpg,image/jpeg,image/png" id="uploader" @change="handleFiles" style="position: absolute;left: -1000px;opacity: 0;">
    </div>
</template>
<script>
import oss from "@/util/oss.js"
import { spu } from "@/service"
export default {
    name: "searchProductNew",
    props: {

    },
    data() {
        return {
            // 关键字
            keyword: null,
            raw_query: null,
            old_keyword: null,
            // 分类
            category: 0,
            placeholder: '请输入家具名称（例如：红木家具）',
            placeholderList: ['请输入家具名称（例如：红木家具）', '输入材料名称（如：岩板）', '输入企业名称搜索（例如：问一得三）', '输入公司名称或个人姓名搜索名片（例如：问一得三）', '暂不可用', '输入搜索关键词：（如轻奢 或 办公）'],

            searchClassify: "furniture",
            restaurants: [],
            searchHints: [], //底纹列表
            searchHots: [], //热搜列表
            placeholderHints: null,
            placeholderIndex: null,
            placeholderID: null,
            timeoutId: null,
            searchImageUrl:null,
        }
    },
    mounted() {
        if (this.$route.query.keyword) {
            this.keyword = this.$route.query.keyword
        }
        if (this.$route.query.imgUrl) {
            this.searchImageUrl = this.$route.query.imgUrl
        }
        this.searchHot()
        /*       this.placeholderIndex=0
               this.setPlaceholder()*/
    },
    methods: {
        setPlaceholder() {
            // console.log('setPlaceholder')
            if (this.searchHints.length > 1) {
                this.placeholderHints = this.searchHints[this.placeholderIndex].hint
                this.placeholderIndex = this.placeholderIndex == (this.searchHints.length - 1) ? 0 : this.placeholderIndex + 1
                this.placeholderID = setTimeout(() => {
                    clearTimeout(this.placeholderID)
                    this.setPlaceholder()
                }, 5000)
            }

        },
        clickHot(v) {
            this.searchImageUrl=null
            this.keyword = v.hot
            this.raw_query = v.hot
            this.clickSearch()
        },
        searchHot() {
            spu.searchHot({}).then(res => {
                if (res) {
                    if (res.searchHints) {
                        this.searchHints = res.searchHints
                        this.placeholderIndex = 0
                        this.setPlaceholder()
                        // this.placeholderHints=this.searchHints[this.placeholderIndex]
                    }
                    if (res.searchHots) {
                        this.searchHots = res.searchHots
                    }
                }
            })
        },
        setCategory(v) {
            this.category = v
            this.placeholder = this.placeholderList[v]
            if (this.category < 2) {
                this.placeholderHints = this.placeholder
                this.setPlaceholder()
            } else {
                this.placeholderID && clearTimeout(this.placeholderID)
            }


        },
        keywordFiltr(fn) {
            if (!this.keyword && this.category < 2 && this.placeholderHints && (!this.searchImageUrl || this.$route.path!='/SearchImage')) {
                if(this.searchImageUrl){
                    this.$router.replace('/SearchImage?imgUrl=' + this.searchImageUrl).catch(err => {});;
                    console.log(666)
                    return
                }else{
                    this.keyword = this.placeholderHints
                    this.searchImageUrl=null
                }
            }
            this.$oucy.keywordFiltr(this.keyword).then(res => {
                this[fn]()
            }, err => {})
        },
        // 点搜索
        clickSearch: function() {
            // if(this.keyword!=null && this.keyword===this.old_keyword){
            //     console.log('已查询出当前关键字结果')
            //     return
            // }
            const me = this;
            if(!this.keyword && (this.category == '0' || this.category == '1') && this.searchImageUrl && this.$route.path=='/SearchImage'){
                return
            }else{
                this.searchImageUrl=null
            }
            if(!(this.category == '0' || this.category == '1') && this.searchImageUrl){
                this.searchImageUrl=null
            }
            if (this.category == '0') {
                if (!this.keyword) {
                    this.$message.error('请输入要搜索的内容');
                    return
                }
                me.$router.replace('/SearchFurniture?keyword=' + this.keyword + '&raw_query=' + this.raw_query).catch(err => {});;
            } else
            if (this.category == '1') {
                if (!this.keyword) {
                    this.$message.error('请输入要搜索的内容');
                    return
                }
                me.$router.replace('/SearchMaterial?keyword=' + this.keyword + '&raw_query=' + this.raw_query).catch(err => {});;
            } else
            if (this.category == '2') {
                me.$router.replace('/searchenterprise?keyword=' + this.keyword + '&raw_query=' + this.raw_query).catch(err => {});;
            } else
            if (this.category == '3') {
                me.$router.replace('/SearchCard?keyword=' + this.keyword + '&raw_query=' + this.raw_query).catch(err => {});;
            } else
            if (this.category == '5') {
                me.$router.replace('/SearchAskBuy?keyword=' + this.keyword + '&raw_query=' + this.raw_query).catch(err => {});;
            }
            this.old_keyword = this.keyword
        },
        go: function(path) {
            this.$router.push(path)
        },
        // 关键字触发
        querySearchAsync(queryString, cb) {
            this.timeoutId && clearInterval(this.timeoutId)
            if (this.category > 1) {
                cb([]);
                return
            }
            if (!this.keyword || !queryString) {
                cb([]);
                return
            }
            this.timeoutId = setTimeout(() => {
                spu.searchProductSuggest({ category: this.category, keyword: queryString }).then(res => {
                    this.raw_query = queryString
                    var restaurants = []
                    if (res && res.suggestions) {
                        for (let v of res.suggestions) {
                            restaurants.push({ value: v })
                        }
                    }
                    cb(restaurants);
                })
            }, 1000);
        },
        // 点选提示触发
        handleSelect(item) {
            console.log(item);
            this.keyword = item.value

            this.clickSearch()
        },
        clickCamera() {
            // this.$message('开发中,敬请期待...')
            this.$refs.uploader.click()
        },
        handleFiles(event) {
            // 从 input 元素中获取 files: FileList
            let files = event.target.files
            this.searchImage(files[0])
        },
        searchImage(file) {
            let Oss=new oss()
            Oss.startUpload(file).then(res=>{
                this.searchImageUrl=res
                // this.$message('上传成功')
                this.$router.replace('/SearchImage?imgUrl=' + res);
                this.$refs.uploader.value=null
                this.keyword=null
            });
        },
    },
    beforeDestroy() {
        this.placeholderID && clearTimeout(this.placeholderID)
    }
}
</script>
<style scoped lang="less">
.search {
    display: inline-block;
    position: relative;
    padding-bottom: 50px;
    text-align:center;
}

.btns {
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    text-align: left;
    color: #ffffff;
    margin-bottom: 20px;
}

.btn {
    border-radius: 4px;
    padding: 5px 10px;
    margin: 0 15px;
    cursor: pointer;
}

.btn.select {
    background: #fff;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: 700;
    text-align: left;
    color: #0175e0;
    position: relative;

    &:before {
        content: " ";
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-top: solid 5px #fff;
        border-right: solid 5px transparent;
        border-bottom: solid 5px transparent;
        border-left: solid 5px transparent;
    }

}

.search /deep/ .el-input-group__append button.el-button,
.el-input-group__append div.el-select .el-input__inner,
.el-input-group__append div.el-select:hover .el-input__inner,
.el-input-group__prepend button.el-button,
.el-input-group__prepend div.el-select .el-input__inner,
.el-input-group__prepend div.el-select:hover .el-input__inner {
    background: #f38f4a;
    color: #fff;


    border-radius: 0 6px 6px 0;
    border: 1px solid #f38f4a;
    width: 100px;
}

.search /deep/ .el-input__inner {
    border-color: #fff;
    border: 2px solid #fff;
    // background: transparent; 
}

.hots {
    position: absolute;
    color: #eee;
}

.hot {
    font-size: 14px;
    font-weight: 400;
    margin: 10px;

    &:hover {
        color: #fff;
    }
}

/deep/ .el-input-group__append,
/deep/ .el-input-group__prepend {
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.camera {
    margin-top: 7px;
    margin-right: 3px;
    width: 26px;
    height: 26px;
}
/deep/ .el-input-group__append{
    overflow: hidden;
}
.rightBtn{
    height: 42px;
}
</style>