import oucy from '@/util/oucyUtil.js'
// 实名认证信息控制层
const usercommission = {
	/**
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getUserCommission: (params) => {
        return oucy.postRequest('/client/user/usercommission/getUserCommission', params)
    },	
    /** 发送账号设置手机验证码(客户端使用)
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    accSetupSendCode: (params) => {
        return oucy.postRequest('/client/user/usercommission/accSetupSendCode', params)
    },   
    /** 提现佣金到余额
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    commissionToBlance: (params) => {
        return oucy.postRequest('/client/user/usercommission/commissionToBlance', params)
    }
}

export default usercommission

