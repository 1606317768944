import oucy from '@/util/oucyUtil.js'
// 企业vr册管理
const enterpriseVrAlbum = {
	/**
     * 新增企业vr册
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    addEnterpriseVrAlbum: (params) => {
        return oucy.postRequest('/client/enterprise/enterprisevralbum/addEnterpriseVrAlbum', params)
    },

     /**
     * 删除企业vr册
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    delEnterpriseVrAlbum: (params) => {
        return oucy.postRequest('/client/enterprise/enterprisevralbum/delEnterpriseVrAlbum', params)
    },

     /**
     * 修改企业vr册
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    updateEnterpriseVrAlbum: (params) => {
        return oucy.postRequest('/client/enterprise/enterprisevralbum/updateEnterpriseVrAlbum', params)
    },

     /**
     * 查询用户的vr册
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllEnterpriseVrAlbumsClient: (params) => {
        return oucy.postRequest('/client/enterprise/enterprisevralbum/getAllEnterpriseVrAlbumsClient', params)
    },

}

export default enterpriseVrAlbum
