<!-- 媒体框 支持图片 视频混排 -->
<template>
    <div class="boxs" :style="{marginRight:-size/20 +'px'}">
        <span v-for="(url,index) of previewSrcList" class="box" :style="{width: size +'px', height: size +'px',borderRadius:size/50 +'px',marginRight:size/20 +'px',marginBottom:size/20 +'px'}" :key="url+index">
            <video class="video" :src="url" @click="$oucy.openNewPage(url)" v-if="getType(url)=='video'"></video>
            <el-image :src="url" class="image" :preview-src-list="imageList" v-else></el-image>
            <i class="el-icon-delete delete pointer f14" v-if="isShowDel" @click="clickDel(url,index)"></i>
        </span>
    </div>
</template>
<script>
export default {
    name: "MyMedia",
    props: {
        srcList: {
            type: Array,
            default: [], //'video/mp4', //'image':''
        },
        size: {
            type: Number,
            default: 100
        },
        isShowDel:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            imageList: [],
        }
    },
    computed: {
        previewSrcList: function() {
            let list = [],
                imageList = []
            for (let v of this.srcList) {
                let url=null
                if (v.startsWith('http')) {
                    url=v
                }else{
                    url=this.$oucy.ossUrl + v
                }                
                list.push(url)
                if (this.getType(url) == 'image') {
                    imageList.push(url)
                }
            }
            this.imageList = imageList
            return list
        }
    },
    methods: {
        getType(url) {
            return url && url.endsWith('mp4') ? 'video' : 'image'
        },
        clickDel(v,i){
            this.$emit('clickDel',{v:v,i:i})
        }

    }
}
</script>
<style scoped lang="less">
.boxs {

    display: inline-block;
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
    span {
        font-size: 0;
        display: inline-block;
    }
    .box{
        overflow: hidden;
        background: #EFEFEF;
        border:1px solid #f1f1f1;
        display: flex;
        align-items: center;
        position:relative;
        margin-left: 10;
        &:hover{
            .delete{
                display: inline-block;
            }
        }
    }
    .delete{
        position:absolute;
        padding: 10px;
        z-index: 999;
        right: 10px;
        top: 0px;
        display: none;
        // box-shadow: 1px 1px 3px #fff;
        &:hover{
            color: #f00;
        }
    }
    .video,.image{
width: 100%;
height: auto;
    }
}
</style>