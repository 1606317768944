import oucy from '@/util/oucyUtil.js'
// 钱包
const balance = {
    /**
     * 充值
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createRechargeOrder: (params) => {
        return oucy.postRequest('/client/financial/balance/createRechargeOrder', params)
    },
    /**
     * 明细
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getBalanceHis: (params) => {
        return oucy.postRequest('/client/financial/balance/getBalanceHis', params)
    },
    /**
     * 提现
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    createWithdraw: (params) => {
        return oucy.postRequest('/client/financial/balance/createWithdraw', params)
    },
    /**
     * 查询用户的提现记录
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getBalanceWithdraw: (params) => {
        return oucy.postRequest('/client/financial/balance/getBalanceWithdraw', params)
    },
    /**
     * 查询用户在线充值历史记录
     * @param  {object} 请求的参数
     * @return {Promise} 返回一个Promise
     */
    getBalanceRecharge: (params) => {
        return oucy.postRequest('/client/financial/balance/getBalanceRecharge', params)
    },

}

export default balance