import oucy from '@/util/oucyUtil.js'
// 判断客户端用户是否实名认证
const realnameauthentication = {
	/**
     * 查询客户端用户的所有企业
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    isRealNameAuthentication: (params={}) => {
        return oucy.postRequest('/client/user/realnameauthentication/isRealNameAuthentication', params)
    },

}

export default realnameauthentication
