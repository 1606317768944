<!--  -->
<template>
    <div style="position: fixed;bottom: 500px;z-index: 9999;" :class="enterpriseTicketForUser && enterpriseTicketForUser.length?'show':'notshow'">
        <el-popover placement="top" width="407" trigger="click">
            <div>
                <div style="font-size: 20px; font-family: MicrosoftYaHei-, MicrosoftYaHei; font-weight: normal; color: #AAAFB7; text-align: center;">- 店铺优惠券 -</div>
                <div style="height: 380px;overflow: auto;padding: 20px;">
                    <div v-if="enterpriseTicketForUser && enterpriseTicketForUser.length">
                        <div v-for="(v,i) of enterpriseTicketForUser" style="margin-top:24px" :key="i">
                            <coupon3 :v="v"></coupon3>
                        </div>
                    </div>
                    <div v-else class="c-2 text-center p-t-50">-- 暂无优惠券 --</div>
                </div>
            </div>
            <el-button size="mini" class="m-l-10" slot="reference" style="padding: 0;background: rgba(0,0,0,0);border: none;">
                <img src="@/assets/images/coupon/lin.png" alt="" style="width:98px;height:auto;">
                <span class="f14" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);color: #fff;">店铺优惠券</span>
            </el-button>
        </el-popover>
    </div>
</template>
<script>
import { ticket } from "@/service"
import { localSet, localGet, localDel } from "@/store/store";
export default {
    name: "enterpriseCoupon",
    props: {
        enterpriseId: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            enterpriseTicketForUser: [],
            loginUser: {},
        }
    },
    created() {

        console.log('created2')
    },
    mounted() {
        console.log('mounted2')
        this.loginUser = localGet('loginUser')
        if (this.enterpriseId && this.loginUser && this.loginUser.id) {

            this.getEnterpriseTicketForUser()
        }
    },
    methods: {
        takeCoupons() {
            ticket.takeCoupons({ ticketId: this.v.ticketPoolBasic.id }).then(res => {
                this.$message('领取成功')
                this.v.valid = !this.v.valid
            })
        },
        getEnterpriseTicketForUser() {
            ticket.getEnterpriseTicketForUser({ enterpriseId: this.enterpriseId }).then(res => {
                this.enterpriseTicketForUser = res || []
            })
        },
    },
}
</script>
<style scoped lang="less">
    .show{
        right:0px;
    }
    .notshow{
right: -200px;
    }
</style>