<!-- 倒计时 -->
<template>
    <div class="box">
        <span>{{replenish(hour)}}:{{replenish(minute)}}:{{replenish(sec)}}</span>
    </div>
</template>
<script>
export default {
    name: "countDown",
    props: {
        autoStart: {
            type: Boolean,
            default: true,
        },
/*        h: {
            type: [Number,String],
            default: 0,
        },
        m: {
            type: [Number,String],
            default: 0,
        },*/
        s: {
            type: [Number,String],
            default: 0,
        }

    },
    data() {
        return {
            timId: null,
            hour: 0,
            minute: 0,
            sec: 0,
        }
    },
    mounted() {
        // this.myinit()
    },
    methods: {
        myinit() {
            this.timId && clearTimeout(this.timId)
            this.hour= 0
            this.minute= 0
            this.sec= 0

            if(this.s>60){
                this.minute = ~~(this.s/60)
                this.sec = ~~(this.s%60)
            }else{
                this.sec = this.s || 0
            }

            // this.minute += this.m

            // if(this.minute>60){
            //     this.hour = ~~(this.minute/60)
            //     this.minute = ~~(this.minute%60)
            // }
            // this.hour += this.h || 0

            
                this.start();
         
        },
        start() {
            // 继续
            let goNo = true
            if (this.sec > 0) {
                --this.sec
            } else {
                if (this.minute > 0) {
                    --this.minute
                    this.sec = 59
                } else {
                    if (this.hour > 0) {
                        --this.hour
                        this.minute = 59
                    } else {
                        goNo = false
                        console.log('时间到了')
                        this.$emit('stopTime',{})
                    }
                }
            }
            this.timId = setTimeout(() => {
                goNo && this.start();
            }, 1000)
            this.$emit('updateTime',{hour:this.hour,minute:this.minute,sec:this.sec})

        },
        replenish(v) {
            if (v < 10) {
                return '0' + v
            } else {
                return v
            }
        },


    },
    beforeDestroy() {
        this.timId && clearTimeout(this.timId)
    }
}
</script>
<style scoped lang="less">
    .box{
        display: inline-block;
    }
</style>