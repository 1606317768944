/**
 * 文件上传
 * @author zhangjianchun
 * @date 2022-3-30
 **/

import axios from 'axios'

import { v4 as uuidv4 } from 'uuid'
import { aliyun } from "@/service"
import { Message ,MessageBox, Notification } from "element-ui";
import SparkMD5 from "spark-md5"


class oss {
    /**
     * 构造函数
     * @param  {String} ossDir 上传的文件夹 'dynamic-boss',//存普通文件 'lsj_video',//存短视频文件专用
     */
    constructor(ossDir = 'dynamic-boss') {
        this.ossUrl = "https://aliyunoss.lesoujia.com/"
        this.ossDir = ossDir
        this.file = null
        this.uploadProgress = null
        this.source = null
        this.complete = null
    }

    /**
     * 开始上传
     * @param  {[type]} file [description]
     * @return {[type]}      [description]
     */
    startUpload(file) {
        console.log('新的上传 方法')
        return new Promise((resolve, reject) => {
            if (!file) {
                console.log('上传文件不能为空')
                reject('上传文件不能为空')
            }
            // console.log(file)
            this.file = file
            this.uploadProgress = Notification({
                title: file.name,
                // title: file.name && file.name.length > 20 ? file.name.substr(0, 18) + '...' : file.name,
                dangerouslyUseHTMLString: true,
                // message: '<strong>这是 <i>HTML</i> 片段</strong>',
                message: '获取文件中...',
                duration: 0,
                onClose: () => {
                    this.onClose()
                }
            });
            if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/gif' || file.type == 'video/mp4') {
                // 获取文件MD5
                this.message('读取文件中...')
                this.getFileMd5(file).then(md5Name => {
                    let ossFile_extName = this.getFileExtension(file)
                    let ossFileName = md5Name + '.' + ossFile_extName
                    let url = this.ossUrl + this.ossDir + '/' + ossFileName + '?Date=' + new Date().getTime()
                    this.message('连接阿里云...')
                    if (file.type == 'video/mp4') {
                        // 判断视频是否存在
                        this.getHaveVideo(url).then(res => {
                            // 存在直接返回路径
                            this.message('极速上传完成')
                            this.messageClose()
                            resolve(ossFileName)
                        }).catch(err => {
                            // 不存在 去上传
                            this.aliyunSignature(file, md5Name).then(res => {
                                resolve(res)
                            }).catch(err => {
                                reject(err)
                            })
                        })
                    } else {
                        // 判断图片是否存在
                        this.getHaveImage(url).then(res => {
                            // 存在直接返回路径
                            this.message('极速上传完成')
                            this.messageClose()
                            resolve(ossFileName)
                        }).catch(err => {
                            // 不存在 去上传
                            this.aliyunSignature(file, md5Name).then(res => {
                                resolve(res)
                            }).catch(err => {
                                reject(err)
                            })
                        })
                    }
                }).catch(err => {
                    // 获取不到文件MD5 去上传
                    this.aliyunSignature(file).then(res => {
                        resolve(res)
                    }).catch(err => {
                        reject(err)
                    })
                })
            } else {
                Message("不支持的文件类型,请重新选择")
                // 文件类型不是图片和视频 去上传
                // this.aliyunSignature(file).then(res => {
                //     resolve(res)
                // }).catch(err => {
                //     reject(err)
                // })
            }
        });
    }
    /**
     * 获取阿里云签名
     * @return {[type]} [description]
     */
    aliyunSignature(file, fileName) {
        return new Promise((resolve, reject) => {
            this.message('获取签名中...')
            aliyun.aliyunSignature({ ossDir: this.ossDir }).then(ossSign => {
                this.message('开始上传...')
                return this.uploadRequest(file, ossSign, fileName).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                    this.message('上传失败请重试')
                })
            }).catch(err => {
                reject(err)
            })
        });
    }
    /**
     * 上传文件
     * @param file
     * @returns {AxiosPromise}
     */
    uploadRequest(file, ossSign, fileName = uuidv4()) {
        return new Promise((resolve, reject) => {
            // 获取签名后发起上传
            const headParams = {
                'Content-Type': 'multipart/form-data'
            }

            // const blobSrc = URL.createObjectURL(file);        
            let ossFile_extName = this.getFileExtension(file)

            let ossFileName = fileName + '.' + ossFile_extName;

            const form = new FormData();
            form.append('Key', `${this.ossDir}/${ossFileName}`)
            form.append('OSSAccessKeyId', ossSign.AccessKeyId)
            form.append('Policy', ossSign.Policy)
            form.append('Signature', ossSign.Signature)
            form.append('Success_Action_Status', '200')
            form.append("file", file);

            // 取消用的
            const CancelToken = axios.CancelToken;
            this.source = CancelToken.source();

            axios({
                url: ossSign.Host,
                filePath: file,
                name: 'file',
                data: form,
                method: 'post',
                onUploadProgress: (progressEvent) => {
                    // let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
                    // this.complete = ` ${complete}　　${this.formatSize(progressEvent.loaded)} / ${this.formatSize(progressEvent.total)}`
                    // this.message(this.complete)
                    this.messageHtml(progressEvent)
                },
                headers: headParams,
                cancelToken: this.source.token
            }).then(res => {
                this.source = null
                console.log('axios 上传成功')
                resolve(ossFileName)
                this.uploadProgress.close()
                this.uploadProgress = null
            }).catch(err => {
                if (err && err.message == 'userCancel') {
                    // 用户取消
                }
                reject()
                // console.log('axios 上传失败')
            })
        });
    }

    /**
     * 获取文件 扩展名
     * @param  {[type]} file [description]
     * @return {[type]}      [description]
     */
    getFileExtension(file) {
        if (file.type == 'image/jpeg') {
            return 'jpeg'
        } else
        if (file.type == 'image/png') {
            return 'png'
        } else
        if (file.type == 'image/gif') {
            return 'gif'
        }
        if (file.type == 'video/mp4') {
            return 'mp4'
        } else {
            if (file.name) {
                let tmp_ext_name = file.name.substring(file.name.lastIndexOf('.') + 1);
                return tmp_ext_name || '';
            } else {
                return ''
            }
        }
    }

    /**
     * 获取文件MD5
     * @param  {[type]} file [description]
     * @return {[type]}      [description]
     */
    getFileMd5(file) {
        return new Promise((resolve, reject) => {
            let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
                chunkSize = 2097152, // Read in chunks of 2MB
                chunks = Math.ceil(file.size / chunkSize),
                currentChunk = 0,
                spark = new SparkMD5.ArrayBuffer(),
                fileReader = new FileReader();

            fileReader.onload = (e) => {
                spark.append(e.target.result); // Append array buffer
                currentChunk++;

                if (currentChunk < chunks) {
                    loadNext();
                } else {
                    resolve(spark.end());
                }
            };

            fileReader.onerror = () => {
                reject();
            };

            function loadNext() {
                let start = currentChunk * chunkSize,
                    end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

                fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
            }

            loadNext();
        });
    }

    /**
     * 判断图片是否存在
     * @param  {[type]} imgurl [description]
     * @return {[type]}        [description]
     */
    getHaveImage(imgurl) {
        return new Promise((resolve, reject) => {
            try {
                let ImgObj = new Image();
                ImgObj.src = imgurl;
                //没有图片，则返回-1
                ImgObj.onload = function() {
                    resolve();
                }
                ImgObj.onerror = function() {
                    reject();
                }
            } catch (e) {
                reject(e)
            }
        });
    }

    /**
     * 判断视频是否存在
     * @param  {[type]} url [description]
     * @return {[type]}     [description]
     */
    getHaveVideo(videoUrl) {
        return new Promise((resolve, reject) => {
            try {
                let video = document.createElement("video");
                video.oncanplay = (e) => {
                    resolve();
                }
                video.onerror = (e) => {
                    reject()
                }
                video.src = videoUrl
                video.load()
            } catch (e) {
                reject(e)
            }
        });
    }

    /**
     * 更新提示语的
     * @param  {[type]} title [description]
     * @return {[type]}       [description]
     */
    message(title) {
        if (this.uploadProgress) {
            this.uploadProgress.message = title
        }
    }
    /**
     * 更新提示语的
     * @param  {[type]} title [description]
     * @return {[type]}       [description]
     */
    messageHtml(progressEvent) {
        if (this.uploadProgress) {
            let complete = (progressEvent.loaded / progressEvent.total * 100) 
            // this.complete = ` ${complete}　　${this.formatSize(progressEvent.loaded)} / ${this.formatSize(progressEvent.total)}`
            let html = `<div style="with:300px">
            <div style="position: relative;background: #f1f1f1;height: 10px;border-radius: 6px;overflow: hidden;width:100%">
                <div style="background: #409EFF;height: 10px;width: ${complete}%;transition:width 0.5s"></div>
            </div>
            <div style="color: #606266;margin-top:10px;display: flex;justify-content: space-between;">
                <span>${complete>0?(complete).toFixed(2):0}%</span>
                <span style="margin-left: 10px;">${this.formatSize(progressEvent.loaded)}</span>
                <span style="margin-left: 10px;">${this.formatSize(progressEvent.total)}</span>
            </div>
        </div>`
            this.uploadProgress.message = html
            this.complete = html
        }
    }
    /**
     * 关闭弹框
     * @return {[type]} [description]
     */
    messageClose() {
        if (this.uploadProgress) {
            this.uploadProgress.close()
            this.uploadProgress = null
        }
    }
    /**
     * 关闭弹框 回调
     * @return {[type]}   [description]
     */
    onClose() {
        this && this.cancel && this.cancel()
    }

    /**
     * 取消文件上传
     * @return {[type]} [description]
     */
    cancel() {
        // 上传中的有 source 对象
        if (this.source) {
            MessageBox.confirm(`确认取消"${this.file.name}"文件上传`, '取消文件上传', {
                dangerouslyUseHTMLString: true,
                distinguishCancelAndClose: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                // type: 'warning'
            }).then((res) => {
                this.source && this.source.cancel('userCancel');
                this.source = null
                this.uploadProgress = null
            }).catch((err) => {
                // 弹框显示回来
                if (this.uploadProgress) {
                    this.uploadProgress = Notification({
                        title: this.file.name && this.file.name.length > 20 ? this.file.name.substr(0, 18) + '...' : this.file.name,
                        // dangerouslyUseHTMLString: true,
                        message: this.complete,
                        duration: 0,
                        onClose: () => {
                            this.onClose()
                        }
                    })
                }
            });
        }
    }

    /**
     * 格式化文件大小, 输出成带单位的字符串
     * @param {Number} size 文件大小
     * @param {Number} [pointLength=2] 精确到的小数点数。
     * @param {Array} [units=[ 'B', 'K', 'M', 'G', 'TB' ]] 单位数组。从字节，到千字节，一直往上指定。
     *    如果单位数组里面只指定了到了K(千字节)，同时文件大小大于M, 此方法的输出将还是显示成多少K.
     */
    formatSize(size, pointLength, units) {
        var unit;
        units = units || ['B', 'K', 'M', 'G', 'TB'];
        while ((unit = units.shift()) && size > 1024) {
            size = size / 1024;
        }
        return (unit === 'B' ? size : size.toFixed(pointLength === undefined ? 2 : pointLength)) + unit;
    }
};

export default oss

/*
1.引入
 
import oss from "@/util/oss.js"

2.调用
let Oss=new oss()
Oss.startUpload(file).then(res=>{
    console.log('成功',res)
   
}).catch(err=>{
    console.log('失败',err)
})

还有很多优化空间

*/