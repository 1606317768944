import axios from "axios";
import Paho from "paho-mqtt";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { encrypt } from "./ase";
import {
  localSet,
  localGet,
  localDel,
  locaClear,
  sessionSet,
  sessionGet,
  setCookie,
  getCookie,
} from "../store/store";
import { userRecordEvent, elk } from "@/service";
import { Loading, MessageBox, Notification } from "element-ui";
import { stopword } from "@/service";
import SparkMD5 from "spark-md5";
/**
 * 常用方法封装 请求，文件上传等
 * @author ouchengyun.
 **/
const oucy = {
  app: null,
  isICPAudit: true, // (window.location.hostname=='localhost' || window.location.hostname.indexOf('192.168')>-1)?true:false,//《ICP临时去除》 false 不显示  true 显示《显示价格》
  loadingInstance: null,
  thisUUID: null,
  isHidden: false,
  mqtt: null,
  mqttArgs: {
    host: null,
    port: null,
    clientId: null,
    reconnectTimeout: null,
    instanceId: null,
    accessKey: null,
    secretKey: null,
    topic: null,
    useTLS: null,
    cleansession: null,
    groupId: null,
  },

  scanKeyOfPreLogin: "scan_pre_login",
  scanKeyOfLogin: "scan_login",
  scanKeyOfPreBind: "scan_pre_bind",
  scanKeyOfBind: "scan_bind",
  scanKeyOfRealNamePhoto: "scan_realname_photo",
  scanKeyModifyMainWechatAccount: "scan_modify_main_wechat_account",

  msgKeys: {
    QRSCAN_PRE_BIND: "qrscan_pre_bind",
    QRSCAN_CONFIRM_BIND: "qrscan_confirm_bind",
    WECHAT_PRE_LOGIN: "wechat_pre_login",
    WECHAT_CONFIRM_LOGIN: "wechat_confirm_login",
    WECHAT_REALNAME_AVATA_UPLOADD: "wechat_realname_avata_upload",
    LOCAL_LOGIN: "local_login",
    UPDATE_LOGIN_USER_INFO: "update_login_user_info",
    SPU_PAY_SUCCESS: "spu_pay_success",
    BALANCE_PAY_SUCCESS: "balance_pay_success",
    SHOP_LEVEL_PAY_SUCCESS: "shop_level_pay_success", // 铺升级后的下行消息推送key值
    LOGOUT_SUCCESS: "logout_success", // 退出登录成功
    LOGIN_SUCCESS: "login_success", // 登录成功
    IM_MESSAGE: "im_message", // 收到IM信息
    SYSTEM_MESSAGE: "system_message", // 收到系统信息
    IM_FRIEND: "im_friend", // 收到IM 好友申请信息
    IM_MESSAGE_NO_READ_COUNT: "im_message_no_read_count", //
    SYSTEM_ALL_READ_MESSAGE: "system_all_read_message", //
    CHECKFRIENDSNUM: "checkFriendsNum", //
    OPEN_IM_MESSAGE: "open_im_message", // 打开IM信息
    TEXT_IMAGE: "text_image", // 图文消息
    LIVE_ROOM: "live_room", // 直播间消息
    LIVE_ROOM_KICKING: "live_room_kicking", // 踢人消息
    LIVE_ROOM_BEGIN: "live_room_begin", // 开始直播
    LIVE_ROOM_STOP: "live_room_stop", // 暂停直播
    LIVE_ROOM_END: "live_room_end", // 结束直播
    LIVE_ROOM_RESTORE: "live_room_restore", // 暂停恢复直播
    LIVE_ROOM_DISABLE_SEND_MSG: "live_room_disable_send_msg", // 禁言消息
    LIVE_ROOM_ENABLE_SEND_MSG: "live_room_enable_send_msg", // 取消禁言消息
    LIVE_ROOM_DELETE_MESSAGE: "live_room_delete_message", // 删除聊天互动消息
    LIVE_ROOM_BAN: "live_room_ban", // 禁止直播消息
    LIVE_ROOM_BAN_RESTORE: "live_room_ban_restore", // 禁止直播恢复消息
    LIVE_ROOM_LEAVE: "live_room_leave", // 进入直播间
    LIVE_ROOM_ONLINE: "live_room_online", // 离开直播间
    LIVE_ROOM_NO_STREAM_MESSAGE: "live_room_no_stream_message", // 直播间推流状态改变事件
    UPDATA_STOCK_CAR_NUM: "updata_stock_car_num", // 更新进货车数量
    CARD_MEMBER_PAY_SUCCESS: "card_member_pay_success", // 购买会员卡
    LIVE_ROOM_GIVE_LIKES: "live_room_give_likes", // 购买会员卡
    SPU_IMPORT: "spu_import", // 导入商品
  },
  // enterpriseEnterStatus   企业入驻状态 0未入驻 1申请入驻，待审核 3审核驳回 4审核通过
  enterpriseEnterStatus: {
    WAITING_FOR_APPLY: 0,
    APPLYING_FOR_ENTERPRISE: 1,
    // APPLYING_FOR_AGENCY:2,
    APPLY_REFUSED: 3,
    APPLY_SUCCESS_ENTERPRISE: 4,
    // APPLY_SUCCESS_AGENCY:5
  },
  // 买家身份认证
  buyerAuthenticationStatus: {
    WAITING_FOR_APPLY: 0,
    APPLYING: 1,
    APPLY_SUCCESS: 2,
    APPLY_FAIL: 3,
  },

  // enterpriseAuthenticationStatus  企业认证状态 0未认证 1已申请，待审核 2审核通过 3审核驳回
  enterpriseAuthenticationStatus: {
    WAITING_FOR_APPLY: 0,
    APPLYING: 1,
    APPLY_SUCCESS: 2,
    APPLY_FAIL: 3,
  },

  brandAuditStatusEnum: {
    WAITING_FOR_APPLY: 0,
    APPLYING: 1,
    APPLY_SUCCESS: 2,
    APPLY_FAIL: 3,
  },

  goodCategory: {
    FURNITURE: 0,
    MATERIAL: 1,
  },

  baseHost: "https://boss.lesoujia.com",
  loginPrefixUP: "username_password:",
  loginPrefixPHONE: "telephone:",
  loginPrefixWECHART: "wechat_h5:",
  pageWidth: 1280,

  tokenKey: "Authorization",
  userInfoKey: "loginUser",
  serverImg: "https://aliyunoss.lesoujia.com/lsj_mobile/image/", //前端图片素材OSS路径 手机那里的那过来用
  ossUrl: "https://aliyunoss.lesoujia.com/dynamic-boss/",
  ossUrlPrefixVideo: "https://aliyunoss.lesoujia.com/lsj_video", //视频上传文件OSS路径
  notErrUrl: ["/client/im/liveheat/saveLiveRoomHea", "/h5-feitui-video-parse"],
  channel: process.env.VUE_APP_CHANNEL,
  operation_p_page_last_start_time: new Date().getTime(),
  path: "",
  operationUv: null,
  uploadFileObject: {
    uploadProgress: null,
    file: {},
  },
  go: function (path, callback) {
    oucy.app.$router.push(path).then((res) => {
      if (callback) {
        callback();
      }
    });
  },
  replace: function (path, callback) {
    oucy.app.$router.replace(path).then((res) => {
      if (callback) {
        callback();
      }
    });
  },
  openNewPage(url) {
    if (url.startsWith("http")) {
    } else {
      url = location.origin + url;
    }
    console.log(url);
    window.open(encodeURI(url), "_blank");
  },

  back: function () {
    oucy.app.$router.back();
  },

  sortMp4Image(list) {
    if (!list) {
      return list;
    }
    if (typeof list == "string") {
      list = JSON.parse(list);
    }
    let mp4 = [],
      image = [];
    for (let v of list) {
      if (v.includes(".mp4")) {
        mp4.push(v);
      } else {
        image.push(v);
      }
    }
    return [...mp4, ...image];
  },
  isValidPhone: function (str) {
    const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
    return reg.test(str);
  },

  setToken: function (authenticationToken) {
    localSet(oucy.tokenKey, authenticationToken);
  },

  getToken: function () {
    return localGet(oucy.tokenKey);
  },

  removeToken: function () {
    // localDel(oucy.tokenKey);
    locaClear(); //删除缓存
  },
  setTitle(title = "乐搜家 家居供应链服务平台") {
    document.title = title;
  },
  /**
   * JS获取url参数
   */
  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  },
  /**
   * 雷达
   * @return {[type]} [description]
   */
  recordPageAction(obj = {}, updateStart = true) {
    // obj.source='pc'
    // 雷达记录：页面行为    0无  1查看企业名片 2查看员工名片列表 3查看员工名片 4查看视频列表 5查看短视频 6查看商品列表 7查看商品
    obj.operation_p_page_radar_view_type =
      obj.operation_p_page_radar_view_type || 0;
    let type = [
      "0无",
      "/enterpriseDetail",
      "2查看员工名片列表",
      "/staffDetail",
      "/ShopVideo",
      "页面弹框 开关 处理",
      "6查看商品列表 有两个页面",
      "/product",
    ];
    let url = obj.path || oucy.path;

    if (url.indexOf(type[1]) > -1) {
      obj.operation_p_page_radar_view_type = 1;
      obj.operation_p_page_radar_object_id = oucy.getQueryVariable("id");
    }
    if (url.indexOf(type[3]) > -1) {
      obj.operation_p_page_radar_view_type = 3;
      obj.operation_p_page_radar_object_id = oucy.getQueryVariable("id");
    }

    // 可能在 查看视频列表页面 看视频
    if (
      obj.operation_p_page_radar_view_type != 5 &&
      url.indexOf(type[4]) > -1
    ) {
      obj.operation_p_page_radar_view_type = 4;
      obj.operation_p_page_radar_object_id =
        oucy.getQueryVariable("enterpriseId");
    }
    // VIP企业 和普通企业
    if (url == "/ShopVip" || url == "/Shop") {
      obj.operation_p_page_radar_view_type = 6;
      obj.operation_p_page_radar_object_id =
        oucy.getQueryVariable("enterpriseId");
    }
    if (url.indexOf(type[7]) > -1) {
      obj.operation_p_page_radar_view_type = 7;
      obj.operation_p_page_radar_object_id = oucy.getQueryVariable("spuId");
    }
    obj.operation_p_page_now_id = url;
    obj.operation_p_page_now_name = obj.record || document.title || "乐搜家";
    let operation_p_page_last_start_time =
      obj.operation_p_page_last_start_time ||
      oucy.operation_p_page_last_start_time;
    delete obj.operation_p_page_last_start_time;
    delete obj.record;
    delete obj.path;
    obj.operation_p_page_last_count_time = Math.floor(
      (new Date().getTime() - operation_p_page_last_start_time) / 1000
    );
    if (obj.operation_p_page_last_count_time > 0) {
      elk.recordPageAction(obj);
    }
    // 不是来自页面的 不更新这个时间
    if (updateStart) {
      oucy.operation_p_page_last_start_time = new Date().getTime();
    }
  },
  /**
   * 发送用户操作行为操作记录
   * @return {[type]} [description]
   */
  receiveUserRecordEvent(obj = {}) {
    return;
    obj.source = "pc";
    let loginUser = localGet("loginUser") || {};
    obj.page = window.location.href;
    obj.record = obj.record || "乐搜家";
    obj.userId = loginUser.id || null;
    obj.userName = loginUser.userDcodeUsername || null;
    obj.userName =
      loginUser.ltRealNameAuthenticationDto &&
      loginUser.ltRealNameAuthenticationDto.authenticationName
        ? `${obj.userName}(${loginUser.ltRealNameAuthenticationDto.authenticationName})`
        : obj.userName;
    userRecordEvent.receiveUserRecordEvent(obj);
  },
  /**
   * 发送用户操作错误操作记录
   * @return {[type]} [description]
   */
  receiveErrorEvent(obj = {}) {
    obj.source = "pc";
    let loginUser = localGet("loginUser") || {};
    obj.page = window.location.href;
    obj.record = obj.record || "乐搜家";
    obj.userId = loginUser.id || null;
    obj.userName = loginUser.userDcodeUsername || null;
    obj.userName =
      loginUser.ltRealNameAuthenticationDto &&
      loginUser.ltRealNameAuthenticationDto.authenticationName
        ? `${obj.userName}(${loginUser.ltRealNameAuthenticationDto.authenticationName})`
        : obj.userName;
    userRecordEvent.receiveErrorEvent(obj);
  },

  /**
   * 获取数组最后一个
   * @param  {[type]} array
   * @return {[type]}  any
   */
  getArrayLast: function (array) {
    if (Array.isArray(array) && array.length > 0) {
      return array[array.length - 1];
    } else {
      return null;
    }
  },
  /**
   * html特殊字符转义
   * @param  { string } unsafe str
   * @return { string }
   */
  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  },
  /**
   * 字符串替换
   * @param  stringObject 字符串
   * @param  regexp 目标
   * @param  replacement 替换
   * @return  str 字符串
   */
  strReplace(stringObject, regexp, replacement) {
    if (!stringObject) {
      return stringObject;
    }
    return stringObject.replace(new RegExp(regexp, "g"), replacement);
  },
  /**
   * 字符串转数组
   * @param   str 字符串
   * @param   separator  分隔
   * @return  array
   */
  strToArray: function (str, separator) {
    if (str) {
      return str.split(separator);
    } else {
      return null;
    }
  },
  /**
   * 获取短信验证码间隔时间
   */
  getCodeTime: function (uuid) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest("/client/user/userauth/getCodeTime", {
          uuid: uuid || oucy.getUUID(),
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getType(url, type = "mp4") {
    return url && url.endsWith(type) ? "video" : "image";
  },
  /**
   * 获取uuid
   * @returns {string|any}
   */
  getUUID: function () {
    if (!oucy.thisUUID) {
      let UUID = null; //localGet('UUID')
      console.log("getUUID:", UUID);
      if (UUID) {
        oucy.thisUUID = UUID;
      } else {
        oucy.thisUUID = uuidv4();
      }
    }
    return oucy.thisUUID;
  },

  /**
   * 获取OperationUv
   * @returns {string|any}
   */
  getOperationUv() {
    if (!oucy.operationUv) {
      let operationUv = getCookie("operationUv");

      if (operationUv) {
        oucy.operationUv = operationUv;
      } else {
        oucy.operationUv = uuidv4();
      }
      setCookie("operationUv", oucy.operationUv);
    }
    return oucy.operationUv;
  },

  getLocalUserEntity: function () {
    let localUser = localGet(oucy.userInfoKey);
    if (localUser && typeof localUser == "string") {
      return JSON.parse(localUser);
    } else {
      return localUser;
    }
  },

  logout: function () {
    localDel(oucy.userInfoKey);
    oucy.removeToken();
  },

  /**
   * 使用账号和密码登录
   * @param username
   * @param password
   */
  loginUp: function (username, password) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest("/login", {
          username: encrypt(oucy.loginPrefixUP + username),
          password: encrypt(password),
        })
        .then((res) => {
          oucy.setToken(res.token);
          localSet(oucy.userInfoKey, res.user);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /**
   * 字段名称修改
   * @param {[type]} list   [description]
   * @param {[type]} oldkey [description]
   * @param {[type]} newkey [description]
   */
  keyAs(list, oldkey, newkey = "name") {
    for (let v of list) {
      v[newkey] = v[oldkey];
      if (v.children && v.children.length) {
        oucy.keyAs(v.children, oldkey, newkey);
      }
    }
  },
  /**
   * 获取 ArrayObjcet 中的 key 返回一个数组
   * @param  {ArrayObjcet} list [description]
   * @param  {String} k    [description]
   * @return {[type]}      [description]
   */
  getSrcList(ArrayObjcet, k = "url") {
    if (!ArrayObjcet) return [];
    let list = [];
    for (let v of ArrayObjcet) {
      if (v[k]) list.push(v[k]);
    }
    return list;
  },
  /**
   * 列表上下移动元素
   * @param  {Array}  list 要移动的列表
   * @param  {String} type 类型 上移或下移
   * @param  {[type]} i    当前元素索引
   */
  listUpDnMove(list = [], type = "up", i) {
    if (typeof i != "number") {
      console.error("错误的索引:", i);
      return;
    }
    if (Array.isArray(list)) {
      if (list.length < 2) return;
      if (type == "dn") {
        if (i == list.length - 1) {
          oucy.app.$message("已经在底部了");
          return;
        }
        list[i] = list.splice(i + 1, 1, list[i])[0];
      } else if (type == "up") {
        if (i == 0) {
          oucy.app.$message("已经在顶部了");
          return;
        }
        list[i] = list.splice(i - 1, 1, list[i])[0];
      }
    }
  },
  /**
   * 使用短信验证码登录
   * @param phoneNumber
   * @param phoneCode
   * @returns {Promise<unknown>}
   */
  loginPhone: function (phoneNumber, phoneCode) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest("/login", {
          username: encrypt(oucy.loginPrefixPHONE + phoneNumber),
          password: encrypt(phoneCode),
        })
        .then((res) => {
          oucy.setToken(res.token);
          localSet(oucy.userInfoKey, res.user);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 使用微信第三方授权登录
   * @param openid
   * @param passcode
   * @returns {Promise<unknown>}
   */
  loginWechat: function (openid, passcode) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest("/login", {
          username: encrypt(oucy.loginPrefixWECHART + openid),
          password: encrypt(passcode),
        })
        .then((res) => {
          oucy.setToken(res.token);
          localSet(oucy.userInfoKey, res.user);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 获取当前登录用户基本信息
   */
  queryLoginUserInfo: function () {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest("/client/user/userauth/getloginUserInfo", {})
        .then((res) => {
          console.error(
            "当前接口已弃用，请使用  /client/user/userauth/queryUserMaterial"
          );
          reject(
            "当前接口已弃用，请使用  /client/user/userauth/queryUserMaterial"
          );
          return;
          // oucy.postRequest('/userAuth/getloginUserInfo', {}).then(res => {
          localSet(oucy.userInfoKey, res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 获取身份列表
   */
  queryIdentity: function (isEnterprise) {
    return new Promise((resolve, reject) => {
      let url = isEnterprise
        ? "/client/enterprise/ltidentity/findAllLtIdentity"
        : "/client/user/ltidentity/findAllLtIdentity";
      oucy
        .postRequest(url, {
          identityIsEnterprise: isEnterprise,
        })
        .then((res) => {
          // localSet(oucy.userInfoKey, res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 查询个人身份认证状态
   */
  queryPersonalIdentifyStatus: function () {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest(
          "/client/user/buyerauthentication/returnBuyerAuthenticationState",
          {}
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 查询个人身份认证信息
   */
  queryPersonalIdentifyInfo: function () {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest(
          "/client/user/buyerAuthentication/findBuyerAuthenticationByLtUserAuthId",
          {}
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 查询企业入驻状态
   */
  queryEnterApplyStatus: function (obj = {}) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest(
          "/client/enterprise/enterpriseinfo/queryEnterpriseEnterStatus",
          obj
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 查询企业入驻信息
   * @returns {Promise<unknown>}
   */
  queryEnterApplyInfo: function (obj = {}) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest(
          "/client/enterprise/enterpriseinfo/findEnterpriseByLtUserAuthId",
          obj
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 查询企业认证状态
   * @returns {Promise<unknown>}
   */
  queryEnterpriseIdentifyStatus: function (obj = {}) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest(
          "/client/enterprise/enterpriseAuthentication/isEnterpriseAuthentication",
          obj
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 查询用户所有品牌
   * @param brandCategory
   * @param brandAuditStatus
   * @returns {Promise<unknown>}
   */
  /*    queryUserBrand: function(brandCategory, brandAuditStatus){
        return new Promise((resolve, reject) => {
            oucy.postRequest('/client/user/furnitureGlobalBrand/getUserBrands', {
                brandCategory:brandCategory,
                brandAuditStatus:brandAuditStatus
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        });
    },*/

  /**
   * 查询用户所有分类
   * @param category
   * @returns {Promise<unknown>}
   */
  queryUserClassify: function (category) {
    return new Promise((resolve, reject) => {
      reject("请用 furnitureClassify.findByLtUserAuthAndClassifyCategory()");
      /* oucy.postRequest('/client/user/furnitureclassify/getAllFurnitureClassifysMent', {//0
            oucy.postRequest('/furnitureClassify/findByLtUserAuthAndClassifyCategory', {//0
                classifyCategory: category
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });*/
    });
  },

  /**
   * 查询全局属性选项
   */
  queryGlobalAttr: function (category) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest(
          "/client/product/furnitureglobalattr/getAllFurnitureGlobalAttrs",
          {
            category: category,
          },
          false
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 查询用户指定商品分类下的所有spec模板
   * @param classifyId
   * @param start
   * @param limit
   * @returns {Promise<unknown>}
   */
  queryUserSpecTemplate: function (classifyId, start, limit) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest(
          "/client/product/furniturespecificationoption/getAllEnterpriseInfo",
          {
            furnitureClassifyId: classifyId,
            start: start,
            limit: limit,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 上传微信图片
   * @param serverId
   */
  uploadWechatImg: function (serverId) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest("/wechat/uploadWechatFile", {
          //0
          serverId: serverId,
          wechatClassify: 0,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 获取区域
   */
  findChina: function (chinaId) {
    return new Promise((resolve, reject) => {
      oucy
        .postRequest("/client/auth/china/findChina", {
          chinaId: chinaId,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * 获取区域[用于下拉框]
   */
  cityProps: {
    lazy: true,
    lazyLoad(node, resolve) {
      const { level } = node;
      oucy.findChina(level === 0 ? null : node.value).then((res) => {
        resolve(
          Array.from(res).map((item) => ({
            value: item.chinaId,
            label: item.chinaName,
            leaf: !item.hasChildren,
          }))
        );
      });
    },
  },

  /**
   * post
   * @param url
   * @param params
   * @returns {AxiosPromise}
   */
  postRequest: function (url, params = {}, Loading = true) {
    let headParams = {
      "Content-Type": "application/json",
      channel: oucy.channel,
    };

    let authToken = localGet(oucy.tokenKey);
    if (authToken && authToken.length > 0) {
      headParams.Authorization = authToken;
    }
    params.operationUv = oucy.getOperationUv();
    params.operationOriginPlatform = 0;
    return axios({
      method: "post",
      url: `${oucy.baseHost}${url}`,
      data: JSON.stringify(params),
      timeout: 1000 * 60, //请求超时 60秒
      headers: headParams,
      Loading: Loading,
    });
  },

  /**
   * post 潘工 用户记录的 baseHost
   * @param url
   * @param params
   * @returns {AxiosPromise}
   */
  postRequest_2: function (url, params = {}) {
    let headParams = {
      "Content-Type": "application/json",
      channel: oucy.channel,
    };

    let authToken = localGet(oucy.tokenKey);
    if (authToken && authToken.length > 0) {
      headParams.Authorization = authToken;
    }
    params.operationUv = oucy.getOperationUv();
    params.operationOriginPlatform = 0;
    return axios({
      method: "post",
      url: `${oucy.baseHost}${url}`,
      data: JSON.stringify(params),
      timeout: 1000 * 60, //请求超时 60秒
      headers: headParams,
    });
  },

  /**
   * get
   * @param url
   * @returns {AxiosPromise}
   */
  getRequest: function (url) {
    let headParams = {
      "Content-Type": "application/json",
      channel: oucy.channel,
    };

    let authToken = localGet(oucy.tokenKey);
    if (authToken && authToken.length > 0) {
      headParams.Authorization = authToken;
    }

    return axios({
      method: "get",
      url: `${oucy.baseHost}${url}`,
      timeout: 1000 * 60, //请求超时 60秒
      headers: headParams,
    });
  },

  /*
   * 直接上传本地文件到阿里云oss
   * @param {本地文件} localFilePath
   */
  uploadLocalFile2oss: async function (file, fileName = uuidv4()) {
    // 获取阿里云签名
    let headParams = {
      channel: oucy.channel,
    };

    let authToken = localGet(oucy.tokenKey);
    if (authToken && authToken.length > 0) {
      headParams.Authorization = authToken;
    }

    let ossDir = "dynamic-boss";

    const data = {
      ossDir: ossDir,
      operationUv: oucy.getOperationUv(),
      operationOriginPlatform: 0,
    };
    const ossSign = await axios({
      method: "post",
      data: data,
      onUploadProgress: oucy.onUploadProgress,
      url: oucy.baseHost + "/client/thirdparty/aliyun/aliyunSignature",
      timeout: 1000 * 60, //请求超时 60秒
      headers: headParams,
    });

    // 获取签名后发起上传
    const headParams2 = {
      "Content-Type": "multipart/form-data",
    };

    // const blobSrc = URL.createObjectURL(file);
    let tmp_ext_name = file.name.substring(file.name.lastIndexOf(".") + 1);
    let ossFile_extName = tmp_ext_name ? tmp_ext_name : "";

    let ossFileName = Date.now().toString() + "." + ossFile_extName;

    const form = new FormData();
    form.append("Key", `${ossDir}/${ossFileName}`);
    form.append("OSSAccessKeyId", ossSign.AccessKeyId);
    form.append("Policy", ossSign.Policy);
    form.append("Signature", ossSign.Signature);
    form.append("Success_Action_Status", "200");
    form.append("file", file);

    oucy.uploadFileObject.file = file;

    const res2 = await axios({
      url: ossSign.Host,
      filePath: file,
      name: "file",
      data: form,
      method: "post",
      onUploadProgress: oucy.onUploadProgress,
      headers: headParams2,
    });

    // oucy.ossUrlPrefixVideo + '/' + ossFileName
    return ossFileName;
  },

  /**
   *
   *      视频上传专用
   *
   *
   * 直接上传网络文件到阿里云oss
   * @param {Object} file
   */
  uploadNetFile2oss: async function (file) {
    let me = this;
    return new Promise((resolve, reject) => {
      oucy
        .postRequest("/client/thirdparty/aliyun/uploadNetworkUrl", {
          networkUrl: netFilePath,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /**
   * 上传文件
   * @param file
   * @returns {AxiosPromise}
   */
  uploadRequest: function (file) {
    console.error("这里上传文件 可以优化");
    return oucy.uploadLocalFile2oss(file);
  },

  /**
   * 下载文件
   * @param url
   * @param params
   * @returns {AxiosPromise}
   */
  downloadRequest: function (url, params = {}) {
    let headParams = {
      "Content-Type": "application/json",
      channel: oucy.channel,
    };

    let authToken = localGet(oucy.tokenKey);
    if (authToken && authToken.length > 0) {
      headParams.Authorization = authToken;
    }
    params.operationUv = oucy.getOperationUv();
    params.operationOriginPlatform = 0;
    return axios({
      method: "post",
      url: `${oucy.baseHost}${url}`,
      timeout: 1000 * 60 * 3, //请求超时 60秒
      data: JSON.stringify(params),
      responseType: "blob",
      headers: headParams,
    });
  },
  onUploadProgress(progressEvent) {
    let complete =
      (((progressEvent.loaded / progressEvent.total) * 100) | 0) + "%";
    if (oucy.uploadFileObject.uploadProgress) {
      oucy.uploadFileObject.uploadProgress.message = complete;
      if ((((progressEvent.loaded / progressEvent.total) * 100) | 0) == 100) {
        setTimeout(() => {
          oucy.uploadFileObject.uploadProgress.close();
          oucy.uploadFileObject.uploadProgress = null;
        }, 500);
      }
    } else {
      oucy.uploadFileObject.uploadProgress = Notification({
        title: "上传文件中",
        // title: oucy?.uploadFileObject?.file?.name||'上传文件中',
        // dangerouslyUseHTMLString: true,
        message: `${complete}`,
        duration: 0,
      });
    }
  },
  /**
   * 人民币数字转大写金额
   * @param { Number} n 数字
   * @return String
   */
  ToString(n) {
    if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
      return "数据非法"; //判断数据是否大于0
    }

    var unit = "千百拾亿千百拾万千百拾元角分",
      str = "";
    n += "00";

    var indexpoint = n.indexOf("."); // 如果是小数，截取小数点前面的位数

    if (indexpoint >= 0) {
      n = n.substring(0, indexpoint) + n.substr(indexpoint + 1, 2); // 若为小数，截取需要使用的unit单位
    }

    unit = unit.substr(unit.length - n.length); // 若为整数，截取需要使用的unit单位
    for (var i = 0; i < n.length; i++) {
      str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i); //遍历转化为大写的数字
    }

    return str
      .replace(/零(千|百|拾|角)/g, "零")
      .replace(/(零)+/g, "零")
      .replace(/零(万|亿|元)/g, "$1")
      .replace(/(亿)万|壹(拾)/g, "$1$2")
      .replace(/^元零?|零分/g, "")
      .replace(/元$/g, "元整"); // 替换掉数字里面的零字符，得到结果
  },
  /**
   * 图片文件[大小][格式]检测
   * @param file
   * @returns {Promise<unknown>}
   */
  checkImgFile: function (file, size = 1) {
    return new Promise((resolve, reject) => {
      const isImage = file.type.indexOf("image") !== -1;
      const isLt1M = file.size / 1024 / 1024 < size;

      if (!isImage) {
        oucy.app.$notify.warning({
          title: "提示信息",
          message: "只能上传图片格式png、jpg、gif!",
          duration: 4500,
        });
        reject("只能上传图片格式png、jpg、gif!");
      }

      if (!isLt1M) {
        oucy.app.$notify.warning({
          title: "提示信息",
          message: `上传图片大小必须小于${size}M`,
          duration: 4500,
        });
        reject(`上传图片大小必须小于${size}M`);
      }

      resolve(file);
    });
  },

  /**
   * 图片[宽高比例]检测
   * @param file
   * @param squareWidth
   * @param squareHeight
   * @returns {Promise<unknown>}
   */
  checkImgSquare: function (file, squareWidth, squareHeight) {
    return new Promise((resolve, reject) => {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.onload = function () {
        let valid = true;
        if (squareWidth === 0 || squareHeight === 0) {
          valid = true;
        } else {
          valid = img.width / img.height === squareWidth / squareHeight;
        }
        if (valid) {
          resolve(file);
        } else {
          oucy.app.$notify.warning({
            title: "提示信息",
            message:
              "上传图片宽高比值必须等于 " + squareHeight + ":" + squareHeight,
            duration: 4500,
          });
          reject(
            "上传图片宽高比值必须等于 " + squareHeight + ":" + squareHeight
          );
        }
      };
      img.src = _URL.createObjectURL(file);
    });
  },

  /**
   * 图片[宽高比例]检测
   * @param file
   * @param squareWidth
   * @param squareHeight
   * @returns {Promise<unknown>}
   */
  checkImgSquare2: function (file, squareWidth, squareHeight) {
    return new Promise((resolve, reject) => {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.onload = function () {
        let valid = true;
        if (squareWidth === 0 || squareHeight === 0) {
          valid = true;
        } else {
          valid = img.width / img.height === squareWidth / squareHeight;
        }
        if (valid) {
          resolve(file);
        } else {
          reject(file);
        }
      };
      img.src = _URL.createObjectURL(file);
    });
  },
  checkUrl() {},
  /**
   * 初始化axios
   */
  initAxios: function () {
    if (oucy.loadingInstance) {
      oucy.loadingInstance.close();
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (oucy.loadingInstance) {
          oucy.loadingInstance.close();
        }
      }, 5000);
      axios.defaults.withCredentials = false;
      axios.interceptors.request.use(
        (config) => {
          if (oucy.loadingInstance) {
            oucy.loadingInstance.close();
          }
          // location.pathname!='/'.. 首页那几个tab 不显示 Loading
          oucy.loadingInstance =
            location.pathname != "/" &&
            location.pathname != "/askBuyList" &&
            location.pathname != "/furniture" &&
            location.pathname != "/material" &&
            location.pathname != "/company" &&
            location.pathname != "/card" &&
            config.Loading &&
            Loading.service({});
          return config;
        },
        (err) => {
          if (oucy.loadingInstance) {
            oucy.loadingInstance.close();
          }
          const errorInfo = "本次请求未能成功，请检查网络连接是否正常";
          MessageBox.alert(errorInfo, {
            center: true,
            roundButton: false,
          })
            .then((r) => {})
            .then(function () {
              return Promise.reject(new Error(errorInfo));
            });
        }
      );
      axios.interceptors.response.use(
        (data) => {
          if (oucy.loadingInstance) {
            oucy.loadingInstance.close();
          }
          if (
            data &&
            data.data &&
            data.data.type &&
            data.data.type === "application/vnd.ms-excel"
          ) {
            return data;
          } else {
            let resultData = data.data;
            // 调用云涵数的 直接返回
            if (
              data.config.url.indexOf("https://unicloud.lesoujia.com") != -1
            ) {
              return data.data;
            }
            // 用户操作记录的 如果不成功 也不用抛err
            else if (
              data.config.url.indexOf(
                "/client/elk/userrecordevent/receiveUserRecordEvent"
              ) != -1 ||
              data.config.url.indexOf(
                "/client/elk/useroperation/recordPageAction"
              ) != -1 ||
              data.config.url.indexOf("https://lesoujia.oss") > -1
            ) {
              return {};
            } else if (resultData) {
              if (resultData.statusCode == 0) {
                return resultData.result;
              }
              // 直播管理系统
              // 400101 输密码
              // 400102 是黑名单
              // 400103 无权限
              else if (
                resultData.statusCode == 400101 ||
                resultData.statusCode == 400102 ||
                resultData.statusCode == 400103 ||
                resultData.statusCode == 400104
              ) {
                return Promise.reject(resultData);
              } else if (resultData.statusCode == 401) {
                oucy.removeToken();
                // 首页不要弹登框
                if (window.location && window.location.pathname == "/") {
                  return Promise.reject(resultData);
                }
                oucy.app.eventBus.$emit(oucy.msgKeys.LOCAL_LOGIN, null);
                return Promise.reject(new Error(resultData.errMessage));
              } else if (resultData.statusCode == 304) {
                oucy.app.$router.replace("/fullaccount");
              } else {
                console.log("data.config.url", data.config.url);
                oucy.isICPAudit &&
                  oucy.notErrUrl.indexOf(data.config.url.split("com")[1]) ==
                    -1 &&
                  Notification.warning({
                    title: "提示信息",
                    message: resultData.errMessage,
                    duration: 4500,
                  });
                if (oucy.loadingInstance) {
                  oucy.loadingInstance.close();
                }
                return Promise.reject(new Error(resultData.errMessage));
              }
            } else {
              const errorInfo = "系统出错了，请稍后再试";
              oucy.isICPAudit &&
                Notification.warning({
                  title: "提示信息",
                  message: errorInfo,
                  duration: 4500,
                });
              return Promise.reject(new Error(errorInfo));
            }
          }
        },
        (err) => {
          if (oucy.loadingInstance) {
            oucy.loadingInstance.close();
          }
          console.log(err);
          if (err && err.message == "userCancel") {
            return Promise.reject(err);
          }
          const errorInfo = "本次请求未能成功，请检查网络连接是否正常。";
          oucy.isICPAudit &&
            Notification.error({
              title: "提示信息",
              message: errorInfo,
              duration: 4500,
            });
          return Promise.reject(new Error(errorInfo));
        }
      );
      resolve("axios初始化完成");
    });
  },

  /**
   * 解析url参数
   * @param name
   * @returns {string | null}
   */
  getUrlKey: function (name) {
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
          location.href
        ) || [, ""])[1].replace(/\+/g, "%20")
      ) || null
    );
  },
  /**
   * 获取名称
   * @param  {[type]} userInfoEntity [description]
   * @return {[type]}                [description]
   */
  getUserName(userInfoEntity) {
    let name = "";
    if (userInfoEntity.detailNickname) {
      name = userInfoEntity.detailNickname;
    } else if (userInfoEntity.userPhoneNumber) {
      name = userInfoEntity.userPhoneNumber;
    } else if (userInfoEntity.userDcodeUsername) {
      name = userInfoEntity.userDcodeUsername;
    } else {
      name = "江小鱼";
    }
    if (name.length > 8) {
      return { nameSubstr: name.substr(0, 7) + "...", name: name };
    } else {
      return { name: name, nameSubstr: name };
    }
  },
  /**
   * 初始化自定义js函数库
   */
  initJAVASCRIPT: function () {
    if (!Array.prototype.subsetTo) {
      Array.prototype.subsetTo = function (arr) {
        return this.every((v) => arr.includes(v));
      };
    }
    if (!Array.prototype.subObjectTo) {
      Array.prototype.subObjectTo = function (arr) {
        return this.every(function (subItem) {
          let has = false;
          for (let i = 0; i < arr.length; i++) {
            if (oucy.isObjectValueEqual(arr[i], subItem)) {
              has = true;
              break;
            }
          }
          return has;
        });
      };
    }
    if (!String.prototype.Trim) {
      String.prototype.Trim = function () {
        return this.replace(/(^\s*)|(\s*$)/g, "");
      };
    }
    if (!String.prototype.LTrim) {
      String.prototype.LTrim = function () {
        return this.replace(/(^\s*)/g, "");
      };
    }
    if (!String.prototype.RTrim) {
      String.prototype.RTrim = function () {
        return this.replace(/(\s*$)/g, "");
      };
    }
    // 格式化日期时间
    Date.prototype.format = function (fmt) {
      var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "H+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds(), //毫秒
      };

      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }

      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }

      return fmt;
    };

    /**
     * Created by zhangjianchun on 21/4/21.
     */

    /**
     * 对Date的扩展，在Date上进行时间上的+加-减
     * new Date('2021-4-21 10:00').dateAdd('d',1) ==> 2021-4-22 10:00
     * new Date('2021-4-21 10:00').dateAdd('d',-1) ==> 2021-4-20 10:00
     */
    Date.prototype.DateAdd = function (strInterval, Number) {
      let dtTmp = this;

      switch (strInterval) {
        // 秒
        case "s":
          return new Date(Date.parse(dtTmp) + 1000 * Number);
        // 分钟
        case "m":
          return new Date(Date.parse(dtTmp) + 60000 * Number);
        // 时
        case "h":
        case "H":
          return new Date(Date.parse(dtTmp) + 3600000 * Number);
        // 天
        case "d":
          return new Date(Date.parse(dtTmp) + 86400000 * Number);
        // 周 7天
        case "w":
          return new Date(Date.parse(dtTmp) + 86400000 * 7 * Number);
        // 季 3个月
        case "q":
          return new Date(
            dtTmp.getFullYear(),
            dtTmp.getMonth() + Number * 3,
            dtTmp.getDate(),
            dtTmp.getHours(),
            dtTmp.getMinutes(),
            dtTmp.getSeconds()
          );
        // 月
        case "M":
          return new Date(
            dtTmp.getFullYear(),
            dtTmp.getMonth() + Number,
            dtTmp.getDate(),
            dtTmp.getHours(),
            dtTmp.getMinutes(),
            dtTmp.getSeconds()
          );
        // 年
        case "y":
          return new Date(
            dtTmp.getFullYear() + Number,
            dtTmp.getMonth(),
            dtTmp.getDate(),
            dtTmp.getHours(),
            dtTmp.getMinutes(),
            dtTmp.getSeconds()
          );
      }
    };
  },

  /**
   * 判断两个对象是否相等，---------该方法还不严谨
   * @param obj1
   * @param obj2
   * @returns {boolean}
   */
  isObjectValueEqual: function (obj1, obj2) {
    let o1 = obj1 instanceof Object;
    let o2 = obj2 instanceof Object;
    if (!o1 || !o2) {
      return obj1 === obj2;
    }
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (let o in obj1) {
      let t1 = obj1[o] instanceof Object;
      let t2 = obj2[o] instanceof Object;
      if (t1 && t2) {
        return oucy.isObjectValueEqual(obj1[o], obj2[o]);
      } else if (obj1[o] !== obj2[o]) {
        return false;
      }
    }
    return true;
  },

  /**
   * 初始化消息队列
   */
  initMQTT: function () {
    // let mqtt = sessionGet('mqtt')
    // if(mqtt){
    //     console.log('会话有MQTT')
    //     // oucy.mqtt=mqtt
    //     console.log(mqtt)
    //     // return
    // }
    return new Promise((resolve, reject) => {
      // if(!oucy.mqtt){
      oucy
        .postRequest("/client/auth/mqtt/queryMqttParams", {
          // clientId: oucy.getUUID(),
          // uuid: oucy.getUUID(),
        })
        .then((res) => {
          oucy.mqttArgs = res;
          oucy.mqtt = new Paho.Client(
            oucy.mqttArgs.host,
            oucy.mqttArgs.portWsMqtt,
            oucy.mqttArgs.clientId
          );
          oucy.mqtt.onConnectionLost = oucy.onConnectionLost;
          oucy.mqtt.onMessageArrived = oucy.onMessageArrived;
          oucy.mqttConnect();
          oucy.ossUrl = res.ossPrefix;
          let clientIds = oucy.mqttArgs.clientId.split("@@@");
          if (clientIds && clientIds.length) {
            oucy.thisUUID = clientIds[1];
          }
          resolve(oucy.mqtt);
        })
        .catch((err) => {
          console.log(err);
          oucy.isICPAudit &&
            Notification.error({
              title: "提示信息",
              message: "无法连接建立与服务器的连接，请检查网络",
              duration: 4500,
            });
          reject(err);
        });

      // }else{
      // console.log(oucy.mqtt)
      // }
    });
  },
  resetMQTT(mqttData) {
    oucy.mqttArgs = mqttData;
    oucy.mqtt = new Paho.Client(
      oucy.mqttArgs.host,
      oucy.mqttArgs.portWsMqtt,
      oucy.mqttArgs.clientId
    );
    oucy.mqtt.onConnectionLost = oucy.onConnectionLost;
    oucy.mqtt.onMessageArrived = oucy.onMessageArrived;
    oucy.mqttConnect();
    let clientIds = oucy.mqttArgs.clientId.split("@@@");
    if (clientIds && clientIds.length) {
      oucy.thisUUID = clientIds[1];
    }
  },
  mqttConnect: function () {
    oucy.mqtt.connect({
      userName:
        "Signature|" + oucy.mqttArgs.accessKey + "|" + oucy.mqttArgs.instanceId,
      password: CryptoJS.HmacSHA1(
        oucy.mqttArgs.clientId,
        oucy.mqttArgs.secretKey
      ).toString(CryptoJS.enc.Base64),
      useSSL: oucy.mqttArgs.useTLS,
      timeout: 3,
      onSuccess: function () {
        // Notification({
        //     title: '提示信息',
        //     message: '基于p2p的连接已经建立',
        //     type: 'success',
        //     duration: 2000
        // });
        //oucy.mqttSubscriber(oucy.mqttArgs.topic);
        console.log("mqttConnect onSuccess");
        // sessionSet('mqtt',oucy.mqtt)
      },
      mqttVersion: 4,
      cleanSession: oucy.mqttArgs.cleansession,
      onFailure: function (message) {
        oucy.isICPAudit &&
          Notification({
            title: "提示信息",
            message: "连接失败，正在重新呼叫...",
            type: "success",
            duration: 2000,
          });
        setTimeout(oucy.mqttConnect, oucy.mqttArgs.reconnectTimeout);
      },
    });
  },

  mqttSubscriber: function (mqttTopic) {
    const me = this;
    if (oucy.mqtt && oucy.mqtt.isConnected() && mqttTopic) {
      console.log("mqttSubscriber", mqttTopic);
      setTimeout(function () {
        oucy.mqtt.subscribe(mqttTopic, {
          qos: 0,
          onSuccess: function () {
            Notification({
              title: "提示信息",
              message: "已连接服务器!",
              type: "success",
              duration: 4000,
            });
          },
          onFailure: function () {
            oucy.isICPAudit &&
              Notification({
                title: "提示信息",
                message: "通信协议建立失败，正在重试...!",
                type: "info",
                duration: 2000,
              });
            oucy.mqttSubscriber(mqttTopic);
          },
        });
      }, 2000);
    }
  },

  mqttUnsubscriber: function (mqttTopic) {
    const me = this;
    if (oucy.mqtt && oucy.mqtt.isConnected() && mqttTopic) {
      setTimeout(function () {
        oucy.mqtt.unsubscribe(mqttTopic, {
          qos: 0,
          onSuccess: function () {
            Notification({
              title: "提示信息",
              message: "已连接服务器!",
              type: "success",
              duration: 4000,
            });
          },
          onFailure: function () {
            oucy.isICPAudit &&
              Notification({
                title: "提示信息",
                message: "通信协议建立失败，正在重试...!",
                type: "info",
                duration: 2000,
              });
            oucy.mqttUnsubscriber(mqttTopic);
          },
        });
      }, 2000);
    }
  },

  onMessageArrived: function (message) {
    const payload = message.payloadString;
    console.log("收到MQTT：", JSON.parse(message.payloadString));
    if (payload) {
      const jsonMessage = JSON.parse(payload);
      const msgKey = jsonMessage.messageKey;
      const msgVal = jsonMessage.messageValue;
      if (!!msgKey && !!msgVal) {
        oucy.app.eventBus.$emit(msgKey, msgVal);
      }
    }
  },
  onConnectionLost: function () {
    /*        if(oucy.isHidden){
            oucy.confirm('通信协议已断开，刷新页面重新连接').then(res=>{
                location.reload();
            },err=>{})
            return
        }*/

    setTimeout(function () {
      if (oucy.mqtt) {
        oucy.isICPAudit &&
          Notification({
            title: "提示信息",
            message: "失去服务器连接，正在重新连接..." + oucy.getUUID(),
            type: "success",
            duration: 2000,
          });
        oucy.mqttConnect();
      }
    }, oucy.mqttArgs.reconnectTimeout);
  },
  objectToStr(object) {
    let str = "";
    for (let v in object) {
      str += object[v] + ",";
    }
    return str;
  },
  /**
   * 验证身份证号码
   * @param  {[type]}  card [description]
   * @return {Boolean}      [description]
   */
  isCardNo(card) {
    if (!card) {
      return false;
    } else {
      // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      return reg.test(card);
    }
  },
  /**
   * 中国人的姓名长度一般都是2-4 个中文
   * @param  {[type]}  name [description]
   * @return {Boolean}      [description]
   */
  isName(name) {
    if (!name) {
      return false;
    } else {
      var regName = /^[\u4e00-\u9fa5]{2,4}$/;
      return regName.test(name);
    }
  },
  confirm(text, title = "提示") {
    return new Promise((resolve, reject) => {
      MessageBox.confirm(text, title, {
        dangerouslyUseHTMLString: true,
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // type: 'warning'
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // 关键字过虑
  keywordFiltr(keys) {
    return new Promise((resolve, reject) => {
      if (!keys) {
        resolve();
        return;
      } else if (typeof keys == "object") {
        keys = oucy.objectToStr(keys);
      }
      stopword
        .assertStopwordsList({
          paramList: JSON.stringify(oucy.strToArray(keys, ",")),
        })
        .then((res) => {
          if (res && res.status == 0) {
            // 禁
            let text = `包含<span style="color:#F00">${res.param.valueOf()}</span>,关键字，禁止提交`;
            oucy.confirm(text).then(
              (res) => {
                reject(res);
              },
              (err) => {
                reject(err);
              }
            );
          } else if (res && res.status == 2) {
            // 替
            let text = `关键字"<span style="color:#F00">${res.param.valueOf()}</span>",将会被替换，是否继续？`;
            oucy.confirm(text).then(
              (res) => {
                resolve(res);
              },
              (err) => {
                reject(err);
              }
            );
          } else {
            resolve();
          }
        });
    });
  },
  // 获取时间差 是否显示时间
  getShowDate(list, i, k) {
    if (!list || list.length == 1 || !i || !k) {
      return true;
    } else {
      let a = oucy.strReplace(list[i][k], "-", "/");
      let b = oucy.strReplace(list[i - 1][k], "-", "/");
      return (
        new Date(a).getTime() / 1000 - new Date(b).getTime() / 1000 > 60 * 3
      );
    }
  },
  // 获取时间差 是否显示时间 反转
  getShowDateReverse(list, i, k) {
    if (!list || list.length == 1 || list.length * 1 - 1 == i || !k) {
      return true;
    } else {
      let a = oucy.strReplace(list[i][k], "-", "/");
      let b = oucy.strReplace(list[i + 1][k], "-", "/");
      return (
        new Date(a).getTime() / 1000 - new Date(b).getTime() / 1000 > 60 * 3
      );
    }
  },
  // 格式化时间
  timestampFormat(dateTimeStamp) {
    dateTimeStamp = oucy.strReplace(dateTimeStamp, "-", "/");
    // console.log(dateTimeStamp)
    // dateTimeStamp = dateTimeStamp.replace(/-/g,'/')

    let timestamp = new Date(dateTimeStamp).getTime() / 1000;
    function zeroize(num) {
      return (String(num).length == 1 ? "0" : "") + num;
    }

    let curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
    let timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

    let curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
    let tmDate = new Date(timestamp * 1000); // 参数时间戳转换成的日期对象

    let Y = tmDate.getFullYear(),
      m = tmDate.getMonth() + 1,
      d = tmDate.getDate();
    let H = tmDate.getHours(),
      i = tmDate.getMinutes(),
      s = tmDate.getSeconds();

    if (timestampDiff < 60) {
      // 一分钟以内
      return "刚刚";
    } else if (timestampDiff < 3600) {
      // 一小时前之内
      return Math.floor(timestampDiff / 60) + "分钟前";
    } else if (
      curDate.getFullYear() == Y &&
      curDate.getMonth() + 1 == m &&
      curDate.getDate() == d
    ) {
      return "今天" + " " + zeroize(H) + ":" + zeroize(i);
    } else {
      let newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
      if (
        newDate.getFullYear() == Y &&
        newDate.getMonth() + 1 == m &&
        newDate.getDate() == d
      ) {
        return "昨天" + " " + zeroize(H) + ":" + zeroize(i);
      }
      // 和微信一样4天内的为周
      else if (
        newDate.getFullYear() == Y &&
        newDate.getMonth() + 1 == m &&
        newDate.getDate() < d * 1 + 4
      ) {
        let w = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ][tmDate.getDay()];
        return w + " " + zeroize(H) + ":" + zeroize(i);
      } /*else if ( curDate.getFullYear() == Y ) { // 年内
                return  zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
            } */ else {
        return (
          Y +
          "年" +
          zeroize(m) +
          "月" +
          zeroize(d) +
          "日 " +
          zeroize(H) +
          ":" +
          zeroize(i)
        );
      }
    }
  },
  getUserHasVip() {
    let loginUser = localGet(oucy.userInfoKey) || {};
    if (
      oucy.getToken() &&
      loginUser.userMemberCardLevelName &&
      loginUser.userMemberCardLevelValue &&
      loginUser.userMemberCardLevelValue
    ) {
      return true;
    } else {
      return false;
    }
  },
  /**
   * 判断是否为空
   */
  isNull(val) {
    if (val instanceof Array) {
      if (val.length === 0) return true;
    } else if (val instanceof Object) {
      if (JSON.stringify(val) === "{}") return true;
    } else if (typeof val === "number") {
      return false;
    } else {
      if (
        val === "null" ||
        val == null ||
        val === "undefined" ||
        val === undefined ||
        val === ""
      )
        return true;
      return false;
    }
    return false;
  },
};

export default oucy;
