<!-- 会员价 -->
<template>
    <div class="VIPPrice">
        <span class="text pointer" v-if="!loginUser.id" @click.stop="openlogin">登录查看会员价</span>
        <span class="text" v-else-if="!loginUser.userMemberCardNumber">升级VIP查看会员价</span>
        <div class="df-ai-c" v-else-if=" data.spuMemberSinglePrise && (data.spuMemberSinglePrise*1) != NaN && (data.spuMemberSinglePrise*1)>0"><img src="@/assets/images/VIPPrice.png" alt="" class="VIPPriceImg"><span class="text">￥{{data.spuMemberSinglePrise}}</span></div>
        <div v-else class="text">会员价:咨询客服</div>
    </div>
</template>
<script>
import { localSet, localGet, localDel } from "@/store/store";
export default {
    name: "VIPPrice",
    props: {
        data: {
            type: Object,
            default: function() {
                return {}
            }
        },
    },
    data() {
        return {
            hasClick: false,
            loginUser:{}

        }
    },
    mounted() {
        this.loginUser = localGet('loginUser')||{}
    },
    methods: {
        openlogin(){
            this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.LOCAL_LOGIN, null);
        }
    },
}
</script>
<style scoped lang="less">
.VIPPrice {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    // line-height: 16px;
    color: #B35C1F;
    background: linear-gradient(269deg, #FFF5D2 0%, #FDF4D2 91%, #FDF4D2 100%);
    display: flex;
    align-items: center;
    /*display: inline-block;*/
}
.text{
    display: inline-block;
    padding: 4px 8px;
}
.pointer{
    width: 100%;
}
.VIPPriceImg {
    height: 24px;
}
</style>
