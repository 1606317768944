import oucy from '@/util/oucyUtil.js'
// 
const logicSite = {
	/**
     * 查询站点
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllLogicSite: (params) => {
        return oucy.postRequest('/client/enterprise/logicsite/getAllLogicSite', params)
    },

}

export default logicSite
