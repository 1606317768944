import oucy from '@/util/oucyUtil.js'
// 参数模板
const furnitureSpecificationOption = {
	/**
     * 查询用户的所有模板数据 [客户端调用]（分页）
	 * @param  {object} 请求的参数
	 * @return {Promise} 返回一个Promise
	 */
    getAllEnterpriseInfo: (params={}) => {
        return oucy.postRequest('/client/product/furniturespecificationoption/getAllEnterpriseInfo', params)
    }
}

export default furnitureSpecificationOption
