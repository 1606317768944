<!-- 评论框 -->
<template>
    <div class="box">
        <span v-if="hasNest" class="triangle"></span>
        <div class="commentBox p-t-10 m-t-10" v-for="(v,i) of obj.content" :key="i">
            <div class="left">
                <img :src="$oucy.ossUrl + v.commentUserAvata" class="pic" v-if="v.commentUserAvata">
            </div>
            <div class="right">
                <div class="name df-jc-s-b "><span>{{v.commentUserNickname}}</span>
                    <span class="c-2 f14 pointer" @click="setParentCommentId(v)" v-if="parentCommentId==v.id">收起回复</span>
                    <span class="c-2 f14 pointer" @click="setParentCommentId(v)" v-else>回复</span>
                </div>
                <div class="time">{{v.commentCreatetime}}</div>
                <div class="comment">
                <span class="commentReply" v-if="v.commentReply">回复 {{v.commentReply}}</span>{{v.commentContent}}
                </div>
                <div class="spuList">
                    
                    <div v-for="(item,index) in v.searchSpuList" :key="item.id" class="spuList_item" v-if="v.searchSpuList">
                        <spu :data="item" :isShowStock="false"></spu>
                        <!-- {{item }} -->
                    </div>
                    <div v-for="(item,index) in v.enterpriseDetailList" :key="index" class="spuList_item" v-if="v.enterpriseDetailList">
                        <enterprise :data="item"></enterprise>
                    </div>
                </div>
                <div class="m-t-10" v-if="parentCommentId==v.id">
                    <inputBox2 ref="inputBox2" @callback="callback" :hasNest="hasNest" placeholder="请输入回复内容" btnText="回复"></inputBox2>
                </div>
                <div v-if="v.list && v.list.content" class="commentBox2">
                    <comment3 :hasNest="true" :ReturnsObject="v.list" @callback="callback" :moreParentCommentId="v.id" :parentCommentId="parentCommentId" @clickMore="clickMore" @setParentCommentId="setParentCommentId"></comment3>
                </div>
            </div>
        </div>
        <div class="text-center f12 c-2 m-t-25 pointer" @click="clickMore(obj)" v-if="hasNest && obj.content && obj.content.length<obj.totalElements">更多回复 <i class="el-icon-d-arrow-right" style="transform: rotate(90deg)"></i></div>
    </div>
</template>
<script>
    import { buyAsk} from "@/service"
export default {
    name: "comment3",
    props: {
        ReturnsObject: {
            type: [Array, Object],
            default: function() {
                return {}
            },
        },
        btnText: {
            type: String,
            default: '回复',
        },
        placeholder: {
            type: String,
            default: '请输入评论内容',
        },
        // 是否嵌套
        hasNest: {
            type: Boolean,
            default: false,
        },
        // 回复的父级Id
        parentCommentId: {
            type: String,
            default: null,
        },
        // 请求更多父级Id
        moreParentCommentId: {
            type: String,
            default: null,
        },

    },
    data() {
        return {
            isShowInput: true,
            isShowComment: true,
            textarea: '',
            imageList: [],
            previewSrcList: [],
        }
    },
    computed: {
        obj: function() {
            return this.ReturnsObject
        }
    },
    mounted() {

    },
    methods: {
        setParentCommentId(v) {
            this.$emit('setParentCommentId', v)
            console.log('点了:', v.id)
        },
        uploadImageBack(v) {
            this.imageList.push(v)
            this.previewSrcList.push(this.$oucy.ossUrl + v)
        },
        deletIme(i) {
            this.imageList.splice(i, 1)
            this.previewSrcList.splice(i, 1)
        },
        getSrcList(list) {
            let srcList = []
            for (let v of list) {
                srcList.push(this.$oucy.ossUrl + v.cardPersonCommentAttachUrl)
            }
            return srcList
        },
        reset() {
            // this.parentCommentId = null
            console.log(this.$refs.inputBox2)
            if(this.$refs.inputBox2 && typeof this.$refs.inputBox2.reset =='furniture'){
                this.$refs.inputBox2.reset()
            }
        },
        callback(v) {
            console.log(v)
            if (this.parentCommentId) {
                v.parentCommentId = this.parentCommentId
            }
            this.$emit('callback', v)
        },
        clickMore(v){
            console.log(v)
            let obj={
                limit: this.obj.size,
                start: this.obj.number+1,
                buyAskId :this.$route.query.id,
                parentCommentId :this.moreParentCommentId,
            }
            buyAsk.queryBuyAskComment(obj).then(res => {
                if(res && res.content){
                    this.obj.content.push(...res.content)
                    this.obj.number=res.number
                }else{
                    this.$alert('后台没有数据返回')
                }
                // this.ReturnsObject = res
            })

            // this.$emit('clickMore', v)
        },
    },
}
</script>
<style scoped lang="less">
.commentBox {
    display: flex;
}

.pic {
    width: 42px;
    height: 42px;
    border-radius: 42px;
}

.right {
    flex: 1;
    padding-left: 10px
}

.name {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.time {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #888888;
}

.comment {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555C68;
}

.commentBox2 {
    background: #F6F7F8;
    width: 100%;
    padding: 0px 20px 10px;
    position: relative;
    box-sizing: border-box;
}

.cardName {
    display: inline-block;
    color: #fff;
    padding: 1px 6px;
    background: #F29F1C;
    border-radius: 2px;
}

.triangle {
    position: absolute;
    left: 20px;
    top: -16px;
    border-top: 8px transparent solid;
    border-left: 8px transparent solid;
    border-right: 8px transparent solid;
    border-bottom: 8px #F6F7F8 solid;
}

.spuList {
    display: flex;
    flex-wrap: wrap;
}

.spuList_item {
    width: 200px;
    // height: 288px;
    margin: 20px;
    position: relative;
}

/deep/ .spuList_item img,
/deep/ .spuList_item .item img {
    width: 150px;
    height: auto;
}

.enterpriseList {
    display: flex;
}

.box {
    position: relative;
}

.triangle {
    position: absolute;
    left: 20px;
    top: -16px;
    border-top: 8px transparent solid;
    border-left: 8px transparent solid;
    border-right: 8px transparent solid;
    border-bottom: 8px #F6F7F8 solid;
}
.commentReply{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #555C68;
    background: #E5ECF4;
    border-radius: 2px;
    display: inline-block;
    padding:0 6px;
}
</style>